import React from 'react'
import {useNavigate} from 'react-router-dom'

const Button = ({buttonText, cancelButton, onClickHandler}) => {
    const buttonClass = cancelButton ? 'btn btn-danger join_room_cancel_button' : 'btn btn-primary join_room_success_button'

    return (
        <button onClick={onClickHandler} className={buttonClass}>
            {buttonText}
        </button>
    )
}

const JoinRoomButton = ({handleJoinToRoom, isRoomHost}) => {
    const successButtonText = isRoomHost ? 'Host':'Join'

    let navigate = useNavigate()

    const pushToIntroductionPage = () => {
        navigate('/')
    }
  return (
    <div className='join_room_buttons_container d-flex justify-content-center gap-3 w-100 mt-4'>
        <Button 
            buttonText={successButtonText}
            onClickHandler={handleJoinToRoom}
        />
        <Button
            buttonText='Cancel'
            cancelButton
            onClickHandler={pushToIntroductionPage}
        />
    </div>
  )
}

export default JoinRoomButton