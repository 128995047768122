import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class SubmittedVsNotSubmitted extends Component {

  componentDidMount() {
   // console.log(this.props.submitted, this.props.notSubmitted)
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
    // if(prevProps) {
    //   this.chart.dispose();
    // }
    this.initChart();
    
  }
  
  initChart(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("submitted-vs-not-submitted", am4charts.PieChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    
    chart.data = [
        {
            category: "Submitted",
            values: this.props.submitted?this.props.submitted:0
          },
          {
            category: "Not Submitted",
            values: this.props.notSubmitted?this.props.notSubmitted:0
          }
        
      ];


      chart.innerRadius = am4core.percent(40);
      var pieSeries = chart.series.push(new am4charts.PieSeries3D());
        pieSeries.dataFields.value = "values";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;

        // Disabling labels and ticks on inner circle
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

    // Disable sliding out of slices
    pieSeries.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    pieSeries.slices.template.states.getKey("hover").properties.scale = 1.1;
    pieSeries.colors.list = [
        am4core.color("#8067dc"),
        am4core.color("#dc67ce"),
    ];

    chart.legend = new am4charts.Legend();

        chart.legend.markers.template.paddingTop= 3;
        chart.legend.position = 'bottom'
        // chart.legend.markers.template.width = 13;
        // chart.legend.markers.template.height = 13;
        
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.height = 14;
        marker.width = 14;
        chart.legend.itemContainers.template.paddingTop = 3 ;
        chart.legend.itemContainers.template.paddingBottom = 0;
        chart.legend.itemContainers.template.fontSize = 14;
            
  }
    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="submitted-vs-not-submitted" style={{ width: "100%", height: "270px", margin: "0 auto" }} />;
  }

 
}

export default SubmittedVsNotSubmitted;
