import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange, adjacents }) => {
  const renderPageNumbers = () => {
    const start = Math.max(1, currentPage - adjacents);
    const end = Math.min(totalPages, currentPage + adjacents);

    const pages = [];
    for (let i = start; i <= end; i++) {
      pages.push(
        <li key={i} className={i === currentPage ? 'active' : ''}>
          <button className='page-link' onClick={() => onPageChange(i)}>{i}</button>
        </li>
      );
    }
    return pages;
  };
  

  return (
    <ul className="pagination justify-content-center m-0">
      <li className='page-item'>
        <button className='page-link' onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
        <i class="bi bi-caret-left-square-fill"></i>
        </button>
      </li>
      {renderPageNumbers()}
      <li>
        <button className='page-link' onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
        <i class="bi bi-caret-right-square-fill"></i>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
