import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";

// Import slick css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// env import
import {environment} from '../environment';

import {callAxios, convertDateTimeFromIso} from "../utils/utils";

import { app, database, auth } from "../firebaseConfig";

import {
    collection,
    query,
    where,
    onSnapshot,
    documentId
  } from "firebase/firestore";

  import { useLocation, useNavigate } from 'react-router-dom'
import { createPrivateConversation } from "../utils/twilioConversation";



export const BdmCamList = (props) => {
    let navigate = useNavigate();
    const {state} = useLocation();
    const pageType = props.pageType;
    const [allOnlineOfflineUsers, setAllOnlineOfflineUsers] = useState([]);
    const [camUsers, setCamOnlineUsers] = useState([]);
    const [bdmUsers, setBdmOnlineUsers] = useState([]);
    const [height, setHeight] = useState();

    const [BDMUSERS, setBdmUsers] = useState([]);
    const [CAMUSERS, setCamUsers] = useState([]);

    const ref= useRef(null)

    const queryConstraintsCAM = [];
    const queryConstraintsBDM = [];

    queryConstraintsCAM.push(where('userType', '==', 'CAM'));
    queryConstraintsCAM.push(where('status', '==', 'Online'));
    queryConstraintsCAM.push(where('isDeleted', '==',0));
    const camQuery = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraintsCAM); 

    queryConstraintsBDM.push(where('userType', '==',  'BDM'));
    queryConstraintsBDM.push(where('status', '==', 'Online'));
    queryConstraintsBDM.push(where('isDeleted', '==',0));
    const bdmQuery = query(collection(database,environment.REACT_APP_USERS_DB), ...queryConstraintsBDM);   
    
    useEffect(() => {
        setHeight(ref.current.clientHeight) ;
        
          const getCAMusers = async () => {

            onSnapshot(camQuery, (querySnapshot) => {
                let cusers = [];
                querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                data["id"] = doc.id;
                cusers.push({ ...doc.data(), id: doc.id });
              });
              setCamUsers(cusers);
            });
    
            }

            getCAMusers();

            const getBDMusers = async () => {

                onSnapshot(bdmQuery, (querySnapshot) => {
                    let busers = [];
                    querySnapshot.forEach((doc) => {
                    const id = doc.id;
                    const data = doc.data();
                    data["id"] = doc.id;
                    busers.push({ ...doc.data(), id: doc.id });
                  });
                  setBdmUsers(busers);
                });
        
                }
            
                getBDMusers();
    },[])
//    console.log(BDMUSERS,CAMUSERS)

    var settings = {
       // infinite: items.length > 3,
        arrows: true,
        dots: false,
        infinite: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 4,
       // slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                }
            }
        ]
    }
    const navigateToProfile = (item)=>{
       navigate('/profile', { state: {user:item}});
       } 

    const navigateToChat = (item) =>{
       // console.log(identity)
       createPrivateConversation(item.name, item.email).then(function(conversation){
        navigate('/chats');
       }).catch((err)=>{
        console.log("something went wrong Error::",err)
       })
       
    }
    const camUsersHtml = CAMUSERS.map((item, index) =>{
        const image = !item.profile_image?require("../resources/images/Avatar.png"):item.profile_image;
        const fireBaseTime = new Date(
            item.loggedInTime.seconds * 1000 + item.loggedInTime.nanoseconds / 1000000
          );
          const dateNew = fireBaseTime.getDate() + ' '+ fireBaseTime.toLocaleString('default', { month: 'long' }) +' '+fireBaseTime.getFullYear();
          const atTime = fireBaseTime.toLocaleTimeString();
          let since =  atTime;
        return (
            <div className='user-slider-wrapper d-flex gap-2 p-2 rounded-1 overflow-hidden position-relative' key={"cam-listing--"+index}>
                 
                <div className='user-img position-relative' style={{ height: '65px', width: '65px', minWidth: '65px' }}>
                    <img className='img-fluid rounded-circle border border-secondary' src={image} alt="User"/>
                    <div className="user-status position-absolute rounded-circle" style={{ backgroundColor: 'green' }}></div>
                </div>
                <div className='user-detail text-truncate'>
                <div className="btn-wrapper d-flex align-items-center justify-content-center position-absolute top-0 start-0 bottom-0 end-0">
                <button type="button" className="btn btn-sm btn-danger rounded-pill px-3 fw-medium" onClick={() =>{navigateToChat(item)}}><i class="bi bi-chat-dots-fill" ></i> Chat</button>
                <button type="button" className="btn btn-sm btn-success rounded-pill px-3 fw-medium ms-2" onClick={() =>{navigateToProfile(item)}}><i class="bi bi-person-circle"></i> Profile</button>
                </div>
                    <div className='name text-capitalize text-truncate'>{item.name}</div>
                    <div className='time small text-capitalize'>Since {since}</div>
                    <div className='date small text-capitalize'>Date {dateNew}</div>
                </div>
            </div>
        );
    })
    
    const bdmUsersHtml = BDMUSERS.map((item, indexx) =>{

        const image = !item.profile_image?require("../resources/images/Avatar.png"):item.profile_image;
        const fireBaseTime = new Date(
            item.loggedInTime.seconds * 1000 + item.loggedInTime.nanoseconds / 1000000
          );
          const dateNew = fireBaseTime.getDate() + ' '+ fireBaseTime.toLocaleString('default', { month: 'long' }) +' '+fireBaseTime.getFullYear();
          const atTime = fireBaseTime.toLocaleTimeString();
          let since =  atTime;
        return (
            
            <div className='user-slider-wrapper d-flex gap-2 p-2 rounded-1 overflow-hidden position-relative' key={"bdm-online-listing"+indexx} >
                
                
            <div className='user-img position-relative' style={{ height: '65px', width: '65px', minWidth: '65px' }}>
                <img className='img-fluid rounded-circle border border-secondary' src={image} alt="User" />
                <div className="user-status position-absolute rounded-circle" style={{ backgroundColor: 'green' }}></div>
            </div>
            <div className='user-detail text-truncate'>
            <div className="btn-wrapper d-flex align-items-center justify-content-center position-absolute top-0 start-0 bottom-0 end-0">
                <button type="button" className="btn btn-sm btn-danger rounded-pill px-3 fw-medium" onClick={() =>{navigateToChat(item)}}><i class="bi bi-chat-dots-fill" ></i> Chat</button>
                <button type="button" className="btn btn-sm btn-success rounded-pill px-3 fw-medium ms-2" onClick={() =>{navigateToProfile(item)}}><i class="bi bi-person-circle"></i> Profile</button>
                </div>
                <div className='name text-capitalize text-truncate'>{item.name}</div>
                <div className='time small text-capitalize'>Since {since}</div>
                <div className='date small text-capitalize'>Date {dateNew}</div>
            </div>
            </div>
        );
    })
   

    return (
        <div className="bdm-cam-wrapper mb-3" id="pills-tab" role="tablist">
            <div className='row g-2 g-lg-4'>
                <div className='col-lg-1 col-md-2 btn-cam-wrapper'>
                <button className={`${ pageType === "lead-listing"?"active " : null } btn btn-secondary rounded-1 w-100 h-100 lh-sm py-2`} id="cam-btn" data-bs-toggle="pill" data-bs-target="#cam-btn1" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{CAMUSERS.length} Online CAM</button>
                </div>
                <div className='col-lg-10 col-md-8 order-3 order-md-2'>
                    <div className="tab-content px-4 px-lg-2" id="bdm-cam-content" ref={ref} >
                        {/* CAM Listing */}
                        <div className={`${ pageType === "lead-listing"?"show active " : "" } tab-pane`}  id="cam-btn1" role="tabpanel" aria-labelledby="cam-btn" tabIndex={0}>
                            <Slider {...settings} className={`${ CAMUSERS.length === 0?"no-bdm" : "" } bdm-cam-holder`}>
                                {CAMUSERS.length > 0 ? 
                                camUsersHtml: 
                                <div className='user-slider-wrapper text-capitalize text-center p-3 rounded-1' key="noBDM">
                                    No CAM available
                                </div> 
                                }
                            </Slider>
                        </div>

                        {/* BDM Listing */}
                        <div className={`${ pageType === "lead-description"?"show active " : "" } tab-pane`} id="bdm-btn1" role="tabpanel" aria-labelledby="bdm-btn" tabIndex={0}>
                            <Slider {...settings} className={`${ BDMUSERS.length === 0?"no-bdm" : "" } bdm-cam-holder`}>
                                {BDMUSERS.length > 0 ? 
                                
                                bdmUsersHtml : 
                                <div className='user-slider-wrapper text-capitalize text-center p-3 rounded-1' key="noBDM">
                                    No BDM available
                                </div> 
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='col-lg-1 col-md-2 order-2 order-md-3 btn-cam-wrapper'>
                <button className={`${ pageType === "lead-description"?"active " : null } btn btn-secondary rounded-1 w-100 h-100 lh-sm py-2`} id="bdm-btn" data-bs-toggle="pill" data-bs-target="#bdm-btn1" type="button" role="tab" aria-controls="bdm-btn" aria-selected="false">{BDMUSERS.length} Online BDM</button>
                </div>
            </div>
        </div>
    )
}