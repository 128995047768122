import React from 'react';
import { setFormId, setIdentity, setRoomId } from '../store/actions';
import { connect } from 'react-redux';
import { store } from '../store/store';
import { collection, doc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from 'firebase/firestore';
import { database } from '../firebaseConfig';
import { environment } from '../environment';
import { useNavigate } from 'react-router-dom';

import notificationSound from "../static/callTune.mp3";
import { useState } from 'react';


export const CallPopUp = ({callFrom,roomId, user, setRoomIdAction, setIdentityAction, setFormIdAction, docId, callTo, callStatus,callType}) => {
    let navigate = useNavigate();

     
   //console.log(callFrom,roomId, user, setRoomIdAction, setIdentityAction, setFormIdAction, docId, callTo, callStatus)
    const [incomingAudio, setAudio] = useState(new Audio(notificationSound));

   

    const handleAudioCall = async (docId, nameValue, roomId) => {
        // incomingAudio.pause();
        
        localStorage.setItem("callReceived","true");
        
        //console.log(roomId,nameValue,docId)
        store.dispatch(setIdentity(nameValue));
        store.dispatch(setRoomId(roomId));
        store.dispatch(setFormId(docId));

          const formRef = doc(
            database,
            environment.REACT_APP_FIREBASE_DB_CALLS,
            docId
          );
          if(callType === "group"){
            const formData = query(
              collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
              where("roomId", "==", roomId)
            );
            const querySnapshot = await getDocs(formData);
            querySnapshot.forEach(async (docData) => {
             const participants = [];
             docData.data().participants.forEach((participant)=>{
                if(participant.identity === user?.email ){
                  participants.push({status:"joined",identity:participant.identity});
                  document.getElementById("audiVideoCall").classList.add("d-none");
                  
                }
                else{
                  participants.push(participant)
                  document.getElementById("audiVideoCall").classList.remove("d-none");
                 
                }
             })
           //console.log("participant Status updated:::",participants)
              //console.log(relevant);
              const formRef2 = doc(
                database,
                environment.REACT_APP_FIREBASE_DB_CALLS,
                docId
              );
              
                updateDoc(formRef2, {
                  //relevant: [...docData.data().relevant ,{display_name:name,email:email}],
                  participants: participants
                })
                  .then(() => {
                    // console.log("clientJoined successfully");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              
            });
            updateDoc(formRef, {
              // roomId: roomId,
              callStatus: "in-progress",
              callReceivedByName: user?.displayName,
              callReceivedByEmail: user?.email,
              isCallPickedByUser:true,
              callJoinedTime: serverTimestamp()
            }).then(() => {
                //console.log("Data Sent");
                const stateData = {
                  camera: false,
                  isMicMuted: false,
                  isRejoin: false,
                  callType:callType,
                  callToName:callTo,
                  callFromName: user?.displayName,
                  callFromEmail: user?.email,
                  roomId:roomId,
                  docId:docId,
                  isTryingToJoining:false
                };
                
               const stateQueryString = JSON.stringify(stateData);
                // Construct the URL with the state data
                
                const url = `/call?state=${btoa(stateQueryString)}`;
                 // Open the URL in a new tab
                window.open(url, '_blank');
              })
              .catch((err) => {
                //console.log(err);
              });
          } else{
            incomingAudio.pause();
            document.getElementById("audiVideoCall").classList.add("d-none");
            updateDoc(formRef, {
              roomId: roomId,
              callStatus: "in-progress",
              
              callReceivedByName: user?.displayName,
              callReceivedByEmail: user?.email,
              isCallPickedByUser:true,
              callJoinedTime: serverTimestamp()
            }).then(() => {
                //console.log("Data Sent");
                const stateData = {
                  camera: false,
                  isMicMuted: false,
                  isRejoin: false,
                  callType:callType,
                  callToName:callTo,
                  callFromName: user?.displayName,
                  callFromEmail: user?.email,
                  roomId:roomId,
                  docId:docId,
                  isTryingToJoining:false
                };
                
               const stateQueryString = JSON.stringify(stateData);
                // Construct the URL with the state data
                const url = `/call?state=${btoa(stateQueryString)}`;
                 // Open the URL in a new tab
                window.open(url, '_blank');
              })
              .catch((err) => {
                //console.log(err);
              });
          }
          // updateDoc(formRef, {
          //   roomId: roomId,
          //   callStatus: "in-progress",
            
          //   callReceivedByName: user?.displayName,
          //   callReceivedByEmail: user?.email,
          //   isCallPickedByUser:true,
          //   callJoinedTime: serverTimestamp()
          // }).then(() => {
          //     //console.log("Data Sent");
          //     const stateData = {
          //       camera: false,
          //       isMicMuted: false,
          //       isRejoin: false,
          //       callType:callType,
          //       callToName:callTo,
          //       callFromName: user?.displayName,
          //       callFromEmail: user?.email,
          //       roomId:roomId,
          //       docId:docId
          //     };
              
               
          //    const stateQueryString = JSON.stringify(stateData);
              
               
              
          //     // Construct the URL with the state data
          //     const url = `/call?state=${btoa(stateQueryString)}`;
              
               
              
          //     // Open the URL in a new tab
          //     window.open(url, '_blank');
          //   })
          //   .catch((err) => {
          //     //console.log(err);
          //   });
               
      };

      const handleVideoCall = async (docId, nameValue, roomId) => {
        incomingAudio.pause();
        localStorage.setItem("callReceived","true");
        // document.getElementById("audiVideoCall").classList.add("d-none");
        //console.log(roomId,nameValue,docId)
        store.dispatch(setIdentity(nameValue));
        store.dispatch(setRoomId(roomId));
        store.dispatch(setFormId(docId));

          const formRef = doc(
            database,
            environment.REACT_APP_FIREBASE_DB_CALLS,
            docId
          );
          if(callType === "group"){
            const formData = query(
              collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
              where("roomId", "==", roomId)
            );
            const querySnapshot = await getDocs(formData);
            querySnapshot.forEach(async (docData) => {
             const participants = [];
             docData.data().participants.forEach((participant)=>{
                if(participant.identity === user?.email ){
                  participants.push({status:"joined",identity:participant.identity});
                  document.getElementById("audiVideoCall").classList.add("d-none");
                  
                }
                else{
                  participants.push(participant)
                  document.getElementById("audiVideoCall").classList.add("d-none");
                 
                }
             })
           //console.log("participant Status updated:::",participants)
              //console.log(relevant);
              const formRef2 = doc(
                database,
                environment.REACT_APP_FIREBASE_DB_CALLS,
                docId
              );
              
                updateDoc(formRef2, {
                  //relevant: [...docData.data().relevant ,{display_name:name,email:email}],
                  participants: participants
                })
                  .then(() => {
                    // console.log("clientJoined successfully");
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              
            });
            updateDoc(formRef, {
              roomId: roomId,
              callStatus: "in-progress",
              
              callReceivedByName: user?.displayName,
              callReceivedByEmail: user?.email,
              isCallPickedByUser:true,
              callJoinedTime: serverTimestamp()
            }).then(() => {
                //console.log("Data Sent");
                const stateData = {
                  camera: true,
                  isMicMuted: false,
                  isRejoin: false,
                  callType:callType,
                  callToName:callTo,
                  callFromName: user?.displayName,
                  callFromEmail: user?.email,
                  roomId:roomId,
                  docId:docId,
                  isTryingToJoining:false
                };
                
               const stateQueryString = JSON.stringify(stateData);
                // Construct the URL with the state data
                const url = `/call?state=${btoa(stateQueryString)}`;
                 // Open the URL in a new tab
                window.open(url, '_blank');
              })
              .catch((err) => {
                //console.log(err);
              });
          } else{
            document.getElementById("audiVideoCall").classList.add("d-none");
            updateDoc(formRef, {
              roomId: roomId,
              callStatus: "in-progress",
              
              callReceivedByName: user?.displayName,
              callReceivedByEmail: user?.email,
              isCallPickedByUser:true,
              callJoinedTime: serverTimestamp()
            }).then(() => {
                //console.log("Data Sent");
                const stateData = {
                  camera: true,
                  isMicMuted: false,
                  isRejoin: false,
                  callType:callType,
                  callToName:callTo,
                  callFromName: user?.displayName,
                  callFromEmail: user?.email,
                  roomId:roomId,
                  docId:docId,
                  isTryingToJoining:false
                };
                
               const stateQueryString = JSON.stringify(stateData);
                // Construct the URL with the state data
                const url = `/call?state=${btoa(stateQueryString)}`;
                 // Open the URL in a new tab
                window.open(url, '_blank');
              })
              .catch((err) => {
                //console.log(err);
              });
          }
          // updateDoc(formRef, {
          //   roomId: roomId,
          //   callStatus: "in-progress",
            
          //   callReceivedByName: user?.displayName,
          //   callReceivedByEmail: user?.email,
          //   isCallPickedByUser:true,
          //   callJoinedTime: serverTimestamp()
          // }).then(() => {
          //     //console.log("Data Sent");
          //     const stateData = {
          //       camera: true,
          //       isMicMuted: false,
          //       isRejoin: false,
          //       callType:callType,
          //       callToName:callTo,
          //       callFromName: user?.displayName,
          //       callFromEmail: user?.email,
          //       roomId:roomId,
          //       docId:docId
          //     };
              
               
          //    const stateQueryString = JSON.stringify(stateData);
              
               
              
          //     // Construct the URL with the state data
          //     const url = `/call?state=${btoa(stateQueryString)}`;
              
               
              
          //     // Open the URL in a new tab
          //     window.open(url, '_blank');
          //   })
          //   .catch((err) => {
          //     //console.log(err);
          //   });
      };

      const handleCancelCall = async (docId, nameValue, roomId,callType) => {
        if(callType === "group"){
          incomingAudio.pause();
        localStorage.setItem("InitiatedCallTime","");
        localStorage.setItem("callType","");
        localStorage.setItem("callDocId","");
        localStorage.setItem("callReceived","");
        document.getElementById("audiVideoCall").classList.add("d-none");
        store.dispatch(setIdentity(nameValue));
        store.dispatch(setRoomId(roomId));
        store.dispatch(setFormId(docId));

        const formData = query(
          collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
          where("roomId", "==", roomId)
        );
        const querySnapshot = await getDocs(formData);
        querySnapshot.forEach(async (docData) => {
         const participants = [];
         docData.data().participants.forEach((participant)=>{
            if(participant.identity === user?.email ){
              participants.push({status:"rejected",identity:participant.identity});
              
              
            }
            else{
              participants.push(participant)
             
            }
         })
       //console.log("participant Status updated:::",participants)
          //console.log(relevant);
          const formRef2 = doc(
            database,
            environment.REACT_APP_FIREBASE_DB_CALLS,
            docId
          );
          
            updateDoc(formRef2, {
              //relevant: [...docData.data().relevant ,{display_name:name,email:email}],
              participants: participants
            })
              .then(() => {
                // console.log("clientJoined successfully");
              })
              .catch((error) => {
                console.log(error);
              });
          
        });
      
        }
        else{
          incomingAudio.pause();
        localStorage.setItem("InitiatedCallTime","");
        localStorage.setItem("callType","");
        localStorage.setItem("callDocId","");
        localStorage.setItem("callReceived","");
        document.getElementById("audiVideoCall").classList.add("d-none");
        store.dispatch(setIdentity(nameValue));
        store.dispatch(setRoomId(roomId));
        store.dispatch(setFormId(docId));

            const formRef = doc(
            database,
            environment.REACT_APP_FIREBASE_DB_CALLS,
            docId
          );
          updateDoc(formRef, {
            roomId: roomId,
            callStatus: "rejected",
            isCallJoined:"rejected",
            callRejectedByName: user?.displayName,
            callRejectedByEmail: user?.email,
            callJoinedTime: serverTimestamp()
          }).then(() => {
              //console.log("Data Sent");
            })
            .catch((err) => {
              //console.log(err);
            });
        }
               
      };
        
      const shortName = callFrom.split(" ").map((shortName) => {
        return shortName[0];
      });

      const toShortName = callTo.split(" ").map((shortName) => {
        return shortName[0];
      });

        return (
            <div id="audiVideoCall" className="d-none incoming-call-modal bg-white border-bottom border-4 border-primary rounded-4 overflow-hidden">
            <div className="text-center p-0 position-relative">
                <div className="user-bg d-flex align-items-center justify-content-center">
                    <div className='user-shortname fw-medium'>{shortName}</div>
                   
                </div>
                <div className="relative-content bg-dark bg-opacity-75 d-flex flex-column justify-content-center position-absolute top-0 start-0 end-0 bottom-0 p-4">
                    { callType === "private"?
                    <div className="caller-name fs-6 text-light mb-4"><div className="d-block text-truncate text-capitalize mb-1">{callFrom}</div>is calling you</div>
                    :
                    <div className="caller-name fs-6 text-light mb-4"><div className="d-block text-truncate text-capitalize mb-1">{callTo}</div>is calling you</div>
                    }
                    <div className="chip-wrapper mb-4">
                        <div className="chip-img d-flex align-items-center justify-content-center rounded-circle border border-1 overflow-hidden mx-auto" style={{ height: "60px", width: "60px" }}>
                            <div className='user-shortname fs-4 fw-medium'>{toShortName}</div>
                           
                        </div>
                    </div> 
                    {callType === "group"?
                    <div className="action-wrapper d-flex flex-wrap align-items-center justify-content-center gap-3">
                   
                        <button type="button" className="btn btn-primary d-flex align-items-center justify-content-center rounded-circle" title="Video Call" onClick={() =>{handleVideoCall(docId,"_PRIVATE_" + user?.displayName,roomId)}}><img src={require('../resources/images/video-call.png')} alt="" /></button>
                        <button type="button" className="btn btn-primary d-flex align-items-center justify-content-center rounded-circle" title="Audio Call" onClick={() =>{handleAudioCall(docId,"_PRIVATE_" + user?.displayName,roomId)}}><i class="bi bi-telephone-fill"></i></button>
                        <button type="button" className="btn btn-danger d-flex align-items-center justify-content-center rounded-circle" title="Reject Call" onClick={() =>{handleCancelCall(docId,"_PRIVATE_" + user?.displayName,roomId,callType)}}><i class="bi bi-telephone-fill" style={{ transform: 'rotate(135deg)' }}></i></button>
                        
                       
                      
                        </div>
                        :
                        <div className="action-wrapper d-flex flex-wrap align-items-center justify-content-center gap-3">
                   
                        <button type="button" className="btn btn-primary d-flex align-items-center justify-content-center rounded-circle" title="Video Call" onClick={() =>{handleVideoCall(docId,"_GROUP_" + user?.displayName,roomId)}}><img src={require('../resources/images/video-call.png')} alt="" /></button>
                        <button type="button" className="btn btn-primary d-flex align-items-center justify-content-center rounded-circle" title="Audio Call" onClick={() =>{handleAudioCall(docId,"_GROUP_" + user?.displayName,roomId)}}><i class="bi bi-telephone-fill"></i></button>
                        <button type="button" className="btn btn-danger d-flex align-items-center justify-content-center rounded-circle" title="Reject Call" onClick={() =>{handleCancelCall(docId,"_GROUP_" + user?.displayName,roomId,callType)}}><i class="bi bi-telephone-fill" style={{ transform: 'rotate(135deg)' }}></i></button>
                      
                        </div>
                        }
                </div>
            </div>
        </div>
      )
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//       setIdentityAction: (identity) => dispatch(setIdentity(identity)),
//       setRoomIdAction: (id) => dispatch(setRoomId(id)),
//       setFormIdAction: (id) => dispatch(setFormId(id)),
//     //   setLoggedinUser: (user) => dispatch(setUserInfo(user))
//     };
//   };
  
//   const mapStoreStateToProps = (state) => {
//     return {
//       ...state,
//     };
//   };
  
//   export default connect(
//     mapStoreStateToProps,
//     mapDispatchToProps
//   )(CallPopUp);

// export default CallPopUp;