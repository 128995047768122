import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class MobileLiveBoth extends Component {

  componentDidMount() {
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
    // if( this.chart) {
    //   this.chart.dispose();
    // }
    this.initChart();
    
  }
  
  initChart(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("mobile-live-both", am4charts.PieChart);

    chart.hiddenState.properties.opacity = 0;
    
    chart.data = [
        {
            device: "Mobile",
          value: this.props.mobile?this.props.mobile:0
        },
        {
            device: "Live",
          value: this.props.live?this.props.live:0
        },
        {
            device: "Mobile + Live",
          value: this.props.both?this.props.both:0
        }
        
      ];

      
      
      var series = chart.series.push(new am4charts.PieSeries());
        series.dataFields.value = "value";
        series.dataFields.radiusValue = "value";
        series.dataFields.category = "device";
        series.slices.template.cornerRadius = 1;
        series.colors.step = 3;

        series.hiddenState.properties.endAngle = -90;

        series.labels.template.maxWidth = 30;
        series.labels.template.wrap = true;
        series.labels.template.fontSize = 11;
        series.labels.template.__disabled = true;
        series.labels.template.disabled =true;
        

        chart.legend = new am4charts.Legend();

        chart.legend.markers.template.paddingTop= 3;
        // chart.legend.markers.template.width = 13;
        // chart.legend.markers.template.height = 13;
        
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.height = 14;
        marker.width = 14;
        chart.legend.itemContainers.template.paddingTop = 2;
        chart.legend.itemContainers.template.paddingBottom = 0;
        chart.legend.itemContainers.template.fontSize = 14;
            
  }
    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="mobile-live-both" style={{ width: "100%", height: "270px", margin: "0 auto" }} />;
  }

 
}

export default MobileLiveBoth;
