import React, { useState } from "react";
import './Chat.css';
import { Link, useNavigate } from 'react-router-dom';
import imgDefault from '../resources/images/Avatar.png';



import { useEffect } from "react";
import { addOrUpdateParticipant, addParticipantToConversation, createPrivateConversation, deleteConversation, getTokenFromTwilioForConversation, update_user_status } from "../utils/twilioConversation";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { JoinNowPopup } from "../LeadsListingPage/JoinNowPopup";
import { v4 as uuidv4 } from "uuid";
import { store } from "../store/store";
import { setIdentity, setRoomId, setUserInfo } from "../store/actions";
import { connect, useSelector } from "react-redux";
import { collection, doc, getDocs, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore";
import { database } from "../firebaseConfig";

import notificationSound from "../static/callTune.mp3";

import interactionSound from "../static/Intereact.mp3";

import { AddParticipants } from "./AddParticipants";
import { GroupParticipants } from "./GroupParticipants";

import { MessageHoverOption } from "./MessageHoverOption";
import { EditMessage } from "./EditMessage";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RenameGroup } from "./RenameGroup";
import JoinNow from "./JoinNow";

const ChatBoat = (props) =>{
    const [interactAudio, setInteract] = useState(new Audio(interactionSound));
    const [outGoingAudio, setAudio] = useState(new Audio(notificationSound));
    let navigate = useNavigate();
    const { setTwilioAccessTokenForChatAction } = props;
    const [isActive, setIsActive] = useState(false);
    const [token, setChatToken] = useState('');
    const [messages, setMessages]= useState([]);
    const [users, setUsers] = useState([]);
    // const [isOnScreen, setIsOnScreen] = useState(true);

    const {setRoomIdAction, setIdentityAction, setLoggedinUser, allCalls, isReceived} =props;
    
    const loggedInUser = props?.user? props?.user?.email  : localStorage.getItem("userEmail");
   
    

    // For Discard model
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => {
        setModalShow(false)
        
    };
      useEffect(() => {
        if (props?.loading) return;
        if (!props?.user) navigate("/");
        else {
            
            getTokenFromTwilioForConversation(props?.user?.email).then((token)=>{
                setChatToken(token)
                //////console.log(token)
             })
          const userInfo = {
            uid: props?.user?.uid,
            email: props?.user?.email,
            displayName: props?.user?.displayName,
            accessToken: props?.user?.accessToken,
            idToken: props?.user?.idToken,
          };
          setLoggedinUser(userInfo);
        }
      }, [props?.user]);
      
     
    const openChatbar = (event) => {
        setIsActive(current => !current);
    };
    const closeChatbar = (event) => {
        setIsActive(false);
    };

    // For New Chat header
    const [isOpen, setIsOpen] = useState(false);

    const openNewChat = (event) => {
        setIsOpen(current => !current);
    };

    const closeNewChat = (event) => {
        setIsOpen(false);
    };

    const initiatChat = async(friendlyName, uniqueName) =>{
        
        ////console.log("chat is going to initializing....",friendlyName,uniqueName);
        createPrivateConversation(friendlyName,uniqueName);
        
    } 

    // const addParticipant = async(friendlyName, uniqueName) =>{
        
    //     ////console.log("chat is going to initializing....",friendlyName,uniqueName);
    //     createConversation(friendlyName,uniqueName);
        
    // }

    const searchUsers = (keyword) =>{
        callAxios(
            environment.REACT_APP_API_URL_Live + "api/v1/users/get-user-by-keyword",
            {keyword:keyword},
            "post"
          ).then((users)=>{
            setUsers(users.data);
          })
    }
    
    const handleDeleteConversation = () =>{
        // deleteConversation("CHc5f25a5ce1a249dbb3d26c25b05b1c6e");
       // addOrUpdateParticipant("CHf8d1641935fa477f993777b4337ff463","shreyashibhattacharya@virtualemployee.com","Shreyashi Bhattacharya","ponrajs@virtualemployee.com")
    }

    // const handleAudioCall = () =>{
    //     const email = document.getElementById("audio-call").value;
    //     let callToNAme = document.getElementById("audio-call").getAttribute('data-name');
    //     const createdRoomId = uuidv4();
    //     const docId = uuidv4();
    //     // setIdentityAction("_PRIVATE_" + props?.user?.displayName);
    //     // setRoomIdAction(createdRoomId);
      
    //     navigate("/call", {
    //         state: {
    //           id: 0,
    //           camera: false,
    //           isMicMuted: false,
    //           isRejoin: false,
    //           callFromName : props?.user?.displayName,
    //           callToName:callToNAme,
    //           callFromEmail: props?.user?.email,
    //           comingFrom:"caller"
    //         },
    //       });

    //      // playSound();

    //       setDoc(doc(database, "calls", docId), {
    //         roomId: createdRoomId,
    //         callFromEmail: props?.user?.email,
    //         callFromName : props?.user?.displayName,
    //         callToName:callToNAme,
    //         isCallJoined: false,
    //         callTo: email,
    //         isReceived: false,
    //         timeStamp: serverTimestamp(),
    //         callStatus:"initiated"
    //       })
    //         .then(() => {
    //           ////console.log("Data Sent");
    //         })
    //         .catch((err) => {
    //           ////console.log(err);
    //         });
      
    // }

    const callPushNotification = (email,displayName,link,notificationType) =>{
        callAxios(environment.REACT_APP_API_URL_Live+'api/v1/fcm/send-push-notification',{title: displayName, textBody: "is calling you...", email:email,type:"call",link:link,notificationType:notificationType,groupParticipants:[]}, "post" )
    }

    

    const handleAudioCall = () =>{
        playInteract();
        const email = document.getElementById("audio-call").value;

        let callToNAme = document.getElementById("audio-call").getAttribute('data-name');
        let callType = document.getElementById("audio-call").getAttribute('data-call-type');
        let conversationSid = document.getElementById("audio-call").getAttribute('data-conversation-sid');
        const createdRoomId = uuidv4();
        const docId = uuidv4();

        setIdentityAction("_PRIVATE_" + props?.user?.displayName);
        setRoomIdAction(createdRoomId);
       
        if(callType === "private"){
            const stateData = {
                camera: false,
                isMicMuted: false,
                callType:callType,
                isRejoin: false,
                callFromName: props?.user?.displayName,
                callFromEmail: props?.user?.email,
                roomId:createdRoomId,
                docId:docId,
                isTryingToJoining:false
              };
              
              const stateQueryString = JSON.stringify(stateData);
              
              const url = `/call?state=${btoa(stateQueryString)}`;

              callPushNotification(email,props?.user?.displayName,url,callType);
              
              window.open(url, '_blank');
            setDoc(doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, docId), {
                roomId: createdRoomId,
                callFromEmail: props?.user?.email,
                callType:callType,
                callFromName : props?.user?.displayName,
                callToName:callToNAme,
                callToEmail: email,
                timeStamp: serverTimestamp(),
                callStatus:"initiated",
                docId:docId,
                conversationSid:conversationSid
              })
                .then(() => {
                  ////console.log("Data Sent");
                })
                .catch((err) => {
                  ////console.log(err);
                });
    
                
        }
        else{

            const stateData = {
                camera: false,
                isMicMuted: false,
                callType:callType,
                isRejoin: false,
                callToName:callToNAme,
                callFromName: props?.user?.displayName,
                callFromEmail: props?.user?.email,
                roomId:createdRoomId,
                docId:docId,
                isTryingToJoining:false
              };
              
              const stateQueryString = JSON.stringify(stateData);
              
              const url = `/call?state=${btoa(stateQueryString)}`;
              window.open(url, '_blank');

              callPushNotification(email,props?.user?.displayName,url,callType);
           
            const usersEmail = email.split(",");
            const participants =[];
            participants.push({status:"initiated",identity:props?.user?.email})
            usersEmail.forEach((identity)=>{
                participants.push({status:"initiated",identity:identity})
            })
            
            setDoc(doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, docId), {
                roomId: createdRoomId,
                
                callFromEmail: props?.user?.email,
                callFromName : props?.user?.displayName,
                callToName:callToNAme,
                callType:callType,
                callGroupEmails: email.split(","),
                timeStamp: serverTimestamp(),
                callStatus:"initiated",
                docId:docId,
                participants:participants,
                conversationSid:conversationSid
              })
                .then(() => {
                  ////console.log("Data Sent");
                })
                .catch((err) => {
                  ////console.log(err);
                });
    
                
                  
                  
        }
        
      
    }

    const handleVideoCall = () =>{
        playInteract();
        const email = document.getElementById("audio-call").value;
        let callToNAme = document.getElementById("audio-call").getAttribute('data-name');
        let callType = document.getElementById("audio-call").getAttribute('data-call-type');
        let conversationSid = document.getElementById("audio-call").getAttribute('data-conversation-sid');
        const createdRoomId = uuidv4();
        const docId = uuidv4();

        if(callType === "private"){
            const stateData = {
                camera: true,
                isMicMuted: false,
                isRejoin: false,
                callFromName: props?.user?.displayName,
                callFromEmail: props?.user?.email,
                roomId:createdRoomId,
                docId:docId,
                isTryingToJoining:false
              };
              
              const stateQueryString = JSON.stringify(stateData);
              
              const url = `/call?state=${btoa(stateQueryString)}`;
              window.open(url, '_blank');
              callPushNotification(email,props?.user?.displayName,url,callType);
            setDoc(doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, docId), {
                roomId: createdRoomId,
                callFromEmail: props?.user?.email,
                callFromName : props?.user?.displayName,
                callToName:callToNAme,
                callToEmail: email,
                timeStamp: serverTimestamp(),
                callStatus:"initiated",
                docId:docId,
                conversationSid:conversationSid
              })
                .then(() => {
                  ////console.log("Data Sent");
                })
                .catch((err) => {
                  ////console.log(err);
                });
    
                
                 
        }
        else{
            const stateData = {
                camera: true,
                isMicMuted: false,
                isRejoin: false,
                callType:callType,
                callToName:callToNAme,
                callFromName: props?.user?.displayName,
                callFromEmail: props?.user?.email,
                roomId:createdRoomId,
                docId:docId,
                isTryingToJoining:false
              };
              
              const stateQueryString = JSON.stringify(stateData);
              
              const url = `/call?state=${btoa(stateQueryString)}`;
              window.open(url, '_blank');
              callPushNotification(email,props?.user?.displayName,url,callType);
            const usersEmail = email.split(",");
            const participants =[];
            participants.push({status:"initiated",identity:props?.user?.email})
            usersEmail.forEach((identity)=>{
                participants.push({status:"initiated",identity:identity})
            })
            //console.log(participants)
            setDoc(doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, docId), {
                roomId: createdRoomId,
                callFromEmail: props?.user?.email,
                callFromName : props?.user?.displayName,
                callToName:callToNAme,
                callType:callType,
                callGroupEmails: email.split(","),
                timeStamp: serverTimestamp(),
                callStatus:"initiated",
                docId:docId,
                participants:participants,
                conversationSid:conversationSid
              })
                .then(() => {
                  ////console.log("Data Sent");
                })
                .catch((err) => {
                  ////console.log(err);
                });
    
                
        }
         
    }
    const playSound = () =>{
        outGoingAudio.play();
          outGoingAudio.addEventListener('ended', function () {
            setTimeout(() => {
                outGoingAudio.play();
            }, 2000);
            
            }, false);
    }

    const stopSound = () =>{
        outGoingAudio.stop();
         
    }

    const playInteract = () =>{
        interactAudio.play();
    }

    /*
    *   group chat and call feature
    */
            // Add participants card
        const [isCardVisible, setIsCardVisible] = useState(false);

        const [renameGroup, setRenameGroup] = useState(false);

        const showCardRename = () => {
            setRenameGroup(true);
            // closeGroupParticipants();
        };
        const closeCardRename = () => {
            setRenameGroup(false);
        };

        const showCard = () => {
            setIsCardVisible(true);
            closeGroupParticipants();
        };
        const closeCard = () => {
                setIsCardVisible(false);
        };

        // Group participants card
        const [isGroupParticipants, setIsGroupParticipants] = useState(false);
        // const showGroupParticipants = () => {
        //     setIsGroupParticipants(true);
        // };
        const closeGroupParticipants = () => {
                setIsGroupParticipants(false);
        };

        const showGroupParticipants = (event) => {
            setIsGroupParticipants(current => !current);
            closeCard();
    };

    

    /*
    * end group feature
    */

    const HandleAction = () =>{
        //console.log("in adding")
       // addOrUpdateParticipant("CH6fa52b0e1bec4b1f9c5d0c9ba34e33f4");
    }

    // For tooltip option on message hover
    let DarkModeBtn;
    const darkMode = () => {
        const value= document.getElementById("dark-mode-onoff").value;
        document.body.classList.toggle('dark-mode');
        if(value === "dark"){
            localStorage.setItem("darkMode","on");
            document.getElementById("dark-mode-onoff").value = "night";
            document.getElementById("dark-night-showhide").classList.remove("bi-brightness-high-fill");
            document.getElementById("dark-night-showhide").classList.remove("text-muted");
            document.getElementById("dark-night-showhide").classList.add("bi-moon-stars-fill");
            document.getElementById("dark-night-showhide").classList.add("text-primary");

            // DarkModeBtn = <i className="bi bi-moon-stars-fill text-primary" title="Light mode"></i>;
        }
        else{
            localStorage.setItem("darkMode","off");
            document.getElementById("dark-mode-onoff").value = "dark";
            document.getElementById("dark-night-showhide").classList.add("bi-brightness-high-fill");
            document.getElementById("dark-night-showhide").classList.add("text-muted");
            document.getElementById("dark-night-showhide").classList.remove("bi-moon-stars-fill");
            document.getElementById("dark-night-showhide").classList.remove("text-primary");
        }
        
        
    }

    
    if(localStorage.getItem("darkMode") === "on"){
        document.body.classList.add('dark-mode');
        const btnAvailable = document.getElementById("dark-night-showhide");
        if(btnAvailable){
             btnAvailable.classList.remove("bi-brightness-high-fill");
            btnAvailable.classList.remove("text-muted");
            btnAvailable.classList.add("bi-moon-stars-fill");
            btnAvailable.classList.add("text-primary");
        }
       
        
    } else{
        document.body.classList.remove('dark-mode');
        const btnAvailable = document.getElementById("dark-night-showhide");
        if(btnAvailable){
             btnAvailable.classList.add("bi-brightness-high-fill");
            btnAvailable.classList.add("text-muted");
            btnAvailable.classList.remove("bi-moon-stars-fill");
            btnAvailable.classList.remove("text-primary");
        }

    }
   
   
    const onStatusCss = loggedInUser + " user-status position-absolute z-1";
    return (
        <>
        <audio src={require("../static/Intereact.mp3")} id="interactDocumentFirstChat" ></audio>
         <div className='chatboat-page'>
            <div className='chatboat-wrapper d-flex h-100 position-relative'>
                <aside className={`d-flex flex-column shadow-md border-end ${isActive ? 'active' : ''}`}>
                    <div className="list-head d-flex align-items-center justify-content-between border-bottom px-3 py-2">
                        <div className="left">
                            <div className="dropdown">
                                <button className="btn dropdown-togglee fs-5 fw-medium border-0 bg-transparent p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                     Chat {/*<i className="fs-6 bi bi-chevron-down small text-muted ms-1"></i>  */}
                                    {/* <button className="btn btn-danger" onClick={handleDeleteConversation}>Delete</button> */}
                                </button>
                                {/* <ul className="dropdown-menu">
                                    <li><a className="dropdown-item small active" to={"#"}>Action</a></li>
                                    <li><a className="dropdown-item small" to={"#"}>Another action</a></li>
                                    <li><a className="dropdown-item small" to={"#"}>Something else here</a></li>
                                </ul> */}
                                {/* <button onClick={HandleAction}>Add</button> */}
                            </div>
                        </div>
                        <div className="right d-flex gap-1">
                            <button type="button" className="btn border-0 bg-transparent p-1" title="Filter"><i className="bi bi-filter fs-5 text-muted"></i></button>
                            <button id="new-chat-search-filter" type="button" className="btn border-0 bg-transparent p-1" title="New Chat" onClick={openNewChat}><i className="bi bi-pencil-square text-muted"></i></button>
                        </div>
                    </div>
                    <div className="list-wrap flex-fill overflow-auto" id="conversationListLeftSide">
                    <div class="main-item" id="leftLoader-chat">
                        <div class="animated-background"></div>
                    </div>
                        {/* {Data.map((user, id) => (
                            <div className="user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2" key={id} onClick={()=>{initiatChat(user.email)}}>
                                <div className="w-75 d-flex align-items-center gap-2">
                                    <div className="user-pic position-relative">
                                        <img src={user.img} alt="User" className="user-chip" />
                                      
                                        <div className="user-status position-absolute z-1">
                                            {user.status === 'active' ? <span className="bg-success text-white"><i className="bi bi-check-circle"></i></span> : ''}
                                            {user.status === 'away' ? <span className="bg-warning text-white"><i className="bi bi-clock"></i></span> : ''}
                                            {user.status === 'offline' ? <span className="bg-white text-muted"><i className="bi bi-x-circle"></i></span> : ''}
                                            {user.status === 'call' ? <span className="bg-danger text-danger"><i className="bi bi-circle-fill"></i></span> : ''}
                                        </div>
                                    </div>
                                    <div className="user-detail text-nowrap text-truncate">
                                        <div className="user-name text-capitalize text-truncate lh-sm">{user.name}</div>
                                        <div className="user-email small text-truncate">{user.email}</div>
                                    </div>
                                </div>

                                <div className="flex-fill text-end small">{user.time_ago}</div>
                            </div>
                        ))} */}
                    </div>
                </aside>
                <div className="main-chat-wrapper small">
                    <nav className="chat-header list-head border-bottom navbar navbar-expand-lg py-0">
                        <div className="container-fluid px-xl-3">
                            {/* <a className="navbar-brand" href="#">Navbar</a> */}
                            <div className="user-card d-flex justify-content-between gap-2 me-2">
                                <div className="d-flex align-items-center gap-2 d-none" id="currentUser">
                                    <div className="user-pic position-relative">
                                    <div className="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 small fw-semibold text-primary border border-primary" id="current-user-short-name"></div>
                                    <div className="onlineu-status user-status position-absolute z-1" id="online-offline-status"></div>
                                    {/* <div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden">
                                        <div class="user-shortname text-blue" id="current-user-short-name"></div>

                                        
                                        </div></div> */}
                                        {/* <img src={imgDefault} alt="User" className="user-chip" />
                                        {Data.map((user, idx) => (
                                            <div className="user-status position-absolute z-1" key={idx} >
                                                {user.status === 'active' ? <span className="bg-success text-white"><i className="bi bi-check-circle"></i></span> : ''}
                                                {user.status === 'away' ? <span className="bg-warning text-white"><i className="bi bi-clock"></i></span> : ''}
                                                {user.status === 'offline' ? <span className="bg-white text-muted"><i className="bi bi-x-circle"></i></span> : ''}
                                                {user.status === 'call' ? <span className="bg-danger text-danger"><i className="bi bi-circle-fill"></i></span> : ''}
                                            </div>
                                        ))} */}
                                    </div>
                                    <div className="user-id fs-6 fw-semibold text-capitalize text-truncate" id="showCurrentChatName"></div>
                                    <span className="ms-1 pointer text-dark fs-6 d-none" id="showHideEditGroupOption" onClick={showCardRename}><i className="bi bi-pencil"></i></span>
                                </div>
                                
                            </div>

                            <div className="d-flex align-items-center d-lg-none">
                                <button className="border-0 bg-transparent text-muted d-md-none py-0 me-2" type="button" title="View user lists" onClick={openChatbar}><i className="bi bi-person-circle fs-2"></i></button>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#chatNavbar" aria-controls="chatNavbarContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon small"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="chatNavbar">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" to={"#"}>Chat</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link className="nav-link" to={"#"}>Files</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"#"}>Organisation</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"#"}>Activity</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to={"#"}>LinkedIn</Link>
                                    </li> */}
                                </ul>
                                <div className="d-flex align-items-center gap-2 mb-2 mb-lg-0">
                                    <div className="d-none btn-group" id="callAreaButtons" role="group" aria-label="Basic outlined example">
                                        <button type="button" id="video-call" className="btn btn-outline-light border text-body lh-sm" title="Video call" onClick={handleVideoCall}><i className="bi bi-camera-video fs-18"></i></button>
                                        <button type="button" id="audio-call" className="btn btn-outline-light border text-body lh-sm" title="Audio call" onClick={handleAudioCall}><i className="bi bi-telephone"></i></button>
                                        { props?.user? 
                                        <JoinNow user={props?.user}/>
                                        :null
                                        }
                                        
                                    </div>
                                    <button type="button" className=" fs-5 border-0 bg-transparent p-0 mx-1" onClick={darkMode} value={localStorage.getItem("darkMode") === "on"?"night":"dark"} id="dark-mode-onoff">
                                    <i className="bi bi-brightness-high-fill text-muted" title="Dark mode" id="dark-night-showhide"></i>                                                        
                                      
                                    </button>
                                    
                                    <button id="singleParticipant-withoutGroup" type="button" className="d-none text-muted border-0 bg-transparent p-0" title="Add People" onClick={showCard}><i className="bi bi-person-add fs-5"></i></button>
                                    <button id="groupParticipantBtn" type="button" className="d-none text-muted border-0 bg-transparent text-nowrap p-0" title="View and add participants" onClick={showGroupParticipants}><i className="bi bi-person-add fs-5 "></i><span className="fw-medium ms-1" id="groupParticipantCount"></span></button>
                                </div>
                            </div>
                        </div>

                        <div className={`newchat-wrapper bg-white h-100 w-100 z-1 ${isOpen ? 'position-absolute d-block' : 'd-none'}`} id="new-chat-wrapper">
                            <div className="dropdown bg-white h-100 w-100">
                                <input type="text" className="form-control border-0 rounded-0 h-100 px-3" id="newChat" placeholder="Enter name, email, group or tag" data-bs-toggle="dropdown" onKeyUp={(e)=>{searchUsers(e.target.value)}}  autoComplete="off"/>
                                <ul className="dropdown-menu border rounded-0 m-0 py-0 w-100 overflow-auto" style={{ maxHeight: '50vh' }}>
                                    {users.map((user, idd) => (
                                        <li className="border-bottom border-light" key={idd} onClick={()=>{initiatChat(user.display_name,user.email)}}>
                                            <a className="dropdown-item small d-flex align-items-center gap-2" href="#">
                                                <div className="user-pic position-relative">
                                                    <img src={!user.image?require("../resources/images/Avatar.png"):user.image} alt={user.display_name} className="user-chip" />
                                                    <div className="user-status position-absolute z-1">
                                                        {user.status === 'active' ? <span className="bg-success text-white"><i className="bi bi-check-circle"></i></span> : ''}
                                                        {user.status === 'away' ? <span className="bg-warning text-white"><i className="bi bi-clock"></i></span> : ''}
                                                        {user.status === 'offline' ? <span className="bg-white text-muted"><i className="bi bi-x-circle"></i></span> : ''}
                                                        {user.status === 'call' ? <span className="bg-danger text-danger"><i className="bi bi-circle-fill"></i></span> : ''}
                                                    </div>
                                                </div>
                                                <div className="user-detail text-nowrap text-truncate">
                                                    <div className="user-name text-capitalize text-body text-truncate lh-sm">{user.display_name}</div>
                                                    <div className="user-email small text-muted text-truncate">{user.email}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </nav>

                    {/* Message Area */}
                    <div className="message-wrap" id="message-box-scroll" >
                        {/* If Chat message available */}
                        <div className="chatArea d-none px-3 px-xl-4 pt-2 mx-auto w-100" id="chat-printing-area" style={{ maxWidth: '1056px' }} >
                        {/* <div className="message-myside message-card me">
                                <div className="box-wrapper mb-2">
                                    <div className="msg-label text-capitalize fs-12 mb-1">12/10/21<span className='ms-1'>12:15</span></div>
                                    <div className="d-flex gap-1 align-items-end justify-content-end">
                                        <div className="msg px-3 py-2" onMouseEnter={openTooltip} onMouseLeave={closeTooltip}>Lorem dolor sit1.
                                            {messageTooltip && (
                                                <MessageHoverOption />
                                            )}
                                        </div>
                                        <div className="btn-icon">
                                          
                                            <i className="bi bi-eye text-primary" title="Seen"></i>

                                           
                                        </div>
                                    </div>
                                    <EditMessage />
                                </div>
                            </div> */}

                        </div>
                        {/* <Modal show={true} onHide={() => setModalShow(true)} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2 update-message-modal" dialogClassName="p-4">
                <Modal.Body className="p-4">
                    <div className='text-dark fs-18 fw-semibold'>Do you want to discard this draft?</div>
                    <div class="add-participants btn-wrapper d-flex gap-2 justify-content-end mt-3">
                        <Button variant='danger' size='sm' onClick={() => setModalShow(false)} className='shadow'>Cancel</Button>
                        <Button variant='primary' size='sm' onClick={handleClose} className='shadow'>Discard</Button>
                    </div>
                </Modal.Body>
            </Modal> */}
<div className="modalDiscard">
    
</div>

                        {/* When starting new chat */}
                        <div className="chatwindow-wrapper d-flex flex-column align-items-center justify-content-center h-100">
                            <div className="img-wrapper mb-3">
                                <img src={require('../resources/images/chat-window.png')} alt="Chat" className="img-fluid" style={{ maxWidth: "250px" }} />
                            </div>
                            <div className="chat-title fs-6 fw-semibold mb-1">You're starting a new conversation</div>
                            <div className="chat-subtitle"></div>
                        </div>
                    </div>

                    <div className="message-footer w-100 pt-4 pb-2 px-3 px-xl-4 mx-auto" style={{ maxWidth: '1056px' }}>
                    <div className="d-none small text-muted mb-1" id="typing-indicator"></div>
                    <div className="mb-1 border rounded-1 overflow-hidden" style={{backgroundColor:'#e2e2e2'}}>
                        <div className="d-none empty-msg text-muted px-3 py-1" id="please-type-a-message">Please type a message to continue...</div>
                            <input type="text" className="form-control border-0 rounded-0 shadow-none py-2 d-none input-items" autoComplete="off"  id="send-message-input" placeholder="Type a message..." />
                        </div>
                        <div className="icon-wrapper d-flex align-items-baseline justify-content-between gap-2">
                            <div className="left-menu d-flex flex-wrap gap-1">
                                {/* <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Format"><i className="bi bi-textarea-t"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Set delivery option"><i className="bi bi-exclamation fs-4"></i></button> */}
                                <form className="chat-app-form" action="javascript:void(0)" encType="multipart/form-data">
                                                                   
                                    <label for="files" className="d-none bg-transparent border-0 lh-sm p-0 input-items" title="Attach file"><i className="bi bi-paperclip"></i>
                                    <input type="file" name="media" id="files" hidden />
                                    </label>
                                </form>
                                
                                {/* <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Loop component"><i className="bi bi-geo"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Emoji"><i className="bi bi-emoji-smile"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Giphy"><i className="bi bi-filetype-gif"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Schedule a meeting"><i className="bi bi-calendar-plus"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Stream"><i className="bi bi-cast"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Viva Learning"><i className="bi bi-book"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Updates"><i className="bi bi-journal-arrow-down"></i></button> */}
                            </div>
                            <div className="right-menu d-flex gap-1">
                                {/* <button type="button" className="bg-transparent border-0 lh-sm p-0 ms-auto" title="Record a video clip"><i className="bi bi-camera-reels"></i></button> */}
                                <button type="button" className="d-none input-items bg-transparent border-0 lh-sm p-0 " id="send-message-button" title="Send"><i className="bi bi-send fs-18"></i></button>
                            </div>
                        </div>
                    </div>
                    {/* Add Participants code */}
                    {isCardVisible && (
                            <AddParticipants closeCard={closeCard} />
                    )}

                     {/* Rename Group name pop up code */}
                     {renameGroup && (
                            <RenameGroup closeCard={closeCardRename} />
                    )}


                    {/* Group Participants code */}
                    {isGroupParticipants && (
                            <GroupParticipants open={showCard} />
                    )}
                </div>
            </div>
            <EditMessage />
        </div>
        {/* <JoinNowPopup user={props?.user} isGoLive={isGoLive} to="room" data={""} /> */}

        {/* <button
        style={{ display: "none" }}
        id="p-box"
        data-bs-toggle="modal"
        data-bs-target="#joinCall"
        className="btn btn-warning btn-custom btn-next rounded-1 mt-1"
      ></button> */}
        </>
       
    )

}

const mapDispatchToProps = (dispatch) => {
    return {
      setIdentityAction: (identity) => dispatch(setIdentity(identity)),
      setRoomIdAction: (id) => dispatch(setRoomId(id)),
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(ChatBoat);

//   export default ChatBoat;
