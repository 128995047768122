import React, { Component } from "react";
/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

/* Chart code */
// Themes begin
am4core.useTheme(am4themes_animated);
let chart;
class WorldChart extends Component {
   
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {
    
    // if(chart._super) {
    //   chart.dispose();
    // }
    this.initChart();
    
  }
  
  leadFromCountry = (arr) =>
  arr.reduce((p, c) => {
 
    var country = c.country;

    if (!p.hasOwnProperty(country)) {
      p[country] = 0;
    }
   
    p[country]++;
    
    return p;
  }, {});

  
  initChart(){
    let chart = am4core.create("world-chart", am4maps.MapChart);
    chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#fff");
chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0.9;
    const countryData = Object.entries(this.leadFromCountry(this.props.data));
    const worldMapdata = [];
    let color=0;
    countryData.map((item)=>{
      color++;
      if(item[0]!==""){
        worldMapdata.push({id:item[0],name:item[0], value:item[1], color:chart.colors.getIndex(color)});
      }

    })
   

let mapData = worldMapdata;

// Set map definition
chart.geodata = am4geodata_worldLow;

// Set projection 
chart.projection = new am4maps.projections.Miller();

// Create map polygon series 
let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
polygonSeries.exclude = ["AQ"];
polygonSeries.useGeodata = true;
polygonSeries.nonScalingStroke = true;
polygonSeries.strokeWidth = 0.5;
polygonSeries.calculateVisualCenter = true;

let imageSeries = chart.series.push(new am4maps.MapImageSeries());
imageSeries.data = mapData;
imageSeries.dataFields.value = "value";

let imageTemplate = imageSeries.mapImages.template;
imageTemplate.nonScaling = true

let circle = imageTemplate.createChild(am4core.Circle);
circle.fillOpacity = 0.7;
circle.propertyFields.fill = "color";
circle.tooltipText = "{name}: [bold]{value}[/]";


imageSeries.heatRules.push({
  "target": circle,
  "property": "radius",
  "min": 4,
  "max": 30,
  "dataField": "value"
})

imageTemplate.adapter.add("latitude", function(latitude, target) {
  let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
  if(polygon){
    return polygon.visualLatitude;
   }
   return latitude;
})

imageTemplate.adapter.add("longitude", function(longitude, target) {
  let polygon = polygonSeries.getPolygonById(target.dataItem.dataContext.id);
  if(polygon){
    return polygon.visualLongitude;
   }
   return longitude;
})

  
  }
    

  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }
  
  render() {
    return <div id="world-chart" style={{ width: "100%", height: "400px", margin: "0 auto" }} />;
  }
}

export default WorldChart;