import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const EditMessage = (props) => {
    // For EditMessage Card
    const [isEditMessage, setIsEditMessage] = useState(true);
    const closeEditMessage = () => setIsEditMessage(false);
    // const showEditMessage = () => setIsEditMessage(true);

    // For Discard model
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => {
        setModalShow(false)
        setIsEditMessage(false)
    };

    return (
        <>
            {/* Edit message card code */}
            {/* {isEditMessage && ( */}
                <div className='EditMessage fs-6'>
                    <div className="message-footer w-100">
                        <div className="mb-1">
                            <input type="text" className="form-control rounded-1 py-2" id="msgText" value={'Lorem dolor sit.'} placeholder="Type a message..." />
                        </div>
                        <div className="icon-wrapper d-flex align-items-baseline justify-content-between gap-2">
                            <div className="left-menu d-flex flex-wrap">
                                {/* <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Format"><i className="bi bi-textarea-t"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Set delivery option"><i className="bi bi-exclamation fs-4"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Attach file"><i className="bi bi-paperclip"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Loop component"><i className="bi bi-geo"></i></button> */}
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Emoji"><i className="bi bi-emoji-smile"></i></button>
                                <button type="button" className="bg-transparent border-0 lh-sm p-0" title="Giphy"><i className="bi bi-filetype-gif"></i></button>
                            </div>
                            <div className="right-menu d-flex gap-1">
                                <button type="button" className="bg-transparent border-0 lh-sm p-0 ms-auto" title="Cancel" onClick={() => setModalShow(true)}><i className="bi bi-x-lg"></i></button>
                                <button type="submit" className="bg-transparent border-0 lh-sm p-0" title="Done" onClick={closeEditMessage}><i className="bi bi-check2 fs-5"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
           

            {/* Discard model code */}
            <Modal show={modalShow} onHide={() => setModalShow(false)} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2" dialogClassName="p-4">
                <Modal.Body className="p-4">
                    <div className='text-dark fs-18 fw-semibold'>Do you want to discard this draft?</div>
                    <div class="add-participants btn-wrapper d-flex gap-2 justify-content-end mt-3">
                        <Button variant='danger' size='sm' onClick={() => setModalShow(false)} className='shadow'>Cancel</Button>
                        <Button variant='primary' size='sm' onClick={handleClose} className='shadow'>Discard</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}