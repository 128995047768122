import React, { useState, useEffect } from 'react'
import { app, database, auth } from "../../firebaseConfig";
import axios from "axios";
import * as qs from "qs";
import {
  collection,
  query,
  where,
  onSnapshot,
  documentId,
} from "firebase/firestore";
import { environment } from '../../environment';
import loading from '../../static/loading.gif'

export const CallOnMobile = ({ formId, audio }) => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState("");
  const [callStatus, setCallStatus] = useState("Connecting");
  const [isPlayed, setPlay] = useState(false);
  const [callDuration, setCallDuration] = useState(0);


  useEffect(() => {

    const getFormDetails = async () => {
      if(formId){
         const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), where(documentId(), '==', formId));
       onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          setClientEmail(data?.email);
          setClientName(data?.name);
          setCallStatus(data?.CallStatus || "Connecting");
          setCallDuration(parseInt(data?.CallDuration));
        });
      });
      }
     
    }
    //if(comingFrom)
    getFormDetails();
  }, [formId]);

  useEffect(() => {
    if (callStatus == "ringing") {
      if (audio) {
        audio.loop = true;
        playSound();
      }

    }
    else if (callStatus == "in-progress" || callStatus == "completed") {
      
      closeModal();
      setPlay(false);
      stopSound();
    }
    else {

      setPlay(false);
      stopSound();
    }

  }, [callStatus])

  const closeModal = () => {
    stopSound();
    document.getElementById("audioModel").style.display = "none";
  }

  // Play the ringing sound
  function playSound() {
    if (audio) {
      audio.play();
      setPlay(true);
    }
  }

  // Stop the playing sound
  function stopSound() {
    if (audio !== null) {
      audio.pause();
    }
  }

  function getStatus() {

    const message = (callStatus == "Connecting" || callStatus == "queued" || callStatus == "initiated") ? <div className='user-status mt-3'>
    <button className='btn btn-info bg-gradient text-capitalize rounded-pill px-4 py-2' style={{ minWidth: '150px' }} onClick={() => { closeModal() }}>{callStatus}</button>
  </div> : (callStatus == "busy" || callStatus == "no-answer") ? <div className='user-status mt-3'>
      <button className='btn btn-info bg-gradient text-capitalize rounded-pill px-4 py-2' style={{ minWidth: '150px' }} onClick={() => { closeModal() }}>{callStatus}</button>
    </div> : (callStatus == "completed") ? <div className='user-status mt-3'>
      <button className='btn btn-success bg-gradient text-capitalize rounded-pill px-4 py-2' style={{ minWidth: '150px' }} onClick={() => { closeModal() }}>{callStatus}</button>
    </div> : <div className='user-status mt-3'>
      <button className='btn btn-danger bg-gradient text-capitalize rounded-pill px-4 py-2' style={{ minWidth: '150px' }} onClick={() => { closeModal() }}>{callStatus}</button>
    </div>;
    return message;

  }


  return (
    <>
      <div className="modal" id="audioModel" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="audioModelLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content call_mobile_model">
            <div className="cancel-icon bg-dark text-light d-flex align-items-center justify-content-center rounded-circle p-2" data-bs-dismiss="modal" aria-label="Close" onClick={() => { closeModal() }}>
              <i class="bi bi-x fw-bold fs-3"></i>
            </div>
            <div className="modal-body bg-secondary text-center py-5">
              <div className="user-img rounded-circle border border-light overflow-hidden mx-auto" style={{ height: '200px', width: '200px' }}>
                <img className="w-100 h-100" src={require('../../resources/images/Avatar.png')} alt='User' />
              </div>
              <div className="user-info mt-3">
                <div className="user-name fs-4 text-capitalize">{clientName}</div>
                <div className="user-id"></div>

              </div>
              {getStatus()}

            </div>
          </div>
        </div>
      </div>

    </>
  )
}
