import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../firebaseConfig";
import { callAxios, callCrmApi } from "../utils/utils";
import { environment } from "../environment";
import $ from "jquery";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { useEffect } from "react";

export const BdmCamQuesAfterCall = ({ roomId, room, siteUrl, comingFrom }) => {
  const [user, loading, error] = useAuthState(auth);
  $(".modal-backdrop").css("--bs-backdrop-opacity", "1");
  
  const [bdmStartYesNo, setBdmStartYesNo] = useState("");

  const [camStartYesNo, setCamStartYesNo] = useState("");

  const [camClientJoined, setClientJoinedCam] = useState("");

  const [bdmClientJoined, setClientJoinedBdm] = useState("");

  

  //window.addEventListener("beforeunload", beforeUnloadListener);

 

  const saveCamFormToFirestore = async (roomId, feedbackForm,loggedInUserEmail) => {
    window.onbeforeunload = null;
    //console.log(roomId, feedbackForm)
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      if(docData.data().leadId){
      const userDATA=   await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/crm/get-user",
        { email: loggedInUserEmail },
        "post"
      );
      
        if(userDATA.data.user.systemuserid.length > 0){
          await saveFeedbackToCRM(feedbackForm,"CAM Feedback",docData.data().leadId,userDATA.data.user.systemuserid);
        }
        
    
      
    }
     
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
       
          updateDoc(formRef, {
            
            camFeedback: feedbackForm,
            camFormSubmitTime: serverTimestamp(),
            camFormSubmitStatus: true
          })
            .then(() => {
             //console.log("cam feedback updated successfully");
                callAxios(
                environment.REACT_APP_API_URL_Live +
                  "api/v1/cam-bdm-feedback/save-cam-bdm-feedback",
                { feedback: feedbackForm },
                "post"
              ).then((response) => {
                if (response.data.status === 200) {
                  window.onbeforeunload = null;
                  //window.location.href = siteUrl;
                  $("#cam-feedback-form").css("display", "none");
                  toast.success("Feedback submitted!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                } else {
                  toast.error("something went wrong!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
                setTimeout(() => redirect(siteUrl), 1000);
              });
            })
            .catch((error) => {
              redirect(siteUrl);
              console.log(error);
            });
        
        
      
    });
  };
  const saveBdmFormToFirestore = async (roomId, feedbackForm,loggedInUserEmail) => {
    window.onbeforeunload = null;
   // console.log(roomId, feedbackForm)
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      if(docData.data().leadId){
        const userData = await callAxios(
          environment.REACT_APP_API_URL_Live + "api/v1/crm/get-user",
          { email: loggedInUserEmail },
          "post"
        );
  
          if(userData.data.user.systemuserid.length > 0){
          await saveFeedbackToCRM(feedbackForm,"BDM Feedback",docData.data().leadId,userData.data.user.systemuserid);
          }
     
      }
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
       
          updateDoc(formRef, {
            bdmFeedback: feedbackForm,
            bdmFormSubmitTime: serverTimestamp(),
            bdmFormSubmitStatus: true
          })
            .then(() => {
               //console.log("bdm Feedback successfully");
                callAxios(
      environment.REACT_APP_API_URL_Live +
        "api/v1/cam-bdm-feedback/save-cam-bdm-feedback",
      { feedback: feedbackForm },
      "post"
    ).then((response) => {
      if (response.data.status === 200) {
        window.onbeforeunload = null;
        $("#bdm-feedback-form").css("display", "none");
        toast.success("Feedback submitted!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        
      } else {
        toast.error("something went wrong!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setTimeout(() => redirect(siteUrl), 1000);
    });
            })
            .catch((error) => {
              redirect(siteUrl)
              console.log(error);
            });
        
        
      
    });
  };

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const StarRating = () => {
    return (
      <div className="rating-wrapper d-flex gap-2 fs-5">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <i
              className={
                index <= (hover || rating)
                  ? "bi bi-star-fill text-primary"
                  : "bi bi-star text-secondary"
              }
              key={"star-"+index}
              onClick={() => setRating(index)}
              onDoubleClick={() => {
                setRating(0);
                setHover(0);
              }}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(rating)}
            ></i>
          );
        })}
      </div>
    );
  };

  //console.log(user)
  /* Cam states */
  const [didFaceAnyIssue, setDidfaceAnyIssue] = useState(undefined);
  const [keyDiscussionPoint, setKeyDiscussionPoint] = useState(undefined);
  const [didTheClientExpress, setDidTheClientExpress] = useState(undefined);
  const [doYouAnticipat, setDoYouAnticipat] = useState(undefined);
  const [potentialRisks, setPotentialRisks] = useState(undefined);
  const [rateThePerformance, setRateThePerformance] = useState(0);
  const [suggestionForBdm, setSuggestionForBdm] = useState(undefined);
  const [describeTheTechnicalIssue, setDescribeTheTechnicalIssue] =
    useState(undefined);

  //console.log(keyDiscussionPoint, didTheClientExpress,doYouAnticipat,potentialRisks,rateThePerformance,suggestionForBdm,didFaceAnyIssue,describeTheTechnicalIssue)

  const redirect = (url) => {
    //window.location.href = url;
    window.close();
  };

  const updateAppVersion = async (roomId,  comingFrom) => {
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      //console.log(docData.id, ' => ', docData.data());
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
       
      if(comingFrom === "cam"){
        updateDoc(formRef, {
          
          camAppVersion: localStorage.getItem("appVersion")
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
          });  
      }

      if(comingFrom === "bdm"){
        updateDoc(formRef, {
          bdmAppVersion: localStorage.getItem("appVersion"),
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
          });  
      }
        
    });
  };

   
useEffect(()=>{
  // console.log("Cam yes no---------->",camStartYesNo,camStartYesNo.length)
  if(camStartYesNo.length > 0){
    if(camStartYesNo === "No"){
     
      document.getElementById("camBodyYesNo").classList.add("d-none");
      document.getElementById("camNoOpted-Additional-Question").classList.remove("d-none");
      
    } else{
      console.log("in yes")
      document.getElementById("camBodyYesNo").classList.remove("d-none");
      document.getElementById("camNoOpted-Additional-Question").classList.add("d-none");
    }
  }
 
},[camStartYesNo])

useEffect(()=>{
  // console.log("Cam yes no---------->",camStartYesNo,camStartYesNo.length)
  if(bdmStartYesNo.length > 0){
    if(bdmStartYesNo === "No"){
     
      document.getElementById("BdmBodyYesNo").classList.add("d-none");
      document.getElementById("bdmNoOpted-Additional-Question").classList.remove("d-none");
      
    } else{
      console.log("in yes")
      document.getElementById("BdmBodyYesNo").classList.remove("d-none");
      document.getElementById("bdmNoOpted-Additional-Question").classList.add("d-none");
    }
  }
 
},[bdmStartYesNo])
 
  const camSubmitHandler = async (e) => {
    e.preventDefault();
    localStorage.setItem("isClientCall", false);

    

    
    $("#cam-feedback-form").css("display", "block");

    let data = [];
    if(camStartYesNo === "No"){
       data = [
        {
          question:
            "Has the client joined us for this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: camStartYesNo,
          userType: "cam",
        },
        
        {
          question:
            "Would you like to make any updates in the CRM for other stakeholders?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: camClientJoined,
          userType: "cam",
        }
      ];
    } else {
      data = [
        {
          question:
            "Has the client joined us for this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: camStartYesNo,
          userType: "cam",
        },
        {
          question:
            "Could you summarize the key discussion points from the call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: keyDiscussionPoint,
          userType: "cam",
        },
        {
          question:
            "Did the client express interest in any of our products or services?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: didTheClientExpress,
          userType: "cam",
        },
        {
          question: "What do you anticipate as the next steps after this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: doYouAnticipat,
          userType: "cam",
        },
        {
          question:
            "Were there any potential risks that you identified during the call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: potentialRisks,
          userType: "cam",
        },
        {
          question:
            "How would you rate the performance of the BDM during this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: rating,
          userType: "cam",
        },
        {
          question:
            "Do you have any suggestions for the BDM to improve for future calls?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: suggestionForBdm,
          userType: "cam",
        },
        {
          question: "Did you face any technical issues during the NM-Live call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: didFaceAnyIssue,
          userType: "cam",
        },
      ];
    }

   
    $("#cam-form").attr("disabled", "disabled");
    saveCamFormToFirestore(roomId,data);
    
    
    
  };

  /* cam state end */

  /* BDM states */

  const [keyDiscussionPointBdm, setKeyDiscussionPointBdm] = useState(undefined);
  const [didTheClientExpressBdm, setDidTheClientExpressBdm] =
    useState(undefined);
  const [nextStep, setNextStep] = useState(undefined);
  const [doYouHaveResource, setDoYouHaveResource] = useState(undefined);
  const [didYouFindAnyResource, setDidYouFindAnyResource] = useState(undefined);
  const [identifiedAnyRisks, setIdentifiedAnyRisks] = useState(undefined);
  const [rateTheCam, setRateTheCam] = useState(0);
  const [didFaceAnyIssueBdm, setDidfaceAnyIssueBdm] = useState(undefined);
  const [describeTheTechnicalIssueBdm, setDescribeTheTechnicalIssueBdm] =
    useState(undefined);



  const bdmSubmitHandler = (e) => {
    e.preventDefault();
    //updateAppVersion(roomId, "bdm");
    window.onbeforeunload = null;
    localStorage.setItem("isClientCall", false);
    
    $("#bdm-feedback-form").css("display", "block");
    $("#bdm-form").attr("disabled", "disabled");

    let data = [];

    if(bdmStartYesNo === "Yes"){
      data = [
        {
          question: "Has the client joined us for this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: bdmStartYesNo,
          userType: "bdm",
        },
        {
          question: "Please summarize the key points discussed during the call.",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: keyDiscussionPointBdm,
          userType: "bdm",
        },
        {
          question:
            "Did the client express interest in any of our products or services?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: didTheClientExpressBdm,
          userType: "bdm",
        },
        {
          question: "What are the next steps following this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: nextStep,
          userType: "bdm",
        },
        {
          question:
            "Do you have resources in your own team for this requirement?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: doYouHaveResource,
          userType: "bdm",
        },
        {
          question: "Can you identify any risks based on this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: identifiedAnyRisks,
          userType: "bdm",
        },
        {
          question: "How would you rate the CAM person on this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: rating,
          userType: "bdm",
        },
        {
          question:
            "Did you encounter any technical difficulties during the call in NM-Live?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: didFaceAnyIssueBdm,
          userType: "bdm",
        },
      ];
    }  else {
      data = [
        {
          question: "Has the client joined us for this call?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: bdmStartYesNo,
          userType: "bdm",
        },
        {
          question: "What are your thoughts on this requirement, given the details currently available?",
          email: user?.email,
          uid: user?.uid,
          roomId: roomId,
          answer: bdmClientJoined,
          userType: "bdm",
        }
      ];
    }
   

    if (doYouHaveResource === "no") {
      const newObj = {
        question:
          "Did you find any resource for this requirement on Global Bench?",
        email: user?.email,
        uid: user?.uid,
        roomId: roomId,
        answer: didYouFindAnyResource,
        userType: "bdm",
      };
      data.splice(4, 0, newObj);
    }

    if (didFaceAnyIssueBdm === "yes") {
      const newObj = {
        question: "Could you please specify the issue?",
        email: user?.email,
        uid: user?.uid,
        roomId: roomId,
        answer: describeTheTechnicalIssueBdm,
        userType: "bdm",
      };
      data.push(newObj);
    }

    saveBdmFormToFirestore(roomId,data, user?.email);
  };
  /* bdm state ended */

  const saveFeedbackToCRM = async(feeback,subject,leadid,CRMID) =>{
    console.log(feeback)
    let bodyHtml = '';
    
    bodyHtml +='<body style="font-family: Arial, Helvetica, sans-serif;margin: 0;padding: 15px 0;"><div class="container" style="width: 100%; max-width: 1140px; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto;box-sizing: border-box;">';

    bodyHtml +='<div class="cover_box"><div class="heading_title" style="font-size: 1.5rem; font-weight: 600; text-align: left; text-transform: capitalize; margin-bottom: 10px;">'+subject+' <span style="color: #0d6efd;font-size: 1rem;">(Via NM-Live)</span></div><div class="main_wrapper" style="border: 1px solid #ccc; border-radius: 8px; overflow: auto;">';

    feeback.forEach((item,index)=>{
      bodyHtml +='<div class="ques_ans_wrapper" style="background-color: rgba(0,0,0,.03);border-bottom: 1px solid #ccc;padding: 1rem;"><div class="ques_detail" style="color: #0d6efd; margin-bottom: 5px;">'+item['question']+'</div><div class="ans_detail" style="color: #3d3d3d;margin-bottom: 5px;">'+item['answer']+'</div></div>';
    })

    bodyHtml +='</div></div></div></body>';
    
    const data = JSON.stringify({
      operation:"createannotations",
      leadid: leadid,
      subject:subject,
      notetext:bodyHtml,
      MSCRMCallerID:CRMID
    });
  //  const data = {
  //     "operation":"createannotationsdev",
  //     "leadid":"1b704204-8fe9-ec11-bb3d-000d3a0a6045",
  //     "subject":"Test Subject 12 dec23",
  //     "notetext":"<p>hello test body</p>",
  //     "MSCRMCallerID":"cf4bc695-7eb9-ec11-983f-002248d5efb4"
  // }

    console.log(data)

     await callCrmApi(data)
  }

  const isRequriedCam = camStartYesNo ==="Yes" ? true: false;
  const isRequriedCamForAdditional = camStartYesNo ==="Yes" ? false: true;
  const isRequriedBdm = bdmStartYesNo ==="Yes" ? true: false;
  const isRequriedBdmForAdditional = bdmStartYesNo ==="Yes" ? false: true;
  return (
    <div>
      <ToastContainer />
      {/* BDM Question After Call Section */}
      <div className="bdm-question-after-call">
        <div
          className="modal fade"
          id="bdmQuestionModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="bdmQuestionModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content text-body">
              <form
                id="bdmQuestionModal"
                className="d-flex flex-column overflow-hidden"
                method="post"
                onSubmit={bdmSubmitHandler}
              >
                <div class="modal-header bg-primary text-white px-lg-4 py-2">
                  <div>
                  <h6 class="modal-title">BDM Feedback Details</h6>
                  <div className="text-light">*Providing feedback is crucial, as it will be automatically recorded in the CRM.</div>
                  </div>
                  <div
                    id="bdm-feedback-form"
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      marginTop: "10px",
                      display: "none",
                    }}
                  >
                    <div
                      class="meeting-pointer-spinner"
                      style={{ marginRight: "10px" }}
                    ></div>
                    Please wait ....
                  </div>
                  {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ filter: 'invert(1)', opacity: 1 }}></button> */}
                  <button
                    type="submit"
                    id="bdm-form"
                    className="btn btn-success border-white"
                    style={{ minWidth: "80px" }}
                  >
                    Submit
                  </button>
                </div>
                <div className="modal-body px-lg-4 py-4 small">
                <div className="mb-3">
                    <label for="noting" className="form-label text-muted">
                      1. Has the client joined us for this call?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="startQuestionBdmyesNo"
                          value="Yes"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setBdmStartYesNo(e.target.value);
                          }}
                        />
                        <label className="form-check-label">
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="startQuestionBdmyesNo"
                          value="No"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setBdmStartYesNo(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="gridRadios2ioi">
                          No
                        </label>
                      </div>
                     
                    </div>
                  </div>
                  <div className="mb-3 d-none" id="bdmNoOpted-Additional-Question">
                    <label  className="form-label text-muted">
                      2. What are your thoughts on this requirement, given the details currently available?
                      call?
                    </label>
                    <textarea
                      className="form-control"
                     
                      rows="4"
                      placeholder=""
                      required = {isRequriedBdmForAdditional}
                      onChange={(e) => {
                        setClientJoinedBdm(e.target.value);
                      }}
                    ></textarea>
                  </div>
                  
                  <div className="d-none" id="BdmBodyYesNo">
                  <div className="mb-3">
                    <label for="bdmTextarea1" className="form-label text-muted">
                      2. Please summarize the key points discussed during the
                      call.
                    </label>
                    <textarea
                      className="form-control"
                      id="bdmTextarea1"
                      rows="4"
                      placeholder=""
                      required = {isRequriedBdm}
                      onChange={(e) => {
                        setKeyDiscussionPointBdm(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-muted">
                      3. Did the client express interest in any of our products
                      or services?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios1"
                          id="gridRadios1"
                          value="Yes, interested in Team Model Services"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDidTheClientExpressBdm(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="gridRadios1">
                          Yes, interested in Team Model Services
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios1"
                          id="gridRadios2"
                          value="Yes, interested in Dedicated Services"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDidTheClientExpressBdm(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="gridRadios2">
                          Yes, interested in Dedicated Services
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios1"
                          id="gridRadios3"
                          value="no"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDidTheClientExpressBdm(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="gridRadios3">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      for="bdmAnticipate"
                      className="form-label text-muted"
                    >
                      4. What are the next steps following this call?
                    </label>
                    <select
                      className="form-select"
                      name="anticipate"
                      id="bdmAnticipate"
                      required = {isRequriedBdm}
                      onChange={(e) => {
                        setNextStep(e.target.value);
                      }}
                    >
                      <option selected value="">
                        Please select
                      </option>
                      <option value="Schedule Interview with Candidates">
                        Schedule Interview with Candidates
                      </option>
                      <option value="Need to Share Time Estimation">
                        Need to Share Time Estimation
                      </option>
                      <option value="Need to Share Proposal">
                        Need to Share Proposal
                      </option>
                      <option value="Need Another Meeting">
                        Need Another Meeting
                      </option>
                      <option value="Client Will Update Us">
                        Client Will Update Us
                      </option>
                      <option value="No Idea, CAM Knows">
                        No Idea, CAM Knows
                      </option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-muted">
                      5. Do you have resources in your own team for this
                      requirement?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios2"
                          id="yes2"
                          value="yes"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDoYouHaveResource(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="yes2">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios2"
                          id="no2"
                          value="no"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDoYouHaveResource(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="no2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  {doYouHaveResource === "no" ? (
                    <div className="mb-3">
                      <label className="form-label text-muted">
                        6. Did you find any resource for this requirement on
                        Global Bench?
                      </label>
                      <div className="check-wrapper">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="checkRadios3"
                            id="yes3"
                            value="yes"
                            required = {isRequriedBdm}
                            onClick={(e) => {
                              setDidYouFindAnyResource(e.target.value);
                            }}
                          />
                          <label className="form-check-label" for="yes3">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="checkRadios3"
                            id="no3"
                            value="no"
                            required = {isRequriedBdm}
                            onClick={(e) => {
                              setDidYouFindAnyResource(e.target.value);
                            }}
                          />
                          <label className="form-check-label" for="no3">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="mb-3">
                    <label for="bdmTextarea2" className="form-label text-muted">
                      {doYouHaveResource === "yes" ? 6 : 7}. Can you identify
                      any risks based on this call?
                    </label>
                    <textarea
                      className="form-control"
                      id="bdmTextarea2"
                      rows="4"
                      placeholder=""
                      required = {isRequriedBdm}
                      onChange={(e) => {
                        setIdentifiedAnyRisks(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="mb-4">
                    <label className="form-label text-muted">
                      {doYouHaveResource === "yes" ? 7 : 8}. How would you rate
                      the CAM person on this call?
                    </label>
                    <StarRating />
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-muted">
                      {doYouHaveResource === "yes" ? 8 : 9}. Did you encounter
                      any technical difficulties during the call in NM-Live?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios4"
                          id="yes4"
                          value="yes"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDidfaceAnyIssueBdm(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="yes4">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="checkRadios4"
                          id="no4"
                          value="no"
                          required = {isRequriedBdm}
                          onClick={(e) => {
                            setDidfaceAnyIssueBdm(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="no4">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* (This question only appears if the answer to the previous question was "Yes") */}
                  {didFaceAnyIssueBdm === "yes" ? (
                    <div className="mb-3">
                      <label
                        for="bdmTextarea3"
                        className="form-label text-muted"
                      >
                        {doYouHaveResource === "yes" ? 10 : 11}. Could you please
                        specify the issue?
                      </label>
                      <textarea
                        className="form-control"
                        id="bdmTextarea3"
                        rows="4"
                        placeholder=""
                        required = {isRequriedBdm}
                        onChange={(e) => {
                          setDescribeTheTechnicalIssueBdm(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  ) : null}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* CAM Question After Call Section */}
      <div className="cam-question-after-call">
        <div
          className="modal fade"
          id="camQuestionModal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="camQuestionModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content text-body">
              <form
                id="camQuestionModal"
                className="d-flex flex-column overflow-hidden"
                method="post"
                onSubmit={camSubmitHandler}
              >
                <div class="modal-header bg-primary text-white px-lg-4 py-2">
                  <div className="">
                  <h6 class="modal-title">CAM Feedback Details</h6>
                  <div className="text-light">*Providing feedback is crucial, as it will be automatically recorded in the CRM.</div>
                  </div>
                  <div
                    id="cam-feedback-form"
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      marginTop: "10px",
                      display: "none",
                    }}
                  >
                    <div
                      class="meeting-pointer-spinner"
                      style={{ marginRight: "10px" }}
                    ></div>
                    Please wait ....
                  </div>
                  <button
                    type="submit"
                    id="cam-form"
                    className="btn btn-success border-white"
                    style={{ minWidth: "80px" }}
                  >
                    Submit
                  </button>
                  {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ filter: 'invert(1)', opacity: 1 }}></button> */}
                </div>
                <div className="modal-body px-lg-4 py-4 small">
                <div className="mb-3">
                    <label for="" className="form-label text-muted">
                      1. Has the client joined us for this call?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasClientJoinedForCam"
                          value="Yes"
                          required
                          onClick={(e) => {
                            setCamStartYesNo(e.target.value);
                          }}
                        />
                        <label className="form-check-label">
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="hasClientJoinedForCam"
                          value="No"
                          required
                          onClick={(e) => {
                            setCamStartYesNo(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="">
                          No
                        </label>
                      </div>
                      
                    </div>
                  </div>
                  <div className="mb-3 d-none" id="camNoOpted-Additional-Question">
                    <label for="camNoOpted" className="form-label text-muted">
                      2. Would you like to make any updates in the CRM for other stakeholders?
                    </label>
                    <textarea
                      className="form-control"
                      id="camNoOpted"
                      rows="4"
                      placeholder=""
                      required ={isRequriedCamForAdditional}
                      onChange={(e) => {
                        setClientJoinedCam(e.target.value);
                      }}
                    ></textarea>
                  </div>
                 
                  <div className="d-none" id="camBodyYesNo">
                  <div className="mb-3">
                    <label for="camTextarea1" className="form-label text-muted">
                      2. Could you summarize the key discussion points from the
                      call?
                    </label>
                    <textarea
                      className="form-control"
                      id="camTextarea1"
                      rows="4"
                      placeholder=""
                      required = {isRequriedCam}
                      onChange={(e) => {
                        setKeyDiscussionPoint(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-muted">
                      3. Did the client express interest in any of our products
                      or services?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios1"
                          id="camRadios1"
                          value="Yes, interested in Team Model"
                          required = {isRequriedCam}
                          onClick={(e) => {
                            setDidTheClientExpress(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="camRadios1">
                          Yes, interested in Team Model Services
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios1"
                          id="camRadios2"
                          value="Yes, interested in Dedicated Services"
                          required = {isRequriedCam}
                          onClick={(e) => {
                            setDidTheClientExpress(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="camRadios2">
                          Yes, interested in Dedicated Services
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios1"
                          id="camRadios3"
                          value="no"
                          required = {isRequriedCam}
                          onClick={(e) => {
                            setDidTheClientExpress(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="camRadios3">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      for="camAnticipate"
                      className="form-label text-muted"
                    >
                      4. What do you anticipate as the next steps after this
                      call?
                    </label>
                    <select
                      className="form-select"
                      name="anticipate"
                      id="camAnticipate"
                      required = {isRequriedCam}
                      onChange={(e) => {
                        setDoYouAnticipat(e.target.value);
                      }}
                    >
                      <option selected value="">
                        Please select
                      </option>
                      <option value="An interview with candidates needs to be scheduled">
                        An interview with candidates needs to be scheduled
                      </option>
                      <option value="BDM needs to provide a time estimation">
                        BDM needs to provide a time estimation
                      </option>
                      <option value="BDM needs to put forward a proposal">
                        BDM needs to put forward a proposal
                      </option>
                      <option value="BDM needs to arrange another meeting">
                        BDM needs to arrange another meeting
                      </option>
                      <option value="Client will update us">
                        Client will update us
                      </option>
                      <option value="Not sure, BDM should know.">
                        Not sure, BDM should know.
                      </option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label for="camTextarea2" className="form-label text-muted">
                      5. Were there any potential risks that you identified
                      during the call?
                    </label>
                    <textarea
                      className="form-control"
                      id="camTextarea2"
                      rows="4"
                      placeholder=""
                      required = {isRequriedCam}
                      onChange={(e) => {
                        setPotentialRisks(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="mb-4">
                    <label className="form-label text-muted">
                      6. How would you rate the performance of the BDM during
                      this call?
                    </label>
                    <StarRating />
                  </div>

                  <div className="mb-3">
                    <label for="camTextarea3" className="form-label text-muted">
                      7. Do you have any suggestions for the BDM to improve for
                      future calls?
                    </label>
                    <textarea
                      className="form-control"
                      id="camTextarea3"
                      rows="4"
                      placeholder=""
                      required = {isRequriedCam}
                      onChange={(e) => {
                        setSuggestionForBdm(e.target.value);
                      }}
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label className="form-label text-muted">
                      8. Did you face any technical issues during the NM-Live
                      call?
                    </label>
                    <div className="check-wrapper">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios2"
                          id="camRadios4"
                          value="yes"
                          required = {isRequriedCam}
                          onClick={(e) => {
                            setDidfaceAnyIssue(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="camRadios4">
                          Yes
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="camRadios2"
                          id="camRadios5"
                          value="no"
                          required = {isRequriedCam}
                          onClick={(e) => {
                            setDidfaceAnyIssue(e.target.value);
                          }}
                        />
                        <label className="form-check-label" for="camRadios5">
                          No
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* This question appears only if 'Yes' was selected for the previous question */}
                  {didFaceAnyIssue === "yes" ? (
                    <div className="mb-3">
                      <label
                        for="camTextarea4"
                        className="form-label text-muted"
                      >
                        9. Could you please describe the technical issue that
                        occurred?
                      </label>
                      <textarea
                        className="form-control"
                        id="camTextarea4"
                        rows="4"
                        placeholder=""
                        required = {isRequriedCam}
                        onChange={(e) => {
                          setDescribeTheTechnicalIssue(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  ) : null}

                  </div>
                 
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* additional question first  */}
      
    </div>
  );
};
