import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { callAxios, callCrmApi, convertDateTimeFromIso, convertReadable, convertReadableTime, getExpiryTime, getIndexBySystemUserId, timeDiffDaysHourMinSec } from "../utils/utils";
import { environment } from "../environment";
import searchIcon from '../resources/images/search.svg';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { auth, database, getAuth, idToken } from "../firebaseConfig";
// import preloader css
import "../LeadsListingPage/LeadsListingPage.css";
import PhoneCallCard from "../Cards/PhoneCallCard";
import AppointmentCard from "../Cards/AppointmentCard";
import EmailCard from "../Cards/EmailCard";

import NoteCard from "../Cards/NoteCard";
import TaskCard from "../Cards/TaskCard";
import { useSelector } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import CreateNotes from "../modals/CreateNotesModal";
import axios from "axios";

import userData from '../utils/Users.json';
import { isJwtExpired } from "jwt-check-expiration";
import { useNavigate } from "react-router-dom";

export const LeadAnnotation = () => {
  let navigate = useNavigate();
 // console.log(idToken)
 useEffect(()=>{
 // console.log("useEffect calling");
 //uncomment if you apply token system
  // if(localStorage.getItem("idToken") !== ''){
  //   const token = localStorage.getItem("idToken");
  //  // console.log(isJwtExpired(token))
  //   if(isJwtExpired(token)){
  //     const auth1 = getAuth();
  //         auth1.currentUser.getIdToken(true)
  //             .then(async function(idToken) {
                
  //               localStorage.setItem("idToken",idToken);
                
            
  //             }).catch(function(error) {
              
  //             });
  //   }
  // }
  
})
  const userInfo = useSelector((state) => state.userInfo);
    //console.log(userInfo)
  const [user, loading, error] = useAuthState(auth);
  const [active, setActive] = useState(null);
  const [allData, setAllDate] = useState([]);
  const [newLeadId, setNewLeadId] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [isLoadingSSA, setIsloadingSSA] = useState(false);
  const [leadId_, setLeadId] = useState('');


  const [leadIdFromSearch, setMessage] = useState('');

  const [meetingDetails, setMeetingDetails] = useState([]);

  const [searchText, setUpdated] = useState(leadIdFromSearch);

  const { state } = useLocation();
  const { leadId, userType, meetingList, name, image } = state;

  const [isReadMore, setIsReadMore] = useState(true);

  const [clientName, setClientName] = useState(null);
  const [description, setDescription] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const [country, setCountry] = useState(null);

  const [searchResults, setSearchResults] = useState([]);

  const [rdsUser, setRdsUser] = useState({});

 // console.log("meeting left side data, ",meetingList)

 
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  //console.log(leadId);

  const [noteData, setNoteData] = useState([]);
  const [new_leadid, setnew_leadid] = useState(null);

  const timeDiffDaysHourMinSec = (date_future, date_now) => {
 
    let delta = Math.abs(date_future - date_now) / 1000;
  
      // calculate (and subtract) whole days
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;
  
      // calculate (and subtract) whole hours
      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
  
      // calculate (and subtract) whole minutes
      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
  
      // what's left is seconds
      const seconds = delta % 60;
  
      let dayText = days > 1? " days": " day";
  
      let showDays = days > 0? days + dayText:'';
  
      let hourText = hours>0? " hrs": " hr";
  
      let showHours = hours > 0? hours + hourText:'';
  
      let minuteText = minutes>0? " mins": " min";
  
      let showMinutes = minutes > 0? minutes + minuteText:'';
  
      let secondText = seconds>0? " sec": " sec";
  
      let showSeconds = seconds > 0? seconds + secondText:'';
  
    
  
      return showDays +' '+ showHours + ' ' + showMinutes + ' ' + showSeconds +' Ago';
  }
  
  const getAnnotation = async (item,type) => {
    
    //console.log(item)
    let dateNew;
    let atTime;
    if(type === 'search'){
      const fireBaseTime = new Date(
        item.timeStamp
      );
      dateNew = fireBaseTime.toDateString();
      atTime = fireBaseTime.toLocaleTimeString();
    }
    else{
      const fireBaseTime = new Date(
        item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
      );
      dateNew = fireBaseTime.toDateString();
      atTime = fireBaseTime.toLocaleTimeString();
    }
    

    setClientName(item.name);
    setDescription(item.description);
    setDateTime(convertReadable(item.timeStamp) + ' ' +convertReadableTime(item.timeStamp));
    setCountry(item.country);
    setNewLeadId(null);

    const data = JSON.stringify({
      "operation":"fetchleadbyemail",
      "email": item.email,
    });

    // const response = await callCrmApi(token,data)
    // console.log(response)

    const urlEmail = "https://u1mn5pz8p1.execute-api.us-east-1.amazonaws.com/dev/nmdata?operation=fetchleadbyemail&email="+item.email;

    const getDataByEmail = await axios.get(urlEmail);

    //console.log(getDataByEmail.data.data[0])
    if(getDataByEmail.data.data[0]){
      const newLeadID = getDataByEmail.data.data[0].new_leadid?getDataByEmail.data.data[0].new_leadid:"";
    
    setNewLeadId(newLeadID);

    const opportunityId = getDataByEmail.data.data[0]._qualifyingopportunityid_value?getDataByEmail.data.data[0]._qualifyingopportunityid_value:'';

    const leadId = getDataByEmail.data.data[0].leadid?getDataByEmail.data.data[0].leadid:'';

    const annotationPayload = JSON.stringify({
      "operation":"fetchannotations",
      "leadid": leadId,
      "opportunityid":opportunityId
  })

  setLeadId(leadId)
 // console.log(annotationPayload)

  const annotationResponse = await callCrmApi(annotationPayload)
   // console.log(annotationResponse.data.data)

    // const annotation = await callAxios(
    //   environment.REACT_APP_API_URL_Live + "api/v1/leads/get-note-nm",
    //   { email: item.email, apiKey: "nmlive" },
    //   "post"
    // );

   //console.log(annotation.data.leadData.new_leadid);
   const fullData = annotationResponse.data.data;
   //console.log("data length:::", fullData)
      
    const TotalEle = fullData.length;
      for(let i=0; i<fullData.length;i++){
        const getArrIndex = getIndexBySystemUserId(userData,fullData[i]._createdby_value);
        // const user = await Users.findOne({ where:{systemuserid: fullData[i]._createdby_value}});
        if(getArrIndex > -1){
          fullData[i]['display_name'] = userData[getArrIndex].display_name;
          fullData[i]['image'] = userData[getArrIndex].image;
         
        }
              if (i + 1 === fullData.length) {
                ////console.log(TotalEle);
          // Last one.
        } else {
          // Not last one.
          ////console.log(item.createdon, index, index+1,arrayObj[index+1].createdon)
          const diff = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[i+1].createdon));
          const totalTime = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[TotalEle-1].createdon));
         
          ////console.log("Time Diff::",timeAgo)
          fullData[i]['timeAgo'] = diff;
          fullData[i]['totalTime'] = totalTime;
          fullData[i]['opportunityId'] = opportunityId;
          fullData[i]['leadId'] = leadId;


        }
       
      }
      //console.log(fullData)
     setNoteData(fullData);
    
     
    
    setIsloading(false);
    } else {
      setNoteData([]);
      setIsloading(false);
    }
    
  };
//console.log(userData)
//console.log(noteData)
  useEffect(()=>{
    if (loading) return;
    if (!user) navigate("/");
    if(meetingList === "fromNav"){
      const queryConstraints = [];
     
      queryConstraints.push(orderBy('timeStamp', 'desc'));
      const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);
      //console.log(user.email);
  
          onSnapshot(q, (querySnapshot) => {
              let meetings = [];
              querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                data["id"] = doc.id;
                if(userType === "CAM"){
                  if(data.attendantEmail === user.email  && (data.clientJoinedLiveTime || Number(data.CallDuration) > 50)){
                      meetings.push({ ...doc.data(), id: doc.id });
                  }
                }
  
                if(userType === "BDM"){
                  if(data.attendantBdmEmail === user.email &&  (data.clientJoinedLiveTime || Number(data.CallDuration) > 50)){
                      meetings.push({ ...doc.data(), id: doc.id });
                  }
                }
                
                
                
              });
              setMeetingDetails(meetings);
              
            if(meetings.length > 0){
              //console.log("inside 1")
              processData(meetings[0],"meeting");
            }
            else {
              // console.log("use effect calling",meetingList.lenght)
              // console.log("inside 2")
               setIsloadingSSA(true)
             }
              //setIsloading(false)
            });
  
         

     
    } else {
      if(meetingList.length > 0){
        //console.log("inside 1")
        processData(meetingList[0],"meeting");
      } else {
       // console.log("use effect calling",meetingList.lenght)
       // console.log("inside 2")
        setIsloadingSSA(true)
      }
    }
    
    callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/crm/get-user",
      { email: user.email },
      "post"
    ).then((user) =>{
      setRdsUser(user.data.user);
    })
    
  },[])

  
// console.log(rdsUser)
  const processData = (item,type) => {
    setIsloading(true);
   // console.log("in right top data");
    setActive(item.id);
    getAnnotation(item,type);
  };
//console.log(meetingDetails)
let meetinghtml;

if(meetingList === "fromNav"){
  meetinghtml = meetingDetails.map((item, id) => {
    const fireBaseTime = new Date(
      item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
    );
    const dateNew = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();
    let timeStampfor = dateNew + " " + atTime;
    return (
      <div
        className={`client-card rounded-4 position-relative p-3 pe-4 mb-2 ${
          item.id === active ? "selected" : ""
        }`}
        key={item.id}
        onClick={() => processData(item,"meeting")}
      >
        {item.name ? (
          <div className="client-name fs-5 text-light text-capitalize mb-1">
            {item.name}
          </div>
        ) : null}
        {item.timeStamp ? (
          <div className="client-date fs-6 text-white text-opacity-50 text-capitalize lh-sm mb-1">
            {dateNew}
            <span>{atTime}</span>
          </div>
        ) : null}
        {item.country ? (
          <div className="client-location fs-6 text-warning text-opacity-75 text-capitalize">
            {item.country}
          </div>
        ) : null}
      </div>
    );
  });
} else {
  meetinghtml = meetingList.map((item, id) => {
    const fireBaseTime = new Date(
      item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
    );
    const dateNew = fireBaseTime.toDateString();
    const atTime = fireBaseTime.toLocaleTimeString();
    let timeStampfor = dateNew + " " + atTime;
    return (
      <div
        className={`client-card rounded-4 position-relative p-3 pe-4 mb-2 ${
          item.id === active ? "selected" : ""
        }`}
        key={"annotationlist-"+item.id}
        onClick={() => processData(item,"meeting")}
      >
        {item.name ? (
          <div className="client-name fs-5 text-light text-capitalize mb-1">
            {item.name }
          </div>
        ) : null}
        {item.timeStamp ? (
          <div className="client-date fs-6 text-white text-opacity-50 text-capitalize lh-sm mb-1">
            {item.timeStamp.split("T")[0]}
            <span> {convertReadableTime(item.timeStamp)}</span>
          </div>
        ) : null}
        {item.country ? (
          <div className="client-location fs-6 text-warning text-opacity-75 text-capitalize">
            {item.country}
          </div>
        ) : null}
      </div>
    );
  });
}

 
  const loggedInUser = useSelector((state) => state.userInfo);
    const userRoleStore = useSelector((state) => state.userRole);
    const loggedInUserRole = userRoleStore? userRoleStore:localStorage.getItem("userRole");

  const handleChange = async(event,loggedInUser, loggedInUserRole) => {

   //console.log(event.target.value,loggedInUser,loggedInUserRole)
    setMessage(event.target.value);

    if(event.target.value === ""){
      setSearchResults([])
    }
    if(event.target.value.length > 0){
      
      const getSearchRecords = await callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/leads/autocomplete",
        { keyword: leadIdFromSearch,loggedInUser:loggedInUser,loggedInUserRole:loggedInUserRole },
        "post"
      );
    // console.log(getSearchRecords.data.leadData)
      setSearchResults(getSearchRecords.data.leadData)
    }
    
  };



const handleSearch = async() =>{
    setUpdated(leadIdFromSearch);
    //console.log("in Search::", leadIdFromSearch)
    if(leadIdFromSearch === ""){
        document.getElementById("search-box").style.border = "1px solid red";
        return false;
    }



    

}


const meetinghtmlSearch = searchResults.map((item, id) => {
 
  return (
    <div
      className={`client-card rounded-4 position-relative p-3 pe-4 mb-2 ${
        item.id === active ? "selected" : ""
      }`}
      key={item.id}
      onClick={() => processData(item,"search")}
    >
      {item.name ? (
        <div className="client-name fs-5 text-light text-capitalize mb-1">
          {item.name}
        </div>
      ) : null}
      {item.timeStamp ? (
        <div className="client-date fs-6 text-white text-opacity-50 text-capitalize lh-sm mb-1">
          {convertReadable(item.timeStamp)}
          <span>{convertReadableTime(item.timeStamp)}</span>
        </div>
      ) : null}
      {item.country ? (
        <div className="client-location fs-6 text-warning text-opacity-75 text-capitalize">
          {item.country}
        </div>
      ) : null}
    </div>
  );
});


// console.log("html length::",item?.notetext.length)
  return (
    <>
    <div className="client-listing-page py-4 py-xxl-5">
      <div className="container-fluid container-xxl">
        {/* CAM & BDM Info */}
<div className="cam-bdm-info d-flex align-items-center gap-3 rounded-1 p-3 mb-3" style={{backgroundColor: '#282826'}}>
    <div className="user-img position-relative">
        <img className="img-fluid rounded-circle border border-secondary" src={image} alt="" style={{width:'60px',height:'60px'}} />
        <div className="user-status position-absolute rounded-circle" style={{backgroundColor: 'green'}}></div>
    </div>
    <div className="user-detail text-truncate">
        <div className="name fs-5 text-light text-capitalize text-truncate">{name}</div>
        {/* <div className="date small text-capitalize">Date 26 July 2023</div> */}
    </div>
</div>
        <div className="client-detail-wrapper">
          <div className="row">
            <div className="col-xl-3 col-md-4 mb-4 mb-md-0">
              <div className="client-list-section">
              <div className='sticky-top search-bg-color mb-3 p-3'>
   <div className='searchfield-wrapper position-relative'>
      <input type="search" id="search-box" className='form-control bg-transparent text-light py-2' placeholder='Enter leadId or Name...' onChange={(event)=>{handleChange(event,loggedInUser,loggedInUserRole)}}  />
      <img src={searchIcon} style={{cursor:"pointer"}} onClick={(event)=>{handleChange(event,loggedInUser,loggedInUserRole)}}  alt='Search Icon' className='position-absolute top-50 end-0 translate-middle' />
   </div>
</div>
                {searchResults.length > 0? meetinghtmlSearch:meetinghtml}
                
                </div>
            </div>
            <div className="col-xl-9 col-sm-8">
              <div className="client-info-details p-3 pb-4">
                <div
                  className="row align-items-center g-2 small"
                  id="lead-more-details"
                >
                  {/* {isLoading ? (
                    <div id="meeting-pointer-Preloader">
                      <div class="meeting-pointer-spinner"></div> Please wait
                      ....
                    </div>
                  ) : null} */}
                  <div className="col-xl-8">
                                <div className="client-name fw-medium text-light text-capitalize mb-1" style={{fontSize:'18px'}}>{clientName}</div>

                                
                                {description ?
                                <div className="requrement-label text-white text-opacity-50 mb-1">Requirement Details</div>
                                :null}
                                <div className="client-requrement">{description}</div>
                            </div>
                            <div className="col-xl-4 text-xl-end mt-3 mt-xl-0">
                            { userType === "SSA" || userType === "SA"? null:
                            <div class="dropdown mb-2">
                              <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="bi bi-plus-circle"></i>
                              </button>
                              <ul class="dropdown-menu">
                             
                                <li><a class="dropdown-item pointer" data-bs-toggle="modal"  data-bs-target="#openCreateNoteModal">Create Note</a></li>
                                {/* <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li> */}
                              </ul>
                            </div>
                            }
                                <div className='attn-date text-white text-opacity-50 mb-1'> <span className='date'>{dateTime}</span> <span className='time'></span></div>
                                {/* <div className='attendent-name text-white text-opacity-50 mb-1'>CAM Puneet Kanda Already in The Group</div> */}
                                {country?
                                <div className="cntry-name title-label text-capitalize text-warning text-opacity-75 mb-1">client location {country}</div>
                                :null}
                                {newLeadId?
                                <div className="cntry-name title-label text-capitalize text-warning text-opacity-75">{newLeadId}</div> 
                                :null }
                            </div>
                </div>

                {/* BDM & CAM Details  */}
                <div className="cam-bdm-attn-details mt-4">
                  <div
                    className="fw-medium text-white"
                    style={{ letterSpacing: "0.5px", fontSize: "18px" }}
                  >
                    {isLoading || isLoadingSSA? null : "All Details"} 
                   
                  </div>

                  <div className="cam-bdm-wrapper">
                    {isLoading
                      ? (
                        <div id="meeting-pointer-Preloader">
                          <div class="meeting-pointer-spinner"></div> Please wait
                          ....
                        </div>
                      )
                      // : noteData?.data?.annotationData.map((item, index) => (
                        : 
                        noteData.length > 0 ?

                        noteData?.map((item, index) => (
                          <div className="cam-bdm-card d-flex gap-2 gap-lg-3 mt-4">
                            <div className="user-wrapper text-center" style={{
                                  height: "40px",
                                  width: "40px"
                                }}>
                              <img
                                src={
                                  item.image
                                    ? item.image
                                    : require("../resources/images/Avatar.png")
                                }
                                alt=""
                                className="border border-1 rounded-circle shadow overflow-hidden"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  maxWidth: "40px",
                                  maxHeight:"40px"
                                }}
                              />
                              {item.timeAgo?
                              <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
                              :null }
                              {/* |
                              {item.timeAgo?
                              <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
                              :null } */}
                            </div>
                            {item.activitytypecode === "phonecall"?
                           
                              <PhoneCallCard item={item} clientName={clientName} />
                              :
                              item.activitytypecode === "appointment"?
                              <AppointmentCard item={item} clientName={clientName}  userData={userData} />
                              :
                              item.activitytypecode === "email"?
                              <EmailCard item={item} clientName={clientName}  userData={userData} />
                              :
                              item.activitytypecode === "task"?
                              <TaskCard item={item} clientName={clientName} />
                              :
                              item.objecttypecode === "lead" || item.objecttypecode === "opportunity"?
                              <NoteCard item={item} clientName={clientName} />
                              :
                            null}
                            {/* <ReadMore item={item} /> */}
                         
                          </div>
                        ))
                      :"No data found"
                      }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateNotes user={user} rdsUser={rdsUser} leadId={leadId_}/>
    </>
    
  );
};