import { Client  } from "@twilio/conversations";
import { v4 as uuidv4 } from "uuid";
import { callAxios } from "./utils";
import { environment } from "../environment";
import $ from "jquery";


let twilio = {
    adminClient:null,
    admin_author_identity: null,
    admin_author_name:null,
    admin_author_image:null,
    admin_current_conversation:null,
    admin_current_conversation_sid:null,
    admin_conversations:[],
    admin_conversationParticipant:[]
    
}

export const getTokenFromTwilioForConversation = async (name,identity) => {
//    console.log(name,identity)    
    const response = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/crm/generate-token`, {identity:identity}, "post");
     const token = response.data;
    
    if (token) {

        twilio.admin_author_identity = identity;
        twilio.admin_author_name = name;
        if(twilio.adminClient){
            twilio.adminClient.shutdown();
        }
        authadminClient(token);
        admin_initializedEvent();
        return token;
    }
  };

  const regeneratetTokenFromTwilioForConversation = async (identity) => {
    const randomId = uuidv4();
    // console.log("refreshing token")
    const response = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/crm/generate-token`, {identity:identity}, "post");
  //////////////console.log(response)
    const token = response.data;
    
    if (token) {

        twilio.author_identity = $("#author-name").data("author-identity");
        twilio.author_name = $("#author-name").data("author-name");
        if(twilio.client){
            twilio.client.shutdown();
        }
      authClient(token);
      admin_initializedEvent();
      return token;
    }
  };

  const authClient = (token) =>{
    //////console.log("in initializing client from utils")
    twilio.client = new Client(token);
  }


  $.fn.scrollDown=function(){
    let el=$(this)
    el.scrollTop(el[0].scrollHeight)
}
  
  const authadminClient = (token) =>{
    console.log("initializing admin adminClient")
    twilio.adminClient = new Client(token);
  }


  const admin_initializedEvent = () =>{
    twilio.adminClient.on('stateChanged',(state)=>{
        twilio.adminClient.getSubscribedConversations().then(function(conversation){
            if(conversation.items.length === 0){
                $("#adminconversationListLeftSide").html("<p style='text-align:center;margin-top:20px;'>Conversation not found!!</p>")
            }
        })

        
        
    })

    twilio.adminClient.on('conversationAdded',(conversation)=>{
        twilio.admin_conversations = [];
        $("#chatDateFilter").removeClass("d-none");
        const filterDate = $("#chatDateFilter").val();
        conversation.getMessages().then(function(messages){
            messages.items.forEach(function(message){
                // //console.log(message)
                if(formatOnlyDate(message.dateCreated) === formatOnlyDate(filterDate)){
                    let shortName = twilio.admin_author_name.split(' ').reduce((acc, subname) =>
                    acc + subname[0], '')
              $("#admin-current-user-short-name").html(shortName)
              $("#AdminshowCurrentChatName").html(twilio.admin_author_name)
              $("#AdmincurrentUser").removeClass("d-none")
              $("#adminconversationListLeftSide").html("");
            //   $("#new-chat-search-filter-admin").trigger("click");
              createadmin_conversationsArray(conversation);
              sortadmin_conversationsByDate()
              appendConversationToList()
              if(conversation.sid===localStorage.getItem("AdminlastConversation")){
                  $('.conversation-'+localStorage.getItem("AdminlastConversation")).trigger('click');
                  $("#admin_chat-printing-area").animate({
                      scrollTop: $("#admin_chat-printing-area").prop('scrollHeight')
                    },1000);
              }
                }
                
                
            })
        });
        // let shortName = twilio.admin_author_name.split(' ').reduce((acc, subname) =>
        //       acc + subname[0], '')
        // $("#admin-current-user-short-name").html(shortName)
        // $("#AdminshowCurrentChatName").html(twilio.admin_author_name)
        // $("#admin-current-user-short-name").removeClass("d-none")
        // $("#adminconversationListLeftSide").html("");
        // $("#new-chat-search-filter").trigger("click");
        // createadmin_conversationsArray(conversation);
        // sortadmin_conversationsByDate()
        // appendConversationToList()
        // if(conversation.sid===localStorage.getItem("AdminlastConversation")){
        //     $('.conversation-'+localStorage.getItem("AdminlastConversation")).trigger('click');
        //     $("#admin_chat-printing-area").animate({
        //         scrollTop: $("#admin_chat-printing-area").prop('scrollHeight')
        //       },1000);
        // }
        
       
        
        
    })

    twilio.adminClient.on('conversationJoined',(conversation)=>{
        console.log('conversation joined');
        ////console.log(conversation)

    })

    twilio.adminClient.on('conversationLeft',(conversation)=>{
        //console.log('conversation left');
       // //console.log(conversationLeft)
       $(".conversation-"+conversation.sid).remove();
    })

    twilio.adminClient.on('conversationRemoved',(conversation)=>{
        console.log('conversation Removed');

        $(".conversation-"+conversation.sid).remove();
        
    })

    twilio.adminClient.on('conversationUpdated',(conversation)=>{
        
        //console.log('conversation updated');
       // //console.log(conversation)
        if(typeof conversation.updateReasons !== 'undefined'){
            let reasons = conversation.updateReasons;
            if(reasons.includes('lastMessage')){
                get_last_message(conversation.conversation);
                $("#adminconversationListLeftSide").prepend($('.conversation-'+conversation.conversation.sid));
            }
        }
    })

    twilio.adminClient.on('messageAdded',(message)=>{
        //console.log('message Added');
        //console.log(message)
        // const messaging = getMessaging();
        if(message.conversation.sid === twilio.admin_current_conversation_sid){
            //console.log(message.conversation._participants)
        message.conversation._participants.forEach(function(participant){
            if(participant.identity !== message.author){
                //console.log(participant.identity)
                // callAxios(environment.REACT_APP_API_URL_Live+'api/v1/fcm/send-push-notification',{title: twilio.admin_author_name, textBody: message.body, email:participant.identity,type:"chat",link:''}, "post" )
            }
        })
    
             messageTemplate(message);
       
        
            $("#admin_chat-printing-area").animate({
                scrollTop: $("#admin_chat-printing-area").prop('scrollHeight')
              },1000);
       }
    })

    twilio.adminClient.on('messageRemoved',(messageRemoved)=>{
        //console.log('message removed');
        ////console.log(messageRemoved)
    })

    twilio.adminClient.on('messageUpdated',(messageUpdated)=>{
        //console.log('message updated');
        ////console.log(messageUpdated)
    })

    twilio.adminClient.on('participantJoined',(participantJoined)=>{
        //console.log('participant Joined');
       // //console.log(participantJoined)
    })

    twilio.adminClient.on('participantLeft',(participantLeft)=>{
        //console.log('participant left');
        ////console.log(participantLeft)
    })

    twilio.adminClient.on('participantUpdated',(participant)=>{
        //console.log('participant updated');
        ////console.log(participant)

       
    })

    twilio.adminClient.on('tokenExpired',(tokenExpired)=>{
         console.log("in Token expired admin ")
        //console.log('tokenExpired');
       // //console.log(tokenExpired)
    })

   

    twilio.adminClient.on('typingStarted',(participant)=>{
        //console.log('typingStarted');
        //console.log(participant);
        if(participant.conversation.sid === twilio.admin_current_conversation_sid){
            $("#typing-indicator").removeClass("d-none");
        $("#typing-indicator").addClass("d-flex");

        let name = participant.attributes.name;

        $("#typing-indicator").html(name + ' is typing...');
        }
        

    })

    twilio.adminClient.on('typingEnded',(participant)=>{
        //console.log('typingEnded');
        ////console.log(participant);

        $("#typing-indicator").addClass("d-none");
        $("#typing-indicator").html("");
    })

    twilio.adminClient.on('tokenAboutToExpire',(tokenAboutToExpire)=>{
        //console.log('tokenAboutToExpire');
       // //console.log(tokenAboutToExpire)
    })

    twilio.adminClient.on('userSubscribed',(userSubscribed)=>{
        console.log('userSubscribed');
       
       
        $("#leftLoader-chat-user").addClass("d-none");
        $('#adminconversationListLeftSideUsers').addClass('d-none');
        $("#adminconversationListLeftSide").removeClass("d-none");
        $("#back-go").removeClass("d-none");
        
       //console.log(userSubscribed)
        // $("#adminconversationListLeftSide").html('')
        // userSubscribed.updateFriendlyName($("#author-name").data("author-name"))
    })
    $(document).on('click','#back-go',function(){
        $("#admin_chat-printing-area").html("");
        $("#newChatAdmin").val("");
        $('#adminconversationListLeftSideUsers').removeClass('d-none');
        $("#adminconversationListLeftSide").addClass("d-none");
        $(this).addClass("d-none");
        $("#admin-current-user-short-name").html('');
        $("#admin-current-user-short-name").addClass('d-none');
        $("#AdminshowCurrentChatName").html('');
    })
    twilio.adminClient.on('userUnsubscribed',(userUnsubscribed)=>{
        //console.log('userUnsubscribed');
        ////console.log(userUnsubscribed)
    })

    twilio.adminClient.on('userUpdated',(userUpdated)=>{
        //console.log('userUpdated');
       // //console.log(userUpdated)
    })  
  }


 const createadmin_conversationsArray = (conversation) =>{
    //console.log("my conversation:::",conversation)
    let obj = {
        sid:conversation.sid,
        dateCreated:(typeof conversation.lastMessage !== 'undefined')?conversation.lastMessage.dateCreated : conversation.dateCreated,
        friendlyName: conversation.friendlyName,
        uniqueName: conversation.uniqueName,
        lastMessage: conversation.lastMessage,
        conversationObj: conversation,
        createdBy: conversation.createdBy,
        
    }

    let index = twilio.admin_conversations.findIndex(_element =>_element.sid === conversation.sid);

    if(index > -1)
        twilio.admin_conversations[index] = obj;
    else
        twilio.admin_conversations.push(obj);
 }

 const sortadmin_conversationsByDate = () =>{
    twilio.admin_conversations.sort(function(a,b){
        return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
    })
 }
  const appendConversationToList = () =>{
    $("#adminconversationListLeftSide").html('');
        if(twilio.admin_conversations.length > 0){
            twilio.admin_conversations.forEach(function(conversation){
                let item = conversationTemplate(conversation,conversation.conversationObj);
                ////console.log(conversation)
                $("#adminconversationListLeftSide").append(item);

                get_last_message(conversation.conversationObj)
            })
        }
        

        

        
        
  }

 
  const conversationTemplate = (conversation,conversationObj)=>{
    //console.log(conversationObj)
    let friendlyNAME;
        let friendlyEMAIL;
        if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group && conversation.createdBy === twilio.admin_author_identity)
        {
            friendlyNAME = conversation.friendlyName;
            friendlyEMAIL = conversationObj.attributes.participant;
       
        }
        else if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group)
        {


            friendlyNAME = conversationObj.attributes.participant_name;
            friendlyEMAIL = conversationObj.attributes.participant_email;;
        }
        else
        {
            friendlyNAME = conversation.friendlyName;
            friendlyEMAIL = conversationObj.attributes.participant;
        }
        
        let shortName = friendlyNAME.split(' ').reduce((acc, subname) =>
              acc + subname[0], '')
        
        let conversationItems;
        if(localStorage.getItem("AdminlastConversation") !=="" && conversation.sid === localStorage.getItem("AdminlastConversation")){
            conversationItems = '<div class="active user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer conversation-'+conversation.sid+' admin-openconversation"  data-conversation-sid="'+conversation.sid+'" data-friendly-name="'+friendlyNAME+'" data-email="'+friendlyEMAIL+'"><span class="unread-messages-count-'+conversation.sid+'"></span>';
        } else{

            conversationItems = '<div class="user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer conversation-'+conversation.sid+' admin-openconversation"  data-conversation-sid="'+conversation.sid+'" data-friendly-name="'+friendlyNAME+'" data-email="'+friendlyEMAIL+'"><span class="unread-messages-count-'+conversation.sid+'"></span>';
        }
        
        
        conversationItems +='<div class="w-75 d-flex align-items-center gap-2"><div class="user-pic position-relative">';

        // conversationItems += '<img src="" alt="User" class="user-chip" />';

        conversationItems += '<div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"><div class="user-shortname text-blue">'+shortName.toUpperCase()+'</div></div></div>';
        
        // conversationItems += '<div class="user-status position-absolute z-1"><span class="bg-success text-white"><i class="bi bi-check-circle"></i></span> </div></div><div class="user-detail text-nowrap text-truncate">';

        conversationItems += '<div class="user-status position-absolute z-1"> </div></div><div class="user-detail text-nowrap text-truncate">';

        
        
        if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group && conversation.createdBy === twilio.admin_author_identity)
        {
        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm">'+conversation.friendlyName+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.uniqueName+'</div>';
        }
        else if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group)
        {


        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm">'+conversationObj.attributes.participant_name+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.attributes.participant_email+'</div>';
        }
        else
        {
        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm">'+conversation.friendlyName+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.uniqueName+'</div>';
        }
       
        
        
        
        // conversationItems +='</div></div><div class="flex-fill text-end small">'+formatDate(conversation.dateCreated)+'</div></div>';
        conversationItems +='</div></div><div class="flex-fill text-end small"></div></div>';

        return conversationItems;
        // $("#adminconversationListLeftSide").prepend(conversationItems);
  }

  const conversationTemplateByDate = (conversation,conversationObj)=>{
    //console.log(conversationObj)
    let friendlyNAME;
        let friendlyEMAIL;
        if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group && conversation.createdBy === twilio.admin_author_identity)
        {
            friendlyNAME = conversation.friendlyName;
            friendlyEMAIL = conversationObj.attributes.participant;
       
        }
        else if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group)
        {


            friendlyNAME = conversationObj.attributes.participant_name;
            friendlyEMAIL = conversationObj.attributes.participant_email;;
        }
        else
        {
            friendlyNAME = conversation.friendlyName;
            friendlyEMAIL = conversationObj.attributes.participant;
        }
        
        let shortName = friendlyNAME.split(' ').reduce((acc, subname) =>
              acc + subname[0], '')
        
        let conversationItems;
        if(localStorage.getItem("AdminlastConversation") !=="" && conversation.sid === localStorage.getItem("AdminlastConversation")){
            conversationItems = '<div class="active user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer conversation-'+conversation.sid+' admin-openconversation"  data-conversation-sid="'+conversation.sid+'" data-friendly-name="'+friendlyNAME+'" data-email="'+friendlyEMAIL+'"><span class="unread-messages-count-'+conversation.sid+'"></span>';
        } else{

            conversationItems = '<div class="user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer conversation-'+conversation.sid+' admin-openconversation"  data-conversation-sid="'+conversation.sid+'" data-friendly-name="'+friendlyNAME+'" data-email="'+friendlyEMAIL+'"><span class="unread-messages-count-'+conversation.sid+'"></span>';
        }
        
        
        conversationItems +='<div class="w-75 d-flex align-items-center gap-2"><div class="user-pic position-relative">';

        // conversationItems += '<img src="" alt="User" class="user-chip" />';

        conversationItems += '<div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"><div class="user-shortname text-blue">'+shortName.toUpperCase()+'</div></div></div>';
        
        // conversationItems += '<div class="user-status position-absolute z-1"><span class="bg-success text-white"><i class="bi bi-check-circle"></i></span> </div></div><div class="user-detail text-nowrap text-truncate">';

        conversationItems += '<div class="user-status position-absolute z-1"> </div></div><div class="user-detail text-nowrap text-truncate">';

        
        
        if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group && conversation.createdBy === twilio.admin_author_identity)
        {
        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm">'+conversation.friendlyName+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.uniqueName+'</div>';
        }
        else if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group)
        {


        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm">'+conversationObj.attributes.participant_name+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.attributes.participant_email+'</div>';
        }
        else
        {
        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm">'+conversation.friendlyName+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.uniqueName+'</div>';
        }
       
        
        
        
        // conversationItems +='</div></div><div class="flex-fill text-end small">'+formatDate(conversation.dateCreated)+'</div></div>';
        conversationItems +='</div></div><div class="flex-fill text-end small"></div></div>';

        return conversationItems;
        // $("#adminconversationListLeftSide").prepend(conversationItems);
  }

  const get_last_message = (conversation)=>{
    conversation.getMessages(1).then(function(messages){
        messages.items.forEach(function(message){
       
        $(".last-message-"+conversation.sid).html(message.body)
        })
    })
  }

  const formatDate = (dateVal) =>{
    let newDate  = new Date(dateVal);
    let sMonth  =   padValue(newDate.getMonth()+1);
    let sDay  =   padValue(newDate.getDate());
    let sYear  =   newDate.getFullYear();
    let sHour  =   newDate.getHours();
    let sMinute  =   padValue(newDate.getMinutes());
    let sAMPM  =   'AM';

    let iHourCheck = parseInt(sHour);

    if(iHourCheck > 12){
        sAMPM = 'PM';
        sHour = iHourCheck - 12;

    } else if(iHourCheck === 0){
        sHour  = 12;

    }
    sHour = padValue(sHour);

    return sMonth +'-'+ sDay +'-'+ sYear +" "+ sHour +":"+ sMinute +" "+sAMPM;

  }

  const formatOnlyDate = (dateVal) =>{
    let newDate  = new Date(dateVal);
    let sMonth  =   padValue(newDate.getMonth()+1);
    let sDay  =   padValue(newDate.getDate());
    let sYear  =   newDate.getFullYear();
    let sHour  =   newDate.getHours();
    let sMinute  =   padValue(newDate.getMinutes());
    let sAMPM  =   'AM';

    let iHourCheck = parseInt(sHour);

    if(iHourCheck > 12){
        sAMPM = 'PM';
        sHour = iHourCheck - 12;

    } else if(iHourCheck === 0){
        sHour  = 12;

    }
    sHour = padValue(sHour);

    return sYear +'-'+ sMonth +'-'+ sDay;

  }

  const padValue = (value) =>{
    return (value < 10)? "0" +value:value;
  }

  $(document).on('click','.admin-openconversation',function(){

   //console.log(twilio.admin_author_name)
    let sid = $(this).data('conversation-sid');
    let friendlyName = twilio.admin_author_name;
    let participantEmail = $(this).data('email');
    $(".input-items").removeClass("d-none");
    $("#AdminshowCurrentChatName").html(friendlyName);
    let shortName = friendlyName.split(' ').reduce((acc, subname) =>
              acc + subname[0], '')
    $("#admin-current-user-short-name").html(shortName);
    // $("#chatDateFilter").removeClass("d-none")
    $("#audio-call").attr("value",participantEmail);
    $("#audio-call").attr("data-name",friendlyName);
    $("#video-call").attr("value",participantEmail);
    $("#video-call").attr("data-name",friendlyName);

    $("#callAreaButtons").removeClass("d-none");
    $("#new-chat-wrapper").addClass("d-none");
    $("#new-chat-wrapper").removeClass("position-absolute");
    $("#new-chat-wrapper").removeClass("d-block");

    $("#AdmincurrentUser").removeClass('d-none');
    $(".message-wrap").removeClass("d-none");
    $('.admin-openconversation').removeClass("active");
    $("#admin_chat-printing-area").removeClass("d-none");
    $(".chatwindow-wrapper").addClass("d-none");
    $(this).addClass("active");
    // $('#admin_chat-printing-area').scrollDown();
    if(sid !== twilio.admin_current_conversation_sid){
        $("#typing-indicator").addClass("d-none");
        $("#typing-indicator").addClass("d-flex");
    }
    localStorage.setItem("AdminlastConversation",sid);
    twilio.admin_current_conversation_sid = sid;
    twilio.adminClient.getConversationBySid(sid).then(function(conversation){
        twilio.admin_current_conversation = conversation;
       
        loadMessages();

       
       
        
        
    })
    $("#admin_chat-printing-area").animate({
        scrollTop: $("#admin_chat-printing-area").prop('scrollHeight')
      },1000);
  })

 
export const openGroup = (conversationSid) =>{
  

        //console.log(twilio.admin_author_name)
         let sid = conversationSid;
         let friendlyName = twilio.admin_author_name;
         $(".input-items").removeClass("d-none");
         $("#AdminshowCurrentChatName").html(friendlyName);
         let shortName = friendlyName.split(' ').reduce((acc, subname) =>
                   acc + subname[0], '')
         $("#admin-current-user-short-name").html(shortName);
         // $("#chatDateFilter").removeClass("d-none")
        
     
         
         $("#new-chat-wrapper").addClass("d-none");
         $("#new-chat-wrapper").removeClass("position-absolute");
         $("#new-chat-wrapper").removeClass("d-block");
     
         $("#AdmincurrentUser").removeClass('d-none');
         $(".message-wrap").removeClass("d-none");
         $('.admin-openconversation').removeClass("active");
         $("#admin_chat-printing-area").removeClass("d-none");
         $(".chatwindow-wrapper").addClass("d-none");
         $(this).addClass("active");
         // $('#admin_chat-printing-area').scrollDown();
         if(sid !== twilio.admin_current_conversation_sid){
             $("#typing-indicator").addClass("d-none");
             $("#typing-indicator").addClass("d-flex");
         }
         localStorage.setItem("AdminlastConversation",sid);
         twilio.admin_current_conversation_sid = sid;
         twilio.adminClient.getConversationBySid(sid).then(function(conversation){
             twilio.admin_current_conversation = conversation;
            
             loadMessages();
     
            
            
             
             
         })
         $("#admin_chat-printing-area").animate({
             scrollTop: $("#admin_chat-printing-area").prop('scrollHeight')
           },1000);
       
}


const loadMessages = () =>{
    ////console.log(twilio.admin_current_conversation)
    $("#admin_chat-printing-area").html('');
    twilio.admin_current_conversation.getMessages().then(function(messages){
        messages.items.forEach(function(message){
            // //console.log(message)
            messageTemplate(message);
        })
    });
}

const loadMessagesByDate = (filterDate) =>{
    //console.log(twilio.admin_current_conversation,filterDate)
    $("#admin_chat-printing-area").html('');
    twilio.admin_current_conversation.getMessages().then(function(messages){
        messages.items.forEach(function(message){
            // //console.log(message)
            if(formatOnlyDate(message.dateCreated) === formatOnlyDate(filterDate)){
                messageTemplate(message);
            }
            
        })
    });
}

const messageTemplate = (message) => {
    ////console.log(message)
    // <span class="bg-warning text-white"><i class="bi bi-clock"></i></span> 
    // <span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>
    // <span class="bg-danger text-danger"><i class="bi bi-circle-fill"></i></span> 
    let messageTemplate;
    if(message.author !== twilio.admin_author_identity && typeof message.attributes.participant_name !== 'undefined'){
        let shortName = message.attributes.participant_name.split(' ').reduce((acc, subname) =>
        acc + subname[0], '')

        messageTemplate ='<div class="message-outside"><div class="box-wrapper mb-2 d-flex align-items-baseline gap-2"><div class="user-pic position-relative">';

       // messageTemplate += '<img src="" alt="User" class="user-chip" />';
        messageTemplate += '<div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"><div class="user-shortname text-blue">'+shortName.toUpperCase()+'</div></div></div>';

                                        
        // messageTemplate += '<div class="user-status position-absolute z-1"><span class="bg-success text-white"><i class="bi bi-check-circle"></i></span></div></div><div class="message-card">';

        messageTemplate += '<div class="user-status position-absolute z-1"></div></div><div class="message-card">';
        
        // messageTemplate +='<div class="msg-label text-capitalize fs-12 mb-1 text-muted">'+message.author.split("@")[0]+'<span class="ms-1"></span><span class="ms-1">'+formatDate(message.dateCreated)+'</span></div><div class="msg px-3 py-2">'+message.body+'</div></div></div></div> ';

        messageTemplate +='<div class="msg-label text-capitalize fs-12 mb-1 text-muted">'+message.attributes.participant_name+'<span class="ms-1"></span><span class="ms-1">'+formatDate(message.dateCreated)+'</span> </div>';
        
       if(typeof message.attributes.type !== 'undefined' && message.attributes.type === "media"){
        // messageTemplate +='<img src="" id="media-message-'+message.sid+'" style="height:100px">';
        console.log(message.media.contentType)
        if(message.media.contentType === "application/pdf" )
            {
                messageTemplate +='<div class="msg px-3 py-2">';
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-pdf-fill fs-3 text-danger" title="'+message.media.filename+'"></i></span><div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div><a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a></div>';

                messageTemplate += '</div>';
                // messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" style="height:100px">'+message.media.filename+'</a>';
            } else if(message.media.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            {
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-text-fill fs-3 text-primary" title='+message.media.filename+'></i></span>';

                messageTemplate +='<div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div>';
                
                messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a>';
                
                messageTemplate +='</div>';
               
            } else {
                messageTemplate +='<a href="javascript:;" class="image-preview" data-message-sid="'+message.sid+'"><img src="" id="media-message-'+message.sid+'" style="height:100px"></a>';
            }
        message.media.getContentTemporaryUrl().then(function(url){
           ////console.log(url)
            $('#doc-media-message-'+message.sid).attr('href', url);
             $('#media-message-'+ message.sid).attr('src', url);
        })
       } else {
        if(message.body.includes("http")){
            messageTemplate +='<a href="'+message.body+'" target="_blank"><div class="msg px-3 py-2">'+message.body+'</div></a>';
        }
        else{
            messageTemplate +='<div class="msg px-3 py-2">'+message.body+'</div>';
        }
       
       }
        
       
        
        messageTemplate +='</div></div></div> ';

        
    }
    else{
        messageTemplate = '<div class="message-myside message-card"><div class="box-wrapper mb-2"><div class="msg-label text-capitalize fs-12 mb-1 text-muted">'+message.attributes.participant_name+'<span class="ms-1">'+formatDate(message.dateCreated)+'</span></div>';

        if(typeof message.attributes.type !== 'undefined' && message.attributes.type === "media"){
            console.log(message.media.contentType)
            if(message.media.contentType === "application/pdf" )
            {
                messageTemplate +='<div class="msg px-3 py-2">';
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-pdf-fill fs-3 text-danger" title="'+message.media.filename+'"></i></span><div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div><a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a></div>';

                messageTemplate += '</div>';
                // messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" style="height:100px">'+message.media.filename+'</a>';
            } else if(message.media.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            {
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-text-fill fs-3 text-primary" title='+message.media.filename+'></i></span>';

                messageTemplate +='<div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div>';
                
                messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a>';
                
                messageTemplate +='</div>';
               
            } else {
                messageTemplate +='<a href="javascript:;" class="image-preview" data-message-sid="'+message.sid+'"><img src="" id="media-message-'+message.sid+'" style="height:100px"></a>';
            }
            
                message.media.getContentTemporaryUrl().then(function(url){
                  // //console.log(url)
                    // $('#media-link-'+message.sid).attr('href', url);
                     $('#media-message-'+ message.sid).attr('src', url);
                     $('#doc-media-message-'+ message.sid).attr('href', url);
                })
           } else {
            if(message.body.includes("http")){
                messageTemplate +='<a href="'+message.body+'" target="_blank"><div class="msg px-3 py-2">'+message.body+'</div></a>';
            }
            else{
                messageTemplate +='<div class="msg px-3 py-2">'+message.body+'</div>';
            }
           }
        
        // messageTemplate += '<div class="msg px-3 py-2">'+message.body+'</div>';
        
        messageTemplate += '</div>';
        
        if(message.author === twilio.admin_author_identity){
            messageTemplate += '<p class="d-none bg-dark font-small-1 pe-50 position-relative color-white ps-50 round start-0 text-center seen-'+message.index+'" style="width:100px; float:right; color:white">Seen</p>';
        }
        messageTemplate += '</div>';
       
    }
    

    $("#admin_chat-printing-area").append(messageTemplate);

}

$(document).on('click','.image-preview',function(){
    const messageId = $(this).data('message-sid');
    const url = $("#media-message-"+messageId).attr("src");
   
   window.open(url, '_blank');
})

// $(document).on('change',"#chatDateFilter",function(){
//     const dateFilter = $("#chatDateFilter").val();
//     console.log(dateFilter)
// })

export const filterChatByDate = (filterDate) =>{
    console.log(filterDate);
    loadMessagesByDate(filterDate);
}

export const getSubscribedUser = () =>{
    console.log("in subscribed users");
    twilio.adminClient.subscribedConversations
    .fetch()
    .then(conversations => {
        console.log(conversations)
      const subscribedUsers = [];
  
      conversations.forEach(conversation => {
        conversation.participants
          .fetch()
          .then(participants => {
            participants.forEach(participant => {
              subscribedUsers.push({
                identity: participant.identity,
                friendlyName: participant.friendlyName,
                attributes: participant.attributes,
              });
            });
          });
      });
  
      console.log('Subscribed Twilio Conversation users:', subscribedUsers);
    })
    .catch(error => {
      console.error('Error retrieving subscribed users:', error);
    });
}

export const conversationByDateFilter = async(dateFilter) =>{
    console.log("Getting participants::",dateFilter)
    const startDate = new Date('2023-11-23T00:00:00Z'); // Replace with your desired start date
    const endDate = new Date('2023-11-23T23:59:59Z'); // Replace with your desired end date
    const filterParams = {
        InteractionDateStart: startDate.toISOString(),
        InteractionDateEnd: endDate.toISOString(),
      };
// console.log(filterParams)
      const participants = await twilio.adminClient.participants.list({
        limit: 20, // Limit the number of participants returned
        // filterParams, // Apply the interacted date filter
      });

    //   console.log('Filtered Participants:');
for (const participant of participants) {
  console.log(`Participant Identity: ${participant.identity}`);
  console.log(`Last Interacted At: ${participant.dateUpdated}`);
  console.log('-------------------');
}
}