// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getPerformance } from "firebase/performance";

import {
  getAuth,
  signInWithPopup,
  OAuthProvider,
  onAuthStateChanged,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: "AIzaSyBvyzKU6pxVYSWFwfQ-9kN9o9M_PQhACp8",
  // authDomain: "live-194f8.firebaseapp.com",
  // projectId: "live-194f8",
  // storageBucket: "live-194f8.appspot.com",
  // messagingSenderId: "67213829889",
  // appId: "1:67213829889:web:0debf17e8ea683b5dd9d00"

  apiKey: "AIzaSyAWga21acDKGhY7aqGOtVdnMmCF0HGRWKY",
  authDomain: "live-35345.firebaseapp.com",
  projectId: "live-35345",
  storageBucket: "live-35345.appspot.com",
  messagingSenderId: "786978845564",
  appId: "1:786978845564:web:c0d40d778d1ba4b756aaf9",
  measurementId: "G-53V6QZ7FZX", 
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
getPerformance(app);
export const auth = getAuth(app);
export const database = getFirestore(app);
export const messaging = getMessaging(app);

const provider = new OAuthProvider("microsoft.com");
// provider.addScope('mail.read');
// provider.addScope('mail.send');
provider.addScope('sites.read.all');
provider.addScope('browsersitelists.read.all')
provider.setCustomParameters({
  prompt: "consent",
  tenant: "a28e6f27-7914-40eb-8b05-dc4a98d7b241",
});

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    messaging
      .requestPermission()
      .then(() => messaging.getToken())
      .then((firebaseToken) => {
        resolve(firebaseToken);
      })
      .catch((err) => {
        reject(err);
      });
  });

export {
  provider,
  OAuthProvider,
  signInWithPopup,
  getAuth,
  onAuthStateChanged,
  getToken,
  onMessage,
};
