import React from 'react'
import LeadDetailsLabel from './LeadLabel'
import LeadDetails from './LeadDetails'

const LeadDetailsSection = () => {
  return (
    <div className='lead_section_container d-flex flex-column justify-content-between h-100'>
        <LeadDetailsLabel />
        <LeadDetails />
    </div>
  )
}

export default LeadDetailsSection