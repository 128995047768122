import React, { useState } from "react";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { addDays, subDays } from "date-fns";

const DateRange = ({ onChange, startDate, endDate }) => {
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(startDate), 0),
      endDate: addDays(new Date(endDate), 0),
      key: "selection"
    }
  ]);
  const selectedVal = {
    startDate: subDays(new Date(), 0),
    endDate: addDays(new Date(), 1),
    key: "selection"
  }
//console.log("date ranges::",state[0])
  const handleOnChange = (ranges) => {
    //console.log(ranges.selection.startDate,ranges.selection.endDate);
    setState([
        {
            startDate: subDays(new Date(ranges.selection.startDate), 0),
            endDate: addDays(new Date(ranges.selection.endDate), 0),
            key: "selection"
          }
    ])
    const { selection } = ranges;
    onChange(selection);
    setState([selection]);
  };

  return (
    <DateRangePicker
      onChange={handleOnChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={1}
      ranges={state}
      direction="horizontal"
    />
  );
};

DateRange.propTypes = {
  onChange: PropTypes.func
};

export default DateRange;
