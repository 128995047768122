import { Client  } from "@twilio/conversations";
import { v4 as uuidv4 } from "uuid";
import { callAxios } from "./utils";
import { environment } from "../environment";
import $ from "jquery";

import interactionSound from "../static/message.mp3";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { database } from "../firebaseConfig";
import { is, time } from "@amcharts/amcharts4/core";

const messageSound = new Audio(interactionSound);
let twilio = {
    client:null,
    author_identity: null,
    author_name:null,
    author_image:null,
    current_conversation:null,
    current_conversation_sid:null,
    conversations:[],
    conversationParticipant:[],
    unreadCount:0,
    selectedIdentity:null,
    lastIndex:null
    
}
const playMessageSound = () =>{
    
    messageSound.play();
}
export const getTokenFromTwilioForConversation = async (identity) => {
    const randomId = uuidv4();
    
    const response = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/crm/generate-token`, {identity:identity}, "post");
  //////////////console.log(response)
    const token = response.data;
    
    if (token) {

        twilio.author_identity = $("#author-name").data("author-identity");
        twilio.author_name = $("#author-name").data("author-name");
        if(twilio.client){
            twilio.client.shutdown();
        }
      authClient(token);
      initializedEvent();
      return token;
    }
  };

  const regeneratetTokenFromTwilioForConversation = async (identity) => {
    const randomId = uuidv4();
    console.log("refreshing token")
    const response = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/crm/generate-token`, {identity:identity}, "post");
  //////////////console.log(response)
    const token = response.data;
    
    if (token) {

        twilio.author_identity = $("#author-name").data("author-identity");
        twilio.author_name = $("#author-name").data("author-name");
        if(twilio.client){
            twilio.client.shutdown();
        }
      authClient(token);
      initializedEvent();
      return token;
    }
  };
  $.fn.scrollDown=function(){
    let el=$(this)
    el.scrollTop(el[0].scrollHeight)
}
  
  const authClient = (token) =>{
    //////console.log("in initializing client from utils")
    twilio.client = new Client(token);
  }

//   export const authClientE = (token) =>{
    
//     //////console.log("in initializing client from app ja")
//     twilio.client = new Client(token);
//   }


  const initializedEvent = () =>{
    twilio.client.on('stateChanged',(state)=>{
       ////////console.log(state)

        twilio.client.getSubscribedConversations().then(function(conversation){

           ////////////console.log("my conversations:::",conversation.items.length)
            if(conversation.items.length === 0){
                $("#conversationListLeftSide").html("<p style='text-align:center;margin-top:20px;'>Conversation not found!!</p>")
            }
            else{
                conversation.items.forEach((conversation)=>{
                    get_unread_messages_count(conversation)
                })
            }
            
        })

        

        
        
    })

    twilio.client.on('connectionStateChanged', (currentState)=>{
            ////console.log(currentState)
            // twilio.client.setPushRegistrationId("fcm", "fwFt6B64KN1t3nxwppHgpg:APA91bFvl_QKmWkY3hLpeYUwgXDQ0FKHcbXKS-HJKaC7uSbM6fq2szwn0AhMfHGsx_gCRq5pzoGnkH2Hyj-d4Vf73Df38TloCDXwR6esYtDAWzXYYcGm0eaaT_QgMJyiaG4mH19yAqeh")
            // twilio.client.setPushToken("fwFt6B64KN1t3nxwppHgpg:APA91bFvl_QKmWkY3hLpeYUwgXDQ0FKHcbXKS-HJKaC7uSbM6fq2szwn0AhMfHGsx_gCRq5pzoGnkH2Hyj-d4Vf73Df38TloCDXwR6esYtDAWzXYYcGm0eaaT_QgMJyiaG4mH19yAqeh");
    })

    twilio.client.on('pushNotification',(notification)=>{
        ////console.log("in notificatiopn");
        ////console.log(notification)
    })

   

    twilio.client.on('conversationAdded',async(conversation)=>{
        ////console.log('conversation Added');
       //////////////console.log(conversation)
       
        // update_last_read_message_index(conversation);
        // twilio.admin_conversations = [];
        createConversationsArray(conversation);
        sortConversationsByDate()
        appendConversationToList()
        if(conversation.sid===localStorage.getItem("lastConversation")){
            $('.conversation-'+localStorage.getItem("lastConversation")).trigger('click');
            $("#message-box-scroll").animate({
                scrollTop: $("#message-box-scroll").prop('scrollHeight')
              },1000);
        }

       
        
       
       
        
    })

    twilio.client.on('conversationJoined',async(conversation)=>{
        ////console.log('conversation joined');
        //////////////console.log(conversation)
       
       
       //count_conversation_participants(conversation);
        if(conversation.createdBy === twilio.author_identity)
        update_author_attributes(conversation)

        if(conversation.attributes.group){
            $(".participant-list").html('');
            //following group name updating commented on 20 november 2023
           update_group_friendly_name(conversation)
        }
       
         
        // if(typeof conversation.updateReasons !== 'undefined'){
        //     let reasons = conversation.updateReasons;

        //     if(reasons.includes('lastMessage')  || reasons.includes('lastReadMessageIndex'))
        //          get_unread_messages_count(conversation.conversation)

          
          
        // }

        
        // ////////console.log(twilio.unreadCount)
        const unreadD = await conversation.getUnreadMessagesCount()
       
        twilio.unreadCount += unreadD;
         ////////console.log("unread conversation count::->",twilio.unreadCount)
         if(twilio.unreadCount > 0){
            $("#unreadConversatnoCount").html(twilio.unreadCount)
         }
         else{
            $("#unreadConversatnoCount").html('')
         }
    })

    twilio.client.on('conversationLeft',(conversation)=>{
        //////console.log('conversation left');
       // //////////////console.log(conversationLeft)
       $(".conversation-"+conversation.sid).remove();
    })

    twilio.client.on('conversationRemoved',(conversation)=>{
        //////console.log('conversation Removed');

        $(".conversation-"+conversation.sid).remove();
        
    })

    twilio.client.on('conversationUpdated',async(conversation)=>{
        //console.log('conversation updated-->',conversation);
        if(conversation.conversation.attributes.group){
            update_group_friendly_name(conversation.conversation)
        }
       
        set_message_status_seen(conversation.conversation);
       ////console.log(conversation)
        if(typeof conversation.updateReasons !== 'undefined'){
            let reasons = conversation.updateReasons;

            if(reasons.includes('lastMessage')  || reasons.includes('lastReadMessageIndex'))
                 get_unread_messages_count(conversation.conversation)

            // if(twilio.current_conversation_sid === conversation.sid){
               
            //      update_last_read_message_index(conversation.conversation);
            // }
           

            if(reasons.includes('lastMessage')){
                get_last_message(conversation.conversation);
                $("#conversationListLeftSide").prepend($('.conversation-'+conversation.conversation.sid));
            }
            // ////console.log(twilio.current_conversation_sid, conversation.conversation.sid)
           // update_last_read_message_index(conversation.conversation);
            
             
        }

       
    })

    twilio.client.on('messageAdded',async(message)=>{

       // console.log("messgaeAdded",message)
       
        // if(message.author === twilio.author_identity)
        // set_all_messages_read(message.conversation)
        

        if(message.author !== twilio.author_identity && twilio.current_conversation_sid  === message.conversation.sid){
            // update_last_read_message_index(message.conversation);
            // set_message_status_seen_by_participant(message.index);

            update_last_read_message_index_byparticipant(message.conversation,message.index)

            

        }

       
       
       
        twilio.unreadCount = 0;
        if(message.author !== twilio.author_identity){
            
            
            twilio.client.getSubscribedConversations().then(function(conversation){

                ////////////console.log("my conversations:::",conversation.items.length)
                 if(conversation.items.length > 0){
                     
                     conversation.items.forEach(async(conversation)=>{
                        const unreadD = await conversation.getUnreadMessagesCount()
       
                        twilio.unreadCount += unreadD;
                         ////////console.log("unread conversation count::->",twilio.unreadCount)
                         if(twilio.unreadCount > 0){
                            $("#unreadConversatnoCount").html(twilio.unreadCount)
                         }
                         else{
                            $("#unreadConversatnoCount").html('')
                         }
                     })
                 }
                 
             })
       
        }
        
        
        handlePushNotification(message);

       
        
        if(message.conversation.sid === twilio.current_conversation_sid){
            
      
            messageTemplate(message);
       
        
            $("#message-box-scroll").animate({
                scrollTop: $("#message-box-scroll").prop('scrollHeight')
              },1000);
 
              if(message.attributes.participant_email){
                $("."+message.attributes.participant_email.split("@")[0]).html('<span class="bg-success text-white"><i class="bi bi-check-circle"></i></span>')
               } else{
                $("."+message.attributes.participant_email.split("@")[0]).html('<span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>')
               }
       }
    })

    twilio.client.on('messageRemoved',(messageRemoved)=>{
        //////////////console.log('message removed');
        ////////////////console.log(messageRemoved)
    })

    twilio.client.on('messageUpdated',(message)=>{
        //////console.log('message updated');
        // ////////console.log(message, message.message.body)
        $(".otherSide-updated-message-"+message.message.sid).html(message.message.body)
        $(".other-side-isEdited-"+message.message.sid).html("Edited")
    })

    twilio.client.on('participantJoined',(participant)=>{
        //console.log('participant Joined');
       //console.log(participant)
       if(participant.conversation.attributes.group){
        $('.participant-list').html('');
        count_conversation_participants(participant.conversation)
        getGroupParticipantsEmail(participant.conversation).then((identities)=>{
        $("#audio-call").val(identities.join(","));
        $("#video-call").val(identities.join(","));
        })

        participant.conversation.getParticipants().then(function(participants){
            participants.forEach(function(participant){
                participant.getUser().then(function(user){
                    let template = participant_template(user);
                // //////////////console.log(template)
                    $('.participant-list').append(template);
                
                })
            })
        })
    }

       
    })

    twilio.client.on('participantLeft',(participant)=>{
        //console.log('participant left');
       //console.log(participant)
       
       
       if(participant.conversation.attributes.group){
        $('.participant-list').html('');
            count_conversation_participants(participant.conversation)
            getGroupParticipantsEmail(participant.conversation).then((identities)=>{
            $("#audio-call").val(identities.join(","));
            $("#video-call").val(identities.join(","));
            })

            
            participant.conversation.getParticipants().then(function(participants){
                participants.forEach(function(participant){
                    participant.getUser().then(function(user){
                        let template = participant_template(user);
                    // //////////////console.log(template)
                        $('.participant-list').append(template);
                    
                    })
                })
            })
        }
    })

    twilio.client.on('participantUpdated',async(participant)=>{
        // update_last_read_message_index(participant.participant.conversation);
       
    //    console.log("participantUpdated->",participant.participant.lastReadMessageIndex)
     
      

        if(participant.participant.conversation.attributes.group){
           //following group name updating commented on 20 november 2023
           getGroupParticipantsEmail(twilio.current_conversation).then((identities)=>{
            $("#audio-call").val(identities.join(","));
            $("#video-call").val(identities.join(","));
        })
           update_group_friendly_name(participant.participant.conversation)
            $(".participant-list").html('');
            participant.participant.conversation.getParticipants().then(function(participants){
             participants.forEach(function(participant){
                 participant.getUser().then(function(user){
                     $('.participant-list').append(participant_template(user));
                    
                     })
                 })
             })
        }
       

        
        if(typeof participant.updateReasons !=='undefined'){
            
            let reasons = participant.updateReasons;
            if(reasons.includes('lastReadMessageIndex') || reasons.includes('lastReadTimestamp')){
                get_unread_messages_count(participant.participant.conversation);

                  if(participant.participant.identity !== twilio.author_identity ){
                    // set_message_status_seen(participant.participant.conversation)
                    // //console.log(twilio.lastIndex)
                    set_message_status_seen_by_participant(participant.participant.lastReadMessageIndex)
                    
                  }

                  
                    
            
                // (participant.participant.conversation);
               
            }
        }
    })

    twilio.client.on('tokenExpired',(tokenExpired)=>{
        ////////console.log('tokenExpired');
        regeneratetTokenFromTwilioForConversation(twilio.author_identity)
       // //////////////console.log(tokenExpired)
    })

    // twilio.client.on('tokenAboutToExpire',(tokenAboutToExpire)=>{
    //     ////////console.log('tokenAboutToExpire');
    //     regeneratetTokenFromTwilioForConversation(twilio.author_identity)
    //    // //////////////console.log(tokenAboutToExpire)
    // })
   

    twilio.client.on('typingStarted',(participant)=>{
        //////////////console.log('typingStarted');
        //////////////console.log(participant);
        if(participant.conversation.sid === twilio.current_conversation_sid && participant.identity !== twilio.author_identity){
            $("#typing-indicator").removeClass("d-none");
        $("#typing-indicator").addClass("d-flex");

        let name = participant.attributes.name;

        $("#typing-indicator").html(name + ' is typing...');
        }
        

    })

    twilio.client.on('typingEnded',(participant)=>{
        //////////////console.log('typingEnded');
        ////////////////console.log(participant);

        $("#typing-indicator").addClass("d-none");
        $("#typing-indicator").html("");
    })

    

    twilio.client.on('userSubscribed',async(userSubscribed)=>{
    //console.log('userSubscribed');
      // ////console.log(userSubscribed)
        // $("#conversationListLeftSide").html('')
        // if(twilio)
        // userSubscribed.updateFriendlyName($("#author-name").data("author-name"))
       
        const user = await twilio.client.getUser(twilio.author_identity);
        //console.log(user.friendlyName)
        user.updateAttributes({isOnline: true})
        .then((userData) => {
            
                $("."+twilio.author_identity.split("@")[0]).html('<span class="bg-success text-white"><i class="bi bi-check-circle"></i></span>')
              
        });
    })

    twilio.client.on('userUnsubscribed',(userUnsubscribed)=>{
        //////////////console.log('userUnsubscribed');
        ////////////////console.log(userUnsubscribed)
    })

    twilio.client.on('userUpdated',(user)=>{
       // console.log('userUpdated---->',user);
       //console.log(userUpdated)
       if(user.user.attributes.isOnline){
        $("."+user.user.identity.split("@")[0]).html('<span class="bg-success text-white"><i class="bi bi-check-circle"></i></span>')
        
       } else{
        $("."+user.user.identity.split("@")[0]).html('<span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>')
       }
    })



   
  }

  const handlePushNotification = async(message) =>{
    
    if(message.conversation.attributes.group){
        ////////console.log("group notification");
        getGroupParticipantsEmail(message.conversation).then((identities)=>{
            ////////console.log("group notification::::",identities)
            ////////console.log(message.author,identities,twilio.author_identity)
            if(message.author !==twilio.author_identity){
                playMessageSound()
                callAxios(environment.REACT_APP_API_URL_Live+'api/v1/fcm/send-push-notification',{title: message.conversation.friendlyName, textBody: message.body, email:twilio.author_identity,type:"chat",link:'',groupParticipants:identities,notificationType:"group"}, "post" )
            }
            if(!identities.includes(message.author)){
              
                
            // callAxios(environment.REACT_APP_API_URL_Live+'api/v1/fcm/send-push-notification',{title: message.conversation.friendlyName, textBody: message.body, email:twilio.author_identity,type:"chat",link:'',groupParticipants:identities,notificationType:"group"}, "post" )
        }
        })
    } else {
        ////////console.log("private notification");
        if(message.author !== twilio.author_identity){
                playMessageSound()
            callAxios(environment.REACT_APP_API_URL_Live+'api/v1/fcm/send-push-notification',{title: message.attributes.participant_name, textBody: message.body, email:twilio.author_identity,type:"chat",link:'',groupParticipants:[],notificationType:"private"}, "post" )
        }
        
    }
  }

  const openTooltip = () => {
    $("#chathoverOption").removeClass("d-none");
};
const closeTooltip = () => {
    $("#chathoverOption").addClass("d-none");
};

$(document).on('mouseenter','.showHideMouseHoveOption',function(){
    const messageSid = $(this).data('message-sid');
    //////////console.log(messageSid)
    //////////console.log("in mouseenterevent")
    $("#chathoverOption-"+messageSid).removeClass("d-none");
})

$(document).on('mouseleave','.showHideMouseHoveOption',function(){
    const messageSid = $(this).data('message-sid');
    $("#chathoverOption-"+messageSid).addClass("d-none");
})

 const createConversationsArray = (conversation) =>{
    //////console.log("my conversation:::",conversation)
    let obj = {
        sid:conversation.sid,
        dateCreated:(typeof conversation.lastMessage !== 'undefined')?conversation.lastMessage.dateCreated : conversation.dateCreated,
        friendlyName: conversation.friendlyName,
        uniqueName: conversation.uniqueName,
        lastMessage: conversation.lastMessage,
        conversationObj: conversation,
        createdBy: conversation.createdBy,
        
    }

    let index = twilio.conversations.findIndex(_element =>_element.sid === conversation.sid);

    if(index > -1)
        twilio.conversations[index] = obj;
    else
        twilio.conversations.push(obj);
 }

 const sortConversationsByDate = () =>{
    twilio.conversations.sort(function(a,b){
        return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
    })
    
 }
  const appendConversationToList = () =>{
    $("#conversationListLeftSide").html('');
        if(twilio.conversations.length > 0){
            twilio.conversations.forEach(function(conversation){
                let item = conversationTemplate(conversation,conversation.conversationObj);
                ////////////////console.log(conversation)
                $("#conversationListLeftSide").append(item);

                // get_unread_messages_count(conversation.conversationObj);

                get_last_message(conversation.conversationObj)
            })
        }
        

        
        
  }

  const appendConversationToListAfterDelete = () =>{
    $("#conversationListLeftSide").html('');
        if(twilio.conversations.length > 0){
            twilio.conversations.forEach(function(conversation){
                let item = conversationTemplate(conversation,conversation.conversationObj);
                ////////////console.log(conversation)
                $("#conversationListLeftSide").append(item);

                //get_unread_messages_count(conversation.conversationObj);

                //get_last_message(conversation.conversationObj)
            })
        }
        

        
        
  }

  const conversationTemplate = (conversation,conversationObj)=>{
    //////////////console.log(conversationObj)
    let friendlyNAME;
        let friendlyEMAIL;
        let isGroup;
        if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group && conversation.createdBy === twilio.author_identity)
        {
            friendlyNAME = conversation.friendlyName;
            friendlyEMAIL = conversationObj.attributes.participant;
            isGroup = false;
       
        }
        else if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group)
        {


            friendlyNAME = conversationObj.attributes.participant_name;
            friendlyEMAIL = conversationObj.attributes.participant_email;
            isGroup = false;
        }
        else
        {
            friendlyNAME = conversation.friendlyName;
            friendlyEMAIL = conversationObj.attributes.participant;
            isGroup = true;
        }
        
        let shortName = friendlyNAME.split(' ').reduce((acc, subname) =>
              acc + subname[0], '')
        
        let conversationItems;
        if(localStorage.getItem("lastConversation") !=="" && conversation.sid === localStorage.getItem("lastConversation")){
            conversationItems = '<div class="active user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer conversation-'+conversation.sid+' open-conversation"  data-conversation-sid="'+conversation.sid+'" data-friendly-name="'+friendlyNAME+'" data-email="'+friendlyEMAIL+'" data-group="'+isGroup+'">';
        } else{

            conversationItems = '<div class="user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer conversation-'+conversation.sid+' open-conversation"  data-conversation-sid="'+conversation.sid+'" data-friendly-name="'+friendlyNAME+'" data-email="'+friendlyEMAIL+'" data-group="'+isGroup+'">';
        }
        
        
        conversationItems +='<div class="w-75 d-flex align-items-center gap-2"><div class="user-pic position-relative">';

        // conversationItems += '<img src="" alt="User" class="user-chip" />';

        // conversationItems += '<div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"><div class="user-shortname text-blue">'+shortName.toUpperCase()+'</div></div></div>';
        
        conversationItems +='<div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 small fw-semibold text-primary border border-primary">'+shortName.toUpperCase()+'</div>';
        
        if(!conversationObj.attributes.group){
            conversationItems += '<div class="user-status position-absolute z-1 '+friendlyEMAIL.split("@")[0]+'"> <span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span> </div></div><div class="user-detail text-nowrap text-truncate">';
        }
        

        conversationItems += '<div class="user-status position-absolute z-1"> </div></div><div class="user-detail text-nowrap text-truncate">';

        
        
        if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group && conversation.createdBy === twilio.author_identity)
        {
        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm unread-message-focus-'+conversation.sid+'">'+conversation.friendlyName+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.uniqueName+'</div>';
        }
        else if(typeof conversationObj.attributes.group !== 'undefined' && !conversationObj.attributes.group)
        {


        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm unread-message-focus-'+conversation.sid+'">'+conversationObj.attributes.participant_name+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.attributes.participant_email+'</div>';
        }
        else
        {
        conversationItems += '<div class="user-name text-capitalize text-truncate lh-sm edited-conversation-name-'+conversation.sid+'">'+conversation.friendlyName+'</div>';
        conversationItems += '<div class="user-email small text-truncate last-message-'+conversation.sid+'"></div>';
        // conversationItems += '<div class="user-email small text-truncate">'+conversation.uniqueName+'</div>';
        }
        
        conversationItems +='</div></div><div class="flex-fill text-end small text-truncate">'+formatDate(conversation.dateCreated)+'</div></div>';
        // conversationItems +='</div></div><div class="flex-fill text-end small">';
        // conversationItems +=' <div class="dateTime flex-fill text-end small">'+formatDate(conversation.dateCreated)+'</div>';
        // conversationItems +=' <div class="dropdown-option dropdown" style="display: none ">';
        // conversationItems +='  <button type="button" class="d-none btn border-0 lh-1 pe-0" data-bs-toggle="dropdown" aria-expanded="false" title="More option"><span class="d-inline"><i class="bi bi-three-dots"></i></span></button>';

        //   conversationItems +=' <ul class="dropdown-menu border rounded-1 shadow pb-0 overflow-auto" style=" max-height: 250px">';

        //     conversationItems +='<li class="small"><a class="dropdown-item" href="#"><i class="bi bi-bell-slash"></i> Mute</a></li> <li class="small bg-white border-top sticky-bottom mt-2"><a  class="dropdown-item py-2" href="#"><i class="bi bi-trash3"></i> Delete chat</a></li>';
        //     conversationItems +='  </ul> </div> </div></div>';

        return conversationItems;
        // $("#conversationListLeftSide").prepend(conversationItems);
  }

  const get_last_message = (conversation)=>{
    conversation.getMessages(1).then(function(messages){
        messages.items.forEach(function(message){
        
        // if(typeof conversation.attributes.group !== 'undefined' && !conversation.attributes.group && conversation.createdBy === twilio.author_identity)
        // {
        //     $(".last-message-"+conversation.sid).html("You: "+message.body)
       
        // }
        // else if(typeof conversation.attributes.group !== 'undefined' && !conversation.attributes.group)
        // {


        //     $(".last-message-"+conversation.sid).html(message.body)
        // }
        // else
        // {
        //     $(".last-message-"+conversation.sid).html("You: "+message.body)
        // }
        $(".last-message-"+conversation.sid).html(message.body)
        })
    })
  }

  const formatDate = (dateVal) =>{
    let newDate  = new Date(dateVal);
    let sMonth  =   padValue(newDate.getMonth()+1);
    let sDay  =   padValue(newDate.getDate());
    let sYear  =   newDate.getFullYear();
    let sHour  =   newDate.getHours();
    let sMinute  =   padValue(newDate.getMinutes());
    let sAMPM  =   'AM';

    let iHourCheck = parseInt(sHour);

    if(iHourCheck > 12){
        sAMPM = 'PM';
        sHour = iHourCheck - 12;

    } else if(iHourCheck === 0){
        sHour  = 12;

    }
    sHour = padValue(sHour);

    return sMonth +'-'+ sDay +'-'+ sYear +" "+ sHour +":"+ sMinute +" "+sAMPM;

  }

  const padValue = (value) =>{
    return (value < 10)? "0" +value:value;
  }

  $(document).on('click','.open-conversation',function(){
    $("#interactDocumentFirstChat").trigger("play")
    let sid = $(this).data('conversation-sid');
    let friendlyName = $(this).data('friendly-name');
    let participantEmail = $(this).data('email');
    let isGroup = $(this).data('group');
    twilio.selectedIdentity = participantEmail;
    if(isGroup){
        checkIfGroupCallIsOngoing(sid);
        $(".onlineu-status").html('');
    } else{
        $("#join-call").addClass("d-none")
        $("#audio-call").removeClass("d-none")
        $("#video-call").removeClass("d-none")
        $("#online-offline-status").attr("class","onlineu-status user-status position-absolute z-1 "+participantEmail.split("@")[0])
       update_user_status__(participantEmail).then(function(isUserOnline){
            if(typeof isUserOnline !==  'undefined' && isUserOnline){
           
            $(".onlineu-status").html('<span class="bg-success text-white"><i class="bi bi-check-circle"></i></span>')
        } else{
            
            $(".onlineu-status").html('<span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>')
        }
       })
    //    console.log(isUserOnline)
   
    }
    
    
    $(".input-items").removeClass("d-none");
   
    let shortName = friendlyName.split(' ').reduce((acc, subname) =>
              acc + subname[0], '')
    $("#current-user-short-name").html(shortName);
    // ////console.log(isGroup)
    if(isGroup){
        $("#showCurrentChatName").html(friendlyName);
         $("#showHideEditGroupOption").removeClass("d-none");
    } else {
        $("#showCurrentChatName").html(friendlyName);
         $("#showHideEditGroupOption").addClass("d-none");
    }
    
    
    // if(!$("#join-call").hasClass("joinConversationbtn-"+sid)){
        
    //     $("#join-call").addClass("joinConversationbtn-"+sid)
    // }
    
    
    $("#audio-call").attr("value",participantEmail);
    $("#audio-call").attr("data-name",friendlyName);
    $("#video-call").attr("value",participantEmail);
    $("#video-call").attr("data-name",friendlyName);
    

    $("#audio-call").attr("data-conversation-sid",sid);
    $("#video-call").attr("data-conversation-sid",sid);

    $("#callAreaButtons").removeClass("d-none");
    $("#new-chat-wrapper").addClass("d-none");
    $("#new-chat-wrapper").removeClass("position-absolute");
    $("#new-chat-wrapper").removeClass("d-block");

    $("#currentUser").removeClass('d-none');
    $(".message-wrap").removeClass("d-none");
    $('.open-conversation').removeClass("active");
    $("#chat-printing-area").removeClass("d-none");
    $(".chatwindow-wrapper").addClass("d-none");
    $(this).addClass("active");
    // $('#chat-printing-area').scrollDown();
    if(sid !== twilio.current_conversation_sid){
        $("#typing-indicator").addClass("d-none");
        $("#typing-indicator").addClass("d-flex");
    }
    localStorage.setItem("lastConversation",sid);
    twilio.current_conversation_sid = sid;
    twilio.client.getConversationBySid(sid).then(function(conversation){
        twilio.current_conversation = conversation;
        const callType = conversation.attributes.group? "group":"private";
        if(conversation.attributes.group){
            getGroupParticipantsEmail(conversation).then((identities)=>{
                $("#audio-call").val(identities.join(","));
                $("#video-call").val(identities.join(","));
            })
            
            
            $("#audio-call").attr("data-call-type",callType);
            $("#video-call").attr("data-call-type",callType);

            
        } else{
            $("#audio-call").attr("data-call-type",callType);
            $("#video-call").attr("data-call-type",callType);
        }
        
       
        loadMessages();

        count_conversation_participants(conversation);
        update_last_read_message_index(conversation);
        update_read_conversation_count(conversation);
        // set_message_status_seen(conversation);
        // get_online_users(conversation);

        setTimeout(function(){
            if(isGroup){

                conversation.getParticipants().then(function(participants){
                    participants.forEach(function(participant){
                        participant.getUser().then(function(user){
                            update_user_status__(user.identity).then(function(isUserOnline){
                                if(typeof isUserOnline !==  'undefined' && isUserOnline){
                               $(".onlineu-status").html('');
                                $("."+user.identity.split("@")[0]).html('<span class="bg-success text-white"><i class="bi bi-check-circle"></i></span>')
                            } else{
                                $(".onlineu-status").html('');
                                $("."+user.identity.split("@")[0]).html('<span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>')
                            }
                           })
                         
                        })
                    })
                })
            } else{
                 update_user_status__(participantEmail).then(function(isUserOnline){
                if(typeof isUserOnline !==  'undefined' && isUserOnline){
               
                $("."+participantEmail.split("@")[0]).html('<span class="bg-success text-white"><i class="bi bi-check-circle"></i></span>')
            } else{
                
                $("."+participantEmail.split("@")[0]).html('<span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>')
            }
           })
            }
           
        },2000)
       
        
        
    })
    $("#message-box-scroll").animate({
        scrollTop: $("#message-box-scroll").prop('scrollHeight')
      },1000);
  })

  $(document).on('keyup','#send-message-input',function(e){
    let message = $(this).val();
    
    if(message.length >= 1 && e.keyCode !== 13){
        //user is typing
        twilio.current_conversation.typing();
    } else if(e.keyCode === 13){
        sendMessage(message);
    }
  })

  $(document).on('click','#send-message-button',function(e){
    let message = $('#send-message-input').val();

    if(message.length >= 1){
      
        sendMessage(message);
    }
  })

const sendMessage = async(message) =>{
    if(message.length > 1){
        if(twilio.client){
            await twilio.current_conversation.prepareMessage()
            .setBody(message)
            .setAttributes({
                participant_name: twilio.author_name,
                participant_email: twilio.author_identity
            })
            .build()
            .send();
        }
       

    $("#send-message-input").val('');
    }
    else{
        $("#please-type-a-message").removeClass("d-none")
    }
    
}

const loadMessages = () =>{
    ////////////////console.log(twilio.current_conversation)
    $("#chat-printing-area").html('');
    twilio.current_conversation.getMessages().then(function(messages){
        messages.items.forEach(function(message){
            // //////////////console.log(message)
            messageTemplate(message);
        })
    });
}

const messageTemplate = async(message) => {
    //console.log(message)
    // <span class="bg-warning text-white"><i class="bi bi-clock"></i></span> 
    // <span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span>
    // <span class="bg-danger text-danger"><i class="bi bi-circle-fill"></i></span> 
    
    let messageTemplate;
    if(message.author !== twilio.author_identity && typeof message.attributes.participant_name !== 'undefined'){
        
        const isEdited = message.lastUpdatedBy === message.author?" Edited":''
        let shortName = message.attributes.participant_name.split(' ').reduce((acc, subname) =>
        acc + subname[0], '')

        messageTemplate ='<div class="message-outside"><div class="box-wrapper mb-2 d-flex align-items-baseline gap-2"><div class="user-pic position-relative">';

       // messageTemplate += '<img src="" alt="User" class="user-chip" />';
        // messageTemplate += '<div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"><div class="user-shortname text-blue">'+shortName.toUpperCase()+'</div></div></div>';

        messageTemplate +='<div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 small fw-semibold text-primary border border-primary" id="current-user-short-name">'+shortName.toUpperCase()+'</div>';

                                        
        // messageTemplate += '<div class="user-status position-absolute z-1"><span class="bg-success text-white"><i class="bi bi-check-circle"></i></span></div></div><div class="message-card">';
        // if(message.attributes.participant_email){
        //     const isOnline = await update_user_status__(message.attributes.participant_email);
        //    // console.log(isOnline)
        //     if(isOnline){
        //         messageTemplate += '<div class="user-status position-absolute z-1 '+message.attributes.participant_email.split("@")[0]+'"><span class="bg-success text-white"><i class="bi bi-check-circle"></i></span></div></div><div class="message-card">';
        //     }
        //      else{
        //         messageTemplate += '<div class="user-status position-absolute z-1 '+message.attributes.participant_email.split("@")[0]+'"> <span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span></div></div><div class="message-card">';
        //     }
        // } else{
        //     messageTemplate += '<div class="user-status position-absolute z-1"> </div></div><div class="message-card">';
        // }
        // let messageParticipant;
        // if(message.attributes.participant_email){
        //     messageParticipant = await update_user_status__(message.attributes.participant_email);
        // }

        // let username = Object.keys(message.attributes).length === 2? await update_user_status__(message.attributes.participant_email):"";
       
        // // console.log("message Attributes length:::",username)
       
        messageTemplate += '<div class="user-status position-absolute z-1 '+message.author.split("@")[0]+'"> </div></div><div class="message-card">';
        
        // messageTemplate +='<div class="msg-label text-capitalize fs-12 mb-1 text-muted">'+message.author.split("@")[0]+'<span class="ms-1"></span><span class="ms-1">'+formatDate(message.dateCreated)+'</span></div><div class="msg px-3 py-2">'+message.body+'</div></div></div></div> ';

        messageTemplate +='<div class="msg-label text-capitalize fs-12 mb-1 text-muted">'+message.attributes.participant_name+'<span class="ms-1"></span><span class="ms-1">'+formatDate(message.dateCreated)+'</span><b class="other-side-isEdited-'+message.sid+'">'+isEdited +'</b> </div>';
        
       if(typeof message.attributes.type !== 'undefined' && message.attributes.type === "media"){
        // messageTemplate +='<img src="" id="media-message-'+message.sid+'" style="height:100px">';
        if(message.media.contentType === "application/pdf" )
            {
                messageTemplate +='<div class="msg px-3 py-2">';
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-pdf-fill fs-3 text-danger" title="'+message.media.filename+'"></i></span><div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div><a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a></div>';

                messageTemplate += '</div>';
                // messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" style="height:100px">'+message.media.filename+'</a>';
            } else if(message.media.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            {
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-text-fill fs-3 text-primary" title='+message.media.filename+'></i></span>';

                messageTemplate +='<div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div>';
                
                messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a>';
                
                messageTemplate +='</div>';
               
            } else {
                messageTemplate +='<a href="javascript:;" class="image-preview" data-message-sid="'+message.sid+'"><img src="" id="media-message-'+message.sid+'" style="height:100px"></a>';
            }
           
        message.media.getContentTemporaryUrl().then(function(url){
           ////////////////console.log(url)
            // $('#media-link-'+message.sid).attr('href', url);
             $('#media-message-'+ message.sid).attr('src', url);
             $('#doc-media-message-'+ message.sid).attr('href', url);
        })
       } else {

        if(message.body.includes("http")){
            messageTemplate +='<a href="'+message.body+'" target="_blank"><div class="msg px-3 py-2 otherSide-updated-message-'+message.sid+' message-deleted-'+message.sid+'">'+message.body+'</div></a>';
        }
        else{
            messageTemplate +='<div class="msg px-3 py-2 otherSide-updated-message-'+message.sid+' message-deleted-'+message.sid+'">'+message.body+'</div>';
        }
        
       }
        
       
        
        messageTemplate +='</div></div></div> ';

        
    }
    else{
        const isEdited = message.lastUpdatedBy === message.author?" Edited":'';
        messageTemplate = '<div class="message-myside message-card me"><div class="box-wrapper mb-2">';
      
        messageTemplate +='<div class="msg-label text-capitalize fs-12 mb-1 text-muted showHideOnEdit-'+message.sid+'"><span class="ms-1">'+formatDate(message.dateCreated)+'</span> <b class="isEdited-'+message.sid+'">'+isEdited+'</b></div>';
        messageTemplate += '<div class="d-flex gap-1 align-items-end justify-content-end">';

        if(typeof message.attributes.type !== 'undefined' && message.attributes.type === "media"){
            ////////////////console.log(message.media.contentType)
            if(message.media.contentType === "application/pdf" )
            {
                messageTemplate +='<div class="msg px-3 py-2">';
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-pdf-fill fs-3 text-danger" title="'+message.media.filename+'"></i></span><div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div><a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a></div>';

                messageTemplate += '</div>';
                // messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" style="height:100px">'+message.media.filename+'</a>';
            } else if(message.media.contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            {
                messageTemplate +='<div class="file-type border rounded"><div class="d-flex align-items-center justify-content-between gap-2 text-truncate"><span class="lh-sm"><i class="bi bi-file-earmark-text-fill fs-3 text-primary" title='+message.media.filename+'></i></span>';

                messageTemplate +='<div class="file-name fw-semibold text-body text-truncate">'+message.media.filename+'</div></div>';
                
                messageTemplate +='<a href="" id="doc-media-message-'+message.sid+'" target="_blank" title="'+message.media.filename+'" class="btn text-secondary p-0"><i class="bi bi-download fs-18"></i></a>';
                
                messageTemplate +='</div>';
               
            } else {
                messageTemplate +='<a href="javascript:;" class="image-preview" data-message-sid="'+message.sid+'"><img src="" id="media-message-'+message.sid+'" style="height:100px"></a>';
            }
            
                message.media.getContentTemporaryUrl().then(function(url){
                  // //////////////console.log(url)
                    // $('#media-link-'+message.sid).attr('href', url);
                     $('#media-message-'+ message.sid).attr('src', url);
                     $('#doc-media-message-'+ message.sid).attr('href', url);
                })
           } else {
            if(message.body.includes("http")){
                messageTemplate +='<a href="'+message.body+'" target="_blank"><div class="msg px-3 py-2 showHideMouseHoveOption showHideOnEdit-'+message.sid+'" id="showHideMouseHoveOption" data-message-sid="'+message.sid+'"><div class="show-updated-message-'+message.sid+' message-deleted-'+message.sid+'">'+message.body+'</div></a>';
            }
            else{
                messageTemplate +='<div class="msg px-3 py-2 showHideMouseHoveOption showHideOnEdit-'+message.sid+'" id="showHideMouseHoveOption" data-message-sid="'+message.sid+'"><div class="show-updated-message-'+message.sid+' message-deleted-'+message.sid+'">'+message.body+'</div>';
            }
            // messageTemplate +='<div class="msg px-3 py-2 showHideMouseHoveOption showHideOnEdit-'+message.sid+'" id="showHideMouseHoveOption" data-message-sid="'+message.sid+'"><div class="show-updated-message-'+message.sid+' message-deleted-'+message.sid+'">'+message.body+'</div>';

            messageTemplate +=' <div class="MessageHoverOption position-absolute bottom-100 end-0 m-0 d-none" id="chathoverOption-'+message.sid+'" >';

            messageTemplate +='<div role="group" class="HoverOption">';

            messageTemplate +='<div class="bg-white d-flex align-items-center gap-1 rounded shadow-sm">';

            messageTemplate +='<div class="seperator bg-dark bg-opacity-25" style=" width: 1px; height: 25px; display: block"></div>';

            messageTemplate +=' <ul role="toolbar" class="list-inline m-0">';

            messageTemplate +='<div class="d-flex align-items-center">';

            messageTemplate +='<button  type="button" data-conversation-sid="'+message.conversation.sid+'" class="edit-message edit-message-achor-'+message.sid+' EditButtonMyMessage btn border-0 lh-1 p-2 editButton-'+message.sid+'" data-message-sid="'+message.sid+'"> <span class="d-inline"><i class="bi bi-pencil"></i></span></button>';

            messageTemplate +='<div class="dropdown">';

            messageTemplate +='<button type="button" class="btn border-0 lh-1 p-2" data-bs-toggle="dropdown" aria-expanded="false"><span class="d-inline"><i class="bi bi-three-dots"></i></span></button>';

            messageTemplate +='<ul class="dropdown-menu border rounded-1 shadow pb-0 overflow-auto" style=" max-height: 250px">';
                                    // <li class="small"><Link to={'#'} class="dropdown-item" href="#"><i class="bi bi-reply fs-6"></i> Reply</Link></li>
                                    // <li class="small"><Link to={'#'} class="dropdown-item" href="#"><i class="bi bi-link fs-6"></i> Copy link</Link></li>
            messageTemplate +='<li class="small"><a class="dropdown-item delete-message delete-message-'+message.sid+'" data-message-sid="'+message.sid+'" href="#"><i class="bi bi-trash3"></i> Delete</a></li>';
                                    // <li class="small bg-white border-top sticky-bottom mt-2"><Link to={'#'} class="dropdown-item pe-1 py-2" href="#">More action <i class="bi bi-chevron-right float-end"></i></Link></li>
                                    messageTemplate +='</ul>';
                                    messageTemplate +='</div>';
                                    messageTemplate +='</div>';
                                    messageTemplate +='</ul>';
                                    messageTemplate +='</div>';
                                    messageTemplate +=' </div>';
                                    messageTemplate +=' </div>';
        
            messageTemplate += '</div>';
            if(message.author === twilio.author_identity){
                                            

                messageTemplate +='<div class="d-none btn-icon seen-'+message.index+'" id="seen-'+message.index+'">';
                
                messageTemplate +='<i class="bi bi-eye text-primary" title="Seen"></i>';
    
                
                messageTemplate +=' </div>';
                }
            messageTemplate += '</div>';

            messageTemplate += '<div class="EditMessage fs-6 d-none edit-message-area-'+message.sid+'"">';
            
            messageTemplate += '<div class="message-footer w-100">';
                
            messageTemplate += '<div class="mb-1">';

            messageTemplate += '<input id="edit-message-'+message.sid+'" type="text" class="form-control rounded-1 py-2"  value="Lorem dolor sit." placeholder="Please type a message to continue..." />';
                
            messageTemplate += '</div>';

            messageTemplate += '<div class="icon-wrapper d-flex align-items-baseline justify-content-between gap-2">';

            messageTemplate += '<div class="left-menu d-flex flex-wrap">';

            messageTemplate += '<button type="button" class="bg-transparent border-0 lh-sm p-0" title="Emoji"><i class="bi bi-emoji-smile"></i></button>';

            messageTemplate += '<button type="button" class="bg-transparent border-0 lh-sm p-0" title="Giphy"><i class="bi bi-filetype-gif"></i></button>';

            messageTemplate += '</div>';


            messageTemplate += '<div class="right-menu d-flex gap-1">';

            messageTemplate += '<button id="cancel-edit-'+message.sid+'" class="cancel-edit btn border-0 lh-1 p-2" data-message-sid="'+message.sid+'" type="button" class="bg-transparent border-0 lh-sm p-0 ms-auto" title="Cancel" ><i class="bi bi-x-lg"></i></button>';


            messageTemplate += '<button data-message-sid="'+message.sid+'" id="update-edit-'+message.sid+'" data-conversation-sid="'+message.conversation.sid+'" type="submit" class="update-edit bg-transparent border-0 lh-sm p-0" title="Done" ><i class="bi bi-check2 fs-5"></i></button>';

            messageTemplate += '</div>';

            messageTemplate += '</div>';

            messageTemplate += '</div>';

            messageTemplate += ' </div>';
           }
        
        // messageTemplate += '<div class="msg px-3 py-2">'+message.body+'</div>';
        
        messageTemplate += '</div>';

        
        // if(message.author === twilio.author_identity){
        //     messageTemplate += '<p class="d-none bg-dark font-small-1 pe-50 position-relative color-white ps-50 round start-0 text-center seen-'+message.index+'" style="width:100px; float:right; color:white">Seen</p>';
        // }
        messageTemplate += '</div>';
       
    }
    

    $("#chat-printing-area").append(messageTemplate);
    set_message_status_seen(message.conversation);
}

$(document).on('click','.image-preview',function(){
    const messageId = $(this).data('message-sid');
    const url = $("#media-message-"+messageId).attr("src");
   
   window.open(url, '_blank');
})

$(document).on('click','.EditButtonMyMessage',function(){
    const messageSid = $(this).data('message-sid');
    //////////console.log(messageSid);
    $(".showHideOnEdit-"+messageSid).addClass("d-none");
    $(".EditMessage-"+messageSid).removeClass("d-none");

})

$(document).on('click','.edit-message',async function(e){
    const messageSid = $(this).data("message-sid");
    const conversationSid = $(this).data("conversation-sid");
    //////////console.log(conversationSid,messageSid)
    // const messageResponse = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/conversation/fetch-conversation-message-by-sid`, {conversationSid:conversationSid,messageSid:messageSid}, "post");
    // const message= messageResponse.data.message;

    twilio.current_conversation.getMessages().then(function(messages){
        messages.items.forEach(function(message){
           if(message.sid === messageSid){
            //////////console.log(message.body)
            $("#edit-message-"+ message.sid).val(message.body);
            $("#edit-message-"+ message.sid).removeClass("d-none");
            $("#edit-message-"+ message.sid).addClass("d-block");
            
        
            $(".edit-message-area-"+message.sid).removeClass('d-none')
            $(".show-updated-message-"+message.sid).removeClass("d-block");
            $(".show-updated-message-"+message.sid).addClass("d-none");
                
            }
        })
    });
    //////////console.log(message);
    // $("#edit-message-"+ message.messageSid).val(message.body);
    // $("#edit-message-"+ message.messageSid).removeClass("d-none");
    // $("#edit-message-"+ message.messageSid).addClass("d-block");
    

    // $(".edit-message-area-"+message.messageSid).removeClass('d-none')
    // $(".show-updated-message-"+message.messageSid).removeClass("d-block");
    // $(".show-updated-message-"+message.messageSid).addClass("d-none");
});

$(document).on('click','.cancel-edit',async function(e){
   
    const messageSid = $(this).data("message-sid");
    //////////console.log("messageId::", messageSid)
   $(".modalDiscard").html('<div class="fade modal-backdrop show"></div><div role="dialog"  aria-modal="false" class="fade modal show" tabindex="-1" aria-labelledby="contained-modal-title-vcenter" style="display: block; padding-right: 5px"><div class="modal-dialog p-4 modal-md modal-dialog-centered"><div class="modal-content shadow p-2 update-message-modal"><div class="p-4 modal-body"><div class="text-dark fs-18 fw-semibold">Do you want to discard this draft?</div><div class="add-participants btn-wrapper d-flex gap-2 justify-content-end mt-3"><button type="button" class="shadow btn btn-danger btn-sm cancelMessageDraft">Cancel</button><button type="button" class="shadow btn btn-primary btn-sm discardMessageDraft" data-message-id="'+messageSid+'">Discard</button></div></div></div></div></div>');
   $(".showHideOnEdit-"+messageSid).addClass("d-none");
   
   
    // if(window.confirm("Do you want to discard this draft?") == true){
    //     //////////console.log("you pressed discard",messageSid)
    //     $(".edit-message-area-"+messageSid).removeClass('d-block')
    //     $(".edit-message-area-"+messageSid).addClass('d-none')

    //     $(".show-updated-message-"+messageSid).removeClass("d-none");
    //     $(".show-updated-message-"+messageSid).addClass("d-block");
    // }
    // else{
    //     //////////console.log("you pressed cancel")
    //     // $(".show-updated-message-"+messageSid).removeClass("d-block");
    //     // $(".show-updated-message-"+messageSid).addClass("d-block");
       
    // }
});

$(document).on('click','.cancelMessageDraft',function(){
    $(".modalDiscard").html('');
   
})

$(document).on('click','.discardMessageDraft',function(){
    const messageSid = $(this).data('message-id')
    //////////console.log("you pressed discard",messageSid)
    $(".edit-message-area-"+messageSid).removeClass('d-block')
    $(".edit-message-area-"+messageSid).addClass('d-none')
    $(".showHideOnEdit-"+messageSid).removeClass("d-none");
    $(".show-updated-message-"+messageSid).removeClass("d-none");
    $(".show-updated-message-"+messageSid).addClass("d-block");
    $(".modalDiscard").html('');
})

$(document).on('click','.update-edit',async function(e){
    const messageSid = $(this).data("message-sid");
    const conversationSid = $(this).data("conversation-sid");
    const body = $("#edit-message-"+messageSid).val();
    //////////console.log(conversationSid,messageSid)
    if(body.length > 0){
        twilio.current_conversation.getMessages().then(function(messages){
            messages.items.forEach(function(message){
               if(message.sid === messageSid){
                   // ////////console.log(message)
                    message.updateBody(body).then(function(updated){
                        
                        $(".show-updated-message-"+messageSid).html(body);
                        $(".edit-message-area-"+messageSid).removeClass('d-block')
                        $(".edit-message-area-"+messageSid).addClass('d-none')
                        $(".show-updated-message-"+messageSid).removeClass("d-none");
                        $(".showHideOnEdit-"+messageSid).removeClass("d-none");
                        $(".isEdited-"+messageSid).html("Edited");
                    })
                }
            })
        });
        // await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/conversation/update-conversation-message-by-sid`, {conversationSid:conversationSid,messageSid:messageSid,bodyText:body,updatedBy:twilio.author_name}, "post").then((res)=>{
        //     //////////console.log(res.data)
        //     $(".show-updated-message-"+messageSid).html(body);
        //     $(".edit-message-area-"+messageSid).removeClass('d-block')
        //     $(".edit-message-area-"+messageSid).addClass('d-none')
        //     $(".show-updated-message-"+messageSid).removeClass("d-none");
        //     $(".showHideOnEdit-"+messageSid).removeClass("d-none");
        //     $(".isEdited-"+messageSid).html("Edited");
            
        // }).catch((err)=>{
        //     //////////console.log("message update err::", err)
        // })
    } else{
        $(".edit-message-area-"+messageSid).removeClass('d-none')
        $(".edit-message-area-"+messageSid).addClass('d-block')
        $(".error-message-"+messageSid).text("Please type a message to continue.");

    }
    
    
    
});



$(document).on('click','.delete-message',function(){
    const messageSid = $(this).data("message-sid");
    

    ////////console.log(messageSid)
    twilio.current_conversation.getMessages().then(function(messages){
        messages.items.forEach(function(message){
           if(message.sid === messageSid){
                ////////console.log(message)
                message.remove().then(function(removed){
                    ////////console.log("message removed")
                    $('.showHideOnEdit-'+messageSid).addClass("d-none")
                    
                })
            }
        })
    });
   
})
$(document).on('keyup',"#send-message-input",function(){
    if($(this).val().length >0){
        $("#please-type-a-message").addClass("d-none");
    } else{
        $("#please-type-a-message").removeClass("d-none");
    }
    
})
export const createPrivateConversation = async(friendlyName, uniqueEmail) =>{
    ////////////console.log(uniqueName)
    // callAxios(environment.REACT_APP_API_URL_Live+'api/v1/twilio/fetch-user-by-identity',{identity: uniqueName, friendlyName:friendlyName}, "post" )
    ////////console.log("in create")
    let isExist = false;
    
  const participantArray = [];
  const myConversations = await twilio.client.getSubscribedConversations();

    if(myConversations.items.length > 0){
        myConversations.items.map((conversation)=>{
            ////////////////console.log(conversation)
            
            if(!conversation.attributes.group){
                participantArray.push({
                    conversationSid: conversation.sid,
                    identities : [conversation.attributes.participants]})
            }
            
        })
    }
   //////////console.log(participantArray)

   participantArray.map((item)=>{
    //////////////console.log(item.identities[0],uniqueName)
        if(item.identities[0].includes(uniqueEmail)){
           ////////////console.log("yes")
            isExist = true;
            localStorage.setItem("lastConversation",item.conversationSid);
            $('.conversation-'+item.conversationSid).trigger('click');
            $("#new-chat-search-filter").trigger('click');
            $("#message-box-scroll").animate({
                scrollTop: $("#message-box-scroll").prop('scrollHeight')
              },1000);

            $("#new-chat-wrapper").addClass("d-none");
            $("#new-chat-wrapper").removeClass("position-absolute");
            $("#new-chat-wrapper").removeClass("d-block");
        }
   })
// ////////console.log(isExist)
if(!isExist){
    ////////console.log("in create one to one")
   const randomId = uuidv4();
   ////////console.log()
    twilio.client.createConversation({
        friendlyName:friendlyName,
        uniqueName: randomId,
        attributes:{
            group:false,
            participant_name: twilio.author_name,
            participant_email: twilio.author_identity,
            participant:uniqueEmail,
            author_name: friendlyName,
            author_email:uniqueEmail,
            participants:[twilio.author_identity,uniqueEmail]
        }
    }).then((conversation)=>{
        conversation.join();
        twilio.current_conversation = conversation;
        twilio.current_conversation_sid = conversation.sid;
        twilio.current_conversation.add(uniqueEmail,{
            name:friendlyName,

        })

        localStorage.setItem("lastConversation",conversation.sid);

        $("#new-chat-wrapper").addClass("d-none");
            $("#new-chat-wrapper").removeClass("position-absolute");
            $("#new-chat-wrapper").removeClass("d-block");

    }).catch((error,conversation)=>{
        ////////console.log(error)
        //////////////console.log(conversation)
    })
}




}


export const deleteConversation = (sid) =>{
    twilio.client.getConversationBySid(sid).then(function(conversation){
        ////////////////console.log(conversation)
        conversation.delete();
    })
}

const update_author_attributes = (conversation) =>{
    ////////////////console.log(twilio.author_name)
    conversation.getParticipants().then(function(participants){
        participants.forEach(function(participant){
            if(participant.identity === twilio.author_identity && typeof participant.attributes.name === 'undefined'){
                participant.updateAttributes({
                    name:twilio.author_name
                })
            }
        })
    })
}

const count_conversation_participants = (conversation) =>{
    conversation.getParticipantsCount().then(function(number){
        //update participant count here
        //////////////console.log("participantCount:::",number)
        if(number > 2){
            $("#groupParticipantCount").html(number);
            $("#groupParticipantBtn").removeClass("d-none");
            $("#singleParticipant-withoutGroup").addClass("d-none");
        } else{
           
            $("#singleParticipant-withoutGroup").removeClass("d-none");
            $("#groupParticipantBtn").addClass("d-none");
        }
        

    })
}

const update_last_read_message_index = (conversation) => {
   ////console.log("updating read index::::",conversation)
   
   
  if(typeof conversation.lastMessage !== 'undefined'){
    let last_message = conversation.lastMessage.index;
    //console.log("lastIndex::",last_message)
   if(last_message !== null && parseInt(last_message) > 0){
   
    conversation.updateLastReadMessageIndex(last_message)
    }
   }
   

}

const update_last_read_message_index_byparticipant = (conversation,index) => {
    ////console.log("updating read index::::",conversation)
    

    
     conversation.updateLastReadMessageIndex(index)
    
    
 
 }

const get_unread_messages_count = async(conversation) =>{
  // ////////console.log(conversation)
   let totalCount =  0;
   const lastMessage = await conversation.getMessages(1);
   ////////console.log(lastMessage)
//    if(!conversation.attributes.group){
    conversation.getUnreadMessagesCount().then(function(number){
        // ////////console.log("unreadCount:::",number)
        //  $(".unread-messages-count-"+conversation.sid).html(number);
        ////////console.log(lastMessage.items[0].author, twilio.author_identity)
         if(number > 0){
            // playMessageSound()
            if(lastMessage.items[0].author !== twilio.author_identity && conversation.sid !== twilio.current_conversation_sid)
            {
            $(".edited-conversation-name-"+conversation.sid).css("font-weight","700");
             $(".unread-message-focus-"+conversation.sid).css("font-weight","700")
             $(".last-message-"+conversation.side).css("font-weight","700")
            }
            totalCount +=number;
            // $("#unreadConversatnoCount").html(number)
            
         } else{
             //$("#unreadConversatnoCount").html('')
             $(".edited-conversation-name-"+conversation.sid).css("font-weight","normal");
             $(".unread-message-focus-"+conversation.sid).css("font-weight","normal")
             $(".last-message-"+conversation.side).css("font-weight","normal")
         }
         
     })

//    }
//    else{
//     conversation.getUnreadMessagesCount().then(function(number){
//         // ////////console.log("unreadCount:::",number)
//          $(".unread-messages-count-"+conversation.sid).html(number);
//          if(number > 0){
//             // playMessageSound()
//             //  $("#unreadConversatnoCount").html(number)
//              $(".edited-conversation-name-"+conversation.sid).css("font-weight","700");
//              $(".unread-message-focus-"+conversation.sid).css("font-weight","700")
//              $(".last-message-"+conversation.side).css("font-weight","700")
//          } else{
//             //  $("#unreadConversatnoCount").html('')
//              $(".edited-conversation-name-"+conversation.sid).css("font-weight","normal");
//              $(".unread-message-focus-"+conversation.sid).css("font-weight","normal")
//              $(".last-message-"+conversation.side).css("font-weight","normal")
//          }
         
//      })
//    }
   
//////////console.log("Total count::",totalCount)
}

const update_read_conversation_count = async(conversation) =>{
    twilio.unreadCount = 0;
    twilio.client.getSubscribedConversations().then(function(conversation){

        ////////////console.log("my conversations:::",conversation.items.length)
         if(conversation.items.length > 0){
             
             conversation.items.forEach(async(conversation)=>{
                const unreadD = await conversation.getUnreadMessagesCount()

                twilio.unreadCount += unreadD;
                 ////////console.log("unread conversation count::->",twilio.unreadCount)
                 if(twilio.unreadCount > 0){
                    $("#unreadConversatnoCount").html(twilio.unreadCount)
                 }
                 else{
                    $("#unreadConversatnoCount").html('')
                 }
             })
         }
         
     })
     const lastMessage = await conversation.getMessages(1);
     //////////console.log(lastMessage)
     if(!conversation.attributes.group){
      conversation.getUnreadMessagesCount().then(function(number){
         
          ////////console.log(lastMessage.items[0].author, twilio.author_identity, twilio.unreadCount)
          const updatedUnreadCount = twilio.unreadCount - number;
           if(twilio.unreadCount > 0){
              // playMessageSound()
              
              $("#unreadConversatnoCount").html(twilio.unreadCount)
              
           } else{
               $("#unreadConversatnoCount").html('')
               
           }
           
       })
  
     }
     else{
      conversation.getUnreadMessagesCount().then(function(number){
          // ////////console.log("unreadCount:::",number)
           $(".unread-messages-count-"+conversation.sid).html(number);
           if(twilio.unreadCount > 0){
           
              $("#unreadConversatnoCount").html(twilio.unreadCount)
             
           } else{
              $("#unreadConversatnoCount").html('')
              
           }
           
       })
     }
     
  //////////console.log("Total count::",totalCount)
  }


const set_message_status_seen = (conversation) =>{
    ////console.log(conversation)
    let index = conversation.lastReadMessageIndex;
   ////console.log("message Index is:::", index)
    $(".seen-"+index).removeClass('d-none');
}

const set_message_status_seen_by_participant = (index) =>{
    
    $("#seen-"+index).removeClass('d-none');
}

// $(document).on('focus','#send-message-input',function(){
//    // //////////////console.log("In focus")
//     if(twilio.current_conversation !== null){
//         update_last_read_message_index(twilio.current_conversation);
//         // set_all_messages_read(twilio.current_conversation)
//     }
// })




document.addEventListener('visibilitychange', function() {
    if(document.hidden)
        {
           

       
        }
    else
        {
            if(twilio.current_conversation !== null){
                update_last_read_message_index(twilio.current_conversation);
                // set_all_messages_read(twilio.current_conversation)
            }
               
        }
});



const set_all_messages_read = (conversation) =>{
    conversation.setAllMessagesRead();
}

$(document).on('change', '#files', async function(){
    // alert("media")
   
    const files = document.getElementById("files");
    const formData = new FormData();
   
    for(let i =0; i < files.files.length; i++) {
            formData.append("files", files.files[i]);
    }
    
    fetch(environment.REACT_APP_API_URL_Live + 'api/v1/twilio-voice/upload-media', {
        method: 'POST',
        body: formData,

        
    })
        .then(async(res) => {
            const response = await res.json();
            ////////////////console.log(response)
            if(response.Location.length > 0){
               // //////////////console.log(response.Location)
                sendMediaMessage(response.Location, response.fileName)
            }
        })
        .catch((err) => ("Error occured", err));
})

const sendMediaMessage = async(link, fileName) =>{
    const file = await fetch(link);
    ////////////////console.log(fileName)
   
    const fileBlob = await file.blob();
   

    const sendMediaOption = {
        contentType: file.headers.get('Content-Type'),
        filename: Math.random()+fileName,
        media:fileBlob
    }

    twilio.current_conversation.prepareMessage()
    .setBody('')
    .setAttributes({
        participant_name: twilio.author_name,
        participant_email: twilio.author_identity,
        type:'media'
    })
    .addMedia(sendMediaOption)
    .build()
    .send();
}

const get_online_users = (conversation) =>{
    // $('.participant-list').html('');
    conversation.getParticipants().then(function(participants){
        participants.forEach(function(participant){
            participant.getUser().then(function(user){
                
             //console.log(user)
                if(user.isOnline){
                    ////////////////console.log("user is online");
                   // //////////////console.log(user.friendlyName);
                }

                if(user.isNotifiable){
                    // user is push notification active
                }
            })
        })
    })
}

const participant_template = (participant) =>{

    //////////console.log(participant)

    let shortName = participant.friendlyName.split(' ').reduce((acc, subname) =>
    acc + subname[0], '')

    let list = '<div class="user-card list d-flex align-items-center justify-content-between gap-2 pointer px-3 py-2 participant-group-'+participant.identity+'" key={id}><div class="w-75 d-flex align-items-center gap-2"><div class="user-pic position-relative">';

    list +='<div class="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 small fw-semibold text-primary border border-primary">'+shortName+'</div></div>';

    
    // <div class="user-status position-absolute z-1">
    //                     {user.status === 'active' ? <span class="bg-success text-white"><i class="bi bi-check-circle"></i></span> : ''}
    //                     {user.status === 'away' ? <span class="bg-warning text-white"><i class="bi bi-clock"></i></span> : ''}
    //                     {user.status === 'offline' ? <span class="bg-white text-muted"><i class="bi bi-x-circle"></i></span> : ''}
    //                     {user.status === 'call' ? <span class="bg-danger text-danger"><i class="bi bi-circle-fill"></i></span> : ''}
    //                 </div>
                
    list +='<div class="user-detail text-nowrap text-truncate"><div class="user-name text-capitalize text-truncate lh-sm">'+participant.friendlyName+'</div></div></div>';
    if(participant.identity !== twilio.author_identity){
        list +='<div class="remove_icon flex-fill text-muted text-end delete-participant" data-participant-sid="'+participant.identity+'" title="Remove Participant"><i class="bi bi-x-lg fs-6"></i></div>';
    }
    
    
    list +='</div>';


    return list;
}

$(document).on('click','#groupParticipantBtn',function(){
   //////////////console.log(twilio.current_conversation);
   $('.participant-list').html('');
   twilio.current_conversation.getParticipants().then(function(participants){
    participants.forEach(function(participant){
        participant.getUser().then(function(user){
            let template = participant_template(user);
           // //////////////console.log(template)
            $('.participant-list').append(template);
           
        })
    })
})
})

export const createGroupConversation = async(participants) =>{
   

    const conversation = await twilio.client.getConversationBySid(twilio.current_conversation_sid);
    //////////////console.log(conversation)
    if(conversation.attributes.group){
        ////////////console.log(participants)
        //add participant in existing group
        const existingGroupParticipants = [];
        twilio.current_conversation.getParticipants().then(function(participants_){
            participants_.forEach(function(participant){
                participant.getUser().then(function(user){
                    ////////////console.log(user.friendlyName, user.identity);
                    existingGroupParticipants.push(user.identity)
                
                })
            })
        })

        participants.map((item)=>{
            if(!existingGroupParticipants.includes(item.value)){
                 return twilio.current_conversation.add(item.value,{
                name:item.label,
    
            })
            }  
            
        })
        update_group_friendly_name(conversation)
        $("#cancel-add-participant").trigger("click");
        $('.conversation-'+twilio.current_conversation_sid).trigger('click');
       
        
    }
    else{
        let isExist;
        // create new group
        //////////////console.log("in create new group")

        /*
        * getting selected conversation identity and friendly name
        */
        const selectedConversationUserEmail = $(".conversation-"+ twilio.current_conversation_sid).data("email");
        const selectedConversationUserName = $(".conversation-"+ twilio.current_conversation_sid).data("friendly-name");

        /*
        * adding selected conversation identity and friendly name to group participants
        */
        participants.push({value:selectedConversationUserEmail,label:selectedConversationUserName});

        /*
        * creating requested participant identity array
        */
        let requestingParticipants = [];
        if(participants.length >0){
            participants.map((item)=>{
                return requestingParticipants.push(item.value);
            })
        
        }

        /*
        * creating dispaly group name and participant number
        */
        const displayNo = participants.length - 1;
        const friendlyName =  participants.length > 2 ? participants[0].label.split(" ")[0] +', '+participants[1].label.split(" ")[0] +', +'+displayNo : participants[0].label.split(" ")[0] +' and '+participants[1].label.split(" ")[0];
        //////////////console.log(selectedConversationUserEmail,selectedConversationUserName)

        /*
        * checking existing groups. if requested group is alreay exists then we will redirect to existing group
        */
        const participantArray = [];
        const myConversations = await twilio.client.getSubscribedConversations();
      
          if(myConversations.items.length > 0){
            //  await myConversations.items.forEach(async(conversation)=>{
            for(let i=0; i<myConversations.items.length;i++){
                  
                  if(myConversations.items[i].attributes.group){
                   // ////////////console.log("in existing gruops")
                      participantArray.push({
                          conversationSid: myConversations.items[i].sid,
                          identities : [myConversations.items[i].attributes.participants]
                        })

                        //////////////console.log(conversation.attributes.participants.sort(),requestingParticipants.sort())
                      const groupParticipants = await getGroupParticipantsEmail(myConversations.items[i]);
                          // ////////console.log(groupParticipants)
                      
                        
                        if(JSON.stringify(groupParticipants.sort()) === JSON.stringify(requestingParticipants.sort())){
                         //  ////////console.log("group exists")
                            isExist = true;
                            localStorage.setItem("lastConversation",myConversations.items[i].sid);
                            $('.conversation-'+myConversations.items[i].sid).trigger('click');
                            $("#cancel-add-participant").trigger("click");
                            
                            
                            
                        } 
                        
                        

                       
                  }
                  
                  
            //   })
                }
            }

        /*
        * if isExist is false then we are creating new group with participants
        */
           // ////////console.log(isExist, requestingParticipants,participants,participantArray)

            

           
        if(!isExist){
            const randomId = uuidv4();
            twilio.client.createConversation({
              friendlyName:friendlyName,
              uniqueName: randomId,
              attributes:{
                  group:true,
                  author_name: twilio.author_name,
                  author_identity:twilio.author_identity,
                  participants:requestingParticipants
              }
            }).then((conversation)=>{
              conversation.join();
              twilio.current_conversation = conversation;
              twilio.current_conversation_sid = conversation.sid;
              
              if(participants.length >0){
                  participants.map((item)=>{
                     
                      return twilio.current_conversation.add(item.value,{
                          name:item.label,
              
                      })
                  })
                 
              }
             
      
            localStorage.setItem("lastConversation",conversation.sid);
            $('.conversation-'+conversation.sid).trigger('click');
            $("#cancel-add-participant").trigger("click");
      
            $("#new-chat-wrapper").addClass("d-none");
            $("#new-chat-wrapper").removeClass("position-absolute");
            $("#new-chat-wrapper").removeClass("d-block");
      
          }).catch((error,conversation)=>{
              ////////////console.log(error)
              //////////////console.log(conversation)
          })
        }
        
          
    }
}

$(document).on('click','.delete-participant',function(){
    const participantSid = $(this).data("participant-sid");

    ////////////console.log(twilio.current_conversation_sid, participantSid)
    twilio.current_conversation.removeParticipant(participantSid).then(function(participant){
       
        $('.conversation-'+twilio.current_conversation_sid).trigger('click');
        $("#groupParticipantBtn").trigger("click");
    })
    //following group name updating commented on 20 november 2023
    update_group_friendly_name(twilio.current_conversation)

    

})

const update_group_friendly_name = async(conversation)=>{
    
    if(!conversation.attributes.isGroupNameUpdated){
        //console.log("in group name update:::", conversation.attributes.isGroupNameUpdated)
        getGroupParticipants(conversation).then(groupParticipants => {
        // Now you can use the populated groupParticipants array here
       
        const displayNo = groupParticipants.length === 3? groupParticipants.length -2 : groupParticipants.length -2;
        // let displayParticipantCount;
        // if(displayNo === 3){
        //     displayParticipantCount = displayNo -2;
        // }
        // else if(displayNo > 3){
        //     displayParticipantCount = displayNo -2;
        // }
        const friendlyName =  groupParticipants.length > 2 ?  groupParticipants[0] +', '+groupParticipants[1] +', +'+displayNo : groupParticipants[0] +' and '+groupParticipants[1];

       
        conversation.updateFriendlyName(friendlyName).then(function(){
            if(conversation.sid === localStorage.getItem("lastConversation")){
                let shortName = friendlyName.split(' ').reduce((acc, subname) =>
                acc + subname[0], '')
                  
                    $("#current-user-short-name").html(shortName);
                 $(".edited-conversation-name-"+conversation.sid).html(friendlyName);
            // $("#groupParticipantCount").html(groupParticipants.length);
            $("#video-call").attr("data-name",friendlyName);
            $("#audio-call").attr("data-name",friendlyName);
            $("#showCurrentChatName").html(friendlyName);
            }
           
        })

        
    
    })
    .catch(error => {
        ////console.error("Error:", error);
    });
    } else {
        //console.log("in group name update:::", conversation.attributes.isGroupNameUpdated)
        if(conversation.sid === localStorage.getItem("lastConversation")){

            let shortName = conversation.friendlyName.split(' ').reduce((acc, subname) => acc + subname[0], '')
            $("#showCurrentChatName").html(conversation.friendlyName);
            $("#current-user-short-name").html(shortName);
            $(".edited-conversation-name-"+conversation.sid).html(conversation.friendlyName);
            $("#video-call").attr("data-name",conversation.friendlyName);
            $("#audio-call").attr("data-name",conversation.friendlyName);
            // $("#groupParticipantCount").html(conversation.attributes.groupParticipants.length);
            
        }
    }
    
    
   
}

const getGroupParticipants = (conversation) => {
    return new Promise(async (resolve, reject) => {
        const groupParticipants = [];
        
        try {
            const participants_ = await conversation.getParticipants();
            
            for (const participant of participants_) {
                const user = await participant.getUser();
                groupParticipants.push(user.friendlyName.split(" ")[0]);
            }

            resolve(groupParticipants); // Resolve the promise with the populated array
        } catch (error) {
            reject(error); // Reject the promise if there's an error
        }
    });
}

const getGroupParticipantsEmail = (conversation) => {
    return new Promise(async (resolve, reject) => {
        const groupParticipants = [];
        
        try {
            const participants_ = await conversation.getParticipants();
            
            for (const participant of participants_) {
                const user = await participant.getUser();
                if(twilio.author_identity !== user.identity)
                groupParticipants.push(user.identity);
            }

            resolve(groupParticipants); // Resolve the promise with the populated array
        } catch (error) {
            reject(error); // Reject the promise if there's an error
        }
    });
}

$(document).on('click','.leave-conversation',function(){
    ////////////console.log("In leave:",twilio.current_conversation_sid,twilio.author_identity)
    twilio.current_conversation.leave().then(function(participant){

        $(".cancel-leave-conversation").trigger("click");
        $("#groupParticipantBtn").trigger("click");
        // $('.conversation-'+twilio.current_conversation_sid).trigger('click');
        
    })
   //following group name updating commented on 20 november 2023
    update_group_friendly_name(twilio.current_conversation)
})


// export const addOrUpdateParticipant = async(conversationSid,identity,dname,chadmin) =>{
    
//     const response = await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/twilio/generate-token`, {identity:chadmin}, "post");
//   //////////////console.log(response)
//     const token = response.data;
//     const client = new Client(token);
//     //////////////console.log(conversationSid)
//     client.peekConversationBySid(conversationSid).then(function(conversation){
//         //console.log(conversation)
//         // conversation.add(identity,{
//         //     name:dname,

//         // })
    
        
        
//     })
    
// }

// $(document).on('click','#toggle-dark-mode',function(){
//     const body = document.body;
//   body.classList.toggle('dark-mode');
// });


export const update_Group_Name = async(updatedName) =>{
    ////console.log(updatedName)
    if(updatedName.length > 0){
         const conversation = await twilio.client.getConversationBySid(twilio.current_conversation_sid);
            if(conversation.attributes.isGroupNameUpdated){
                twilio.current_conversation.updateFriendlyName(updatedName).then(function(updatedConverstion){
                    ////console.log(updatedConverstion)
                    let shortName = updatedName.split(' ').reduce((acc, subname) =>
                    acc + subname[0], '')
                        $(".edited-conversation-name-"+updatedConverstion.sid).html(updatedName);
                        $("#showCurrentChatName").html(updatedName);
                        $("#current-user-short-name").html(shortName);
                         
                    })
            } else{
                conversation.updateAttributes({
                    isGroupNameUpdated:true,
                    group:conversation.attributes.group,
                    author_name: conversation.attributes.author_name,
                    author_identity:conversation.attributes.author_identity,
                    participants:conversation.attributes.participants
               }).then(function(updatedConversation){
                   //console.log(updatedConversation)
                   updatedConversation.updateFriendlyName(updatedName).then(function(updatedConverstion){
                    ////console.log(updatedConverstion)
                    let shortName = updatedName.split(' ').reduce((acc, subname) =>
                    acc + subname[0], '')
                        $(".edited-conversation-name-"+updatedConverstion.sid).html(updatedName);
                        $("#showCurrentChatName").html(updatedName);
                        $("#current-user-short-name").html(shortName);

                        
                         
                    })
               })

                
            }
            
    
                
            
    
            
            
         
    } else {

        const conversation = await twilio.client.getConversationBySid(twilio.current_conversation_sid);
           
                
                    
        conversation.updateAttributes({
                    isGroupNameUpdated:false,
                    group:twilio.current_conversation.attributes.group,
                    author_name: twilio.current_conversation.attributes.author_name,
                    author_identity:twilio.current_conversation.attributes.author_identity,
                    participants:twilio.current_conversation.attributes.participants
               }).then(function(updatedConversation){
                   //console.log(updatedConversation)
                   update_group_friendly_name(updatedConversation);
               })
            
            
         
    }

   $("#cancel-update-rename-group").trigger("click");
}

const checkIfGroupCallIsOngoing  = (conversationId) =>{
    ////console.log(conversationId)
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    const queryConstraints = [];
    // queryConstraints.push(where("roomId", "==", RMID));
    queryConstraints.push(where("timeStamp", ">=", startOfToday));
    queryConstraints.push(where("timeStamp", "<=", endOfToday));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
      ...queryConstraints
    );
    onSnapshot(q, (querySnapshot) => {
    
     // if(user){
        querySnapshot.forEach(async(docu) => {
          const id = docu.id;
          const data = docu.data();
          data["id"] = docu.id;
      
          if(data.callStatus === "in-progress" && data.callType === "group" && data.conversationSid ===conversationId){
            // $(".joinConversationbtn-"+data.conversationSid).removeClass("d-none");
            // $(".joinConversationbtn-"+data.conversationSid).attr("data-docId",data.docId)
            // $(".joinConversationbtn-"+data.conversationSid).attr("data-roomId",data.roomId)
            $("#join-call").removeClass("d-none");
            $("#join-call").attr("data-docid",data.docId)
            $("#join-call").attr("data-roomid",data.roomId)
            $("#audio-call").addClass("d-none")
            $("#video-call").addClass("d-none")

          }

          if(data.callStatus !== "in-progress" && data.callType === "group" && data.conversationSid ===conversationId){
          
            $("#join-call").addClass("d-none")
                $("#audio-call").removeClass("d-none")
                $("#video-call").removeClass("d-none")
          }
                    
          
          
        });
     
    
    });
   }

   export const addParticipantToConversation = async(conversationId, email, dname) =>{
   
    twilio.client.getConversationBySid(conversationId).then(function(conversation){
        //console.log("CONVERSATION::::",conversation)
        return conversation.add(email,{
            name:dname,

        })
    })
   
        
    

         
   }


  export const update_user_status = async(identity, status) =>{
   // console.log(identity,status)
    const user = await twilio.client.getUser(identity);
    //console.log(user.friendlyName)
    user.updateAttributes({isOnline: status})
    .then((userData=>{
       // console.log(userData)
    }));
    
}

const update_user_status__ = async(identity) =>{
   // console.log(identity)
    const user = await twilio.client.getUser(identity);
    //console.log(identity,user.attributes.isOnline)
    return user.attributes.isOnline;
    
}


