import React, { useEffect, useState,useMemo } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import { BeforeCallModal } from "./BeforeCallModal";
import { render } from "@testing-library/react";
import { addMoreParticipant, log_, makeOutgoingCallTOVEFirst } from "../../utils/pstn";
import { callAxios } from "../../utils/utils";
import { environment } from "../../environment";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import Timer from "./Timer";
import { AfterCallFeedback } from "./AfterCallFeedback";

const AskPop = ({showModal,leadid,displayName,countryCode,fullname,email,type,leadDesc,formattedDate,topic,leadOpportunityId}) =>{
    //console.log(showModal)
    const [user, loading, error] = useAuthState(auth);
    const [callConnected, setCallConnected] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [CALLSTATUS, setCALLSTATUS] = useState("");

  const [addMoreIcon, setAddMoreIcon] = useState(false)

  const [isMuted, setIsMuted] = useState(false)

  const [feedbackForm, setFeedbackForm] = useState(false)

  const [callDetails, setCallDetails] = useState({})



  const [inProgressConference, setInProgressConference] = useState(false)
  const [inprogressParticipants, setInprogressParticipants] = useState([])
  const [CompletedParticipants, setCompletedParticipants] = useState([])
 

  const inprogressCandidate = [];

    const [updates, setUpdates] = useState([]);
    const [CID, setCid] = useState("");
    const [isEditMessage, setIsEditMessage] = useState(true);
    const closeEditMessage = () => setIsEditMessage(false);
    const [handleShow_, setHandleShow] = useState(false)
      // For Discard model
      const [modalShow, setModalShow] = useState(showModal);
    //   const handleClose = () => {
    //       setModalShow(false)
    //       setIsEditMessage(false)
    //   };

        // dialer code
  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
   
    setShow(true)
    setModalShow(false)
  
};

  // For Dialpad Calling
  const [isDialpad, setIsDialpad] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const [isCallingText, setIsCallingText] = useState("Calling....");

  const [count, setCount] = useState(0);

    // for userConnected modal
    const [showUserModal, setConnectedUser] = useState(false);



    const incrementCount = () => {
      setCount(prevCount => ++prevCount); // Preincrementing count
    };


  const startCall = () => {
    setIsCalling(!isCalling);
    setIsDialpad(false);
  }

  const endtCall = () => {
    localStorage.setItem("conferenceObject",[])
    const cid =  $("#button-call-"+leadid).attr('data-cid')
    callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-conference-status",{ conferenceName:cid,type:"inbound"},"post")

    setShow(false);
    
  }
// useEffect(()=>{
//   console.log(feedbackForm)
//   if(feedbackForm){
//     // render ()
//   }
// },[feedbackForm])
  const openDialpad = () => {
    setIsDialpad(true);
    $("#in-progressPop").hide()
    $("#dialpad-box").show()
  }

  const handleBackToMainScreen = () => {
    
    // setIsDialpad(false);
    $("#dialpad-box").hide()
    $("#in-progressPop").show()
  }
  //

      const setHandler = (leadid, displayName, countryCode, fullname, email, type,leadOpportunityId) => {
        //  setModalShow(false)
        if(type === "fromDialer"){
          const phone = $("#phoneNumber").val();
          const cid =  $("#button-call-"+leadid).attr('data-cid')
          // addMoreParticipant(fullname,phone,cid,setShow)
    
         
        } else{
          // console.log("leadOpportunityId",leadOpportunityId)
          const cid =  $("#button-call-"+leadid).attr('data-cid')
          render(<BeforeCallModal leadOpportunityId={leadOpportunityId} leadid={leadid} displayName={displayName} countryCode={countryCode} fullname={fullname} userEmail={email} handleShow_={true} type={type} setModalShow={setModalShow} setShow1={setShow} cid={cid} setInProgressConference={setInProgressConference} setIsDialpad={setIsDialpad} leadDesc={leadDesc} formattedDate={formattedDate} topic={topic}/>)
        }
        
      }
      // const rejoinClient = (cid,leadid, displayName, countryCode, fullname, email){
      //   addMoreParticipant(fullname,leadid,cid,setShow,"VEMember",countryCode,email)
      // }
      const dialNumber = (val) =>{
        // console.log(val)
        var currentValue = $('#phoneNumber').val();
            
        // Append the desired value (e.g., ' Appended') to it
        var appendedValue = currentValue + val;
        $("#phoneNumber").val(appendedValue)
      }
    
      const removeNumber = ()=>{
        var currentValue = $('#phoneNumber').val();
            
        // Remove the last character from the string
        var newValue = currentValue.slice(0, -1);
        
        // Set the updated value back to the input field
        $('#phoneNumber').val(newValue);
      }
      const BeginCall = (leadid, displayName, countryCode, fullname, email, type) => {
          setModalShow(false)
        //  setShow(false)
        // console.log("begin call")
        const cid =  $("#button-call-"+leadid).attr('data-cid');
    
        if(cid.length >0){
          setCallConnected(true);
          $("#in-progressPop").show()
          const whoDialed =  $("#button-call-"+leadid).attr("data-whoDialed");
          if(whoDialed === "client"){
            const phone = $("#phoneNumber").val();
            addMoreParticipant(fullname,phone,cid,setShow,whoDialed,countryCode,email)
          } else{
            addMoreParticipant(fullname,leadid,cid,setShow,whoDialed,countryCode,email)
          }
          // add participant to conference
          
        } else {
          // making call first to ve member
          const phone = $("#phoneNumber").val();
          makeOutgoingCallTOVEFirst(leadid,phone,displayName,email,fullname,leadDesc,formattedDate,topic,leadOpportunityId)
        }
        
        
      }

 
      const updateData = newData => {
        setInprogressParticipants(newData);
        localStorage.setItem('conferenceObject', JSON.stringify(newData));
      };    
      useEffect(() => {
        const storedData = localStorage.getItem('conferenceObject');
        if (storedData) {
          setInprogressParticipants(JSON.parse(storedData));
        }
      }, [count]);

      useEffect(() => {
        const storedData = localStorage.getItem('completedConferenceParticipants');
        if (storedData) {
          setCompletedParticipants(JSON.parse(storedData));
        }
      }, [count]);

  const addOrUpdateLocalStorage = (update, storageValue) =>{
    let inProgressParticipants ;
    
    if(update.message.type === "client"){
      inProgressParticipants = {
        name:update.message.name,
        to: update.message.id,
        status: update.message.CallStatus,
        parentSid: update.message.parentSid,
        CallSid: update.message.CallSid,
        type:update.message.type,
        id:update.message.id,
        callerEmail:update.message.callerEmail,
        countryCode:update.message.countryCode
      }
     
    } else {
      inProgressParticipants = {
        name:update.message.displayName,
        to: update.message.To,
        status: update.message.CallStatus,
        parentSid: update.message.parentSid,
        CallSid: update.message.CallSid,
        type:update.message.type,
        id:update.message.id,
        callerEmail:update.message.callerEmail,
        countryCode:update.message.countryCode
      }
    }
    
    if (storageValue) {
      let oldData = JSON.parse(storageValue);
      let found = false;

      oldData = oldData.map(item => {
          if (item.CallSid === update.message.CallSid) {
              found = true;
              return {
                  ...item,
                  status: update.message.CallStatus
              };
          } else {
              return item;
          }
      });

      if (!found) {
          oldData.push(inProgressParticipants);
      }

      localStorage.setItem("conferenceObject", JSON.stringify(oldData));
    } else{
      localStorage.setItem("conferenceObject",JSON.stringify([inProgressParticipants]))
    }
  }
//web socket response
useEffect(() => {
    const socket = new WebSocket('ws://100.25.178.223:3232');

    socket.onopen = () => {
      console.log('Connected to WebSocket server roy');
    };

    socket.onmessage = async(event) => {
      setInProgressConference(true)
      const update = JSON.parse(event.data);

     
      
      incrementCount()
      setUpdates(update.message);
      if(update.message.From === "client:"+user?.email){
        localStorage.setItem("conferenceObject",[])
        setCid(update.message.CallSid)
      }
      if(update.message.callerEmail === user?.email){
      //  const PPSid = update.message.parentSid?update.message.parentSid:update.message.CallSid;
     if(update.message.type === "client"){
      await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:leadid,parent_sid:update.message.parentSid,call_sid:update.message.CallSid,identity:update.message.CallStatus,type:update.message.CallStatus,caller:displayName,clientName:update.message.name,number:update.message.To},"post")
     } else{
      await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:leadid,parent_sid:update.message.parentSid,call_sid:update.message.CallSid,identity:update.message.CallStatus,type:update.message.CallStatus,caller:displayName,clientName:update.message.displayName,number:update.message.To},"post")
     }
      
          
      if(update.message.CallStatus === "in-progress" && update.message.SequenceNumber > 1){
        const cid =  $("#button-call-"+leadid).attr('data-cid');
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-before-call",{ action:true, cid:update.message.parentSid,type:"group"},"post")
       
       
        // setAddMoreIcon(true);
        // $("#dial-pad-"+leadid).removeClass("d-none")
        
      }
   
     
      if(update.message.CallStatus === "in-progress" && update.message.type === "client"){
       
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
        const cid =  $("#button-call-"+leadid).attr('data-cid');
        $("#button-call-"+leadid).attr("data-whoDialed","client");
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-before-call",{ action:true, cid:update.message.parentSid,type:"clientModel"},"post")

        // await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:leadid,parent_sid:update.message.parentSid,call_sid:update.message.CallSid,identity:update.message.CallStatus,type:update.message.CallStatus,caller:displayName,clientName:update.message.displayName,number:update.message.To},"post")
        setCallConnected(true);
        $("#in-progressPop").show()
        // setAddMoreIcon(true);
        // $("#dial-pad-"+leadid).removeClass("d-none")
        
      }

      if(update.message.CallStatus === "in-progress" && update.message.type === "VEMember"){
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
        // localStorage.setItem("conferenceObject",JSON.stringify([...updates,update.message]))
        setInprogressParticipants(update.message)
        const getCallDetails = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-data-by-cid",{ action:true, cid:update.message.parentSid},"post")

        // await callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:leadid,parent_sid:update.message.parentSid,call_sid:update.message.CallSid,identity:update.message.CallStatus,type:update.message.CallStatus,caller:displayName,clientName:update.message.displayName,number:update.message.To},"post")

        // console.log(getCallDetails)
        if(getCallDetails.data.callDetails.is_client_modal_open === 0){
          // setShow(true)
          setConnectedUser(true)
          //  setInProgressConference(false)
          setCallConnected(true);
           $("#dial-pad-"+leadid).css("display","none")
           $("#in-progressPop").hide()
        
        }  else{
          // $("#in-progressPop").show()
        }
        // else{
        //   setShow(false)
        // }
        
        // setModalShow(false)
      }

      
      if(update.message.CallStatus === "initiated"){

        setIsCalling(true)
        setIsDialpad(false)
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
         incrementCount()
      }

      if(update.message.CallStatus === "ringing"){
         
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
       
      }
     
      if(update.message.CallStatus === "completed"){

        
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
          
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-conference-status",{ conferenceName:update.message.parentSid,type:"outbound"},"post").then(async(res)=>{
          //  console.log(res.data)
          if(res.data.totalParticipants < 2){
            setShow(false)
            const getCallDetails = await callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-data-by-cid",{ action:true, cid:update.message.parentSid},"post")
            // console.log(getCallDetails.data.callDetails)
            if(getCallDetails.data.callDetails.is_client_joined){
              setFeedbackForm(getCallDetails.data.callDetails.is_client_joined)
              setCallDetails(getCallDetails.data.callDetails)
             
            }
          }
        })
       
       
        
      }

      if(update.message.CallStatus === "no-answer"){

        
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-conference-status",{ conferenceName:update.message.parentSid,type:"outbound"},"post").then((res)=>{
          // console.log(res.data)
          if(res.data.totalParticipants < 2){
            setShow(false)
          }
        })
      }
      if(update.message.CallStatus === "failed"){
        // console.log("in failed")
        
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-conference-status",{ conferenceName:update.message.parentSid,type:"outbound"},"post").then((res)=>{
          // console.log(res.data)
          if(res.data.totalParticipants === 0){
            // console.log("in failed")
            setShow(false)
            // setInProgressConference(false)
          }
        })
      }
      if(update.message.CallStatus === "busy"){

        
        addOrUpdateLocalStorage(update,localStorage.getItem('conferenceObject'))
        incrementCount()
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-conference-status",{ conferenceName:update.message.parentSid,type:"outbound"},"post").then((res)=>{
          // console.log(res.data)
          if(res.data.totalParticipants < 2){
            setShow(false)
          }
        })
        
          
          
       
       
        
      }
      
      }
       
      
    };

    socket.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      socket.close();
    };

  }, []);

    // console.log("Real time update::::",updates.CallStatus,updates.Direction,count)
  // console.log("In progress Participants:::",inProgressConference)
useEffect(()=>{
  // callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/get-conference-participants",{ conferenceName:update.message.parentSid},"post")

  if(updates.CallStatus === "completed" && updates.Direction === "inbound"){
    // console.log("in cam disconnectd call")
    localStorage.setItem("conferenceObject",[])
    localStorage.setItem("completedConferenceParticipants",[])
    callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-conference-status",{ conferenceName:CID,type:"inbound"},"post")
    // $("#dial-pad-"+leadid).addClass("d-none")
    
  } 
},[])



const removeByCallSid = (callSidToRemove, storageValue) => {
  if (storageValue) {
      let oldData = JSON.parse(storageValue);
      
      // Find index of element with matching CallSid
      const indexToRemove = oldData.findIndex(item => item.CallSid === callSidToRemove);

      if (indexToRemove !== -1) {
          // Remove element from array if found
          oldData.splice(indexToRemove, 1);
          localStorage.setItem("conferenceObject", JSON.stringify(oldData));
      }
  }
}
const handleRejoin = (item,leadid, displayName, countryCode, fullname, email, type) => {
  
  setModalShow(false)
  const callSidToRemove = item.CallSid; // Specify the CallSid you want to remove
const storageValue = localStorage.getItem("conferenceObject");
removeByCallSid(callSidToRemove, storageValue); 
// console.log("begin call", item)
const cid =  $("#button-call-"+leadid).attr('data-cid');

if(item.type === "client"){
  // setHandler(leadid, displayName, countryCode, fullname, email,"client")
  addMoreParticipant(fullname,leadid,item.parentSid,setShow,"VEMember",countryCode,email)
} else{
  if(cid.length >0){
    const whoDialed =  $("#button-call-"+leadid).attr("data-whoDialed");
    if(whoDialed === "client"){
      const phone = item.to;
      addMoreParticipant(fullname,phone,cid,setShow,whoDialed,countryCode,email)
    } 
    // add participant to conference
    
  }
}



}



let inProgress;
if(inprogressParticipants && inprogressParticipants.length > 0){
  inProgress = inprogressParticipants.map((item,index)=>{
    if(item.status === "completed"){
      
      return (
        <div className="user-info d-flex align-items-center gap-2">
                      <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
                        <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
                      </div>
                      <div className="user-info text-start">
                        <div className="user-name small fw-light text-light text-truncate">{item.name}</div>
                        <div className="contact-number fs-12 text-muted text-truncate">{item.ToCountry}</div>
                        <span className="badge rounded-pill text-bg-primary pointer pointer" onClick={()=>{handleRejoin(item,leadid, displayName, countryCode, fullname, email, type)}}>Rejoin Call</span>
                      </div>
                    </div>
      )
    }

    else if(item.status === "no-answer"){
      
      return (
        <div className="user-info d-flex align-items-center gap-2">
                      <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
                        <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
                      </div>
                      <div className="user-info text-start">
                        <div className="user-name small fw-light text-light text-truncate">{item.name}</div>
                        <div className="contact-number fs-12 text-muted text-truncate">{item.ToCountry}</div>
                        <span className="badge rounded-pill text-bg-info pointer" onClick={()=>{handleRejoin(item,leadid, displayName, countryCode, fullname, email, type)}}>{"No Answer, "} Dial Again</span>
                      </div>
                    </div>
      )
    } 
    else if(item.status === "busy"){
      
      return (
        <div className="user-info d-flex align-items-center gap-2">
                      <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
                        <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
                      </div>
                      <div className="user-info text-start">
                        <div className="user-name small fw-light text-light text-truncate">{item.name}</div>
                        <div className="contact-number fs-12 text-muted text-truncate">{item.ToCountry}</div>
                        <span className="badge rounded-pill text-bg-danger pointer pointer" onClick={()=>{handleRejoin(item,leadid, displayName, countryCode, fullname, email, type)}}>{"Busy, "}Dial Again ?</span>
                      </div>
                    </div>
      )
    } 
    
    else{
      return (
        <div className="user-info d-flex align-items-center gap-2">
                        <div className="img-wrapper bg-primary bg-opacity-25 border border-1 border-primary rounded-circle overflow-hidden d-flex align-items-center justify-content-center" style={{ width: '42px', height: '42px' }}>
                          <img src={require('../../resources/images/Avatar.png')} className='img-fluid' alt="User" />
                        </div>
                        <div className="user-info text-start">
                          <div className="user-name small fw-light text-light text-truncate">{item.name} [
                            {item.status}
                            ]</div>
                          <div className="contact-number fs-12 text-muted text-truncate">{item.To}</div>
                        </div>
                      </div>
      )
    }
    
  })
}

const handleMute = async(CID,isMuted)=>{
  setIsMuted(isMuted)
  const cid =  $("#button-call-"+leadid).attr('data-cid')
  callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/mute-status",{ callSid:cid,isMuted:isMuted},"post")
}

    return (
        <>
         {/* {useMemo(()=>( */}
                      {/* <Timer  /> */}
                  {/* ),[inProgressConference])} */}
            <Modal show={modalShow} onHide={() => setModalShow(true)} size="md" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="shadow p-2" dialogClassName="p-4">
                <Modal.Body className="p-4">
                    <div className='text-dark fs-18 fw-semibold'>Do you want to add a VE Member to this call?</div>
                    <div class="add-participants btn-wrapper d-flex gap-2 justify-content-end mt-3">
                        <Button variant='success' size='sm' onClick={handleShow} className='shadow '>Yes</Button>
                        <Button variant='info' size='sm'  className='shadow' onClick={() => { setHandler(leadid, displayName, countryCode, fullname, email,type,leadOpportunityId) }}>No</Button>
                        <Button variant='danger' size='sm' onClick={() => setModalShow(false)} className='shadow cancel-leave-conversation'>Cancel</Button>
                    </div>
                </Modal.Body>
            </Modal>
           


             {/* Dialpad Model Code */}
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered contentClassName='bg-dark border border-3 border-primary'>
        <Button variant='dark' className="d-flex align-items-center justify-content-center rounded-circle position-absolute right-0" style={{ height: '40px', width: '40px', top: '-20px', right: '-20px', zIndex: 11 }}>
          <i className="bi bi-x fs-2" onClick={handleClose}></i>
        </Button>
        <Modal.Body className="bg-black text-center rounded p-4">
            {showUserModal?
        <div className="user-connected dark-theme-bg text-center px-3 rounded-3 py-5" id="connected-user-area">
                <div className="d-flex align-items-center justify-content-center gap-4 mb-4">
                    <div className="user-connected-image">
                        <img src={require('../../resources/images/Avatar.png')} alt="user-image"  className="img-fluid border border-1 border-info rounded-circle" style={{width:"80px", height:"80px"}}/>
                    </div>
                    <div className="connected-wrapper d-flex align-items-center justify-content-center gap-2">
                        <span className="text-success">-------</span>
                        <div className="connected-icon d-flex align-items-center justify-content-center border border-1 border-success rounded-circle" style={{height:"45px", width:"45px"}}>
                         <i className="bi bi-arrow-left-right text-success"></i> 
                    </div>  
                        <span className="text-success">-------</span>
                    </div>
                   
                    <div className="user-connected-image">
                        <img src={require('../../resources/images/Avatar.png')} alt="user-image"  className="img-fluid border border-1 border-warning rounded-circle" style={{width:"80px", height:"80px"}}/>
                    </div>
                    
                </div>
                <div className="info-text-client fs-6 text-light mb-4" style={{textAlign:"left"}}>
                Now, the added VE member is on this call with you. If both of you are ready, you can proceed to call the client.
                </div>
                <div className="btn-wrapper">
                    <button type="button" className="btn btn-success py-2 px-4" onClick={() => { setHandler(leadid, displayName, countryCode, fullname, email,"client") }}>Connect to Client</button>
                </div>
            </div>
            :null}
          <div className="dialpad-wrapper mx-auto">

          {/* Conference Call Screen Code */}
          {inProgressConference?
          <div className="confrence-call-screen" id="in-progressPop">
              <div className="wrapper p-4 mb-2">
              <div className="total-user mt-1 mb-3">Conference ({inprogressParticipants.length + 1}) 
               
              - <p className="timer text-warning">
               {callConnected?
                  <Timer  />
                :null}
              </p>
            
              </div>
              <div className="d-flex gap-3 position-relative">
                <div className="left-sec d-flex flex-column align-items-center gap-4">
                  <div className="top-arrow lh-1"><i className="bi bi-chevron-up fs-18 text-secondary"></i></div>
                  <div className="middle-arrow h-100"><span className='d-block rounded-pill bg-secondary' style={{ width: '3px', height: '100%' }}></span></div>
                  <div className="bottom-arrow lh-1"><i className="bi bi-chevron-down fs-18 text-white"></i></div>
                </div>
                <div className="right-sec w-100">
                  <div className="user-wrapper d-flex flex-column gap-2 overflow-auto" style={{ maxHeight: '200px' }}>
                    {inProgress}
                                
                    
                    
                  </div>
                </div>
              </div>
            </div>
            <div className="action-wrapper d-flex justify-content-center gap-4 border-top border-1 border-secondary border-opacity-25 p-3">
              {isMuted ?
              <Button variant='primary' className="rounded-circle" onClick={()=>{handleMute(CID,false)}}>
              <i className="bi bi-mic-mute-fill fs-5"></i>
               </Button> 
               :
               <Button variant='secondary' className="rounded-circle" onClick={()=>{handleMute(CID,true)}}>
               <i className="bi bi-mic-mute-fill fs-5"></i>
                </Button> 
              }
              <Button variant='danger' className="rounded-circle" onClick={()=>{endtCall(CID)}}>
              <i className="bi bi-telephone-fill d-inline-block fs-5" style={{ transform: 'rotate(135deg)' }} ></i>
              </Button>
              <Button variant='success' className="rounded-circle" onClick={openDialpad}>
              <i className="bi bi-grid-3x3-gap-fill fs-5"></i>
              </Button>
            </div>
          </div>
          :null}
          
          
            {isDialpad && (
              <div className="dialpad-screen" id="dialpad-box">
                <div className="top-section">
                  <input type="text" maxLength={15} placeholder='+91' className='form-control bg-transparent border-0 shadow-none text-light p-4'  id="phoneNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" autoComplete='off'  required />
                </div>
                <div className="bottom-section p-4">
                  <div className="row g-2">
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(1)}}>1</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(2)}}>2</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(3)}}>3</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(4)}}>4</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(5)}}>5</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(6)}}>6</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(7)}}>7</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(8)}}>8</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(9)}}>9</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer"><i className="bi bi-asterisk fs-6"></i></div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber(0)}}>0</div>
                    </div>
                    <div className="col-4">
                      <div className="num pointer" onClick={()=>{dialNumber("+")}}>+</div>
                    </div>
                  </div>
                  <div className="action-wrapper row g-2 mt-2">
                  {inProgressConference?
                    <div className="col-4 text-center">
                    <Button variant='success' className="rounded-circle pointer" onClick={handleBackToMainScreen} title="Back ongoing call">
                      <i class="bi bi-arrow-up-left-square-fill"></i>
                      </Button>
                    </div>
                    :null}
                    
                    <div className="col-4 text-center">
                      
                      <Button variant='danger' className="rounded-circle lh-1 pointer" onClick={removeNumber}>
                        <img src={require ('../../resources/images/back-btn.png')} alt="" className='img-fluid' style={{filter:'invert(1)'}} />
                    </Button>
                    </div>
                    <div className="col-4 text-center">
                      <Button variant='success' className="rounded-circle pointer" onClick={() => { BeginCall(leadid, displayName, countryCode, fullname, email,"fromDialer") }}>
                        <i className="bi bi-telephone-fill fs-5"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}


          </div>
        </Modal.Body>
      </Modal>
            {feedbackForm?
            <AfterCallFeedback showFeedback={true} callInfo={callDetails} setFeedbackForm={setFeedbackForm} /> 
            :null}
          
        </>
    )
}

export default AskPop;