import React from "react";
import { useState } from "react";

// import Editor
import  ReactQuill  from  "react-quill";
import  "react-quill/dist/quill.snow.css";

// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import preloader css
import "../LeadsListingPage/LeadsListingPage.css";
// import { connect, useSelector } from "react-redux";
import { callAxios, callCrmApi } from "../utils/utils";
import { environment } from "../environment";
// import axios from "axios";
// import { setToken } from "../store/actions";
// import { auth, getAuth } from "../firebaseConfig";

const CreateNotes = ({user, setTokenAction, rdsUser, leadId}) => {
    
    
    // const expirysamay = 1692207050;
    
    // useEffect(()=>{
    //  // console.log("in use effect token")
    //   if(expiryTime < parseInt(new Date().getTime()/1000)){
    //     setIsTokenExpired(true);
    //    }
    //   if(isTokenExpired){
    //    // console.log("in expired token")
    //     const auth1 = getAuth();
    //     auth1.currentUser.getIdToken(true)
    //         .then(function(idToken) {
    //           //setTokenAction(idToken)
    //           setIsTokenExpired(false);
    //           localStorage.setItem("idToken",idToken);
    //        // console.log("IDTOKEN::::::",idToken)
    //         }).catch(function(error) {
            
    //         });
    //    }
    // },[isTokenExpired])
  
  
    const [subject, setSubject] = useState("");
  // const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");

  const checkValidation = (key, val) => {
    if (key === "subject" && val !== "") {
      document.getElementById("subject").style.border = "none";
    }
    if (key === "priority" && val !== "") {
      document.getElementById("priority").style.border = "none";
    }
    if (key === "description" && val !== "") {
      document.getElementById("note-description").style.border = "none";
    }

    if (key === "changeStatus" && val !== "") {
      document.getElementById("changeStatus").style.border = "none";
    }
  };

  if(description){
    document.getElementById("note-description").style.border = "none";
  }

  // console.log(rdsUser)
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    
    if (subject === "") {
      document.getElementById("subject").style.border = "1px solid red";
      return false;
    }  else if (description === "") {
      document.getElementById("note-description").style.border =
        "1px solid red";
      return false;
    } else {
      document.getElementById("note-preloader").classList.remove("d-none");
      const formData = {
        type:"lead",
        createdBy:rdsUser.display_name,
        uid:rdsUser.uid,
        email:rdsUser.email,
        subject:subject,
        description:description,
        leadId:leadId,
        MSCRMCallerID:rdsUser.systemuserid
       
        
      }
      
      const data = JSON.stringify({
        operation:"createannotations",
        leadid: leadId,
        subject:subject,
        notetext:description,
        MSCRMCallerID:rdsUser.systemuserid
      });

      // console.log(data)
 
    const response = await callCrmApi(data)
    
    

      if (response?.status === 200) {
        resetForm();
       
        await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/crm/add-note",
      {formData},
      "post"
    );
        document.getElementById("note-preloader").classList.add("d-none");
        toast.success("Note Created", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        document.getElementById("note-preloader").classList.add("d-none");
        toast.error("Network Issue!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };
  const resetForm = async () => {
    //console.log("In reset form");
    setSubject("");
    // setPriority("");
    setDescription("");
    const element = document.getElementsByClassName("ql-editor");
    element[0].innerHTML = "";
   
    document.getElementById("issueForm").reset();

    document.getElementById("closeIssueForm").click();
    document.getElementById("note-preloader").classList.add("d-none");
  };
    return (
        <>
        <ToastContainer />
            <div
        className="modal fade"
        id="openCreateNoteModal"
        tabIndex={-1}
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content text-body">
            <div className="modal-header bg-primary bg-gradient text-white px-4">
              <h1 className="modal-title fs-6" id="exampleModalLabel">
                Create Notes
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ filter: "invert(100)" }}
              ></button>
            </div>
            <div className="modal-body px-4">
              <form id="issueForm">
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    aria-describedby="subjectlHelp"
                    placeholder="Enter your subject"
                    onChange={(e) => {
                      setSubject(e.target.value);
                      checkValidation("subject", e.target.value);
                    }}
                    required
                  />
                  {/* <div id="subjectlHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                </div>
                
                <div className="mb-3">
                  <label htmlFor="note-description" className="form-label">
                    Note Description
                  </label>

                  <ReactQuill  id="note-description" theme="snow" style={{height: "150px"}} placeholder="Please enter note here.." onChange={setDescription} />
                  
                </div>
              </form>
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                id="closeIssueForm"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
                style={{ minWidth: "80px" }}
              >
                Close
              </button>
              <button
                onClick={handleFormSubmit}
                type="button"
                className="btn btn-primary px-4"
              >
                Submit
              </button>

              <div class="d-none" id="note-preloader">
                <div
                  style={{ marginLeft: "12px", float: "right" }}
                  class="issues-spinner"
                ></div>{" "}
                Please wait ....
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
 export default CreateNotes;

