import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CameraButton from "./CameraButton";
import LeaveRoomButton from "./LeaveRoomButton";
import MicButton from "./MicButton";
import SwitchToScreenSharingButton from "./SwitchToScreenSharingButton";
import SwitchImg from "../../resources/images/screen_sharing.svg";
import { app, database, auth } from "../../firebaseConfig";
import axios from "axios";
import * as qs from "qs";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  documentId,
  serverTimestamp,
} from "firebase/firestore";
// env import
import { environment } from "../../environment";
import { getCountryCallingCode } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import { setClientInfo } from "../../store/actions";

import "react-toastify/dist/ReactToastify.css";
import { callAxios } from "../../utils/utils";

// cam question pop up
import { BdmCamQuesAfterCall } from "../../LeadsListingPage/BdmCamQuesAfterCall";

const VideoButtons = (props) => {
  //console.log(props)
  const {
    room,
    connectOnlyWithAudio,
    comingFrom,
    setClientInfoAction,
    camera,
    isMicMuted,
  } = props;
  const [BDMUSERS, setBdmUsers] = useState([]);
  const [apiStatusCode, setApiResponseCode] = useState(0);
  const [CAMUSERS, setCamUsers] = useState([]);
  const [shareUrl, setShareUrl] = useState("");
  const [chatToggle, setChatToggle] = useState(false);
  const [streamStatus, setStreamStatus] = useState(0);
  const [contactNo, setContact] = useState(0);
  const [country, setCountry] = useState("");
  const [cinfo, setCinfo] = useState({});
  const [description, setDiscription] = useState();
  const [participants, setParticipants] = useState([]);
  const [isClientOnCall, setIsClientOnCall] = useState(false);

  const queryConstraintsCAM = [];
  const queryConstraintsBDM = [];

  queryConstraintsCAM.push(where("userType", "==", "CAM"));
  queryConstraintsCAM.push(where("status", "==", "Online"));
  queryConstraintsCAM.push(where("isDeleted", "==", 0));
  const camQuery = query(
    collection(database, environment.REACT_APP_USERS_DB),
    ...queryConstraintsCAM
  );

  queryConstraintsBDM.push(where("userType", "==", "BDM"));
  queryConstraintsBDM.push(where("status", "==", "Online"));
  queryConstraintsBDM.push(where("isDeleted", "==", 0));
  const bdmQuery = query(
    collection(database, environment.REACT_APP_USERS_DB),
    ...queryConstraintsBDM
  );

  useEffect(() => {
    const getCAMusers = async () => {
      onSnapshot(camQuery, (querySnapshot) => {
        let cusers = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          cusers.push({ email: data?.email, name: data?.name });
        });
        setCamUsers(cusers);
      });
    };

    getCAMusers();

    const getBDMusers = async () => {
      onSnapshot(bdmQuery, (querySnapshot) => {
        let busers = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          busers.push({ email: data?.email, name: data?.name });
        });
        setBdmUsers(busers);
      });
    };

    getBDMusers();
  }, []);

  useEffect(() => {
    const getFormDetails = async () => {
      openLeadSidenav();
      if(props?.formId){
        const q = query(
          collection(database, environment.REACT_APP_FIREBASE_DB),
          where(documentId(), "==", props.formId)
        );
        onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            setContact(data?.phone);
            setCountry(data?.country);
            setStreamStatus(data.streamerStatus);
            setCinfo({ cname: data?.name, cemail: data?.email });
            setDiscription(data?.description);
            setParticipants(data?.participants);
            setIsClientOnCall(data?.isClientOnCall);
          });
        });
      }
      
    };
    if (comingFrom === true) getFormDetails();
  }, [props.roomId]);

  //console.log("description::", participants);



  //Disconnect room called
  const siteUrl = comingFrom
    ? window.location.origin + "/lead-listing"
    : "https://www.virtualemployee.com/";

  

    const saveFormDataToRds = async(roomId, siteUrl, comingFrom, type) =>{
     // let responseCode = 0;
      const formData = query(
        collection(database, environment.REACT_APP_FIREBASE_DB),
        where("roomId", "==", roomId)
      );
      const querySnapshot = await getDocs(formData);
      querySnapshot.forEach(async (docData) => {
        //console.log(docData.id, ' => ', docData.data());
        const formData = { ...docData.data(), id: docData.id };
        if(comingFrom === true){
          formData['camLeftAt'] = { seconds:new Date().getTime() / 1000, nanoseconds: window.performance.now()};
        }
        if(comingFrom === false){
          formData['clientLeftTime'] = { seconds:new Date().getTime() / 1000, nanoseconds: window.performance.now()};
        }
        if(comingFrom === "Bdm"){
          formData['bdmLeftTime'] = { seconds:new Date().getTime() / 1000, nanoseconds: window.performance.now()};
        }

        if(comingFrom === true && type === "form"){
          formData['isFormOpenedForCam']    = true;
          formData['camAppVersion']         = localStorage.getItem("appVersion");
          formData['camFormOpenTime']       = { seconds:new Date().getTime() / 1000, nanoseconds: window.performance.now()};
          formData['camUserAgent']          = window.navigator.userAgent;
        }

        if(type === "form" && comingFrom === "Bdm"){
          formData['isFormOpenedForBdm']  = true;
          formData['bdmAppVersion']       = localStorage.getItem("appVersion");
          formData['bdmFormOpenTime']     = { seconds:new Date().getTime() / 1000, nanoseconds: window.performance.now()};
          formData['bdmUserAgent']        = window.navigator.userAgent;
        }
        
       // console.log("form Saving to RDS--->",formData);
        await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/leads/save-nm-leads',{formData:JSON.stringify(formData)}, "post" );
        
      });
      //window.location.href = siteUrl;

    
    }

    const updateFormDatawithOutForm = async (roomId, OnlineUsers, type, comingFrom, siteUrl,description,room) => {
      const formData = query(
        collection(database, environment.REACT_APP_FIREBASE_DB),
        where("roomId", "==", roomId)
      );
      const querySnapshot = await getDocs(formData);
      querySnapshot.forEach(async (docData) => {
        //console.log(docData.id, ' => ', docData.data());
        const formRef = doc(
          database,
          environment.REACT_APP_FIREBASE_DB,
          docData.id
        );
        if (comingFrom === true && description !== "") {
        updateDoc(formRef, {
          missedBy: OnlineUsers,
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
            window.location.href = siteUrl;
          }); 
        }
          if(comingFrom === true){
            await updateDoc(formRef, {
              streamerStatus: 0,
              camLeftAt: serverTimestamp(),
            })
              .then(async() => {
                 console.log("cam left successfully");
                 //await saveFormDataToRds(roomId, siteUrl, comingFrom, type);
                
                
                   room.disconnect();
                   window.close();
                 
                
                
               
              })
              .catch((error) => {
                console.log(error);
                window.location.href = siteUrl;
              });
              
          } 
          if(comingFrom === false){
            updateDoc(formRef, {
              streamerStatus: 0,
              clientLeftTime: serverTimestamp()
            })
              .then(() => {
                 console.log("client left successfully");
                // saveFormDataToRds(roomId, siteUrl, comingFrom, type);
                room.disconnect();
                window.location.href = siteUrl;
                
              })
              .catch((error) => {
                console.log(error);
                window.location.href = siteUrl;
              });
            
          }
          if(comingFrom === "Bdm"){
            updateDoc(formRef, {
              streamerStatus: 0,
              bdmLeftTime: serverTimestamp(),
            })
              .then(() => {
                 console.log("bdm left successfully");
                //  saveFormDataToRds(roomId, siteUrl, comingFrom, type);
                 
                  //  window.location.href = siteUrl;
                 
                    room.disconnect();
                    window.close();
                  
            
              })
              .catch((error) => {
                console.log(error);
                window.location.href = siteUrl;
              });
            
          }

          if(comingFrom === "sharedByCam" || comingFrom === "sharedByBdm"){
            // window.location.href = siteUrl;
            if(room)
            {
              room.disconnect();
              window.close();
            }
          }
          
      });

      
    };

    const updateFormDatawithForm = async (roomId, OnlineUsers, type, comingFrom, siteUrl,description) => {
      const formData = query(
        collection(database, environment.REACT_APP_FIREBASE_DB),
        where("roomId", "==", roomId)
      );
      const querySnapshot = await getDocs(formData);
      querySnapshot.forEach(async (docData) => {
        //console.log(docData.id, ' => ', docData.data());
        const formRef = doc(
          database,
          environment.REACT_APP_FIREBASE_DB,
          docData.id
        );
        if (comingFrom === true && description !== "") {
        updateDoc(formRef, {
          missedBy: OnlineUsers,
        })
          .then(() => {
            // console.log("clientJoined successfully");
          })
          .catch((error) => {
            console.log(error);
          }); 
        }
          if(comingFrom === true){
            await updateDoc(formRef, {
              streamerStatus: 0,
              camLeftAt: serverTimestamp(),
            })
              .then(() => {
                // console.log("cam left successfully");
                saveFormDataToRds(roomId, siteUrl, comingFrom, type);
                //window.location.href = siteUrl;
               
              })
              .catch((error) => {
                console.log(error);
              });
              
          } 
          if(comingFrom === false){
            updateDoc(formRef, {
              streamerStatus: 0,
              clientLeftTime: serverTimestamp()
            })
              .then(() => {
                 //console.log("client left successfully");
                 saveFormDataToRds(roomId, siteUrl, comingFrom, type);
                 window.location.href = siteUrl;
                //window.close();
              })
              .catch((error) => {
                console.log(error);
              });
            
          }
       
        if(type === "form" && comingFrom === true){
          updateDoc(formRef, {
            isFormOpenedForCam: true,
            camAppVersion: localStorage.getItem("appVersion"),
            camFormOpenTime: serverTimestamp(),
            camUserAgent:window.navigator.userAgent
          })
            .then(() => {
             // console.log("cam form details");
              saveFormDataToRds(roomId, siteUrl, comingFrom, type);
            })
            .catch((error) => {
              console.log(error);
            });  
        }

        if(type === "form" && comingFrom === "Bdm"){
          updateDoc(formRef, {
            streamerStatus: 0,
            bdmLeftTime: serverTimestamp(),
            isFormOpenedForBdm: true,
            bdmAppVersion: localStorage.getItem("appVersion"),
            bdmFormOpenTime: serverTimestamp(),
            bdmUserAgent:window.navigator.userAgent
          })
            .then(() => {
              //console.log("bdm form Details");
              saveFormDataToRds(roomId, siteUrl, comingFrom, type);
            })
            .catch((error) => {
              console.log(error);
            });  
        }

        if(comingFrom === "sharedByCam" || comingFrom === "sharedByBdm"){
         // window.location.href = siteUrl;
         window.close();
        }
          
      });

      
    };

  const handleRoomDisconnectWithForm = async () => {
    if(comingFrom === true){
      localStorage.setItem("camJoinedLead", null);
    }

    if(comingFrom === "Bdm"){
      localStorage.setItem("bdmJoinedLead", null);
    }

    localStorage.setItem("setLocalImg", undefined);
    const siteUrl = comingFrom
      ? window.location.origin + "/lead-listing"
      : "https://www.virtualemployee.com/";
    updateFormDatawithForm(props?.roomId,BDMUSERS,"form",comingFrom, siteUrl,description);
    if(room)
    {
      room.disconnect();
    }
    
    if (comingFrom === true && description !== "") {
      //console.log("In save online bdm ")
      // callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/questions/save-online-cam-bdm-by-lead',{type: "cam",online:CAMUSERS,roomId:props.roomId}, "post");
      callAxios(
        environment.REACT_APP_API_URL_Live +
          "api/v1/questions/save-online-cam-bdm-by-lead",
        { type: "bdm", online: BDMUSERS, roomId: props?.roomId },
        "post"
      );
    } 
    //await updateStreamerstatus(props.roomId, siteUrl);
  };

  const handleRoomDisconnectionWithoutForm = async () => {
    if (comingFrom === true && description !== "") {
      // callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/questions/save-online-cam-bdm-by-lead',{type: "cam",online:CAMUSERS,roomId:props.roomId}, "post");
      await callAxios(
        environment.REACT_APP_API_URL_Live +
          "api/v1/questions/save-online-cam-bdm-by-lead",
        { type: "bdm", online: BDMUSERS, roomId: props?.roomId },
        "post"
      );
    }
    const siteUrl = comingFrom
      ? window.location.origin + "/lead-listing"
      : "https://www.virtualemployee.com/";

    if(comingFrom === true){
      localStorage.setItem("camJoinedLead", null);
      saveFormDataToRds(props?.roomId, siteUrl, comingFrom, "noform")
    }

    if(comingFrom === "Bdm"){
      localStorage.setItem("bdmJoinedLead", null);
      saveFormDataToRds(props?.roomId, siteUrl, comingFrom, "noform")
    }

    if(comingFrom === false){
      saveFormDataToRds(props?.roomId, siteUrl, comingFrom, "noform")
    }

    
    localStorage.setItem("setLocalImg", undefined);
    
    setTimeout(() => {
      updateFormDatawithOutForm(props?.roomId,BDMUSERS,"noform",comingFrom, siteUrl,description,room);
  }, 1000);
    
    
 
    
    //window.location.href = siteUrl;
   
  };
  
  const getShareLink = () => {
    let urltoShare =
      "https://live.virtualemployee.com/join-room?comingFrom=" +
      comingFrom +
      "&id=";
    let param = btoa(room?.name);
    let concated = urltoShare + param;
    setShareUrl(concated);
  };

  // Open Sidebartransform: translateX(0px);
  function openChatNav() {
    closePrticpntNav();
    if (comingFrom === true) closeLeadSidenav();
    document.getElementById("chatSidenav").style.transform = "translateX(0px)";
    document.getElementById("main").style.marginRight = "300px";
    //document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.getElementById("chat").classList.add("active");
  }

  // Close Sidebar
  function closeChatNav() {
    document.getElementById("chatSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
    //document.body.style.backgroundColor = "white";
    document.getElementById("chat").classList.remove("active");
  }

  function openPrticpntNav() {
    closeChatNav();
    if (comingFrom === true) closeLeadSidenav();
    document.getElementById("partcipantSidenav").style.transform =
      "translateX(0px)";
    document.getElementById("main").style.marginRight = "300px";
    //document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.getElementById("partcipant").classList.add("active");
  }

  function closePrticpntNav() {
    document.getElementById("partcipantSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
    //document.body.style.backgroundColor = "white";
    document.getElementById("partcipant").classList.remove("active");
  }

  function openLeadSidenav() {
    closeChatNav();
    closePrticpntNav();
    document.getElementById("LeadDetailsSidenav").style.transform =
      "translateX(0px)";
    document.getElementById("main").style.marginRight = "300px";
    // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
    document.getElementById("leadDetails").classList.add("active");
  }

  function closeLeadSidenav() {
    document.getElementById("LeadDetailsSidenav").style.transform =
      "translateX(300px)";
    document.getElementById("main").style.marginRight = "0";
    //document.body.style.backgroundColor = "white";
    document.getElementById("leadDetails").classList.remove("active");
  }

  function handleCopyText() {
    var copyText = document.getElementById("clipboard-area");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  //https://new-live-7455.twil.io/validateNumber
  const lookupPhoneNumber = async (phoneNumber) => {
    try {
      const response = await axios.get(
        "https://new-live-7455.twil.io/checkNumber?phone=" + phoneNumber
      );
      const data = response.data;
      // Log the phone number returned by the Twilio Function
      return data?.success;
    } catch (err) {
      //console.error(err);
      //throw err;
      return false;
    }
  };

  //Call Twilio Function
  const makeACall = async () => {
    toast.success("Please keep patience we are trying to connect..", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // updating mobile button status to forms or veforms database
    updateCamMobileStatus(props?.roomId);
    setClientInfoAction(cinfo);
    let countryCode = "+1";
    if (contactNo != 0 && contactNo?.length >= 10) {
      countryCode = "+" + getCountryCallingCode(country);
    }
    let contactNoUp = contactNo.slice(-10);
    let concatNo = countryCode + contactNoUp;
    let isValid = await lookupPhoneNumber(concatNo);
    console.log("isValid");
    console.log(isValid);
    if (isValid) {
      updateNumberValid(props?.roomId, true);
      document.getElementById("audioModel").style.display = "block";

      let updateMob = await updateContact(concatNo, props.formId);

      const functionName = "add-to-videocall"; // Replace with your serverless function name
      const accountId = "new-live-7455"; // Replace with your Twilio account ID

      const url = `https://${accountId}.twil.io/${functionName}`;
      const data = {
        // Replace with your request payload
        To: concatNo,
        roomId: props?.roomId,
      };
      const username = process.env.REACT_APP_ACCOUNT_SID;
      const password = process.env.REACT_APP_APIKEY_SID;
      const headers = {
        Authorization: `Basic ${window.btoa(`${username}:${password}`)}`,
      };
      axios
        .post(url, data, { headers })
        .then((response) => {
          //console.log(response?.data);
        })
        .catch((error) => {
          // document.getElementById("audioModel").style.display = "none";

          console.error(error);
          // toast.error("Sorry unable to make a call.", {
          //   position: "top-center",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          //   });
        });
    } else {
      updateNumberValid(props?.roomId, false);
      document.getElementById("audioModel").style.display = "none";
      toast.error("Sorry the input number is not valid to make a call.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      console.log("Unable to validate ");
    }
  };

  const updateCamMobileStatus = async (roomId) => {
    const formData = query(
      collection(database, "playerstreamer"),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      // console.log(docData.id, ' => ', docData.data());
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
      updateDoc(formRef, {
        camClickedMobileButton: true,
        camClickedMobileButtonTime: serverTimestamp(),
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const updateNumberValid = async (roomId, isValid) => {
    const formData = query(
      collection(database, "playerstreamer"),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      // console.log(docData.id, ' => ', docData.data());
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
      updateDoc(formRef, {
        isNumberValid: isValid,
      })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const updateContact = async (contactNo, dataID) => {
    const formRef = doc(database, "playerstreamer", dataID);
    updateDoc(formRef, {
      phone: contactNo,
    })
      .then(() => {
        console.log("done");
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand navbar-dark bg-dark topbar sticky-top">
        <div className="container-fluid px-md-3">
          <a className="navbar-brand navbar-timer" href="#">
            <i className="bi bi-stopwatch"></i>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav ms-auto navbar-nav-scroll align-items-center ">
              {comingFrom ? (
                <li className="nav-item dropdown me-2">
                  <a className="nav-link text-white text-center">
                    {/* <i class="bi bi-circle-fill in-process" style={{color:'var(--theme-color2)'}}></i>
                  <i class="bi bi-circle-fill your-reached" style={{color:'#ffe300'}}></i> */}

                    {/* <div className='menu-title'>In Process</div>
                  <div className='menu-title'>Your Reached</div> */}
                    {streamStatus == 3 ? (
                      <div>
                        <i
                          class="bi bi-circle-fill now-speak"
                          style={{ color: "#7ed959" }}
                        ></i>
                        <div className="menu-title">Now speak</div>
                      </div>
                    ) : streamStatus == 2 ? (
                      <div>
                        <i
                          class="bi bi-circle-fill your-reached"
                          style={{ color: "#ffe300" }}
                        ></i>
                        <div className="menu-title">Your Reached</div>
                      </div>
                    ) : (
                      <div>
                        <i
                          class="bi bi-circle-fill in-process"
                          style={{ color: "var(--theme-color2)" }}
                        ></i>
                        <div className="menu-title">In Process</div>
                      </div>
                    )}
                  </a>
                </li>
              ) : (
                <li></li>
              )}

              {comingFrom ? (
                <li className="nav-item dropdown me-2">
                  <a
                    className="nav-link text-white text-center"
                    onClick={() => openLeadSidenav()}
                    id="leadDetails"
                  >
                    <i class="bi bi-card-list"></i>
                    <div className="menu-title">Lead Details</div>
                  </a>
                </li>
              ) : (
                <li></li>
              )}
              <li className="nav-item dropdown me-2 dropdown share-link">
                <a
                  className="nav-link text-white text-center"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  onClick={() => {
                    getShareLink();
                  }}
                  aria-expanded="false"
                >
                  <i className="bi bi-link-45deg"></i>
                  <div className="menu-title">Share Link</div>
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end caret-indicator p-3"
                  style={{ width: "300px" }}
                >
                  <div className="input-group">
                    <input
                      type="text"
                      id="clipboard-area"
                      className="form-control"
                      placeholder="URL"
                      aria-label="Copy URL"
                      aria-describedby="basic-addon2"
                      value={shareUrl}
                      readOnly
                    />
                    <button
                      className="input-group-text pointer"
                      onClick={handleCopyText}
                    >
                      <i className="bi bi-files"></i>
                    </button>
                  </div>
                </ul>
              </li>
              {/*For Call on Mobile Phone*/}
              {comingFrom === true && comingFrom != "Bdm" && contactNo?.length >= 10 ? (
                <li className="nav-item me-md-2">
                  <a
                    className="nav-link text-white text-center mobile-call"
                    onClick={() => makeACall()}
                    data-bs-toggle="modal"
                    data-bs-target="#audioModel1"
                  >
                    <i class="bi bi-phone"></i>
                    <div className="menu-title">Call Client's Mobile</div>
                  </a>
                </li>
              ) : null}

              <li className="nav-item dropdown me-2">
                <a
                  className="nav-link text-white text-center"
                  onClick={openPrticpntNav}
                  id="partcipant"
                >
                  <i className="bi bi-people"></i>
                  <div className="menu-title">Participants</div>
                </a>
              </li>
              <li className="nav-item dropdown me-2">
                <a
                  className="nav-link text-white text-center"
                  onClick={openChatNav}
                  id="chat"
                >
                  <i className="bi bi-chat-dots"></i>
                  <div className="menu-title">Chat</div>
                </a>
              </li>
              <li className="nav-item dropdown me-2 d-none d-lg-block">
                {!connectOnlyWithAudio && (
                  <CameraButton room={room} camera={camera} />
                )}
              </li>
              <li className="nav-item me-2 d-none d-lg-block">
                <MicButton room={room} isMicMutEd={isMicMuted} />
              </li>
              <li className="nav-item me-3 d-none d-lg-block">
                <SwitchToScreenSharingButton room={room} />
              </li>
              <li className="nav-item me-3 d-none d-lg-block">
                <LeaveRoomButton
                  room={room}
                  comingFrom={comingFrom}
                  handleRoomDisconnection={handleRoomDisconnectWithForm}
                  participants={participants}
                  handleRoomDisconnectionWithoutForm={
                    handleRoomDisconnectionWithoutForm
                  }
                  isClientOnCall={isClientOnCall}
                  roomId={props?.roomId}
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand navbar-dark bg-dark topbar fixed-bottom d-lg-none">
        <div className="container-fluid px-md-4">
          <ul className="navbar-nav mx-auto navbar-nav-scroll align-items-center ">
            <li className="nav-item dropdown me-2">
              {!connectOnlyWithAudio && (
                <CameraButton room={room} camera={camera} />
              )}
            </li>
            <li className="nav-item me-2">
              <MicButton room={room} isMicMutEd={isMicMuted} />
            </li>
            <li className="nav-item">
              <SwitchToScreenSharingButton room={room} />
            </li>
            <li className="nav-item ms-3">
              <LeaveRoomButton
                room={room}
                comingFrom={comingFrom}
                handleRoomDisconnection={handleRoomDisconnectWithForm}
                participants={participants}
                handleRoomDisconnectionWithoutForm={
                  handleRoomDisconnectionWithoutForm
                }
                isClientOnCall={isClientOnCall}
                roomId={props?.roomId}
              />
            </li>
          </ul>
        </div>
      </nav>
      <BdmCamQuesAfterCall
        roomId={props.roomId}
        room={room}
        siteUrl={siteUrl}
        comingFrom={comingFrom}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setClientInfoAction: (info) => dispatch(setClientInfo(info)),
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps, mapDispatchToProps)(VideoButtons);
