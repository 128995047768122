import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { callAxios, convertReadable, convertReadableTime, convertSecondsToMinutes } from "../utils/utils";
import { getCountryCallingCode } from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addMoreParticipant } from "../utils/pstn";
import { BeforeCallModal } from "./modal/BeforeCallModal";
import { render } from "@testing-library/react";
import { environment } from "../environment";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import $ from "jquery";
import AskPop from "./modal/AskPop";


const Card = ({ item, id, user, active, setActive, setCallDetails, setDetailsLoading }) => {
  // console.log(id, item.leadid)

  const navigate = useNavigate();
  const [handleShow_, setHandleShow] = useState(false)

  const regionNames = new Intl.DisplayNames(
    ['en'], { type: 'region' }
  );

  // dialer code
  // For Bootstrap Model
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.stopPropagation();
    setShow(true)
  };

  // For Dialpad Calling
  const [isDialpad, setIsDialpad] = useState(true);
  const [isCalling, setIsCalling] = useState(false);

  const startCall = () => {
    setIsCalling(!isCalling);
    setIsDialpad(false);
  }

  const endtCall = () => {
    setIsCalling(false);
    setIsDialpad(true);
  }

  const openDialpad = () => {
    setIsCalling(false);
    setIsDialpad(true);
  }

  //
  const countryCode = item.new_countryonform ? "+" + getCountryCallingCode(item.new_countryonform) : "";
  // console.log(countryCode)



  const AMPM = parseInt(item.createdon.toString().split("T")[1].split(":")[0]) > 12 ? "PM" : "AM"
  const dateTime = item.createdon.toString().split("T")[0] + " " + item.createdon.toString().split("T")[1].split(".")[0] + " " + AMPM;






const BeginCall = (leadid, displayName, countryCode, fullname, email, type) => {
  // setShow(false)
//  console.log("begin call")
 const cid =  $("#button-call-"+leadid).attr('data-cid');

   const phone = $("#phoneNumber").val();
     addMoreParticipant(fullname,phone,cid,setShow,"client",countryCode,email)
     
}

  useEffect(() => {
    if (id === 0) {
      getCallDetails(id, item.leadid,item.topic)
    }
  }, [])
  const getCallDetails = (id, leadid,topic) => {
    setActive(id);
    setDetailsLoading(true);
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-call-details-by-id", { leadId: leadid, topic:topic,userType:localStorage.getItem("userRole"), email:user?.email }, "post").then((callDetails) => {
      setCallDetails(callDetails.data.details);
      setDetailsLoading(false);
    })
  }

  const dialNumber = (val) =>{
    console.log(val)
    var currentValue = $('#phoneNumber').val();
        
    // Append the desired value (e.g., ' Appended') to it
    var appendedValue = currentValue + val;
    $("#phoneNumber").val(appendedValue)
  }

  const removeNumber = ()=>{
    var currentValue = $('#phoneNumber').val();
        
    // Remove the last character from the string
    var newValue = currentValue.slice(0, -1);
    
    // Set the updated value back to the input field
    $('#phoneNumber').val(newValue);
  }

  const askHandler = (e) =>{
    e.stopPropagation();
    localStorage.setItem("conferenceObject",[])
    if(item.leadid === null){
      toast.error("Client number not found!. Thank you for trying.....", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
    else{
      const formattedDate = convertReadable(item.createdon) +" "+convertReadableTime(item.createdon);
      render (<AskPop showModal={true} leadOpportunityId={item.leadOpportunityId} leadid={item.leadid} displayName={user?.displayName} countryCode={countryCode} fullname={item.fullname} email={user?.email} type="client" leadDesc={item.new_skillsetjobdescription} formattedDate={formattedDate} topic={item.topic}/>)
    }
    // console.log("In discard modal")
   
 }
  return (

    <>
      <div className={`client-card rounded p-3 mt-2 pointer ${id === active ? "selected" : ""}`} key={id} onClick={() => getCallDetails(id, item.leadid,item.topic)}>
        <ToastContainer />
        <div className="d-flex justify-content-between gap-2 mb-2">
          <div className="left-cover">
            {item.fullname ?
             <div className="">
             <div className="client-name fs-5 text-warning text-capitalize">{item.fullname}</div>
               <div className="description small text-info">{item.topic}</div>
             </div>
              : null}
            {item.createdon ?
              <div className="fs-6 text-white text-opacity-75 text-capitalize">{convertReadable(item.createdon)} <span>{convertReadableTime(item.createdon)}</span></div>
              : null}
          </div>
          
          {/* <div className="call-icon bg-success d-flex align-items-center justify-content-center rounded-circle d-none" style={{ width: '45px', minWidth: '45px', height: '45px' }} id={"dial-pad-" + item.leadid} onClick={handleShow}>
           <i class="bi bi-grid-3x3-gap-fill"></i>
          </div> */}

           <button className="call-icon bg-success d-flex align-items-center justify-content-center rounded-circle button-call" style={{ width: '45px', minWidth: '45px', height: '45px' }} id={"button-call-" + item.leadid} onClick={askHandler} data-cid="" data-whoDialed="">
            <i className="bi bi-telephone-fill text-white"></i>
            
          </button>
          <div className="call-icon bg-danger d-flex align-items-center justify-content-center rounded-circle button-hangup-outgoing hide" data-id={item.leadid} style={{ width: '45px', minWidth: '45px', height: '45px' }} id={"button-hangup-outgoing-" + item.leadid}>
            <i className="bi bi-telephone-x"></i>
          </div>

        </div>
        {item.new_skillsetjobdescription ?
          <div className="requrement-wrapper pe-4">

            <div className="client-location fs-6 text-light mb-1">Requirement Details</div>
            <div className="description small text-light text-opacity-75 ">{item.new_skillsetjobdescription}</div>

          </div> : null}
       
        
      </div>

     
    </>




  )
}

export default Card;