import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { database } from "../firebaseConfig";
import { environment } from "../environment";
import { useState } from "react";
import $ from "jquery";

const JoinNow = ({user}) =>{
    //console.log(user.email)
    const [joinCallStatus, setJoinCallStatus] = useState('');
    const [isShowJoin, setIsShowJoin] = useState(false);
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
  
  
    const queryConstraints = [];
    queryConstraints.push(where("timeStamp", ">=", startOfToday));
    queryConstraints.push(where("timeStamp", "<=", endOfToday));
   //  queryConstraints.push(where("callStatus", "==", "initiated"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
      ...queryConstraints
    );


    const roomId = useSelector((state) => state.roomId);
    
    
    //console.log("join now btn:",roomId)
    useEffect(()=>{

        onSnapshot(q, (querySnapshot) => {
            let forms = [];
          
           // if(user){
              querySnapshot.forEach(async(docu) => {
                const id = docu.id;
                const data = docu.data();
                data["id"] = docu.id;
                forms.push({ ...docu.data(), id: docu.id });
                
                const loggedInUser = user? user.email : localStorage.getItem("userEmail");
                    ////console.log(roomId)          
                if (data.callStatus === 'in-progress' && data.callType === "group" && data.roomId ===roomId) {
                   // //console.log(data)  
                    setJoinCallStatus(data.callStatus)
                    setIsShowJoin(true)
                    $("#join-call").attr("data-conversation-sid-join",data.conversationSid);
                  if(data.participants){
      
                  data.participants.forEach(async(participant)=>{
                    
                    if(participant.identity === loggedInUser){
                        //console.log("call participant details::::---->", participant)
                        $("button[data-conversation-sid-join='"+data.conversationSid+"']").removeClass("d-none");
                        $("button[data-conversation-sid-join='"+data.conversationSid+"']").attr("data-docid",data.docId)
                        $("button[data-conversation-sid-join='"+data.conversationSid+"']").attr("data-roomid",data.roomId)
                        // $(".joinConversationbtn-"+data.conversationSid).removeClass("d-none");
                        // $(".joinConversationbtn-"+data.conversationSid).attr("data-docId",data.docId)
                        // $(".joinConversationbtn-"+data.conversationSid).attr("data-roomId",data.roomId)
                        // $(".joinConversationbtn-"+data.conversationSid).attr("data-conversation-sid-join",data.roomId)
                        $("#audio-call").addClass("d-none")
                        $("#video-call").addClass("d-none")
                    }
                 })
      
                
               
                //  //console.log("joinedMemberCount:",joinedMemberCount,"--LeavedCount--",leaveCount)
                }
                }
                
                if (data.callStatus !== 'in-progress' && data.callType === "group" && data.roomId ===roomId) {
                    // //console.log(data)  
                     setJoinCallStatus(data.callStatus)
                     setIsShowJoin(false)

                     if(data.participants){
      
                        data.participants.forEach(async(participant)=>{
                          
                          if(participant.identity === loggedInUser){
                              //console.log("call participant details::::---->", participant)
                              // $(".joinConversationbtn-"+data.conversationSid).addClass("d-none")
                              $("button[data-conversation-sid-join='"+data.conversationSid+"']").addClass("d-none");
                             
                              $("#audio-call").removeClass("d-none")
                                $("#video-call").removeClass("d-none")
                          }
                       })
            
                      
                     
                      //  //console.log("joinedMemberCount:",joinedMemberCount,"--LeavedCount--",leaveCount)
                      }
                   
                 }  
              });
    
          });

    },[joinCallStatus,roomId])

    //console.log(joinCallStatus)

const handleJoinCall = async () => {
        // incomingAudio.pause();
       
        const docId = $("#join-call").data("docid")
        const roomId = $("#join-call").data("roomid")
        //console.log(docId,roomId)
        const stateData = {
          camera: false,
          isMicMuted: false,
          isRejoin: false,
          callType:"group",
          callToName:"",
          callFromName: user?.displayName,
          callFromEmail: user?.email,
          roomId:roomId,
          docId:docId,
          isTryingToJoining:true
        };
        
       const stateQueryString = JSON.stringify(stateData);
        // Construct the URL with the state data
        
        const url = `/call?state=${btoa(stateQueryString)}`;
         // Open the URL in a new tab
        window.open(url, '_blank');
            const formData = query(
              collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
              where("roomId", "==", roomId)
            );
            const querySnapshot = await getDocs(formData);
            querySnapshot.forEach(async (docData) => {
             const participants = [];
             docData.data().participants.forEach((participant)=>{
                if(participant.identity === user?.email ){
                  participants.push({status:"joined",identity:participant.identity});
                  
                  
                }
                else{
                  participants.push(participant)
                  
                 
                }
             })
           ////console.log("participant Status updated:::",participants)
              ////console.log(relevant);
              const formRef2 = doc(
                database,
                environment.REACT_APP_FIREBASE_DB_CALLS,
                docId
              );
              
                updateDoc(formRef2, {
                  //relevant: [...docData.data().relevant ,{display_name:name,email:email}],
                  participants: participants
                })
                  .then(() => {
                    // //console.log("clientJoined successfully");
                  })
                  .catch((error) => {
                    //console.log(error);
                  });
              
            });
           
                ////console.log("Data Sent");
                
             
          
       
               
      };
    return (
        <>
            <button type="button" id="join-call" className="btn btn-outline-light border text-body lh-sm d-none" title="Join call" onClick={handleJoinCall}>Join</button>
        </>
    )
}

export default JoinNow;