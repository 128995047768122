import React from "react";
import { useState } from "react";
import { convertDateTimeFromIso } from "../utils/utils";
import notebookPenIcon from '../resources/images/notebook-pen.svg';

const TaskCard = ({item,length = 300}) =>{
    //console.log(item)

    const [showLess, setShowLess] = useState(true);

    const renderHtml = (htmlText) =>{
        let returnText;
        if (htmlText && htmlText.length < length) {
          returnText = { __html: htmlText };
        } 
        else {
          if(showLess){
            returnText = {__html: htmlText.slice(0, length)+' ...' };
          } else {  
            returnText = { __html: htmlText };
          }
        }
        return returnText;
      }

      const renderDescription = (htmlText) =>{
        //console.log(htmlText)
        let returnText;
        if (htmlText && htmlText.length < length) {
          returnText = htmlText ;
        } 
        else {
          if(showLess){
            returnText = htmlText.slice(0, length)+' ...' ;
          } else {  
            returnText = htmlText ;
          }
        }
        return returnText;
      }
      let status;
      let bgcolor;
      if(item.new_tasktype === 100000000){
        status = "Resume";
        bgcolor = '#48432e';
      } else if(item.new_tasktype === 100000001){
        status = "Sample";
        bgcolor= '#34422d';
      }
      else if(item.new_tasktype === 100000002){
        status = "Quote";
        bgcolor= '#34422d';
      }
      else if(item.new_tasktype === 100000003){
        status = "Trial";
        bgcolor= '#34422d';
      }
      else if(item.new_tasktype === 100000004){
        status = "Others";
        bgcolor= '#34422d';
      }
      else {
        status = "Test";
        bgcolor= '#34422d';
      }

      let cardType;
    if(item._regardingobjectid_value === item.leadId){
      cardType = "Lead"
    }
    if(item._regardingobjectid_value === item.opportunityId){
      cardType = "Opportunity"
    }

    
    return (
        <div className='small flex-fill'>
    <div className='attn-details-wrapper flex-fill p-3' style={{backgroundColor:bgcolor}}>
        <div className='d-flex flex-wrap align-items-center justify-content-between gap-1 mb-1'>
            <div className="attn-name d-flex align-items-center gap-2">
               
            <div className='icon'>
                <img src={notebookPenIcon} alt='Icon' style={{width:'18px',filter:'invert(1)'}}/>
            </div>
            {item.activitytypecode?
                <div className='attn-name fs-6 fw-medium text-light text-capitalize'>{item.activitytypecode} {status} <span className='fw-normal'>- {cardType}</span></div>
                // <div className='attn-name fs-6 fw-medium text-light text-capitalize'></div>
                :
                null
            }
            </div>
            {item.totalTime?
            <div className='time-ago text-white text-opacity-50'>{item.totalTime}</div>
            : null }
        </div>
        <div className='d-flex flex-wrap justify-content-between gap-1 mb-3'>
        
            <div className="attn-name text-light text-opacity-75 text-capitalize">Created By <span className='attn-name'>{item.display_name?item.display_name:"Unknown"}</span> At <span className='date'> {convertDateTimeFromIso(item.createdon)}</span> <span className='time'></span></div>
            {/* <div className="attn-name text-light text-opacity-75 text-capitalize">Created By <span className='attn-name'>{item.display_name}</span> At <span className='date'> {convertDateTimeFromIso(item.createdon)}</span> <span className='time'></span></div> */}
        </div>
        {item.subject?
        <div className="client-subject mb-3">
            <div className='title-label fw-medium text-light'>Subject</div>
            <div className='description text-light text-opacity-75'>{item.subject}</div>
        </div>
        :null}

        <div className="calling-info d-flex flex-wrap justify-content-xl-between gap-2 overflow-auto mb-3">
            {/* For Call */}
            
            {/* <>
                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Call From</div>
                    <div className='description text-light text-opacity-75 text-capitalize'>Rohit Arora</div>
                </div>
                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Call To</div>
                    <div className='description text-light text-opacity-75 text-capitalize'>Simon Kinda</div>
                </div>
                {item.directioncode?
                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Direction</div>
                    <div className='description text-light text-opacity-75'> {item.directioncode  === true ? "Outgoing" : "Incoming"}</div>
                </div>
                :null}

                {item.actualdurationminutes?
                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Duration</div>
                    <div className='description text-light text-opacity-75'>{item.actualdurationminutes === 1 ? item.actualdurationminutes +" min": item.actualdurationminutes +" mins"}</div>
                </div>
                :null }

                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Outcome</div>
                    {item.tect_outcome === 693980000?
                    <div className='description text-light text-opacity-75 text-capitalize'>Voice Mail</div>
                    :null}

                    {item.tect_outcome === 693980001?
                    <div className='description text-light text-opacity-75 text-capitalize'>Connected & Completed</div>
                    :null}

                    {item.tect_outcome === 693980002?
                    <div className='description text-light text-opacity-75 text-capitalize'>Call Back</div>
                    :null}

                   {item.tect_outcome === 693980003?
                    <div className='description text-light text-opacity-75 text-capitalize'>Not Connected</div>
                    :null}
                </div>
            </> */}
            

        </div>
        {/* <div className="calling-info d-flex flex-wrap justify-content-xl-between gap-2 overflow-auto mb-3">
                                                            <div className='info-box'>
                                                                <div className='title-label fw-medium text-light'>Skills Required</div>
                                                                <div className='description text-light text-opacity-75 text-capitalize'>Digital Marketing</div>
                                                            </div>
                                                            <div className='info-box'>
                                                                <div className='title-label fw-medium text-light'>Skill</div>
                                                                <div className='description text-light text-opacity-75 text-capitalize'>Content</div>
                                                            </div>
                                                            <div className='info-box'>
                                                                <div className='title-label fw-medium text-light'>Attachment</div>
                                                                <div className='description required-wrapper d-flex flex-wrap gap-2 mt-1' style={{ maxWidth: '300px' }}>
                                                                    <div className='required-name bg-white bg-opacity-75 text-dark text-nowrap text-capitalize px-2 py-1'>Resume 1</div>
                                                                    <div className='required-name bg-white bg-opacity-75 text-dark text-nowrap text-capitalize px-2 py-1'>Resume 2</div>
                                                                </div>
                                                            </div>
                                                        </div> */}
        {item?.description && item?.description !=null ?
        <div className="client-requrement">
            <div className='title-label fw-medium text-light'>Description</div>
            <div className='description text-light text-opacity-75'>{renderDescription(item?.description)}</div>
        </div>
        :null}
    </div>
    {item?.description && item?.description.length < length || item?.description === null? null :
    <div className="view-more-box d-flex align-items-center justify-content-between gap-3 px-3 py-1">
        <div className='fw-medium text-light text-opacity-75 pointer' onClick={() => setShowLess(!showLess)}>
        &nbsp;View {showLess ? "More" : "Less"}
        </div>
        <div className='icon lh-1'>
        {showLess ? <i className="bi bi-chevron-down text-white fs-5 pointer"></i> : <i className="bi bi-chevron-up text-white fs-5 pointer"></i>}
            </div>
    </div>
     }
    </div>
    )
}

export default TaskCard;