import React, { useEffect } from "react";
import ChatSection from "./ChatSection/ChatSection";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./RoomPage.css";
import VideoSection from "./VideoSection/VideoSection";
import { connect } from "react-redux";
import {
  setTwilioAccessToken,
  setConnectOnlyWithAudio,
  setIdentity,
  setRoomId,
  joinAsAdmin,
} from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation } from "react-router-dom";
import Overlay from "./Overlay";

function AdminRoomPage(props) {
  const {
    identity,
    roomId,
    setTwilioAccessTokenAction,
    showOverlay,
    isRoomHost,
    setConnectOnlyWithAudioAction,
    connectOnlyWithAudio,
    setRoomIdAction,
    setIdentityAction,
    joinAsAdminAction,
  } = props;
  let navigate = useNavigate();

  const search = useLocation().search;
  const name = new URLSearchParams(search).get("name");
  const joinerRoomId = new URLSearchParams(search).get("id");


  useEffect(() => {
    setIdentityAction(name);
    setRoomIdAction(joinerRoomId);
    joinAsAdminAction(true);

    getTokenFromTwilio(setTwilioAccessTokenAction, name);
    // if(!identity || !roomId){
    //   navigate('/')
    // }else {
    //   getTokenFromTwilio(setTwilioAccessTokenAction, identity);
    // }
  }, []);

  return (
    <>
      <VideoSection comingFrom="admin" isCam={false} />

      {/* Main Video Section */}
      {/* <div id="main" className='room_container main-section p-lg-3 overflow-hidden'>
        <div id='videos_portal' className='when-normal d-flex flex-wrap h-100 overflow-auto position-relative'>
        </div>
      </div> */}

      {/* Main Video Section (When Shared Screen) */}
      {/* <div id="main" className='d-lg-flex room_container main-section p-lg-3 overflow-hidden gap-lg-3'>
        <div id='shared_screen' className='shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info'>
          <img src={require('../resources/images/Introduction-Page.png')} className="object-fit-contain img-fluid" alt="Shared Screen Preview" />
        </div>
        <div id='videos_portal' className='when-shared d-flex flex-wrap align-content-start w-100 h-100 overflow-auto position-relative flex-shrink-1 p-lg-3'>
        </div>
      </div> */}

      <div
        id="main"
        className="room_container main-section p-lg-3 overflow-hidden"
      >
        <div
          id="shared_screen"
          className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"
        ></div>
        <div
          id="videos_portal"
          className="when-normal d-flex flex-wrap h-100 overflow-auto position-relative"
        ></div>
      </div>

      {/* Chat Sidenav */}
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

      {/* Participants Sidenav */}
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>
    </>
  );
}

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) =>
      dispatch(setTwilioAccessToken(token)),
    setConnectOnlyWithAudioAction: (onlyWithAudio) =>
      dispatch(setConnectOnlyWithAudio(onlyWithAudio)),
    setIdentityAction: (identity) => dispatch(setIdentity(identity)),
    setRoomIdAction: (id) => dispatch(setRoomId(id)),
    joinAsAdminAction: (val) => dispatch(joinAsAdmin(val)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(AdminRoomPage);
