import React, { useEffect, useState } from 'react';

import "./CamBdmProfile.css";

import { useLocation, useNavigate } from 'react-router-dom';

import {callAxios, convertReadable, convertReadableTime} from "../utils/utils";

import { environment } from '../environment';

import { database} from "../firebaseConfig";

import {
  collection,

  query,

  orderBy,

} from "firebase/firestore";
import { useSelector } from 'react-redux';

export const CamBdmProfile = () => {
    let navigate = useNavigate();
    const [presentedDays, setProfileData] = useState([]);
    const [LoginLogout, setLoginLogoutDetails] = useState([]);
    const [meetingDetails, setMeetingDetails] = useState([]);
    const [attendanceList, setAttendanceList] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [isloading, setIsloading] = useState(true);

    //getting current date
    const todayDate = new Date();
    const totalDaysTillNow = new Date(todayDate).toLocaleString('default', { day: '2-digit' });

    //console.log("Total Days:",totalDaysTillNow)
    // end 

    // getting state values
    const {state} = useLocation();
    const { user } = state;

    //console.log("user:", user)
    // end 

    //console.log("user info::", user)
    const queryConstraints = [];
   
    queryConstraints.push(orderBy('timeStamp', 'desc'));
    const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);
    //console.log(user.email);
    useEffect(()=>{
        
          const getMeetingData = async() =>{
            callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-meetings',{email:user.email,userType: user.userType}, "post" ).then((meetingData)=>{
                setMeetingDetails(meetingData.data);
                setIsloading(false);
                document.getElementById("meeting-list-db").style.display = "none";
              
           })
       }
       getMeetingData();
        const getProfileData = async() =>{
             callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-profile-data',{email:user.email}, "post" ).then((profileData)=>{
                setProfileData(profileData)
                document.getElementById("attendance-percentage").style.display = "none";
            })
        }
        getProfileData();

        const getLoginLogoutDetails = async() =>{
             callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/login-logout-details',{email:user.email, date:"current"}, "post" ).then((loginLogoutDetails)=>{
                setLoginLogoutDetails(loginLogoutDetails)
            })
        }
        getLoginLogoutDetails();

        const firstLoginLogout = async() =>{
             callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-first-login-logout',{email:user.email,userType:user.userType}, "post" ).then((firstLoginLogout)=>{
                setAttendanceList(firstLoginLogout);
                document.getElementById("attendance-list").style.display = "none";
            })
        }
        firstLoginLogout();

       
    },[user])

    const loggedInUser = useSelector((state) => state.userInfo);
    const userRoleStore = useSelector((state) => state.userRole);
    const loggedInUserRole = userRoleStore? userRoleStore:localStorage.getItem("userRole");
    const loggedInUserRoleArr = ["SA","SSA"];
       // console.log(user,loggedInUser,loggedInUserRole)
    const handleViewMore = async(leadId) =>{

       // console.log("LeadID::", leadId);
       navigate('/meeting-details', { state: { userType:user.userType, meetingList: meetingDetails, name:user.name, image:image}});
        
    }

    const handleViewMoreSASSA = async() =>{

        const meetings= meetingDetails.length > 0 ? meetingDetails:[];
        navigate('/meeting-details', { state: {userType:user.userType, meetingList: meetingDetails, name:user.name, image:image}});
         
     }

    const percentage = parseFloat(presentedDays?.data?.length * 100 / totalDaysTillNow).toFixed(0);

    //console.log("Total percentage::", percentage, presentedDays, presentedDays?.data?.length)

    //console.log(LoginLogout.data)
    //console.log(attendance)
   // console.log("LogIn Logout",LoginLogout.data)
   //console.log(attendanceList.data)
    const [active, setActive] = useState(null);
    const handleClick = async(index, filterDate) => {
       // console.log(filterDate)
        setActive(index);

        await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/login-logout-details',{email:user.email, date:filterDate}, "post" ).then((loginLogoutDetails)=>{
            setLoginLogoutDetails(loginLogoutDetails)
        })

        setRefreshKey(oldKey => oldKey +1)
        
    };
    const image = !user.profile_image?require("../resources/images/Avatar.png"):user?.profile_image;

    const fireBaseTime = new Date(
        user.timeStamp.seconds * 1000 + user.timeStamp.nanoseconds / 1000000
      );

    const dateNew = fireBaseTime.getDate() + ' '+ fireBaseTime.toLocaleString('default', { month: 'long' }) +' '+fireBaseTime.getFullYear();
    
    
  
// console.log(meetingDetails)

let meetingHtml;

if(meetingDetails.length >0 ){
    meetingHtml =  meetingDetails.map((meeting, idx) => {
      
        return (
        <div className="list-item-wrapper small border-5 border-top border-success p-3 mb-3" key={"meetinglisting---"+idx}>
            <div className="row">
                <div className="col-sm-5">
                    <div className="client-name text-capitalize mb-1">{ meeting.name}</div>
                    <div className="d-flex align-items-center total-participants active text-capitalize mb-1">
                        <i className="bi bi-people-fill me-2 fs-5 lh-1"></i>Total Participants { meeting.countParticipants}
                    </div>
            
                    
                
                
                </div>
                <div className="col-sm-7 mt-1 mt-sm-0">
                    <div className='attn-date d-flex gap-2 mb-1'>
                        <div className='title-label'>Meeting Date :</div>
                        <div className='title-value'><i className="bi bi-calendar-check-fill me-2"></i>{meeting?.timeStamp ? convertReadable(meeting.timeStamp) : ""}</div>
                    </div>
                    <div className='attn-login d-flex gap-2'>
                        <div className='title-label'>Calling Time :</div>
                        <div className='title-value'><i className="bi bi-clock-fill me-2"></i>{meeting?.camJoinedAt ? convertReadableTime(meeting.camJoinedAt):""} - {meeting?.camLeftAt ? convertReadableTime(meeting.camLeftAt):""}</div>
                    </div>

                    {/* <div className='attn-login d-flex gap-2'>
                       
                        <div className='title-value'><button className='btn btn-success' onClick={()=>{handleViewMore('10a2aeea-f2a6-4478-b7b6-19aca4390098')}}>View Details</button></div>
                    </div> */}
                </div>
            </div>
            { user.userType === "CAM" && meeting.attendantBdmName?
            <div className='title-label bdm-name'><span className='text-warning me-2'>BDM Name :</span> {meeting.attendantBdmName}</div>
            :null}
    
            { user.userType === "BDM" && meeting.attendantName?
            <div className='title-label bdm-name'><span className='text-warning me-2'>CAM Name :</span> {meeting.attendantName}</div>
            :null}
            <hr className='my-2' />
            <div className="col-md-12">
                { meeting.description?
                <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
                : null }
                 { meeting.description? null:
                <div className="requrement-label text-danger mb-1">Client didn't fill the requirement yet</div>
                 }
                 { meeting.description?
                <div className="client-requrement">{meeting.description}</div>
                :null } 
            </div>
        </div>
    )
    }
    );
} else {
    
    meetingHtml = 'No meeting attended yet!';
    
}


const formatAMPM = (date) => {
    //console.log("LOCAL TIME:",date)
    const localDate = new Date(date).toLocaleString();
   //console.log(localDate)
   // const time = localDate.split(",")[1];
    // var hours = date.getHours();
    // var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // return strTime;
    return localDate.split(",")[1];
  }

  const formatAMPM_5_30 = (date) => {
    //console.log("LOCAL TIME:",date)
    const localDate = new Date(date);
    localDate.setHours(localDate.getHours() + 5);
    localDate.setMinutes(localDate.getMinutes() + 30);
    const localDateUpdated = new Date(localDate).toLocaleString();
    //console.log(localDate)
   // const time = localDate.split(",")[1];
    // var hours = date.getHours();
    // var minutes = date.getMinutes();
    // var ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // minutes = minutes < 10 ? '0'+minutes : minutes;
    // var strTime = hours + ':' + minutes + ' ' + ampm;
    // return strTime;
    return localDateUpdated.split(",")[1];
  }

let logoutLoginDetailsHtml;

if(LoginLogout?.data?.logInLogoutDetails?.length > 0){
    logoutLoginDetailsHtml = LoginLogout?.data?.logInLogoutDetails?.map((item,index) =>{

        const loginDate = new Date(item.log_in).getDate() +' '+ new Date(item.log_in).toLocaleString('default', { month: 'short' }) +' '+ new Date(item.log_in).getFullYear();
    
        const logoutDate = new Date(item.log_out).getDate() +' '+ new Date(item.log_out).toLocaleString('default', { month: 'short' }) +' '+ new Date(item.log_out).getFullYear();
        
        return (
    
            <div className="list-item-wrapper small border border-secondary border-opacity-50 p-3 mb-3 " key={index}>
                    <div className="row">
                        <div className="col-xl-5">
                            <div className="client-name text-capitalize mb-1">{item.display_name}</div>
                            <div className='attn-date d-flex gap-2 mb-1'>
                                <div className='title-label'>Login Date :</div>
                                <div className='title-value'><i className="bi bi-calendar-check-fill me-2"></i>{loginDate}</div>
                            </div>
                            <div className='attn-date d-flex gap-2 mb-1'>
                                <div className='title-label'>Logout Date :</div>
                                <div className='title-value'><i className="bi bi-calendar-check-fill me-2"></i>{item.log_out?logoutDate:'-'}</div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className='attn-login d-flex gap-2 mb-1'>
                                <div className='title-label'>Login Time :</div>
                                <div className='title-value'><i className="bi bi-clock-fill me-2"></i>{item.log_in?formatAMPM(new Date(item.log_in)):'-'}</div>
                            </div>
                            <div className='attn-login d-flex gap-2 mb-1'>
                                <div className='title-label'>Logout Time :</div>
                                <div className='title-value'><i className="bi bi-clock-fill me-2"></i>{item.log_out?formatAMPM(new Date(item.log_out)):'-'}</div>
                            </div>
                            <div className='attn-login d-flex gap-2 mb-1'>
                                <div className='title-label'>Time Duration :</div>
                                <div className='title-value'><i className="bi bi-clock-fill me-2"></i>{item.time_duration && item.time_duration !='NaN:NaN:NaN'?item.time_duration:'-'}</div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    })
}else{
    logoutLoginDetailsHtml = 'Login / Logout Details found for Today';
    
}

let attendaceList;

if(attendanceList?.data?.length > 0){
    attendaceList = attendanceList?.data?.map((item,index) =>{
    
        const loginDate = item.logIn?new Date(item.logIn).getDate() + ' '+ new Date(item.logIn).toLocaleString('default', { month: 'long' }) +' '+new Date(item.logIn).getFullYear() : new Date(item.logOut).getDate() + ' '+ new Date(item.logOut).toLocaleString('default', { month: 'long' }) +' '+new Date(item.logOut).getFullYear();

        const logoutDate = item.logOut?new Date(item.logOut).getDate() + ' '+ new Date(item.logOut).toLocaleString('default', { month: 'long' }) +' '+new Date(item.logOut).getFullYear() : '-';
        
        const filterAttendanceDate = item.logIn?new Date(item.logIn).getFullYear() +'-'+ new Date(item.logIn).toLocaleString('default', { month: '2-digit' }) +'-'+new Date(item.logIn).toLocaleString('default', { day: '2-digit' }) : new Date(item.logOut).getFullYear() +'-'+ new Date(item.logOut).toLocaleString('default', { month: '2-digit' }) +'-'+new Date(item.logOut).toLocaleString('default', { day: '2-digit' });
    
       
        return (
            <div className={`attendance-item shadow-sm rounded-1 ${active === index ? 'selected' : ''}`} key={index} onClick={() => handleClick(index, filterAttendanceDate)}>
                    <div className='attn-date small d-flex gap-2'>
                        <div className='title-label w-50'>Login Date :</div>
                        <div className='title-value w-50'><i className="bi bi-calendar-check-fill me-2"></i>{loginDate}</div>
                    </div>
                    <div className='attn-login small d-flex gap-2'>
                        <div className='title-label w-50'>Login Time :</div>
                        <div className='title-value w-50'><i className="bi bi-clock-fill me-2"></i>{item.logIn?formatAMPM(new Date(item.logIn)):'-'}</div>
                    </div>
                    <div className='attn-date small d-flex gap-2'>
                        <div className='title-label w-50'>Logout Date :</div>
                        <div className='title-value w-50'><i className="bi bi-calendar-check-fill me-2"></i>{logoutDate}</div>
                    </div>
                    <div className='attn-logout small d-flex gap-2'>
                        <div className='title-label w-50'>Logout Time :</div>
                        <div className='title-value w-50'><i className="bi bi-clock-fill me-2"></i>{item.logOut?formatAMPM_5_30(new Date(item.logOut)):'-'}</div>
                    </div>
                    <div className='attn-logout small d-flex gap-2'>
                        <div className='title-label w-50'>Total Joined Calls :</div>
                        <div className='title-value w-50'><i className="bi bi-telephone-plus-fill me-2"></i>{item.calls[0].total_call_joined?item.calls[0].total_call_joined:0}</div>
                    </div>
                    <div className='attn-logout small d-flex gap-2'>
                        <div className='title-label w-50'>Total Connected Calls :</div>
                        <div className='title-value w-50'><i className="bi bi-telephone-plus-fill me-2"></i>{item.calls[0].total_call_connected?item.calls[0].total_call_connected:0}</div>
                    </div>
                </div>
        );
    })
} else {
    attendaceList = 'Attendance log not recorded yet!'
}
const handleHourDisplay = (type)=>{
    if(type === "login"){
        document.getElementById('total-hour').classList.remove("d-none");
    }
    else{
        document.getElementById('total-hour').classList.add("d-none");
    }
    
}

    return (
        <div className='account_page cam-bdm-details py-4 py-xl-5'>
            <div className='container'>
                <div className="row">
                    <div className="col-xl-4 col-lg-5 col-sm-12 mb-4 mb-lg-0">
                        <div className="card border border-1 border-secondary rounded-0">
                            <div className="cam-bdm-details text-center px-3 py-4">
                                <img className="rounded-circle border border-1 shadow overflow-hidden mx-auto mb-2 img-fluid" src={image} alt="Cam BDM Image" style={{ height: "200px", width: "200px" }} />
                                <div className="card-title fs-4 text-light text-opacity-75 text-capitalize mb-0">{user.name}</div>
                                <div className="card-email small mb-1"><i className="bi bi-envelope me-2"></i>{user.email}</div>
                                <div className="bdm-since small text-capitalize">{user.userType} Since {dateNew}</div>
                            </div>
                            <div className='cam-bdm-attend-list border-top border-1 border-secondary px-3 py-4 pt-3'>
                                <div className='fs-6 attendance-label mb-2'>Attendance List</div>

                                <div className='attendance-wrapper'>
                                    <div style={{textAlign:'center'}}>
                                <span class="spinner-profile" id="attendance-list"></span>
                                </div>
                                   {attendaceList}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-7">
                        <div className="row g-3">
                            <div className="col-md-6">
                                <div className="card h-100 text-center py-4 px-3 p-xl-5 border border-1 border-secondary rounded-0">
                                    <div className="card-title fs-5 text-capitalize">Total Calls Attend</div>
                                    <div style={{textAlign:'center'}}>
                                <span class="spinner-profile" id="meeting-list-db"></span>
                                </div>
                                {isloading?null:
                                    <div className="card-value fs-1 fw-bold text-capitalize lh-1">{meetingDetails.length}</div>
                                }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card h-100 text-center py-4 px-3 p-xl-5 border border-1 border-secondary rounded-0">
                                    <div className="card-title fs-5 text-capitalize">My Attendance</div>
                                    
                                    <div className="card-value fs-1 fw-bold text-capitalize lh-1">
                                        
                                    <span class="spinner-profile" id="attendance-percentage"></span>
                                    
                                        {isNaN(percentage) === true? null: percentage+'%'}
                                        </div>
                                </div>
                            </div>
                        </div>

                        <hr className='my-4' />

                        {/* Tab Details Section */}
                        <div className='tab-details-section'>
                            <div className='d-flex flex-column flex-xl-row align-items-xl-center gap-2 mb-3'>
                            <div className="nav nav-tabs flex-column flex-md-row gap-2 border-0" id="nav-tab" role="tablist">
                                <button className="btn btn-secondary border-0 rounded-1 px-3 py-2 active" id="meeting-tab" data-bs-toggle="tab" data-bs-target="#meetingDetails" onClick={()=>{handleHourDisplay("meeting")}} type="button" role="tab" aria-controls="meetingDetails" aria-selected="true">
                                    <i className="bi bi-journal-text"></i> Meeting Details
                                </button>
                                <button className="btn btn-secondary border-0 rounded-1 px-3 py-2 py-2" id="call-tab" data-bs-toggle="tab" data-bs-target="#callDetails"  onClick={()=>{handleHourDisplay("login")}} type="button" role="tab" aria-controls="callDetails" aria-selected="false"><i className="bi bi-telephone"></i> Login/Logout Details</button>
                                { meetingDetails.length >0 && loggedInUserRoleArr.includes(loggedInUserRole) ?
                                <button className='btn btn-success' onClick={()=>{handleViewMoreSASSA()}}>View Details</button>
                                :null}
                                
                                {meetingDetails.length >0 && (loggedInUser?.email === user.email) ?
                                <button className='btn btn-success' onClick={()=>{handleViewMore()}}>View Details</button>
                                :null}

                               
                               
                            </div>
                            
                            <div className="d-none ms-xl-auto"  id="total-hour"><i className="bi bi-clock" style={{ color: 'yellow', opacity: 0.7}}></i> <span className='fw-semibold'>Total Hours:</span> { LoginLogout?.data?.TotalWorkedToday} </div>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="meetingDetails" role="tabpanel" aria-labelledby="meeting-tab" tabIndex={0}>
                                    {/* Meeting Details Listing */}
                                    {isloading?
                                    <div style={{textAlign:'center'}}>
                                <span class="spinner-profile"></span>
                                </div>
                                :
                                    <div className='call-history-section live-listing-wrapper'>
                                       {meetingHtml}
                                    </div>
                                    }
                                </div>
                                <div className="tab-pane fade" id="callDetails" role="tabpanel" aria-labelledby="call-tab" tabIndex={0}>
                                    {/* Call History Listing */}
                                    <div className='call-history-section live-listing-wrapper'>
                                        {logoutLoginDetailsHtml}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
