import { Rating } from 'react-simple-star-rating';
import { convertReadable, convertReadableTime } from '../../utils/utils';

const InterviewStarted = ({interviewStartedData, cardType}) =>{

    const intTime = parseInt(interviewStartedData.interview_time)> 12 && parseInt(interviewStartedData.interview_time) < 24 ? interviewStartedData.interview_time + " PM" : interviewStartedData.interview_time + " AM";
    return (
         
          <div class="info-cards int-started small rounded pointer overflow-auto mt-2">
          <div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">{cardType}</div>
          <div className="info-body p-3">
              <div class="row g-3 mb-3">
                  <div class="col-sm-6">
                      <div className="cand-name text-primary fs-6 fw-medium text-capitalize">{interviewStartedData.display_name}</div>
                      <div className="cand-role text-muted text-capitalize">{interviewStartedData.display_name}</div>
                      {/* <div className="cand-company text-black text-capitalize">{interviewStartedData.current_company}</div> */}
                  </div>
                  <div class="col-sm-6 text-xl-end">
                      <div className="cand-date text-muted text-capitalize">{convertReadable(interviewStartedData.test_starttime) + ' '+convertReadableTime(interviewStartedData.test_starttime)}</div>
                      <div className="cand-status fw-medium text-danger text-capitalize">{interviewStartedData.job_status}</div>
                  </div>
              </div>

              <div class="row g-3">
                  <div class="col-sm-6 col-xl-3">
                      <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                          <div className="label-title text-black">Total Experience:</div>
                          <div className="label-value text-danger">{interviewStartedData.total_exp} Years</div>
                      </div>
                      <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                        <p>{interviewStartedData.display_name} has started online test for {interviewStartedData.sel_language}</p>
                          
                      </div>
                  </div>
                  
                  
                  
              </div>
          </div>
      </div>
    )
}

export default InterviewStarted;