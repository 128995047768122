import React from "react";
import { useState } from "react";
import { callAxios, callCrmApi, convertDateTimeFromIso, getIndexBySystemUserId } from "../utils/utils";
import { environment } from "../environment";

const AppointmentCard = ({item,length = 800, userData}) =>{
    //console.log(item)
    const [isLoading, setIsLoading] = useState(false);
    const [showLess, setShowLess] = useState(true);
    const [body, setBody] = useState({});
    //console.log(body)
    const renderHtml = (htmlText) =>{
        let returnText;
        if (htmlText && htmlText.length < length) {
          returnText = { __html: htmlText };
        } 
        else {
          if(showLess){
            returnText = {__html: htmlText.slice(0, length)+' ...' };
          } else {  
            returnText = { __html: htmlText };
          }
        }
        return returnText;
      }

      const renderDescription = (htmlText) =>{
        let returnText;
        if (htmlText && htmlText.length < length) {
          returnText = htmlText ;
        } 
        else {
          if(showLess){
            returnText = htmlText.slice(0, length)+' ...' ;
          } else {  
            returnText = htmlText ;
          }
        }
        return returnText;
      }
    
    // const getAppoinmentDescription = async(activityid) =>{
    //     setIsLoading(true)
    //      const getAppointment = await callAxios(environment.REACT_APP_CRM_ENDPOINT,
    //       {"operation":"getappointmentbyactivityid", "activityid": activityid}, "post");
    
    //       //console.log(getEmailDesc.data.data[0].description)
    //       setBody(getAppointment.data.data[0].description)
    //       setIsLoading(false)
    //       setShowLess(!showLess)
         
    //   }

      const getAppoinmentDescription = async(activityid) =>{
        setIsLoading(true)
        const payLoad = JSON.stringify({"operation":"getappointmentbyactivityid", "activityid": activityid});
        
        const response = await callCrmApi(payLoad);
        // const getBody = await callAxios(
        //   environment.REACT_APP_API_URL_Live + "api/v1/leads/get-body",
        //   { activityid: activityid,type:"appointment", apiKey: "nmlive" },
        //   "post"
        // );
    
          //console.log(getEmailDesc.data.data[0].description)
          const partyPayload = JSON.stringify({"operation":"fetchactivityparties", "activityid": activityid})
       
          const parties = await callCrmApi(partyPayload);
   
          const parties_ = parties.data.data;
   
           const To = [];
           const From = [];
           const Required = [];
           const Organizer = [];
         for(let i=0; i<parties_.length;i++){
           const getArrIndex = getIndexBySystemUserId(userData,parties_[i]._partyid_value);
           //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
           if(parties_[i].participationtypemask === 1){
             
             if(getArrIndex > -1){
               parties_[i]['display_name'] = userData[getArrIndex].display_name;
               From.push(parties_[i]);
               
             }
             
           }
   
           if(parties_[i].participationtypemask === 2){
             //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
             if(getArrIndex > -1){
               parties_[i]['display_name'] = userData[getArrIndex].display_name;
               To.push(parties_[i]);
             }
         }
   
         if(parties_[i].participationtypemask === 5){
           //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
           if(getArrIndex > -1){
             parties_[i]['display_name'] = userData[getArrIndex].display_name;
             Required.push(parties_[i]);
           }
       }
   
       if(parties_[i].participationtypemask === 7){
         //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
         if(getArrIndex > -1){
           parties_[i]['display_name'] = userData[getArrIndex].display_name;
           Organizer.push(parties_[i]);
         }
     }
           
          
         }
          setBody({desc:response.data.data[0].description,to:To,from:From, require:Required,organizer:Organizer})
          setIsLoading(false)
          setShowLess(!showLess)
         
      }
//console.log(body)
      let cardType;
    if(item._regardingobjectid_value === item.leadId){
      cardType = "Lead"
    }
    if(item._regardingobjectid_value === item.opportunityId){
      cardType = "Opportunity"
    }

    const Body = Object.keys(body).length

    return (
        <div className='small flex-fill'>
    <div className='attn-details-wrapper flex-fill p-3'>
        <div className='d-flex flex-wrap align-items-center justify-content-between gap-1 mb-1'>
            <div className="attn-name d-flex align-items-center gap-2">
                <div className='icon'>
                    <i className="bi bi-calendar-week fs-6 text-light"></i>
                </div>
                {/* <div className='attn-name fs-6 fw-medium text-light text-capitalize'>Appointment</div> */}
                <div className='attn-name fs-6 fw-medium text-light text-capitalize'>{item.activitytypecode} <span className='fw-normal'>- {cardType}</span></div>
            </div>
            {/* <span className="badge rounded-pill text-bg-warning fw-medium ms-2 px-2" onClick={() =>{getAppoinmentDescription(item.activityid)}}>
              Read Appointment
            </span> */}
            {item.totalTime?
            <div className='time-ago text-white text-opacity-50'>{item.totalTime}</div>
            : null }
        </div>
        <div className='d-flex flex-wrap justify-content-between gap-1 mb-3'>
        
            <div className="attn-name text-light text-opacity-75 text-capitalize">Created By <span className='attn-name'>{item.display_name?item.display_name:"Unknown"}</span> At <span className='date'> {convertDateTimeFromIso(item.createdon)}</span> <span className='time'></span></div>
            {/* <div className="attn-name text-light text-opacity-75 text-capitalize">Created By <span className='attn-name'>{item.display_name}</span> At <span className='date'> {convertDateTimeFromIso(item.createdon)}</span> <span className='time'></span></div> */}
        </div>
        {item.subject?
        <div className="client-subject mb-3">
            <div className='title-label fw-medium text-light'>Subject</div>
            <div className='description text-light text-opacity-75'>{item.subject}</div>
        </div>
        :null}
          {Body === 0 ? null :
        <div className="calling-info d-flex flex-wrap justify-content-xl-between gap-2 overflow-auto mb-3">
            {/* For Call */}
           
            <>
            <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Appointment Type</div>
                    {item.new_appointmenttype === 100000000?
                    <div className='description text-light text-opacity-75 text-capitalize'>Resource Interview</div>
                    :null }

                    {item.new_appointmenttype === 100000001?
                    <div className='description text-light text-opacity-75 text-capitalize'>TL Meeting</div>
                    :null }

                    {item.new_appointmenttype === 100000002?
                    <div className='description text-light text-opacity-75 text-capitalize'>CAM Meeting</div>
                    :null }

                   {item.new_appointmenttype === 100000003?
                    <div className='description text-light text-opacity-75 text-capitalize'>Escalation</div>
                    :null }
                </div>
                {body?.organizer.length >0 ?
                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Organizer</div>
                    {body?.organizer.map((item,index)=>(
                      <>
                      <div className='description text-light text-opacity-75 text-capitalize'>{item.display_name}</div>
                      </>
                    ))}
                    
                </div>
                :null }
                {body.require.length >0?
                <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Required</div>
                    <div className='description required-wrapper d-flex flex-wrap gap-2 mt-1' style={{ maxWidth: '300px' }}>
                      {body.require.map((item,index) =>(

                      <>
                      <div className='required-name bg-white bg-opacity-75 text-dark text-nowrap text-capitalize px-2 py-1'>{item.display_name}</div>
                       
                      </>
                        
                      ))}
                    </div>
                </div>
                :null  }
                {item.scheduleddurationminutes?
                <div className='info-box'>
                    
                    <div className='title-label fw-medium text-light'>Duration</div>
                    <div className='description text-light text-opacity-75'>{item.scheduleddurationminutes} minutes</div>
                    
                </div>
                :null}
                {/* <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Start Time</div>
                    <div className='description text-light text-opacity-75 text-capitalize'>25 July 2023 7:30 PM IST</div>
                </div> */}
            </>
            

        </div>
}
        
        {/* <div className="client-requrement">
            
            <div className='description text-light text-opacity-75' dangerouslySetInnerHTML={renderHtml(body)}></div>
        </div> */}
        { isLoading ? (
          <div id="meeting-pointer-Preloader">
            <div class="meeting-pointer-spinner"></div> Please wait
            ....
          </div>
        ) : 
        <div className="requrement-wrapper" >
            {body.desc ?
          <div
            className="client-requrement text-light text-opacity-75"
            dangerouslySetInnerHTML={renderHtml(body.desc)}
          ></div>
          :null}
          </div>
        }
        
    </div>
    
    <div className="view-more-box d-flex align-items-center justify-content-between gap-3 px-3 py-1">
    {showLess ?
        body.desc?
        <div className='fw-medium text-light text-opacity-75 pointer' onClick={() =>{setShowLess(!showLess)}}>
        &nbsp;View More
        </div>
        :
        <div className='fw-medium text-light text-opacity-75 pointer' onClick={() => getAppoinmentDescription(item.activityid)}>
        &nbsp;View More
        </div>

        :
        <div className='fw-medium text-light text-opacity-75 pointer' onClick={() =>{setShowLess(!showLess)}}>
        &nbsp;View Less
        </div>
}
        <div className='icon lh-1'>
        {showLess ? <i className="bi bi-chevron-down text-white fs-5 pointer"></i> : <i className="bi bi-chevron-up text-white fs-5 pointer"></i>}
            </div>
    </div>
     
    </div>
    )
}

export default AppointmentCard;