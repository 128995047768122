import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { connectToRoom, connectToRoomAsAdmin } from "../../utils/twilioUtils";
import RoomLabel from "./RoomLabel";
import TwilioRoom from "./TwilioRoom/TwilioRoom";

const Videos = ({
  room,
  setRoom,
  roomId,
  twilioAccessToken,
  isAdmin,
  formId,
  isCam,
  comingFrom,
  camera,
  isMicMuted
}) => {
  
  useEffect(() => {
    if (twilioAccessToken) {
      if (isAdmin) connectToRoomAsAdmin(twilioAccessToken, roomId, setRoom);
      else connectToRoom(twilioAccessToken, roomId, setRoom, comingFrom, camera, isMicMuted);
    }
  }, [twilioAccessToken]);

  return (
    <div className="videos_container">
      <RoomLabel roomId={roomId} />
      {room && <TwilioRoom room={room} isCam={isCam} comingFrom={comingFrom}/>}
    </div>
  );
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStoreStateToProps)(Videos);
