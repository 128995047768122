import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class CamWithClientAndWithoutClientTime extends Component {
   
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {
    
    // if(chart._super) {
    //   chart.dispose();
    // }
    this.initChart();
    
  }
  
  whoTookHowManyCalls = (arr) =>
  arr.reduce((p, c) => {
 
    var name = c.attendantName;

    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
   
    p[name]++;
    
    return p;
  }, {});

  groupByArray = (arr) =>
    
   arr.reduce((group, product) => {
      const { attendantName } = product;
      group[attendantName] = group[attendantName] ?? [];
      group[attendantName].push(product);
      return group;
    }, {});
   
  
    diff_minutes = (endTime, startTime) => {
        const dt2 = new Date(endTime);
        const dt1 = new Date(startTime);
        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));
      };


  
  initChart(){
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create('cam-with-and-without-client-time', am4charts.XYChart)
//chart.data = fullData;
chart.colors.step = 2;
     const groupData = Object.entries(this.groupByArray(this.props.data));
  
    //console.log("CamTiming:",groupData)
    //console.log(this.props.data)
    let fullData = [];
    let connected = 0;
    for(let y=0;y < groupData.length; y++){
      let innerObj = {};
      if(groupData[y][0] != 'undefined'){
          let totalTime = 0;
          let clientmobileTime = 0;
          let clientliveTime = 0;
          for(let x =0; x<groupData[y][1].length;x++){
            if(!groupData[y][1][x].camJoinedAt || !groupData[y][1][x].camLeftAt )continue;
            const camjoinedTime = new Date(
                groupData[y][1][x].camJoinedAt.seconds * 1000 + groupData[y][1][x].camJoinedAt.nanoseconds / 1000000
              );
            const camLeftTime = new Date(
                groupData[y][1][x].camLeftAt.seconds * 1000 + groupData[y][1][x].camLeftAt.nanoseconds / 1000000
              );

              totalTime += this.diff_minutes(camLeftTime.getTime(),camjoinedTime.getTime())

              if(groupData[y][1][x].CallDuration){
                clientmobileTime += parseInt(groupData[y][1][x].CallDuration) / 60;
              } 
              if(groupData[y][1][x].clientJoinedLiveTime && groupData[y][1][x].clientLeftTime){
                     const clientjoinedTime = new Date(
                groupData[y][1][x].clientJoinedLiveTime.seconds * 1000 + groupData[y][1][x].clientJoinedLiveTime.nanoseconds / 1000000
              );
            const clientLeftTime = new Date(
                groupData[y][1][x].clientLeftTime.seconds * 1000 + groupData[y][1][x].clientLeftTime.nanoseconds / 1000000
              );
              clientliveTime += this.diff_minutes(clientLeftTime.getTime(),clientjoinedTime.getTime())
              }
           
             innerObj.withClient =  parseInt(clientmobileTime + clientliveTime);
              innerObj.CamTime = totalTime;
              
            
          }
          innerObj.name = groupData[y][0];
          innerObj.totalCalls = groupData[y][1].length;

          fullData.push(innerObj)
        
        

      }
    }
    //console.log(fullData)
  
  

    //
    
// Themes end





chart.legend = new am4charts.Legend()
chart.legend.position = 'top'
chart.legend.paddingBottom = 20
chart.legend.labels.template.maxWidth = 95

let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
xAxis.dataFields.category = 'name'
xAxis.renderer.cellStartLocation = 0.1
xAxis.renderer.cellEndLocation = 0.9
xAxis.renderer.grid.template.location = 0;

xAxis.renderer.minGridDistance = 30;
xAxis.renderer.labels.template.horizontalCenter = "right";
xAxis.renderer.labels.template.verticalCenter = "middle";
xAxis.renderer.labels.template.rotation = 270;
xAxis.tooltip.disabled = true;
xAxis.renderer.minHeight = 110;
xAxis.renderer.labels.template.fontSize = 14;


let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
yAxis.min = 0;



function createSeries(value, name, type) {
    let series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'name'
    series.name = name
    series.columns.template.width = am4core.percent(100);

    series.events.on("hidden", arrangeColumns);
    series.events.on("shown", arrangeColumns);
    if(type === 1){
        series.columns.template.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY}) Calls[/]";//"[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
    }
    if(type === 2 || type === 3){
        series.columns.template.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY}) Minutes[/]";//"[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
    }
   

    let bullet = series.bullets.push(new am4charts.LabelBullet())
    bullet.interactionsEnabled = false
    bullet.dy = 30;
    bullet.label.text = '{valueY}'
    bullet.label.fontSize = 10
    bullet.label.marginTop = -10
   // bullet.rotation = 90
    bullet.label.fill = am4core.color('#ffffff')
  

    return series;
}
chart.data = fullData;
// chart.data = [
//     {
//         category: 'Place #1',
//         first: 40,
//         second: 55,
//         third: 60
//     },
//     {
//         category: 'Place #2',
//         first: 30,
//         second: 78,
//         third: 69
//     },
//     {
//         category: 'Place #3',
//         first: 27,
//         second: 40,
//         third: 45
//     },
//     {
//         category: 'Place #4',
//         first: 50,
//         second: 33,
//         third: 22
//     }
// ]


// createSeries('first', 'The First');
// createSeries('second', 'The Second');
// createSeries('third', 'The Third');

createSeries("totalCalls", "Total Calls", 1);
createSeries("CamTime", "Total Spent Time", 2);
createSeries("withClient", "Total Spent Time With Client",3);

function arrangeColumns() {

    let series = chart.series.getIndex(0);

    let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
            let middle = chart.series.length / 2;

            let newIndex = 0;
            chart.series.each(function(series) {
                if (!series.isHidden && !series.isHiding) {
                    series.dummyData = newIndex;
                    newIndex++;
                }
                else {
                    series.dummyData = chart.series.indexOf(series);
                }
            })
            let visibleCount = newIndex;
            let newMiddle = visibleCount / 2;

            chart.series.each(function(series) {
                let trueIndex = chart.series.indexOf(series);
                let newIndex = series.dummyData;

                let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.columns.template.width = am4core.percent(90);
            })
        }
    }
}

    //

  
  }
    

  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }
  
  render() {
    return <div id="cam-with-and-without-client-time" style={{ width: "100%", height: "600px", margin: "0 auto" }} />;
  }
}

export default CamWithClientAndWithoutClientTime;