import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { store } from "../store/store";
import {
  connect,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
} from "twilio-video";
import { setShowOverlay, setMessages, setRoomSid } from "../store/actions";
import {
  Pipeline,
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
} from "@twilio/video-processors";

// api call import

import { callAxios } from '../utils/utils';

// env import
import {environment} from '../environment';
import { collection, doc, getDocs, query, serverTimestamp, updateDoc, where } from "firebase/firestore";
import { database } from "../firebaseConfig";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const audioConstraints = {
  video: false,
  audio: true,
};

  const adminAudioConstraints = {
    video: null,
    audio: null,
  };
  

  let dataChannel = null
const videoConstraints = {
  audio: true,
  video: {
    width: 1280,
    height: 720,
  },
};

// data channel utils

const storeDataToRDS = async(roomId, type)=>{
  //console.log(roomId,type)
  const formData = query(collection(database, environment.REACT_APP_FIREBASE_DB), where('roomId', '==', roomId));
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
      const data = docData.data();
      //console.log(data)
        
        if(type === "client"){
          await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/calls/record-calls-logs',{email:data?.attendantEmail,connectedCount:1,type:"connected", roomId:roomId}, "post" );
        } 
          
    });
}


export const sendMessageUsingDataChannel = (
  content,
  messageCreatedByMe = false
) => {
  const identity = store.getState().identity;

  const ownMessage = {
    identity,
    content,
    messageCreatedByMe,
  };

  addMessageToMessenger(ownMessage);

  const messageToSent = {
    identity,
    content,
  };

  const stringifiedMessage = JSON.stringify(messageToSent);
  dataChannel.send(stringifiedMessage);
};

export const addMessageToMessenger = (message) => {
  const messages = [...store.getState().messages];
  messages.push(message);
  store.dispatch(setMessages(messages));
};

//Connect to room
// export const connectToRoom = async (accessToken, roomId = "test", setRoom, comingFrom) => {
//   const onlyWithAudio = store.getState().connectOnlyWithAudio;
//   const constraints = onlyWithAudio ? audioConstraints : videoConstraints;

//   navigator.mediaDevices
//     .getUserMedia(constraints)
//     .then(async (stream) => {
//       let tracks;

//       // create data track for messages
//       const audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);
//       const dataTrack = new LocalDataTrack();
//       dataChannel = dataTrack;

//       let videoTrack;

//       if (!onlyWithAudio) {
//         videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
//         tracks = [audioTrack, videoTrack, dataTrack];
//       } else {
//         tracks = [audioTrack, dataTrack];
//       }

//       // console.log("Get Tracks Data");
//       // console.log(tracks);

//       const room = await connect(accessToken, {
//         name: roomId,
//         //networkQuality:true,
//         networkQuality: {
//           local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
//           remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
//         },
//         tracks,
//       });
//       //console.log(comingFrom)
//       setRoom(room);
//       store.dispatch(setShowOverlay(false));

//       if(comingFrom === false){
//         storeDataToRDS(roomId,"client");
//       }

//       // uncomment if you want to stop recording
//       await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/stop-recording', { RoomSid: room.sid }, "post");
      
//     })
//     .catch((err) => {
//       console.log("Error coming when trying access to local devices");
//       console.log(err);
//     });
// };

export const connectToRoom = async (accessToken, roomId = "test", setRoom, comingFrom, camera, isMicOn) => {
      //console.log(roomId, accessToken)
     navigator.mediaDevices
    .getUserMedia(videoConstraints)
    .then(async (stream) => {
      let tracks;

      // create data track for messages
      const audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);
      const dataTrack = new LocalDataTrack();
      dataChannel = dataTrack;

      let videoTrack;
      
      // muting audio track
      if(isMicOn){
        audioTrack.disable();
      } 

      // disabling video track
      if (camera) {
        //console.log(camera)
        videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
        tracks = [audioTrack, videoTrack, dataTrack];
      } else {
        //console.log(camera)
        videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
        tracks = [audioTrack, videoTrack, dataTrack];
        videoTrack.disable()
      }

      
      // console.log(accessToken)
      const room = await connect(accessToken, {
        name: roomId,
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
        tracks
      });
      //console.log(comingFrom)
      setRoom(room);
      store.dispatch(setShowOverlay(false));
      store.dispatch(setRoomSid(room.sid));
      if(comingFrom === false){
        storeDataToRDS(roomId,"client");
      }
  
      //await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/stop-recording', { RoomSid: room.sid }, "post");
      // uncomment if you want to stop recording
      if(room.sid && comingFrom === true ){
        await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/stop-recording', { RoomSid: room.sid }, "post");
      }
      
     
      
    })
    .catch((error) => {
      //console.log("Error coming when trying access to local devices");
      //console.log(error.name);
      const rejoinRoom = document.getElementById("rejoinRoom");
      const clientRejoin = document.getElementById("clientRejoin");
      if(error.name === 'NotReadableError'){
        if(rejoinRoom){
          rejoinRoom.classList.add("show");
          rejoinRoom.style.display = 'block';
        }
        if(clientRejoin){
          clientRejoin.innerHTML='<span style="color:orange;"><b>Unable to Access Media:</b></span> Your camera device is occupied by another app. Please close that app first before join!!';
        }
        
    }

    if(error.name === 'NotAllowedError'){
      if(rejoinRoom){
        rejoinRoom.classList.add("show");
        rejoinRoom.style.display = 'block';
      }
      if(clientRejoin){
        clientRejoin.innerHTML='<span style="color:red;"><b>Unable to Access Media:</b></span> You have blocked your camera or microphone for this App. Please allow camera and microphone for this app before join!!';
      }
        
    }

    if(error.name === 'NotFoundError'){
      if(rejoinRoom){
        rejoinRoom.classList.add("show");
        rejoinRoom.style.display = 'block';
      }
      if(clientRejoin){
        clientRejoin.innerHTML='<span style="color:yellow;"><b>Unable to Access Media:</b></span> Your camera or microphone may not be connected properly. Please connect your camera and microphone before join!!';
      }
    }
  
      
    });
      
       
};
// 

export const connectToRoomForChat = async (accessToken, roomId = "test", setRoom, comingFrom, camera, isMicOn) => {
  console.log(roomId, accessToken)
  // const decodedToken = jwt.decode(accessToken);
  // console.log(decodedToken)
 navigator.mediaDevices
.getUserMedia(videoConstraints)
.then(async (stream) => {
  let tracks;

  // create data track for messages
  const audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);
  const dataTrack = new LocalDataTrack();
  dataChannel = dataTrack;

  let videoTrack;
  
  // muting audio track
  if(isMicOn){
    audioTrack.disable();
  } 

  // disabling video track
  if (camera) {
    //console.log(camera)
    videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
    tracks = [audioTrack, videoTrack, dataTrack];
  } else {
    //console.log(camera)
    videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
    tracks = [audioTrack, videoTrack, dataTrack];
    videoTrack.disable()
  }

  
  // console.log(accessToken)
  const room = await connect(accessToken, {
    name: roomId,
    networkQuality: {
      local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
      remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
    },
    tracks
  });
  //console.log(comingFrom)
  setRoom(room);
  store.dispatch(setShowOverlay(false));
  store.dispatch(setRoomSid(room.sid));
  if(comingFrom === false){
    storeDataToRDS(roomId,"client");
  }

  
  //await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/stop-recording', { RoomSid: room.sid }, "post");
  // uncomment if you want to stop recording
  // if(room.sid && comingFrom === true && comingFrom !== "Bdm"){
  //   await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/stop-recording', { RoomSid: room.sid }, "post");
  // }
  
 
  
})
.catch((error) => {
  //console.log("Error coming when trying access to local devices");
  //console.log(error.name);
  const rejoinRoom = document.getElementById("rejoinRoom");
  const clientRejoin = document.getElementById("clientRejoin");
  if(error.name === 'NotReadableError'){
    if(rejoinRoom){
      rejoinRoom.classList.add("show");
      rejoinRoom.style.display = 'block';
    }
    if(clientRejoin){
      clientRejoin.innerHTML='<span style="color:orange;"><b>Unable to Access Media:</b></span> Your camera device is occupied by another app. Please close that app first before join!!';
    }
    
}

if(error.name === 'NotAllowedError'){
  if(rejoinRoom){
    rejoinRoom.classList.add("show");
    rejoinRoom.style.display = 'block';
  }
  if(clientRejoin){
    clientRejoin.innerHTML='<span style="color:red;"><b>Unable to Access Media:</b></span> You have blocked your camera or microphone for this App. Please allow camera and microphone for this app before join!!';
  }
    
}

if(error.name === 'NotFoundError'){
  if(rejoinRoom){
    rejoinRoom.classList.add("show");
    rejoinRoom.style.display = 'block';
  }
  if(clientRejoin){
    clientRejoin.innerHTML='<span style="color:yellow;"><b>Unable to Access Media:</b></span> Your camera or microphone may not be connected properly. Please connect your camera and microphone before join!!';
  }
}

  
});
  
   
};

//Join Room without share his Identity
export const connectToRoomAsAdmin = async (
  accessToken,
  roomId = "test",
  setRoom
) => {
  const onlyWithAudio = store.getState().connectOnlyWithAudio;
  const constraints = onlyWithAudio ? audioConstraints : videoConstraints;

  navigator.mediaDevices
    .getUserMedia(constraints)
    .then(async (stream) => {
      let tracks;

      // create data track for messages
      const audioTrack = new LocalAudioTrack(stream.getAudioTracks()[0]);
      const dataTrack = new LocalDataTrack();
      dataChannel = dataTrack;

      let videoTrack;

      if (!onlyWithAudio) {
        videoTrack = new LocalVideoTrack(stream.getVideoTracks()[0]);
        tracks = [audioTrack, videoTrack, dataTrack];
      } else {
        tracks = [audioTrack, dataTrack];
      }

      const room = await connect(accessToken, {
        name: roomId,
        video: false,
        audio: false,
        networkQuality: {
          local: 1, // LocalParticipant's Network Quality verbosity [1 - 3]
          remote: 2 // RemoteParticipants' Network Quality verbosity [0 - 3]
        },
      });

      setRoom(room);
      store.dispatch(setShowOverlay(false));
    })
    .catch((err) => {
      console.log("Error coming when trying access to local devices");
      console.log(err);
    });
};

// get token from twilio
export const getTokenFromTwilio = async (setAccessToken, identity) => {
  const randomId = uuidv4();
  // const response = await axios.get(
  //     `/token-service?identity=${randomId}${identity}`
  // )
  const response = await axios.get(
    `https://live-work-3589-dev.twil.io/token-service?identity=${randomId}${identity}`
  );

  const data = response.data;

  if (data.accessToken) {
    setAccessToken(data.accessToken);
  }
};

export const getTokenFromTwilioForGroupCall = async (identity) => {
  const randomId = uuidv4();
  // const response = await axios.get(
  //     `/token-service?identity=${randomId}${identity}`
  // )
  const response = await axios.get(
    `https://live-work-3589-dev.twil.io/token-service?identity=${randomId}${identity}`
  );

  const data = response.data;

  if (data.accessToken) {
    return data.accessToken;
  }
};

//check if room exists
export const checkIfRoomExists = async (roomId) => {
  // const response = await axios.get(
  //     `/room-exists?roomId=${roomId}`
  // )
  const response = await axios.get(
    `https://live-work-3589-dev.twil.io/room-exists?roomId=${roomId}`
  );
  return response.data.roomExists;
};

export const blurBackground = async (track) => {
  const blurBackground = new GaussianBlurBackgroundProcessor({
    assetsPath: "/background/",
    maskBlurRadius: 20,
    blurFilterRadius: 15,
  });
  await blurBackground.loadModel();
  if (!track.processor) {

  await track.addProcessor(blurBackground);
  }
};
const loadImage = (name) =>
  new Promise((resolve) => {
    const image = new Image();
    image.src = `background/${name}`;
    image.onload = () => resolve(image);
  });

export const imageBackground = async (track, bgImage) => {
 
  try{
      const backgroundImage = await loadImage(bgImage);
      const bg = new VirtualBackgroundProcessor({
      assetsPath: "/background/",
      backgroundImage,
      maskBlurRadius: 5,
      //pipeline: "Canvas2D",
      fitType:"Fill",
      debounce:false
      });
      await bg.loadModel();
      await track.addProcessor(bg);
    } catch(err){
        console.log("background image applied");
    }
};

  export const previewImageBackground = async (track, bgImage) => {
    let img = new Image();
    img.src = "/background/" + bgImage;
    img.onload = async () => {
      const bg = new VirtualBackgroundProcessor({
        assetsPath: "/background/",
        backgroundImage: img,
        maskBlurRadius: 5,
        pipeline: "Canvas2D",
      });
      await bg.loadModel();
      return track.addProcessor(bg);
    };
  };


export const RemovedBackground = async (track) => {
 
    if (track.processor) {
      await track.removeProcessor(track.processor);
     }
 
 
};
