import React from 'react'

// Close Sidebar
const ParticipantsLabel = () => {
  function closePrticpntNav() {
    document.getElementById("partcipantSidenav").style.transform = "translateX(300px)";
    document.getElementById("main").style.marginRight= "0";
    document.body.style.backgroundColor = "white";
    document.getElementById("partcipant").classList.remove('active');
  }

  return (
    <div className='participants_label_container d-flex align-items-center justify-content-between gap-3 text-white lh-1 border-bottom border-black px-3 py-3' onClick={closePrticpntNav}>
      <p className='participants_label_paragraph text-white fw-semibold mb-0'>Participants</p>
      <span className='pointer'><i className="bi bi-x fs-2"></i></span>
    </div>
  )
}

export default ParticipantsLabel