import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  updateDoc,
  limit,
  serverTimestamp,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { connect, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import "../LeadsListingPage/LeadsListingPage.css";
import "../Header/Header";

import { useAuthState } from "react-firebase-hooks/auth";
import Header from "../Header/Header";

// env import
import { environment } from '../environment';


import MobileLiveBoth from "./MobileLiveBoth";
import ConnectedVsNot from "./ConnectedVsNot";
import CamJoinedMissed from "./CamJoinedMissed";
import SubmittedVsNotSubmitted from "./SubmittedVsNotSubmitted";
import ConnectVsAttemptedOnNotSubmitted from "./ConnectVsAttemptedOnNotSubmitted";
import NumberReceivedVsNotReceived from "./NumberReceivedVsNotReceived";
import CclikedVsNot from "./CclikedVsNot";
import WhoTookChart from './WhoTookChart';
import AttemptedVsNotAttempted from "./AttemptedVsNotAttempted";
import AttemptedCallsFormFilledOrNot from "./AttemptedCallsFormFilledOrNot";
import WorldChart from "./WorldChart";
import CamWithClientAndWithoutClientTime from "./CamWithClientAndWithoutClientTime";
import { setUserInfo } from "../store/actions";

const ChartReport = (props) => {

  const {setLoggedinUser} = props;

  const [user, loading, error] = useAuthState(auth);

  let navigate = useNavigate();

  const listItemCss = {
    padding: "0 15px",
    margin: "5px 0",
    borderRight: "2px solid #ccc",
    boxSizing: "border-box",
    backgroundColor: "red",
  };

  const [allData, setAllDate] = useState([]);

  const [allClientMeetings,setDownloadData] =useState([]);

  const [isLoading, setIsloading] = useState(true);

  const [connectedLeadList,setTotalConnectedLeads] =useState([]);

  const [onlineCam ,setOnlineUsers] =useState([]);

  const [isOngoing, setisOngoing] = useState(false);

  const [audio, setAudio] = useState(new Audio(notificationSound));
  const [isNotify, setNotify] = useState(false);

  const [isGoLive, setGoLive] = useState(false);

  const [relavancyList, setRelavancyList] = useState([]);

  const dbInstance = collection(database, environment.REACT_APP_FIREBASE_DB);
  const addPlayerStremerWithID = collection(database, "playerstreamer");
  const end = new Date();
  const start = new Date(end.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
  // const q = query(collection(database, environment.REACT_APP_FIREBASE_DB),where('timeStamp','>',start),where('timeStamp','<',end));
  //const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), orderBy("timeStamp", "desc"), limit(20)); //last execution query
  // const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), where("formstatus", ">", 0));

  var startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  var endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);
  const queryConstraints = [];
  queryConstraints.push(where('timeStamp', '>=', startOfToday));
  queryConstraints.push(where('timeStamp', '<=', endOfToday));
  queryConstraints.push(orderBy('timeStamp', 'desc'));
  const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {
      
      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };
      setLoggedinUser(userInfo);

    }
  }, [user, loading]);
  //console.log(isLoading)
  //console.log("User Data::", user)
  //Update isTest in Firebase ..
  const updateTestVal = (async (formID) => {
    //console.log(document.getElementById(formID).checked);
    let testVal = document.getElementById(formID).checked;
    const formRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    await updateDoc(formRef, {
      isTest: testVal
    });
  })

  //console.log("Live lists:",allData)

  //console.log("userINFO::", userInfo);
  useEffect(() => {
    onSnapshot(q, (querySnapshot) => {
      let forms = [];
      querySnapshot.forEach((doc) => {
        const id = doc.id;
        const data = doc.data();
        data["id"] = doc.id;
        if (data.formstatus > 0) forms.push({ ...doc.data(), id: doc.id });
        
      });
      setAllDate(forms);
      setIsloading(false)
    });



    //audio.play()
  }, []);


//console.log(onlineCam)
  useEffect(()=>{
    const clientMeetingData = () =>{
        const filteredData = [];
        const totalconnected = [];
  allData.map((item, index) =>{
    if(item.participants){
        
    item.participants.map((p) => {
        
        if (p?.identity.includes("_CL_")) {
            filteredData.push(item);
            //console.log(item)
           
        }

        if (p?.identity.includes("_CL_") || p?.identity.includes("+17862041697-")) {
          totalconnected.push(item);
          //console.log(item)
         
      }
        
    })
    //console.log(filteredData)
    setDownloadData(filteredData)
    setTotalConnectedLeads(totalconnected)
    }
   
});
    }
    clientMeetingData()
},[allData])

  //console.log("Lead listing", allData);
  

  const removeDuplicates= (connectedClientArray) => {
              
    let freshConnectedLeads = [];
    let uniqueObject = {};
    for (let i in connectedClientArray) {

        const objRoomId = connectedClientArray[i]['roomId'];
        uniqueObject[objRoomId] = connectedClientArray[i];
    }
      
    for (let i in uniqueObject) {
      freshConnectedLeads.push(uniqueObject[i]);
    }
      
    return freshConnectedLeads;
}

  let clicked = 0;
  let notClicked = 0;
  let connected = 0;
  let notConnected = 0;
  let submitted = 0;
  let notSubmitted = 0;
  let joined = 0;
  let notJoined = 0;
  let leadsWithNumber = 0;
  let leadsWithOutNumber = 0;

  let attemptsMobileCalls = 0;
  
  let connectedMobileCalls = 0;
  let connectedMobileCallsOnWithoutSubmitForm = 0;

  let failedMobileCallsOnWithoutSubmitForm = 0;

  let mobile = 0;

  let mobile_live = 0;

  //connected = allClientMeetings.length;

  // const connectedLeads = removeDuplicates(allClientMeetings);

  const totalConnectedLead = removeDuplicates(connectedLeadList);

 // connected = connectedLeads.length;
//console.log(totalConnectedLead)
const attempted = 0;
let notAttempted = 0;
  for(let c=0; c< totalConnectedLead.length; c++){
    if(totalConnectedLead[c].clientJoinedLiveTime && !totalConnectedLead[c].camClickedMobileButton)
    connected += 1;
  }



  for (let i = 0; i < allData.length; i++) {
    if (!allData[i].clientSubmitTime) {
      if(allData[i].camClickedMobileButton && allData[i].phone){
        attemptsMobileCalls += 1;
      } 

      if(!allData[i].camClickedMobileButton && allData[i].phone){
        notAttempted += 1;
      } 
      if(allData[i].camClickedMobileButton && (allData[i].CallStatus == "failed" || !allData[i].CallStatus)){
        failedMobileCallsOnWithoutSubmitForm += 1;
      } 
      
      if(allData[i].camClickedMobileButton && allData[i].CallStatus == "completed" && (allData[i].CallDuration != 0 || allData[i].CallDuration != "0")){
        connectedMobileCallsOnWithoutSubmitForm += 1;
      } 

      if(allData[i].phone || allData[i].phone !== "" ){
        leadsWithNumber += 1;
      }

      if(!allData[i].phone || allData[i].phone == "" ){
        leadsWithOutNumber += 1;
      }
      
    }

    
      if(allData[i].camClickedMobileButton && allData[i].CallStatus == "completed" && (allData[i].CallDuration != 0 || allData[i].CallDuration != "0")){
        connectedMobileCalls += 1;
      } 

      if(allData[i].camClickedMobileButton && allData[i].CallStatus == "completed" && (allData[i].CallDuration != 0 || allData[i].CallDuration != "0") && !allData[i].clientJoinedLiveTime){
        mobile += 1;
      }
      
      if(allData[i].camClickedMobileButton && allData[i].CallStatus == "completed" && (allData[i].CallDuration != 0 || allData[i].CallDuration != "0") && allData[i].clientJoinedLiveTime){
        mobile_live += 1;
      } 
      
    

    if (allData[i].clientJoinedLiveTime && allData[i]?.country != "IN") {
      clicked += 1;
    }
    if (!allData[i].clientJoinedLiveTime && allData[i]?.country != "IN") {
      notClicked += 1;
    }
  
    if (allData[i].clientSubmitTime && allData[i]?.country != "IN") {
      submitted += 1;
    } else {
      if (allData[i]?.country != "IN")
        notSubmitted += 1;
    }
    // if (allData[i].formstatus == 1 && allData[i].callStatus != 1) {

    // } else 
    
    if (allData[i].callStatus === 1 && allData[i]?.country != "IN") {
      joined += 1;
    } else {
      // if (allData[i]?.country != "IN" ) 
        notJoined += 1;
    }

  }
  //console.log(userInfo)
  // end graph data implementation


  return (
    <>
      {/* <Header user={userInfo} /> */}
      <div className="container">
        <div className="live-listing-wrapper py-4">
          <div className="d-flex flex-column flex-md-row gap-md-3 align-items-md-center mb-3 mt-2">
            <div className="coll text-nowrap">
              <div className="main-heading text-white text-opacity-75">
             CAM Reports
              </div>
            </div>
           
            
          </div>

    

          {/* Am Charts Section */}
        
          {allData.length > 0 ?
          <>
            <div className="amchart-wrapper mb-3">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                      Today Analysis
                    </button>
                  </h2>
                  <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="alert alert-success alert-dismissible fade show small text-md-center" role="alert">
                        <strong>Note: </strong>Currently, we are excluding any test data that was generated from India (IN).
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      <div className="row g-3">
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">How many leads submit to CRM vs Not?<br></br><br></br></div>
                       
                          <SubmittedVsNotSubmitted submitted={submitted} notSubmitted={notSubmitted} />
                         
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">How many clients clicked on Join A Live Button vs not?</div>
                          
                        
                          <CclikedVsNot clicked={clicked} notClicked={submitted - clicked} />
                        </div>
                     
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">How many clients connected today vs not?</div>
                        
                          <ConnectedVsNot connected={connected + mobile} notConnected={clicked - connected} />
                          
                        </div>
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">Total Missed By CAM vs Joined By CAM Today?</div>
                         
                          <CamJoinedMissed joined={joined} notJoined={notJoined} label1={"Cam Joined"} label2={"Cam Missed"} />
                        </div>
                        <hr></hr>
                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">Comparing Types of Client Connected Calls: <b>Mobile, Video, </b> and Combined <b>Mobile+Video</b></div>
                        
                          <MobileLiveBoth mobile ={mobile} live={connected} both={mobile_live}/>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">Comparison of Received vs Unreceived Numbers in <b>Not Submitted Leads</b></div>
                         
                          <NumberReceivedVsNotReceived received={leadsWithNumber} notReceived={leadsWithOutNumber} />
                        </div>

                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">Attempted vs. Not Attempted Calls for <b>Not Submitted Leads</b> with Mobile Numbers</div>
                         
                          <AttemptedVsNotAttempted attempted={attemptsMobileCalls} notAttempted={notAttempted} />
                        </div>

                        <div className="col-sm-6 col-lg-3">
                          <div className="chart-heading">Comparing Successful vs Unsuccessful Mobile Call Attempts for <b>Not Submitted Leads</b></div>
                         
                          <ConnectVsAttemptedOnNotSubmitted failed={failedMobileCallsOnWithoutSubmitForm} connected={connectedMobileCallsOnWithoutSubmitForm} />
                        </div>

                       
                      
                        
                        
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        

          
            <div className="amchart-wrapper mb-lg-3">
              <div className="accordion" id="accordionExample-bar">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading1">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar" aria-expanded="false" aria-controls="collapseBar">
                      Who took how many Calls Today
                    </button>
                  </h2>
                  <div id="collapseBar" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-bar">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="alert alert-warning alert-dismissible fade show small text-md-center" role="alert">
                        <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      
                      <WhoTookChart data={allData} />
                    </div>

                  </div>
                  
                </div>
              </div>
            </div>

            {/* <div className="amchart-wrapper mb-lg-3">
              <div className="accordion" id="accordionExample-bar">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading1">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-chart" aria-expanded="false" aria-controls="collapseBar">
                    Another chart
                    </button>
                  </h2>
                  <div id="collapseBar-chart" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-bar">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="alert alert-warning alert-dismissible fade show small text-md-center" role="alert">
                        <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      
                      <AttemptedCallsFormFilledOrNot data={allData} />
                    </div>

                  </div>
                  
                </div>
              </div>
            </div> */}

            {/* <div className="amchart-wrapper mb-lg-3">
              <div className="accordion" id="accordionExample-worldChart">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading1">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-chart" aria-expanded="false" aria-controls="collapseBar">
                    Leads from Country
                    </button>
                  </h2>
                  <div id="collapseBar-chart" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-worldChart">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="alert alert-warning alert-dismissible fade show small text-md-center" role="alert">
                        <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      
                      <WorldChart data={allData} />
                    </div>

                  </div>
                  
                </div>
              </div>
            </div> */}

            <div className="amchart-wrapper mb-lg-3">
              <div className="accordion" id="accordionExample-cam-with">
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="heading1">
                    <button className="accordion-button collapsed bg-primary text-white p-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBar-cam-with" aria-expanded="false" aria-controls="collapseBar">
                    Analysis of Total Calls, Time Spent, and Client Interaction Time
                    </button>
                  </h2>
                  <div id="collapseBar-cam-with" className="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#accordionExample-cam-with">
                    <div className="accordion-body rounded-bottom p-3">
                      <div className="alert alert-warning alert-dismissible fade show small text-md-center" role="alert">
                        <strong>Note: </strong>This data is taking test data, too, into consideration in these reports. In the coming days, it will get filtered.
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      
                      <CamWithClientAndWithoutClientTime data={allData} />
                    </div>

                  </div>
                  
                </div>
              </div>
            </div>
            </>
            : null}

{ isLoading?
                        <div className=""  id="lead-list-spinner"   style={{
                                                                    position: "absolute",
                                                                    margin: "21px 0px 0px 0px",
                                                                    left: "44%"
                                                                    }}
                        >
                        <div
                        style={{ marginLeft: "12px", float: "right" }}
                        className="lead-list-spinner"
                        ></div>{" "}
                    Please wait ....
            </div>
            :null }
        
        </div>
      </div>
     
    </>
  );
};


const mapDispatchToProps = (dispatch) => {
  return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(ChartReport);
