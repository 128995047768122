import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
// Datepicker NPM
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import ClientQueryCard from '../Cards/ClientQueryCard';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';

import { DateRangePicker } from 'rsuite';



const LiveClientQueries = ({ setLoggedinUser }) => {
  const { state } = useLocation();
  const { filters } = state; // Read values passed on state
  const [startDate, setStartDate] = useState(new Date());

  const [value, setValue] = useState([]);

  const [allClientMeetings, setDownloadData] = useState([]);



  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();




  const handleChange = (value) => {
    setValue(value);
    getMeetingData(value[0], value[1])
  };

  const removeDuplicates = (connectedClientArray) => {

    let freshConnectedLeads = [];
    let roomIds = [];
    let uniqueObject = {};
    for (let i in connectedClientArray) {

      const objRoomId = connectedClientArray[i]['roomId'];
      uniqueObject[objRoomId] = connectedClientArray[i];
    }

    for (let i in uniqueObject) {
      freshConnectedLeads.push(uniqueObject[i]);
    }

    return freshConnectedLeads;
  }

  const connectedLeads = removeDuplicates(allClientMeetings);

  //console.log("printArray:::",connectedLeads)


  const marketingDetails = () => {
    navigate('/marketing-details')
  }

  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {

      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);



    }
  }, [user, loading]);

  useEffect(() => {

    // const datePickerDate = document.getElementById("wonDatePicker").value;
    setValue([new Date(), new Date()]);
    getMeetingData(new Date(), new Date());

  }, []);

  const getMeetingData = async (startDate, endDate) => {
    setIsLoading(true);
    // setStartDate(date)
    const filteredData = [];
    callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/attendance/get-all-download', { startDate: startDate, endDate: endDate }, "post").then((meetingData) => {
      // console.log("downloadData:::",meetingData.data[0].participants)
      setIsLoading(false);
      if (meetingData.data.length > 0) {
        meetingData.data.map((item, index) => {
          //console.log(index)
          if (item.participants) {
            const participants = JSON.parse(item.participants);
            participants.map((p) => {

              if (p?.identity.includes("_CL_") || p?.identity.includes("+17862041697-")) {
                filteredData.push(item);

              }
            })

          }

        });

        setDownloadData(filteredData)


      }
    })
  }

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };
  let addGroup;
  let campaign;
  //  console.log("date range values:::",filters)
  if (filters.adgroupFilters.length > 0) {
    addGroup = filters.adgroupFilters
    .filter(item => item && item.adgroup) // Filter out null, undefined, or empty strings
  .map(item => (
    <option key={item.adgroup} value={item.adgroup}>
      {item.adgroup}
    </option>
  ));
  }

  if (filters.campaignFilters.length > 0) {
    campaign = filters.campaignFilters.filter(item => item && item.adgroup) 
    .map(item => (
      <option key={item.adgroup} value={item.adgroup}>
        {item.adgroup}
      </option>
    ));
  }

const filterByAdgroup = (adgroup) =>{
  setIsLoading(true)
  const filteredData = [];
  callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-client-filter-data',{type:"adgroup",filterValue:adgroup}, "post" ).then((meetingData)=>{
    setIsLoading(false);
    console.log(meetingData)
    if (meetingData.data.clientData.length > 0) {
      meetingData.data.clientData.map((item, index) => {
        //console.log(index)
        if (item.participants) {
          const participants = JSON.parse(item.participants);
          participants.map((p) => {

            if (p?.identity.includes("_CL_") || p?.identity.includes("+17862041697-")) {
              filteredData.push(item);

            }
          })

        }

      });

      setDownloadData(filteredData)


    }
  })
}

const filterByCampaign = (campaign) =>{
  setIsLoading(true)
  const filteredData = [];
  callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-client-filter-data',{type:"campaign",filterValue:campaign}, "post" ).then((meetingData)=>{
    setIsLoading(false);
    console.log(meetingData)
    if (meetingData.data.clientData.length > 0) {
      meetingData.data.clientData.map((item, index) => {
        //console.log(index)
        if (item.participants) {
          const participants = JSON.parse(item.participants);
          participants.map((p) => {

            if (p?.identity.includes("_CL_") || p?.identity.includes("+17862041697-")) {
              filteredData.push(item);

            }
          })

        }

      });

      setDownloadData(filteredData)


    }
  })
}

  return (
    <div className='MarketingData client_queries my-3'>
      <div className="container">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-md-5">
            <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">Live Client Queries</div>
          </div>

          <div className="col-md-7 text-md-end">
            <div className="d-flex d-lg-inline-flex flex-column flex-sm-row gap-2">
              <div class="input-group" style={{width:"70%"}}>
                <select className="form-select me-2" id="" onChange={(e)=>{filterByAdgroup(e.target.value)}}>
                  <option value="">By Adgroup</option>
                  {addGroup}
                </select>
                <select className="form-select me-2" id="" onChange={(e)=>{filterByCampaign(e.target.value)}}>
                  <option value="">By Campaign</option>
                  {campaign}
                </select>

              </div>




              <DateRangePicker
                value={value}
                onChange={handleChange}
                size="lg"
                block />

            </div>
            {/* <select className="form-select me-2" id="searchType">
                  <option value="">By Adgroup</option>
                  {addGroup}
                </select>
                <select className="form-select me-2" id="searchType">
                  <option value="">By Campaign</option>
                  {campaign}
                </select>
                        <label className="small me-1">Filter By Date:</label>
                        
                         <DateRangePicker
                          value={value}
                          onChange={handleChange}
                         block /> */}
          </div>
        </div>

        <div className="meeting-details-page small rounded-3">
          <div className="list-header fw-semibold text-white rounded-3 p-3 mb-3 d-none d-md-block" style={{ background: "linear-gradient(168.68deg,#0052c9 1.12%,#0a91db)" }}>
            <div className="row gx-3">
              <div className="col col-md-4 col-lg-5">Client Details</div>
              <div className="col col-md-4 col-lg-4">Lead Sources</div>
              <div className="col col-md-4 col-lg-3 text-md-end">Number of people on call</div>
            </div>
          </div>


          {isLoading ? (
            <div
              className=""
              id="lead-list-spinner"
              style={{
                position: "absolute",
                margin: "21px 0px 0px 0px",
                left: "44%",
              }}
            >
              <div
                style={{ marginLeft: "12px", float: "right" }}
                className="lead-list-spinner"
              ></div>{" "}
              Please wait ....
            </div>
          ) :
            connectedLeads.length > 0 ?
              connectedLeads.map((item, index) => {

                return <ClientQueryCard startDate={startDate} item={item} index={index} />
              })
              :
              <p> Client query not found or selected Date</p>
          }

        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(LiveClientQueries);
