import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth, database } from "../../firebaseConfig";
import { environment } from "../../environment";
import { useAuthState } from "react-firebase-hooks/auth";
import { callAxios } from "../../utils/utils";

const LeaveRoomButtonPrivate = ({
  room,
  comingFrom,
  handleRoomDisconnection,
  participants,
  isClientOnCall,
  roomId,
  docId,
  callType
}) => {
    const [user, loading, error] = useAuthState(auth);
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
  
  
    const queryConstraints = [];
    queryConstraints.push(where("timeStamp", ">=", startOfToday));
    queryConstraints.push(where("timeStamp", "<=", endOfToday));
   //  queryConstraints.push(where("callStatus", "==", "initiated"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
      ...queryConstraints
    );
 

   
    const checkAudioVideoCall  = () =>{
     onSnapshot(q, (querySnapshot) => {
       let forms = [];
       const userswindow= [];
      // if(user){
         querySnapshot.forEach((doc) => {
           const id = doc.id;
           const data = doc.data();
           data["id"] = doc.id;
           forms.push({ ...doc.data(), id: doc.id });
           userswindow.push(data.callFromEmail);
           userswindow.push(data.callToEmail);
            const loggedInUser = user?.email? user?.email : localStorage.getItem("userEmail");
        //const loggedInUser = localStorage.getItem("userEmail");
            // //console.log(loggedInUser, data.isReceived)
        //     if (data.callStatus === 'completed' && data.roomId ===roomId && (loggedInUser === data.callFromEmail || loggedInUser === data.callToEmail)) {
        //      console.log(data.callStatus,roomId)
        //      // if(loggedInUser === data.callToEmail){
        //      //   const openCallModal = document.getElementById("audiVideoCall");
        //      //   if(openCallModal){
        //      //     openCallModal.classList.add("d-none");
        //      //   }
        //      //  }
             
        //      window.close();
            
        //    }
            
          
           
           
           
           
         });
        
        
       //}
      
     
     });
    }
    
    
 
    // useEffect(()=>{
     
    //     checkAudioVideoCall();
 
    //  //console.log("calling useEffect!!")
     
     
    // },[])
  
    const handleRoomD = async() =>{
        //console.log("in Leave button action",roomId,docId);
        
        await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/video-media/one-to-one-disconnect`, {roomId:roomId,identity:user?.email,callType:callType}, "post");
        room.disconnect();
    }
  
  return (
    <div className="video_button_container">
      
            <button
              className="d-flex fw-semibold btn btn-danger rounded-1 video_button_end leave-btn py-1 px-3"
              onClick={handleRoomD}
            >
              <span>
                <i className="bi bi-telephbone-fill"></i>
              </span>
              <span className="ms-2 ps-1 d-none d-lg-block">Leave</span>
            </button>
    </div>
  );
};

export default LeaveRoomButtonPrivate;
