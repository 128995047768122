import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class AttemptedVsNotAttempted extends Component {

  componentDidMount() {
   // console.log(this.props.submitted, this.props.notSubmitted)
     this.initChart()
  }
  componentDidUpdate(prevProps) {
    
    // if(prevProps) {
    //   this.chart.dispose();
    // }
    this.initChart();
    
  }
  
  initChart(){
    am4core.useTheme(am4themes_animated);

    var chart = am4core.create("attempted-vs-not-Attempted-on-not-submitted-leads", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    
    chart.data = [
      {
        category: "Attempted",
        values: this.props.attempted?this.props.attempted:0
      },
        {
            category: "Not Attempted",
            values: this.props.notAttempted?this.props.notAttempted:0
          }
          
        
      ];


      chart.radius = am4core.percent(70);
chart.innerRadius = am4core.percent(40);
chart.startAngle = 180;
chart.endAngle = 360;  

var series = chart.series.push(new am4charts.PieSeries());
series.dataFields.value = "values";
series.dataFields.category = "category";

series.slices.template.cornerRadius = 10;
series.slices.template.innerCornerRadius = 7;
series.slices.template.draggable = true;
series.slices.template.inert = true;

series.hiddenState.properties.startAngle = 90;
series.hiddenState.properties.endAngle = 90;

series.labels.template.disabled = true;
series.ticks.template.disabled = true;

series.colors.list = [
    am4core.color("#F1D302"),
    am4core.color("#235789"),
];

chart.legend = new am4charts.Legend();

        chart.legend.markers.template.paddingTop= 3;
        // chart.legend.markers.template.width = 13;
        // chart.legend.markers.template.height = 13;
        
        chart.legend.useDefaultMarker = true;
        let marker = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
        marker.height = 14;
        marker.width = 14;
        chart.legend.itemContainers.template.paddingTop = 2;
        chart.legend.itemContainers.template.paddingBottom = 0;
        chart.legend.itemContainers.template.fontSize = 14;
            
  }
    

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  
  render() {
    return <div id="attempted-vs-not-Attempted-on-not-submitted-leads" style={{ width: "100%", height: "240px", margin: "0 auto" }} />;
  }

 
}

export default AttemptedVsNotAttempted;