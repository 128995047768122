import React, { useEffect, useMemo, useState } from "react";
import searchIcon from "../resources/images/search.svg";
import notebookPenIcon from "../resources/images/notebook-pen.svg";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { callAxios, convertReadable, convertReadableTime } from "../utils/utils";
import { environment } from "../environment";
import { intitializeDevice, log_ } from "../utils/pstn";
import { setUserInfo } from "../store/actions";
import Card from "./Card";
// import DateRange from "../Reports/DateRange";
import SheelaModal from "./modal/SheelaModal";
import $ from "jquery";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CallHistoryDetails = ({ setLoggedinUser }) => {
  // For Sidebar list selected card
  const [updates, setUpdates] = useState([]);
  const [active, setActive] = useState(null);
  const [isSheelaView, setSheelaView] = useState(false);
  const [summary, setSummary] = useState('')
  // For Read more read less functionality
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <>
        {isReadMore ? text.slice(0, 300) : text}
        {text.length > 300 &&
          <span onClick={toggleReadMore} className="text-info pointer">
            {isReadMore ? "...Read More" : " Read Less"}
          </span>
        }
      </>
    );
  };


  const [accessToken, setAccessToken] = useState(null);

  const [limit, setLimit] = useState(10);

  const [searchType, setSearchType] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();

  const [sheelaData, setDataForSheela] = useState({});

  const [callDetails, setCallDetails] = useState([]);

  const [isDetailsLoading, setDetailsLoading] = useState(true);



  const [allData, setData] = useState([]);

  const [isShowCalendar, setIsShowCalendar] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate()





  const [user, loading, error] = useAuthState(auth);

  useEffect(()=>{
    
  },[user])

  useEffect(() => {
    const socket = new WebSocket("ws://100.25.178.223:9016");

    socket.onopen = () => {
      console.log('Connected to WebSocket server roy');
    };

    socket.onmessage = (event) => {
      const update = JSON.parse(event.data);
      setUpdates(update.message);
      if(update.message.callerEmail === user?.email){
        if(update.message.From.includes("client:")){
          log_(update.message.CallStatus)
        } else if(update.message.type === "client"){
          log_(update.message.name +" "+update.message.CallStatus+"....")
        }
        else{
          log_(update.message.displayName +" "+update.message.CallStatus+"....")
        }
      }
      
      
      
    };

    socket.onclose = () => {
      console.log('Disconnected from WebSocket server');
    };

    return () => {
      socket.close(); 
    };

  }, [user]);

// console.log("Real time update::::",updates)

  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {

      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);
      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/crm/get-voice-t", {}, "post").then((token) => {
        setAccessToken(token.data)

      })



      const searchType = $("#searchType").val();
      const date = new Date();
      const syear = date.getFullYear();
    const smonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(date.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
      getDataByDate(formattedStartDate, formattedStartDate,searchType)

    }
  }, [user, loading]);
  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
    if (accessToken)
      intitializeDevice(accessToken,user?.displayName)
  }, [accessToken])


  const analyzeData = (summary) => {
    setDataForSheela(summary)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display = "block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
  }

  const getDataByDate = (startdate, enddate, searchType) => {
    // setStartDate(date)

    setIsLoading(true);
    // console.log(startdate, enddate)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v1/crm/call-crm", { email: user?.email, isDateFilter: true, startdate: startdate, enddate: enddate,searchType:searchType }, "post").then((meetingData) => {
      // console.log("downloadData:::",meetingData.data[0].participants)

      setData(meetingData.data);
      //    console.log(meetingData)
      setIsLoading(false);
      setDetailsLoading(false)
    })


  }

  const handleNameSearch = () => {
    const clientName = document.getElementById("search-by-client-name").value;
    const searchType = $("#searchType").val();
    // console.log(clientName)
    setIsLoading(true);

    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/call-crm", { email: user?.email, isFilterName: true, page: 1, limit: 100, filterValue: clientName,searchType:searchType }, "post").then((meetingData) => {


      setData(meetingData.data);
      //    console.log(meetingData)
      setIsLoading(false);
    })
  }

  // const onChange = (ranges) => {
  //   setIsShowCalendar(false);
   
  //   const syear = ranges.startDate.getFullYear();
  //   const smonth = String(ranges.startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //   const sday = String(ranges.startDate.getDate()).padStart(2, '0');
  //   const formattedStartDate = `${syear}-${smonth}-${sday}`;

  //   const eyear = ranges.endDate.getFullYear();
  //   const emonth = String(ranges.endDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  //   const eday = String(ranges.endDate.getDate()).padStart(2, '0');
  //   const formattedEndDate = `${eyear}-${emonth}-${eday}`;
  //   setStartDate(formattedStartDate);
  //   setEndDate(formattedEndDate);
  //   // console.log(formattedStartDate,formattedEndDate)
  //   const searchType = $("#searchType").val();
  //   getDataByDate(formattedStartDate, formattedEndDate,searchType)
  // };

  const onChange = (startDate) => {
    setIsShowCalendar(false);
    setStartDate(startDate);
    const syear = startDate.getFullYear();
    const smonth = String(startDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const sday = String(startDate.getDate()).padStart(2, '0');
    const formattedStartDate = `${syear}-${smonth}-${sday}`;
    const searchType = $("#searchType").val();
    getDataByDate(formattedStartDate,formattedStartDate,searchType)
  };

  const showCalendar = () => {
    // setIsShowCalendar(true);
    setIsShowCalendar(!isShowCalendar);

  }
  //console.log(callDetails)
  let callDetailsS;
  if (callDetails && callDetails.length > 0) {
    callDetailsS = callDetails.map((item, index) => {
      return (
        <div className="attn-details-wrapper p-3 mt-3" key={index}>
          <div className="row gx-2 gy-1 mb-2">
            <div className="col-lg-8">
              {item.createdAt ?
                <div className="client-name fs-5 fw-semibold text-light text-capitalize mb-1">{convertReadable(item.createdAt) + ' ' + convertReadableTime(item.createdAt)}</div>
                : null}
              {item.caller_name ?
                <div className="text-capitalize">Called By <span className="attn-name">{item.caller_name}</span></div>
                : null}

            </div>
            <div className="col-lg-4 text-lg-end">
              {item.duration ?
                <div className="duration text-light text-opacity-75 mb-1">Duration - <span className="call-time">17:23 Min</span></div>
                : null}
            </div>
          </div>
          {item.summary && Object.entries(item.summary).length > 0 ?
            <div className="requrement-wrapper mb-3">
              <div className="title-label fs-18 text-light">Summary:</div>
              <div className="description small text-light text-opacity-75">

                <>
                  <ReadMore>
                    {item.summary.abstractSummary.summary.content ? item.summary.abstractSummary.summary.content : item.summary.abstractSummary.summary}
                  </ReadMore>
                  {/* <button className="btn btn-success ms-5" onClick={() => analyzeData(item.summary)} >View Sheela Update</button> */}
                </>



              </div>
            </div> : null}

          <div className="compose-media-page row align-items-center g-3">
            <div className="col-lg-9">
            {item.summary && Object.entries(item.summary).length > 0 ?
              <div className="meeting-details-page d-flex align-items-center gap-3">
                <div className="comment-part position-relative flex-fill text-white text-opacity-75 rounded py-2 px-2 px-md-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                  <div className="d-flex flex-column flex-xl-row align-items-xl-center justify-content-between gap-2">
                    <div className="info-sec fs-12">
                      <div className="attn-name fw-medium">{user?.displayName}</div>
                      <p className="m-0">I've completed my work. You can now review all the details.</p>
                    </div>
                    <div className="btn-wrapper">
                      <button className="btn btn-sm btn-success text-nowrap pointer" onClick={() => analyzeData(item.summary)}>Sheela's Update</button>
                    </div>
                  </div>
                </div>
                <div className="img-wrapper">
                  <img className="img-fluid" src={require('../resources/images/meeting-girl.png')} alt="Meeting User" style={{ width: '27px', minWidth: '27px' }} />
                </div>
              </div>
              :null}
            </div>

            <div className="col-lg-3">
              <div className="icon-wrapper d-flex gap-3">
                {item.call_type === 1 ?
                  <div className="group-call">
                    <img src={require('../resources/images/group-call.png')} alt="Group Calling" className="img-fluid" style={{ maxWidth: '40px' }} />
                  </div>
                  :
                  <div className="user-call">
                    <img src={require('../resources/images/user-on-call.png')} alt="Single User Call" className="img-fluid" style={{ maxWidth: '28px' }} />
                  </div>
                }
                {item.call_sid ?
                  <div className="play-icon pointer" onClick={() => { handleMeetingViewAndDownload(item.call_sid) }}>
                    <i className="bi bi-play-circle-fill text-white lh-1" style={{ fontSize: '40px' }}></i>
                  </div>
                  : null}
              </div>
            </div>
          </div>



        </div>
      )
    })
  } else {
    callDetailsS = "No call details found";
  }

  // listen recording
  const closePlayer = () => {
    var audioSrc = document.getElementById("audioSrc");
    audioSrc.pause();
    document.getElementById("audio-player").style.display = "none";
  }
  const handleMeetingViewAndDownload = async (callSid) => {
    //console.log("composition Id:", compositionId, " | type:", type)
    // let mediaResponse;
    // if(type === 'view'){

    const mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/twilio/get-recording-by-sid', { cid: callSid }, "post");
    const url = mediaResponse.data.url;

    var audioSrc = document.getElementById("audioSrc");

    const isSupp = audioSrc.canPlayType("video/mp4");
    if (isSupp == "") {
      audioSrc.src = url;
    } else {
      audioSrc.src = url;
    }
    audioSrc.load();
    audioSrc.play();

    document.getElementById("audio-player").style.display = "block";

    // }
    // else {
    //     mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/download-media',{compositionSid:compositionId},"post");
    //     const url = mediaResponse.data.url;
    //     window.location.href = url;
    // }



  }
  // console.log(callDetails)
  const updateDateFilter= (val) =>{
    if(val.length > 0){
      $("#dateFilterField").hide();
      $("#basic-addon2").removeClass("d-none");
    } else{
      $("#dateFilterField").show();
      $("#basic-addon2").addClass("d-none");
    }
  }
  return (
    <div className="client-listing-page call-history-details py-3">
      <div className="container-fluid container-xxl">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-5">
            <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">Call History Details</div>
          </div>

          <div className="col-lg-7 text-lg-end">
            <div className="d-flex d-lg-inline-flex flex-column flex-sm-row gap-2">
              <div class="input-group">
                <select onChange={(e)=>{setSearchType(e.target.value)}} className="form-select me-2" id="searchType">
                  <option value={"lead"}>By Lead</option>
                  <option value={"opportunity"}>By Opportinity</option>
                </select>
                <input type="search" className="form-control" placeholder="Search by Topic, Client name.." id='search-by-client-name' autoComplete='off' onChange={(e)=>{updateDateFilter(e.target.value)}}/>
                <span className="input-group-text pointer" id="basic-addon2" onClick={handleNameSearch}><i className="bi bi-search"></i></span>
              </div>

              <div className='custom-date-range'  id="dateFilterField">
              <DatePicker
                                // selected={new Date()}
                                selected={startDate}
                                onChange={(date) => { onChange(date) }}
                                className="wonDatePicker m-0"
                                id="wonDatePicker"
                                // renderMonthContent={renderMonthContent}
                                showFullMonthYearPicker
                                dateFormat="yyyy-MM-dd"
                                showIcon={false}
                            />
                {/* <input placeholder={new Date(startDate).toLocaleString("default", { day: '2-digit', month: 'short', year: 'numeric' }) + ' - ' + new Date(endDate).toLocaleString("default", { day: '2-digit', month: 'short', year: 'numeric' })} type="text" className="form-control" id="calendar" onClick={showCalendar} /> */}


                {/* {isShowCalendar ?
                  <div className="date-range-chart" style={{ zIndex: 11 }}>
                    <DateRange onChange={onChange} startDate={startDate} endDate={endDate} showIcon="true" />
                  </div>
                  : null
                } */}
              </div>
            </div>
          </div>
        </div>
        {/* CAM & BDM Client Info */}
        <div className="client-detail-wrapper">
          <div className="row g-3">
            <div className="col-md-5 col-lg-4">
              <div className="client-list-section">

                <div className={`client-card rounded p-3 mt-2 overflow-auto`} id="log" style={{ height: "150px" }}>
                </div>
                {isLoading ? (
                  <div
                    className=""
                    id="lead-list-spinner"
                    style={{
                      position: "relative",
                      margin: "21px 0px 0px 0px",
                      // left: "44%",
                    }}
                  >
                    <div
                      style={{ marginLeft: "12px" }}
                      className="lead-list-spinner"
                    ></div>{" "}
                    Please wait ....
                  </div>
                ) :
                  allData && allData.leads.length > 0 ?
                    allData.leads.map((item, id) => {

                      return <Card startDate={startDate} item={item} id={id} user={user} active={active} setActive={setActive} setCallDetails={setCallDetails} setDetailsLoading={setDetailsLoading} />
                    })
                    :
                    <p> Data not found!!</p>
                }

              </div>
            </div>
            <div className="col-md-7 col-lg-8">
              <div className="client-info-details p-3">
                <div className="fs-4 fw-medium text-warning"><i className="bi bi-person-vcard"></i> Call History Details</div>

                <div className="info-card-wrapper">
                  {isDetailsLoading ? (
                    <div
                      className=""
                      id="lead-list-spinner"
                      style={{
                        position: "absolute",
                        margin: "21px 0px 0px 0px",
                        left: "44%",
                      }}
                    >
                      <div
                        style={{ marginLeft: "12px", float: "right" }}
                        className="lead-list-spinner"
                      ></div>{" "}
                      Please wait ....
                    </div>
                  ) : callDetailsS}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="audio-player" class="modal" role="dialog">
        <div class="modal-dialog">

          <div class="modal-content">

            <div class="modal-body">
              <video width="400" controls autoplay id="audioSrc">
                <source src="" type="video/mp4" ></source>

                Your browser does not support HTML video.
              </video>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
            </div>
          </div>

        </div>
      </div>

      <SheelaModal setSheelaView={setSheelaView} isSheelaView={isSheelaView} sheelaData={sheelaData} />
    </div >
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(CallHistoryDetails);
