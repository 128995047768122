import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class WhoTookChart extends Component {
   
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {
    
    // if(chart._super) {
    //   chart.dispose();
    // }
    this.initChart();
    
  }
  
  whoTookHowManyCalls = (arr) =>
  arr.reduce((p, c) => {
 
    var name = c.attendantName;

    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
   
    p[name]++;
    
    return p;
  }, {});

  groupByArray = (arr) =>
    
   arr.reduce((group, product) => {
      const { attendantName } = product;
      group[attendantName] = group[attendantName] ?? [];
      group[attendantName].push(product);
      return group;
    }, {});
   
  
  


  
  initChart(){
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create('whoTookCall', am4charts.XYChart)
//chart.data = fullData;
chart.colors.step = 2;
     const groupData = Object.entries(this.groupByArray(this.props.data));
  
    //console.log(groupData)
    //console.log(this.props.data)
    let fullData = [];
    let connected = 0;
    for(let y=0;y < groupData.length; y++){
      let innerObj = {};
      if(groupData[y][0] != 'undefined'){
          let liveConnected = 0;
          let mobileConnected = 0;
          for(let x =0; x<groupData[y][1].length;x++){
              if(groupData[y][1][x].clientJoinedLiveTime){
                //innerObj.liveCallConnected = [].push(groupData[y][1][x].clientJoinedLiveTime);
                liveConnected += 1;
              }

              if(groupData[y][1][x].CallDuration && groupData[y][1][x].CallStatus === "completed"){
                //innerObj.mobileCallConnected = [].push(groupData[y][1][x].clientJoinedLiveTime);
                mobileConnected += 1;
              }
              innerObj.LiveConnected = liveConnected;
              innerObj.MobileConnected = mobileConnected;
            
          }
          innerObj.name = groupData[y][0];
          innerObj.totalCalls = groupData[y][1].length;

          fullData.push(innerObj)
        
        

      }
    }
    //console.log(fullData)
  
  

    //
    
// Themes end





chart.legend = new am4charts.Legend()
chart.legend.position = 'top'
chart.legend.paddingBottom = 20
chart.legend.labels.template.maxWidth = 95

let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
xAxis.dataFields.category = 'name'
xAxis.renderer.cellStartLocation = 0.1
xAxis.renderer.cellEndLocation = 0.9
xAxis.renderer.grid.template.location = 0;

xAxis.renderer.minGridDistance = 30;
xAxis.renderer.labels.template.horizontalCenter = "right";
xAxis.renderer.labels.template.verticalCenter = "middle";
xAxis.renderer.labels.template.rotation = 270;
xAxis.tooltip.disabled = true;
xAxis.renderer.minHeight = 110;
xAxis.renderer.labels.template.fontSize = 14;

let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
yAxis.min = 0;

function createSeries(value, name) {
    let series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = value
    series.dataFields.categoryX = 'name'
    series.name = name

    series.events.on("hidden", arrangeColumns);
    series.events.on("shown", arrangeColumns);
    series.columns.template.tooltipText = "[{categoryX}: bold]{categoryX}:({valueY}) Calls[/]";//"[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";
    let bullet = series.bullets.push(new am4charts.LabelBullet())
    bullet.interactionsEnabled = false
    bullet.dy = 30;
    bullet.label.text = '{valueY}'
    bullet.label.fill = am4core.color('#ffffff')


    return series;
}
chart.data = fullData;
// chart.data = [
//     {
//         category: 'Place #1',
//         first: 40,
//         second: 55,
//         third: 60
//     },
//     {
//         category: 'Place #2',
//         first: 30,
//         second: 78,
//         third: 69
//     },
//     {
//         category: 'Place #3',
//         first: 27,
//         second: 40,
//         third: 45
//     },
//     {
//         category: 'Place #4',
//         first: 50,
//         second: 33,
//         third: 22
//     }
// ]


// createSeries('first', 'The First');
// createSeries('second', 'The Second');
// createSeries('third', 'The Third');

createSeries("totalCalls", "Total Calls");
createSeries("LiveConnected", "Live Call");
createSeries("MobileConnected", "Mobile Call");

function arrangeColumns() {

    let series = chart.series.getIndex(0);

    let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
    if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
            let middle = chart.series.length / 2;

            let newIndex = 0;
            chart.series.each(function(series) {
                if (!series.isHidden && !series.isHiding) {
                    series.dummyData = newIndex;
                    newIndex++;
                }
                else {
                    series.dummyData = chart.series.indexOf(series);
                }
            })
            let visibleCount = newIndex;
            let newMiddle = visibleCount / 2;

            chart.series.each(function(series) {
                let trueIndex = chart.series.indexOf(series);
                let newIndex = series.dummyData;

                let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                series.columns.template.width = am4core.percent(90);
            })
        }
    }
}

    //

  
  }
    

  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }
  
  render() {
    return <div id="whoTookCall" style={{ width: "100%", height: "400px", margin: "0 auto" }} />;
  }
}

export default WhoTookChart;