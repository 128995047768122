import React from 'react'


const LeadDetailsLabel = () => {
    
    // Close Sidebar
    function closeLeadSidenav() {
        document.getElementById("LeadDetailsSidenav").style.transform = "translateX(300px)";
        document.getElementById("main").style.marginRight = "0";
        document.body.style.backgroundColor = "white";
        document.getElementById("leadDetails").classList.remove('active');
    }

    return (
        <div className='Lead_label_container d-flex align-items-center justify-content-between gap-3 text-white lh-1 border-bottom border-black px-3 py-3' onClick={closeLeadSidenav}>
            <p className='Lead_label_paragraph text-white fw-semibold mb-0'>Lead Details</p>
            <span className='pointer'><i className="bi bi-x fs-2"></i></span>
        </div>
    )
}

export default LeadDetailsLabel