import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
let chart;
class WonChart extends Component {
   
  componentDidMount() {
    this.initChart();
  }
  componentDidUpdate(prevProps) {
    
    // if(chart._super) {
    //   chart.dispose();
    // }
    this.initChart();
    
  }

  wonCalls = (arr) =>
  arr.reduce((p, c) => {
 
    var name = c.attendantName;

    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
   
    p[name]++;
    
    return p;
  }, {});

  groupByArray = (arr) =>
    
   arr.reduce((group, product) => {
      const { attendantName } = product;
      group[attendantName] = group[attendantName] ?? [];
      group[attendantName].push(product);
      return group;
    }, {});
   
  
  


  
  initChart(){
  //console.log(this.props.wonData)
    let chart = am4core.create('wonChart', am4charts.XYChart)
//chart.data = fullData;
chart.colors.step = 2;
      //const wonLeads = Object.entries(this.wonCalls(this.props.wonData));
      const wonLeads = Object.entries(this.groupByArray(this.props.wonData));
  //console.log(wonLeads)

     let fullData = [];
    // let connected = 0;
    // for(let y=0;y < wonLeads.length; y++){
    //   if(wonLeads[y][0] !== "undefined"){
    //     fullData.push({
    //       "name": wonLeads[y][0],
    //       "points": wonLeads[y][1],
    //       "color": chart.colors.next(),
    //       "bullet": require("../resources/images/won-taj.png")
    //   });
    //   }
      
    // }

    for(let y=0;y < wonLeads.length; y++){
      let innerObj = {};
      //if(wonLeads[y][0] != 'undefined'){

          innerObj.name = wonLeads[y][0];
          innerObj.points = wonLeads[y][1].length;
          innerObj.color= chart.colors.next();
          innerObj.bullet =  wonLeads[y][1][0].attendantImage?wonLeads[y][1][0].attendantImage :require("../resources/images/Avatar.png");

          fullData.push(innerObj)
        
        

      //}
    }
    //console.log(fullData)
  
  

    //
    

    chart.data = fullData;
    
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.inside = true;
    categoryAxis.renderer.labels.template.fill = am4core.color("#000");
    categoryAxis.renderer.labels.template.fontSize = 15;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.minHeight = 150;
    categoryAxis.renderer.labels.template.fontSize = 14;
    

    

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
yAxis.min = 0;
    // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.renderer.grid.template.strokeDasharray = "4,4";
    // valueAxis.renderer.labels.template.disabled = true;
   
    // valueAxis.min = 0;
    
    // Do not crop bullets
    chart.maskBullets = false;
    
    // Remove padding
    //chart.paddingBottom = 200;
    
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "points";
    series.dataFields.categoryX = "name";
    series.columns.template.propertyFields.fill = "color";
    series.columns.template.propertyFields.stroke = "color";
    series.columns.template.column.cornerRadiusTopLeft = 15;
    series.columns.template.column.cornerRadiusTopRight = 15;
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/b]";
    
    // Add bullets
    let bullet = series.bullets.push(new am4charts.Bullet());
    let image = bullet.createChild(am4core.Image);
    image.width = 30;
    image.height = 30;
    
    image.horizontalCenter = "middle";
    image.verticalCenter = "bottom";
    image.dy = 20;
    image.y = am4core.percent(100);
    image.propertyFields.href = "bullet";
    image.tooltipText = series.columns.template.tooltipText;
    image.propertyFields.fill = "color";
    image.filters.push(new am4core.DropShadowFilter());

    //
  }
    

  componentWillUnmount() {
    if (chart) {
      chart.dispose();
    }
  }
  
  render() {
    return <div id="wonChart" style={{ width: "100%", height: "400px", margin: "0 auto" }} />;
  }
}

export default WonChart;