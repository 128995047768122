import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import "./LeadsListingPage.css";
import {
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
    setIdentity,
    setRoomId,
    setFormId,
    setUserInfo,
} from "../store/actions";

import { Link, useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

import CircularProgressBar from '../CircularProgressBar/CircularProgressBar';

// api call import

import { callAxios, convertReadable, convertReadableTime } from '../utils/utils';

// env import
import {environment} from '../environment';

//datepicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AbstractSummaryComponent from "../Summery/AbstractSummery";
import SheelaModal from "../modals/SheelaModal";
import MeetingCard from "../Meetings/DownloadMeetingCard";

const DownloadMeeting = (props) => {
    const {setLoggedinUser} = props;
    const [isSheelaView, setSheelaView] = useState(false);

    const [user, loading, error] = useAuthState(auth);

    const [startDate, setStartDate] = useState(new Date());
    
    let navigate = useNavigate();

    const [allData, setAllDate] = useState([]);

    const [allClientMeetings,setDownloadData] =useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [clickedRoomId,setROOMId] =useState("");

    const [roomData,setRoomData] =useState([]);

    const [isLoadingTrans, setIsloadingTrans] = useState(true);
    const [transcriptData, setTranscriptData] = useState([]);
    const [transcriptRoomIds, setTranscriptRoomIds] = useState([]);

    const [roomId4Sheela, setRoomIdForSheela] = useState('');

    const [allMeetingRoomIds, setAllMeetingRoomIds] = useState([]);

    const [isMeetingLoaded, setIsMeetingLoaded] = useState(false);


    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
   
    const queryConstraints = [];
    queryConstraints.push(where("timestamp", ">=", startOfToday));
    queryConstraints.push(where("timestamp", "<=", endOfToday));
    queryConstraints.push(orderBy("timestamp", "desc"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_COMPOSITION_DB),
      ...queryConstraints
    );

    
   
    useEffect(() => {
        onSnapshot(q, (querySnapshot) => {
          let forms = [];
          let roomArray = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            forms.push({ ...doc.data(), id: doc.id });
            roomArray.push(doc.id);
            
          });
    
          setTranscriptData(forms);
          setIsloadingTrans(false);
          setTranscriptRoomIds(roomArray);
        });
    
        //audio.play()
      }, []);

   
//console.log(transcriptData,isLoadingTrans,transcriptRoomIds)
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");

        if(user){
            const userInfo = {
                uid: user?.uid,
                email: user?.email,
                displayName: user?.displayName,
                accessToken: user?.accessToken,
                idToken: user?.idToken,
              };
              setLoggedinUser(userInfo);
        }
       
    }, [user, loading]);

    const closePlayer = () => { 
        var videoSrc = document.getElementById("videoSrc");
        videoSrc.pause();
        document.getElementById("video-player").style.display="none";
    }
    const handleMeetingViewAndDownload = async(compositionId, type, roomId) =>{
        //console.log("composition Id:", compositionId, " | type:", type)
        let mediaResponse;
        if(type === 'view'){
           
            mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/get-media-url',{compositionSid:compositionId},"post");
            const url = mediaResponse.data.url;
           
            var videoSrc = document.getElementById("videoSrc");

            const isSupp = videoSrc.canPlayType("video/mp4");
            if (isSupp == "") {
                videoSrc.src = url;
            } else {
                videoSrc.src = url;
            }
            videoSrc.load();
            videoSrc.play();

        document.getElementById("video-player").style.display="block";

        }
        else {
            mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/download-media',{compositionSid:compositionId},"post");
            const url = mediaResponse.data.url;
            window.location.href = url;
        }

        
        
    }

    const createComposition = async(roomId) =>{
       // console.log(roomId);
       setROOMId(roomId)
        document.getElementById(roomId).classList.remove("hide");
        const response = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/create-composition',{roomId:roomId},"post");
        if(response){
            //document.getElementById(roomId).classList.add("hide");
        }
    }
    
    const scrollCss = {
        
        width: "100%",
        height: "100px",
        overflow: "auto"
      }
      
      const removeDuplicates= (connectedClientArray) => {
              
        let freshConnectedLeads = [];
        let roomIds = [];
        let uniqueObject = {};
        for (let i in connectedClientArray) {
    
            const objRoomId = connectedClientArray[i]['roomId'];
            uniqueObject[objRoomId] = connectedClientArray[i];
        }
          
        for (let i in uniqueObject) {
          freshConnectedLeads.push(uniqueObject[i]);
        }
          
        return freshConnectedLeads;
    }

    const getRoomIds= (connectedClientArray) => {
              
       
        let roomIds = [];
        let uniqueObject = {};
        for (let i in connectedClientArray) {
    
            const objRoomId = connectedClientArray[i]['roomId'];
            uniqueObject[objRoomId] = connectedClientArray[i];
        }
          
        for (let i in uniqueObject) {
            roomIds.push(uniqueObject[i].roomId);
        }
          
        return roomIds;
    }

    
    const DownloadPageMeetingRoomIds = getRoomIds(allClientMeetings);
    //console.log(transcriptRoomIds, DownloadPageMeetingRoomIds)

    // const forAPIFire = transcriptRoomIds.filter( function(n) { return !this.has(n) }, new Set(DownloadPageMeetingRoomIds) );

    // console.log(forAPIFire)
    const connectedLeads = removeDuplicates(allClientMeetings);

    // console.log("printArray:::",connectedLeads)
     let vlisting;
    if(connectedLeads.length > 0){
        vlisting = connectedLeads?.map((item, index) => {
        
            const customScroll = item.description.length > 337 ? scrollCss:null;
            const spinnerId = item.roomId;
             //if(item.participants && item.participants.length > 2){
                if(item.isCompositionCreated === true){
                    let roominfo =  document.getElementById(item?.roomId);
                    if(roominfo)
                    roominfo.classList.add("hide");
                }
               // console.log("all Data:", item)
            let total = 0;
            total += item.name !== "" ? 1 : 0;
            total += item.email !== "" ? 1 : 0;
            total += item.phone !== "" ? 1 : 0;
            total += item.companyweb !== "" ? 1 : 0;
            total += item.projduration !== "" ? 1 : 0;
            total += item.description !== "" ? 1 : 0;
    
            const percentage = parseFloat(total * 100 / 6).toFixed(0);
    
    
        //     return (
        //         <>
        //             <tr className="list-item-wrapper mt-3 rounded-2 px-2 py-3 p-lg-0" key={"meeting-download-key-"+index}>
        //                 <td className="list-item description">
        //                     {(item.name) ? <div className="client-name text-capitalize mb-1">{item.name}</div> : ''}
                            
        //                     {(item.description) ? 
        //                     <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
        //                     :
        //                     <div className="requrement-label text-danger mb-1">Client didn't fill the requirement yet</div>
        //                     }
        //                      {(item.description) ? 
        //                     <div className="client-requrement" style={ customScroll}>
        //                     {(item.description) ? item.description:null}
        //                     </div>
        //                     :null }
        //                 </td>
                      
        //                 <td className="list-item text-lg-center">
        //                 <div class="mb-2 text-warning text-info">
                               
        //                         {/* {item.CallStatus === 1 && (item.CallDuration != 0 || item.CallDuration != "0") ?
        //                         "Mobile": 
        //                         item?.clientJoinedLiveTime !==''  && Number(item.CallDuration) > 0 ?
        //                         "Live + Mobile" :
                                
        //                         "Live"
        //                         } */}
        //                     </div>
        //                     <div className="client-progress-status d-flex flex-column align-items-lg-center">
        //                         <div className="progressbar-label text-white text-opacity-75 mb-1 d-lg-none">Client's Form Filling Status</div>
        //                         <CircularProgressBar percentage={percentage} />
        //                     </div>
                            
        //                 </td>
                       
    
        //                 <td className="list-item text-lg-end">
        //                     <div className="date-time-state mb-1">{convertReadable(item.timeStamp) + ' '+convertReadableTime(item.timeStamp)}</div>
        //                     {item.RoomStatus === 'in-progress' ? 
        //                     <div className="department-name text-capitalize mb-1">CAM {item.attendantName} already in the group</div>
        //                     :null}
        //                     {item.country?
        //                     <div className="country-name text-capitalize text-warning text-opacity-75 mb-1">
        //                         Client Location {item.country}
        //                     </div>
        //                     :null}
        //                     {item.attendantBdmName?
        //                     <div className="department-name text-capitalize mb-1">
        //                         {item?.attendantBdmName && item?.attendantBdmName != ""
        //                             ? "Attn By :-" + item?.attendantBdmName
        //                             : ""}
        //                     </div>
        //                     : null }
                            
        //                     {
        //                         transcriptRoomIds.includes(item.roomId) ? (
        //                             <button
        //                             onClick={() => analyzeData(item.roomId)}
        //                             className="btn btn-success btn-custom btn-next rounded-1 mt-1"
        //                             >
        //                                 Sheela Report
        //                             </button>
        //                         ) : (

        //                             <button
        //                             className="btn btn-success btn-custom btn-next rounded-1 mt-1"
        //                             >
        //                                 Sheela Analyzing
        //                             </button>
        //                         )


                            
        //                     }
                           
        //                     { !item.isCompositionCreated ? 
        //                     <button
        //                             onClick={() => createComposition(item.roomId)}
        //                             className="btn btn-success btn-custom btn-next rounded-1 mt-1"
        //                             data-toggle="modal" 
        //                             id={"createComBtn-" + item.roomId}
        //                         >
        //                             Create Composition
        //                         </button>
    
        //                         :null }
    
                               
                               
                                
        //                          { item.isCompositionCreated ? 
        //                         <button
        //                             onClick={() => handleMeetingViewAndDownload(item.compositionId,"view", item.roomId)}
        //                             className="btn btn-success btn-custom btn-next rounded-1 mt-1"
        //                             data-toggle="modal" 
        //                             style={{marginRight:'10px'}}
        //                         >
        //                             View Meeting
        //                         </button>
        //                         :null }
        //                         { item.isCompositionCreated ? 
        //                         <button
        //                             onClick={() => handleMeetingViewAndDownload(item.compositionId, "download")}
        //                             className="btn btn-success btn-custom btn-next rounded-1 mt-1"
        //                         >
        //                             Download Meeting
        //                         </button>
        //                         :null }
    
                                
        //                          <div className="d-flex align-items-center justify-content-lg-end gap-2 mt-2">
        //                             <div id="loading-bar-spinner" className="spinner">
        //                                 <div className="spinner-icon hide" id={spinnerId}></div>
        //                             </div>
        //                             { item.compositionPercentageDone ?
        //                               item.isCompositionCreated?null: 
        //                                 <div>{item.compositionPercentageDone} %</div>
                                    
        //                             :null 
        //                             }
        //                             </div>

                                    

                           
                               
        //                 </td>
        //             </tr>
        //         </>
        //     ); 
        //   //}

        return (
            <div className="info-card rounded-2 mt-3" key={"meeting-download-key-" + index}>
            <div className="info-card-details overflow-auto p-2 p-md-3">
                <div className="row gx-3">
                    <div className="col-6 col-lg-1">
                        {/* <div class="mb-1 text-warning text-info">
                            {item.CallStatus === 1 && (item.CallDuration != 0 || item.CallDuration != "0") ?
                                "Mobile" :
                                item?.clientJoinedLiveTime !== '' && Number(item.CallDuration) > 0 ?
                                    "Live + Mobile" :
                                    "Live"
                            }
                        </div> */}
                        <div className="client-progress-status d-flex flex-column">
                            <div className="label text-white text-opacity-75 mb-1 d-lg-none">Client's Form Filling Status</div>
                            <CircularProgressBar percentage={percentage} />
                        </div>
                    </div>
                    <div className="col-6 d-lg-none">
                        <div className="date-time-state text-white text-opacity-75 mb-1">{item.displayTime}</div>
                        {item.camLeftAt  || item.RoomStatus === "in-progress"  ?
                            null
                            : <div className="department-name text-capitalize mb-1">CAM {item.attendantName} already in the group</div>}
                        {item.country ?
                            <div className="countryy-name text-capitalize text-warning text-opacity-75 mb-1">
                                Client Location {item.country}
                            </div>
                            : null}
                        {item.attendantBdmName ?
                            <div className="department-name text-capitalize mb-1">
                                {item?.attendantBdmName && item?.attendantBdmName != ""
                                    ? "Attn By :-" + item?.attendantBdmName
                                    : ""}
                            </div>
                            : null}

                        <div className="d-flex align-items-center justify-content-start gap-2 my-2">
                            <div id="loading-bar-spinner----" className="spinner">
                                <div className="spinner-icon d-none" id={"---"+spinnerId}></div>
                            </div>
                            {item.compositionPercentageDone ?
                                item.isCompositionCreated ? null :
                                    <div>{item.compositionPercentageDone} %</div>
                                : null
                            }
                        </div>
                    </div>
                    <div className="col-lg-9 mt-2 mt-lg-0">
                        {(item.name) ? <div className="client-name text-capitalize mb-1">{item.name}</div> : ''}
                        {(item.description) ?
                            <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
                            :
                            <div className="requrement-label text-danger mb-1">Client didn't fill the requirement yet</div>
                        }
                        {(item.description) ?
                            <div className="client-requrement">
                                {(item.description) ? item.description : null}
                            </div>
                            : null}

                        <div className="row mt-3 pt-lg-3">
                            <div className="col-lg-3 left-sec d-none d-lg-block">
                                <div className="date-time-state text-white text-opacity-75 mb-1">{item.displayTime}</div>
                                {item.camLeftAt || item.RoomStatus === "in-progress" ?
                                    null
                                    : <div className="department-name text-capitalize mb-1">CAM {item.attendantName} already in the group</div>}
                                {item.country ?
                                    <div className="countryy-name text-capitalize text-warning text-opacity-75 mb-1">
                                        Client Location {item.country}
                                    </div>
                                    : null}
                                {item.attendantBdmName ?
                                    <div className="department-name text-capitalize mb-1">
                                        {item?.attendantBdmName && item?.attendantBdmName != ""
                                            ? "Attn By :-" + item?.attendantBdmName
                                            : ""}
                                    </div>
                                    : null}

                                <div className="d-flex align-items-center justify-content-start gap-2 my-2">
                                    <div id="loading-bar-spinner" className="spinner">
                                        <div className="spinner-icon hide" id={spinnerId}></div>
                                    </div>
                                    
                                            <div className="d-none" id={"percentage-showing-"+spinnerId}></div>
                                        
                                </div>
                            </div>
                            <div className="col-lg-9 right-sec">
                                <div className="d-flex align-items-center gap-2">
                                    

                                    {/* for another icon  */}
                                    {
                                         !transcriptRoomIds.includes(item.roomId) ?(
                                    
                                     <div className="icon-part position-relative">
                                        <i class="bi bi-soundwave fs-2"></i>
                                        <span class="position-absolute top-50 start-50 translate-middle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" class="DocSearch-Search-Icon" viewBox="0 0 20 20">
                                                <path d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z" stroke="#68a0f3" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span>
                                    </div> 
                                         ):(
                                            <div className="icon-part">
                                        <i class="bi bi-check-circle-fill text-success fs-18"></i>
                                    </div>
                                         )}

                                    <div className="comment-part position-relative text-white text-opacity-75 rounded py-2 px-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                                        {
                                             transcriptRoomIds.includes(item.roomId) ?(
                                                <div className="row align-items-center g-2">
                                                <div className="col-xl-9 fs-12">
                                                    <div className="attn-name">{user?.displayName}</div>
                                                    <p className="m-0">I've completed my work. You can now review all the details.</p>
                                                </div>
                                                <div className="col-xl-3 text-xl-end">
                                                    <button onClick={() => analyzeData(item.roomId)} className="btn btn-sm btn-success text-nowrap">Sheela's Update</button>
                                                </div>
                                            </div>
                                             ):(
                                                <div className="row align-items-center g-2">
                                                <div className="col-xl-9 fs-12">
                                                    <div className="attn-name">Hello {user?.displayName}</div>
                                                    <p className="m-0">I'am Sheela, the newest member of the VE family. My expertise lies in documenting meeting calls and provide concise summaries. Allow me to attend the call and prepare a detailed report for you.</p>
                                                </div>
                                                <div className="col-xl-3 text-xl-end">
                                                    {/* <button className="btn btn-sm btn-success text-nowrap">Sheela's Update</button> */}
                                                </div>
                                            </div>
                                             )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-sec d-flex flex-wrap gap-2 mt-3">
                            {!item.isCompositionCreated ?
                                <button className="btn btn-success btn-custom btn-next rounded-1" data-toggle="modal"
                                    onClick={() => createComposition(item.roomId)}
                                    id={"createComBtn-" + item.roomId}
                                >
                                    Create Composition
                                </button>
                                : null}

                            {item.isCompositionCreated ?
                                <button className="btn btn-success btn-custom btn-next rounded-1" data-toggle="modal"
                                    onClick={() => handleMeetingViewAndDownload(item.compositionId, "view", item.roomId)}
                                >
                                    View Meeting
                                </button>
                                : null}

                            {item.isCompositionCreated ?
                                <button className="btn btn-success btn-custom btn-next rounded-1"
                                    onClick={() => handleMeetingViewAndDownload(item.compositionId, "download")}
                                >
                                    Download Meeting
                                </button>
                                : null}
                        </div>
                    </div>
                    <div className="col-lg-2 mt-2 mt-lg-0 text-lg-end">
                        <div className="img-wrapper position-relative d-flex align-items-end justify-content-center h-100" style={{ bottom: '-1rem' }}>
                        {
                                 transcriptRoomIds.includes(item.roomId) ? (
                                    <img className="img-fluid" src={require('../resources/images/meeting-girl.png')} alt="Meeting User" style={{ maxHeight: '280px' }} />
                                    ):(
                                        <img className="img-fluid" src={require('../resources/images/meeting-girl2.png')} alt="Meeting User" style={{maxHeight:'260px'}} />
                                       
                                    )
                                }
                            {/* <img className="img-fluid" src={require('../resources/images/meeting-girl.png')} alt="Meeting User" style={{ maxHeight: '280px' }} /> */}
                            {/* <img className="img-fluid" src={require('../resources/images/meeting-girl2.png')} alt="Meeting User" style={{maxHeight:'260px'}} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )

        // return <MeetingCard item = {item} index={index} percentage={percentage} spinnerId={spinnerId} />
        
        });
    } else{
        // setIsLoading(false);
        vlisting = "No meeting found for View or Download"
    }
   

    useEffect(() => {

// getDownloadData(new Date());
const datePickerDate = document.getElementById("wonDatePicker").value;
//console.log(datePickerDate)
getMeetingData(new Date(datePickerDate));

     }, [roomData]);

const getMeetingData = async(date) =>{
    var startOfToday = new Date(date);
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date(date);
    endOfToday.setHours(23, 59, 59, 999);
   
    const queryConstraints = [];
    queryConstraints.push(where("timestamp", ">=", startOfToday));
    queryConstraints.push(where("timestamp", "<=", endOfToday));
    queryConstraints.push(orderBy("timestamp", "desc"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_COMPOSITION_DB),
      ...queryConstraints
    );

    onSnapshot(q, (querySnapshot) => {
        let forms = [];
        let roomArray = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          forms.push({ ...doc.data(), id: doc.id });
          roomArray.push(doc.id);
          
        });
  
        // setTranscriptData(forms);
        // setIsloadingTrans(false);
        setTranscriptRoomIds(roomArray);
      });
    setStartDate(date)
    const filteredData = [];
callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-all-download',{date:date}, "post" ).then((meetingData)=>{
   // console.log("downloadData:::",meetingData.data[0].participants)
   setIsLoading(false);
    if(meetingData.data.length > 0){
        meetingData.data.map((item, index) =>{
                //console.log(index)
            if(item.participants){
                const participants = JSON.parse(item.participants);
                participants.map((p) => {
                
                if (p?.identity.includes("_CL_") || p?.identity.includes("+17862041697-")) {
                    filteredData.push(item);
                    
                    //console.log(item)
                   
                }
            })
         // console.log("Client:::",filteredData)
           
            
           
            }
            
            });

            setDownloadData(filteredData)
            setIsMeetingLoaded(true);
            
        }
        setAllDate(meetingData.data);
    
})
}

const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  useEffect(() => {
    
   
    const getLeadDetails = async () => {
      if(clickedRoomId.length > 0){
        const q = query(collection(database,environment.REACT_APP_FIREBASE_DB), where("roomId", '==', clickedRoomId));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          setRoomData(data);
         
        });
      });
      }
      

      
    }
    getLeadDetails();

    
  }, [clickedRoomId]);
//console.log(roomData)
if(roomData && !roomData.isCompositionCreated){
    const percentageShowing = document.getElementById("percentage-showing-"+roomData.roomId);
    if(percentageShowing){
        percentageShowing.classList.remove("d-none");
        if(roomData.compositionPercentageDone > 0)
        percentageShowing.innerHTML = roomData.compositionPercentageDone + "%";
       
    }
    
}
if(roomData && roomData.isCompositionCreated){
    const meetingLoader =  document.getElementById(roomData.roomId);
    if(meetingLoader)
    meetingLoader.classList.add("hide");
    const compositionBtn = document.getElementById("createComBtn-"+roomData.roomId);
    if(compositionBtn)
    compositionBtn.classList.add("d-none")

    const percentageShowing = document.getElementById("percentage-showing-"+roomData.roomId);
    if(percentageShowing)
    percentageShowing.classList.add("d-none")
}

const analyzeData= (roomId) =>{
    setRoomIdForSheela(roomId)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display="block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
}

const getUnmatchedValues = (arr2, arr1) => {
    //console.log(arr1,arr2)
    return arr1.reduce((unmatched, item) => {
        if (!arr2.includes(item)) {
          unmatched.push(item);
        }
        return unmatched;
      }, []);
  }
useEffect(()=>{
    if(transcriptRoomIds){
        
        // console.log(allClientMeetings)
        
        if(connectedLeads.length > 0){
          
            //console.log(transcriptRoomIds,connectedLeads,DownloadPageMeetingRoomIds)
            const roomIdForTranscription = getUnmatchedValues(transcriptRoomIds,DownloadPageMeetingRoomIds)
            //console.log(roomIdForTranscription.length,transcriptRoomIds.length)
            if(transcriptRoomIds.length > 0 && roomIdForTranscription.length > 0 && transcriptRoomIds.length !== 0)
            //console.log(transcriptRoomIds,roomIdForTranscription)
           callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/CallAudioCompoistionDownloadMeeting',{roomIds:roomIdForTranscription},"post");
            
        }
    }
   
   
},[transcriptRoomIds,isMeetingLoaded])



    return (
        <>
           
           <div className="compose-media-page">
            <div className="container">
                <div className="live-listing-wrapper py-3">
                    <div className="row align-items-center g-2 mb-2">
                        <div className="col-md-8">
                            <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">
                                View and Download Meeting
                            </div>
                        </div>

                        <div className="col-md-4 text-md-end">
                            <label className="small">Filter By Date:</label>
                            <DatePicker
                                // selected={new Date()}
                                selected={startDate}
                                onChange={(date) => { getMeetingData(date) }}
                                className="wonDatePicker mb-2 ms-2"
                                id="wonDatePicker"
                                renderMonthContent={renderMonthContent}
                                showFullMonthYearPicker
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>

                    <div className="meeting-details-page rounded-3">
                        <div className="list-header small fw-semibold text-white rounded-3 p-3 mb-3 d-none d-lg-block"
                            style={{
                                background: "linear-gradient(168.68deg,#0052c9 1.12%,#0a91db)",
                            }}
                        >
                            <div className="row gx-3">
                                <div className="col">Name</div>
                                <div className="col text-lg-center">Client Form Status</div>
                                {/* <div className="col">Participants</div>
                                <div className="col">Time Stats</div> */}
                                <div className="col text-lg-end">Action</div>
                            </div>
                        </div>

                        {isLoading ?
                            <div className="" id="lead-list-spinner"
                                style={{
                                    position: 'fixed',
                                    margin: '21px 0px 0px',
                                    left: '44%'
                                }}
                            >
                                <div style={{ marginLeft: "12px", float: "right" }} className="lead-list-spinner"></div>{" "}
                                Please wait ....
                            </div> :
                            <div>{vlisting}</div>
                        }
                    </div>
                </div>
            </div>
            <div id="video-player" class="modal" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <video width="400" controls autoplay id="videoSrc">
                                <source src="" type="video/mp4" ></source>
                                Your browser does not support HTML video.
                            </video>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


            <div id="video-player" class="modal" role="dialog">
            <div class="modal-dialog">
          
                <div class="modal-content">
                
                <div class="modal-body">
                <video width="400" controls autoplay id="videoSrc">
                <source src="" type="video/mp4" ></source>
                
                Your browser does not support HTML video.
                </video>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
                </div>
                </div>

            </div>
            </div>
            {/* <AbstractSummaryComponent /> */}
            <SheelaModal setSheelaView={setSheelaView} isSheelaView = {isSheelaView} roomId4Sheela={roomId4Sheela}/>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdentityAction: (identity) => dispatch(setIdentity(identity)),
        setRoomIdAction: (id) => dispatch(setRoomId(id)),
        setFormIdAction: (id) => dispatch(setFormId(id)),
        setLoggedinUser: (user) => dispatch(setUserInfo(user)),
    };
};

const mapStoreStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
)(DownloadMeeting);