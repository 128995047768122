import React, { useState } from "react";
import '../Chatboat/Chat.css';
import { Link, useNavigate } from 'react-router-dom';
import imgDefault from '../resources/images/Avatar.png';



import { useEffect } from "react";
import { filterChatByDate, getTokenFromTwilioForConversation, getSubscribedUser, conversationByDateFilter } from "../utils/twilioConversationAdmin";
import { callAxios } from "../utils/utils";
import { environment } from "../environment";
import { JoinNowPopup } from "../LeadsListingPage/JoinNowPopup";
import { v4 as uuidv4 } from "uuid";
import { store } from "../store/store";
import { setIdentity, setRoomId, setUserInfo } from "../store/actions";
import { connect, useSelector } from "react-redux";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { database } from "../firebaseConfig";

import notificationSound from "../static/callTune.mp3";

import interactionSound from "../static/Intereact.mp3";



//datepicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import $ from "jquery";



const ChatBoat = (props) =>{
    const [identity, setIdentity] = useState('');
    const [name, setName] = useState('');

    const [startDate, setStartDate] = useState(new Date());
    const [interactAudio, setInteract] = useState(new Audio(interactionSound));
    const [outGoingAudio, setAudio] = useState(new Audio(notificationSound));
    let navigate = useNavigate();
    const { setTwilioAccessTokenForChatAction } = props;
    const [isActive, setIsActive] = useState(false);
    const [token, setChatToken] = useState('');
    const [messages, setMessages]= useState([]);
    const [users, setUsers] = useState([]);
    const [isGoLive, setGoLive] = useState(false);

    const {setRoomIdAction, setIdentityAction, setLoggedinUser, allCalls, isReceived} =props;
    
    const loggedInUser = props?.user? props?.user?.email  : localStorage.getItem("userEmail");
    const [subscribedUsers, setSubscribedusers] = useState([]);
    const [subscribedUsersupdated, setSubscribedusersUpdated] = useState([]);
    const [groupCount, setGroupCount] = useState([]);

      useEffect(() => {
        if (props?.loading) return;
        if (!props?.user) navigate("/");
        else {
        
            const chatDateFilter = $("#chatDateFilter").val();
            console.log(chatDateFilter)
            callAxios(environment.REACT_APP_API_URL_Live + `api/v1/twilio/getSubscribedUsers`, {chatDateFilter:chatDateFilter}, "post").then((users)=>{
                setSubscribedusers(users.data)
                setSubscribedusersUpdated(users.data)
                $("#leftLoader-chat-user").addClass("d-none");
            })

            callAxios(environment.REACT_APP_API_URL_Live + `api/v1/twilio/group-count`, {}, "post").then((group)=>{
                setGroupCount(group.data)
            })

            conversationByDateFilter(chatDateFilter);
            

            

          const userInfo = {
            uid: props?.user?.uid,
            email: props?.user?.email,
            displayName: props?.user?.displayName,
            accessToken: props?.user?.accessToken,
            idToken: props?.user?.idToken,
          };
          setLoggedinUser(userInfo);
        }
       
      
      }, [props?.user, props?.loading]);


     //console.log(subscribedUsers)
         
      ////console.log("identity", identity)
      
     
     
      

    const openChatbar = (event) => {
        setIsActive(current => !current);
    };
    const closeChatbar = (event) => {
        setIsActive(false);
    };

    // For New Chat header
    const [isOpen, setIsOpen] = useState(false);

    const openNewChat = (event) => {
        setIsOpen(current => !current);
    };

    const closeNewChat = (event) => {
        setIsOpen(false);
    };

    const getConversationList = async(name,identity) =>{

       // console.log(name,identity)
        $("#new-chat-search-filter-admin").trigger("click")
        setIdentity(identity);
        setName(name);
        getTokenFromTwilioForConversation(name,identity)
        // getConversationList(name, identity);

        
    } 



    const searchUsers = (keyword) =>{
        //console.log(keyword.length)
        callAxios(
            environment.REACT_APP_API_URL_Live + "api/v1/users/get-admin-all-user",
            {keyword:keyword},
            "post"
          ).then((users)=>{
            // setUsers(users.data);
            
                if(keyword.length > 0){
                    setSubscribedusers(users.data)
                } else{
                    //console.log(subscribedUsers.length)
                    setSubscribedusers(subscribedUsersupdated)
                }
                
            
            
          })
    }
    
   
    const playSound = () =>{
        outGoingAudio.play();
          outGoingAudio.addEventListener('ended', function () {
            setTimeout(() => {
                outGoingAudio.play();
            }, 2000);
            
            }, false);
    }

    const stopSound = () =>{
        outGoingAudio.stop();
         
    }

    const playInteract = () =>{
        interactAudio.play();
    }

    const getChatByDate = async(date) =>{
        setStartDate(date)
       // console.log(date)

       // getConversationList(name,identity)
        getTokenFromTwilioForConversation(name,identity)
       
    }

    const renderMonthContent = (month, shortMonth, longMonth) => {
        const tooltipText = `Tooltip for month: ${longMonth}`;
        return <span title={tooltipText}>{shortMonth}</span>;
      };

    const setDefaultUser = () =>{
        setSubscribedusers(subscribedUsersupdated)
        $("#new-chat-search-filter-admin").trigger("click");
    }
    
    document.body.classList.remove('dark-mode')
    
   // console.log(subscribedUsers)
    const userHtml = subscribedUsers.map((item,index)=>{
      
        // const short = item.friendlyName.replace(/\s+/g, ".")
        const identity = item.identity?item.identity:item.email;
        let groupAtr ;
        let userIdentity ;
        if(item.sid){
        groupAtr = JSON.parse(item.attributes);
        }
         //console.log(groupAtr.group)
        if(item.uid){
            userIdentity = item.email;
        } else if(groupAtr.group){
            userIdentity = groupAtr.author_identity;
        } else{
            userIdentity = item.identity;
        }
        const displayName = item.friendlyName?item.friendlyName:item.display_name;
        //console.log(item.friendlyName?item.friendlyName:item.display_name, item.sid)

        let shortName = displayName.split(' ').reduce((acc, subname) =>
              acc + subname[0], '')
              
        //console.log(displayName, userIdentity)
        
        return (
            
            <div className="user-card small list d-flex justify-content-between gap-2 border-bottom pointer px-3 py-2 pointer" onClick={()=>{getConversationList(displayName, userIdentity)}} >
                <div className="w-75 d-flex align-items-center gap-2">
                    <div className="user-pic position-relative">
                        <div className="chip-wrapper"><div className="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden">
                            <div className="user-shortname text-blue">{shortName} </div>
                        </div>
                    </div>
        
    
                    <div className="user-status position-absolute z-1"> </div>
                </div>
                <div className="user-detail text-nowrap text-truncate">
                        <div className="user-name text-capitalize text-truncate lh-sm" >{displayName}</div>
                            <div className="user-email small text-truncate"></div>
                </div>
                </div>
                <div className="flex-fill text-end small"></div>
            </div>
        )
    })

    const handleParticipant = ()=>{
        setSubscribedusers(subscribedUsersupdated)
    }

    const handleGroup = ()=>{
        setSubscribedusers(groupCount)
    }
        
    return (
        <>
         <div className='chatboat-page'>
            <div className='chatboat-wrapper d-flex h-100 position-relative'>
                <aside className={`d-flex flex-column shadow-md border-end ${isActive ? 'active' : ''}`}>
                    <div className="list-head d-flex align-items-center border-bottom px-3 pb-2 pt-1 gap-2">
                    <div className="left d-none" id="back-go" onClick={setDefaultUser}>
                            
                            <span type="button" title="Back" onClick={openNewChat}><i className="bi bi-arrow-left fs-5 text-primary"></i></span>
                        </div>
                    <div className="wrapper">
                    <label className="form-label small mb-0 w-100 fw-medium text-dark">Admin Chats</label>
                    <DatePicker
                                            // selected={new Date()}
                                            selected={startDate}
                                            onChange={(date) => {getChatByDate(date)}}
                                            className="wonDatePicker mb-0 rounded-0 border p-1 w-100 fs-12"
                                            renderMonthContent={renderMonthContent}
                                            showFullMonthYearPicker
                                            dateFormat="yyyy-MM-dd"
                                            id="chatDateFilter"
                                            />
                      </div>
                        <div className="right d-flex gap-1">
                            
                            <button id="new-chat-search-filter-admin" type="button" className="btn border-0 bg-transparent p-1" title="New Chat" onClick={openNewChat}><i className="bi bi-search text-muted"></i></button>
                        </div>
                    </div>

                    <div className="list-head d-flex align-items-center justify-content-between gap-1 border-bottom px-3 py-1">
                        <div className="left text-center">
                            <div className="fs-12 fw-medium text-dark">Unique Participants</div>
                            <div className="counter fs-6 fw-medium text-primary pointer" onClick={handleParticipant}>
                                {subscribedUsersupdated.length}
                            </div>
                           
                        </div>
                   
                        <div className="right text-center">
                        <div className="fs-12 fw-medium text-dark">Group Count</div>
                            <div className="counter fs-6 fw-medium text-primary pointer" onClick={handleGroup}>
                                {groupCount.length}
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="list-wrap flex-fill overflow-auto" id="adminconversationListLeftSideUsers">
                    <div class="main-item" id="leftLoader-chat-user">
                        <div class="animated-background"></div>
                    </div>
                    
                    {
                        
                        userHtml
                    }
                       
                    </div>
                    <div className="list-wrap flex-fill overflow-auto d-none" id="adminconversationListLeftSide">
                    <div class="main-item--" id="leftLoader-chat">
                        <div class="animated-background"></div>
                    </div>
                    <p style={{textAlign:"center",marginTop:"20px"}}>Conversation not found!!</p>
                       
                    </div>
                </aside>
                <div className="main-chat-wrapper small">
                    <nav className="chat-header list-head border-bottom navbar navbar-expand-lg py-0">
                        <div className="container-fluid px-xl-3">
                            {/* <a className="navbar-brand" href="#">Navbar</a> */}
                            <div className="user-card d-flex justify-content-between gap-2 me-2">
                                <div className="d-flex align-items-center gap-2 d-none" id="AdmincurrentUser">
                                    <div className="user-pic position-relative">
                                    <div className="user-chip d-flex align-items-center justify-content-center text-uppercase bg-primary bg-opacity-10 small fw-semibold text-primary border border-primary" id="admin-current-user-short-name"></div>

                                    {/* <div class="chip-wrapper"><div class="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden">
                                        <div class="user-shortname text-blue" id="current-user-short-name"></div>

                                        
                                        </div></div> */}
                                        {/* <img src={imgDefault} alt="User" className="user-chip" />
                                        {Data.map((user, idx) => (
                                            <div className="user-status position-absolute z-1" key={idx} >
                                                {user.status === 'active' ? <span className="bg-success text-white"><i className="bi bi-check-circle"></i></span> : ''}
                                                {user.status === 'away' ? <span className="bg-warning text-white"><i className="bi bi-clock"></i></span> : ''}
                                                {user.status === 'offline' ? <span className="bg-white text-muted"><i className="bi bi-x-circle"></i></span> : ''}
                                                {user.status === 'call' ? <span className="bg-danger text-danger"><i className="bi bi-circle-fill"></i></span> : ''}
                                            </div>
                                        ))} */}
                                    </div>
                                    <div className="user-id fs-6 fw-semibold text-capitalize text-truncate" id="AdminshowCurrentChatName"></div>
                                </div>
                            </div>

                            <div className="d-flex align-items-center d-lg-none">
                                <button className="border-0 bg-transparent text-muted d-md-none py-0 me-2" type="button" title="View user lists" onClick={openChatbar}><i className="bi bi-person-circle fs-2"></i></button>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#chatNavbar" aria-controls="chatNavbarContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon small"></span>
                                </button>
                            </div>

                            <div className="collapse navbar-collapse" id="chatNavbar">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <Link className="nav-link active" aria-current="page" to={"#"}>Chat</Link>
                                    </li>
                                   
                                </ul>
                                <div className="d-flex align-items-center gap-2 mb-2 mb-lg-0">
                                
                        
                       
                                            
                     
                    
                                </div>
                            </div>
                        </div>

                        <div className={`newchat-wrapper bg-white h-100 w-100 z-1 ${isOpen ? 'position-absolute d-block' : 'd-none'}`} id="new-chat-wrapper">
                            <div className="dropdown bg-white h-100 w-100">
                                <input type="text" className="form-control border-0 rounded-0 h-100 px-3" id="newChatAdmin" placeholder="Enter name, email, group or tag" data-bs-toggle="dropdown" aria-expanded="false" onKeyUp={(e)=>{searchUsers(e.target.value)}} autoComplete="off" />
                                <ul className="dropdown-menu border rounded-0 m-0 py-0 w-100 overflow-auto" style={{ maxHeight: '50vh' }}>
                                    { users.length > 0?
                                    users.map((user, idd) => (
                                        <li className="border-bottom border-light" key={idd} onClick={()=>{getConversationList(user.display_name,user.email)}}>
                                            <a className="dropdown-item small d-flex align-items-center gap-2" href="#">
                                                <div className="user-pic position-relative">
                                                    <img src={!user.image?require("../resources/images/Avatar.png"):user.image} alt={user.display_name} className="user-chip" />
                                                    <div className="user-status position-absolute z-1">
                                                        {user.status === 'active' ? <span className="bg-success text-white"><i className="bi bi-check-circle"></i></span> : ''}
                                                        {user.status === 'away' ? <span className="bg-warning text-white"><i className="bi bi-clock"></i></span> : ''}
                                                        {user.status === 'offline' ? <span className="bg-white text-muted"><i className="bi bi-x-circle"></i></span> : ''}
                                                        {user.status === 'call' ? <span className="bg-danger text-danger"><i className="bi bi-circle-fill"></i></span> : ''}
                                                    </div>
                                                </div>
                                                <div className="user-detail text-nowrap text-truncate">
                                                    <div className="user-name text-capitalize text-body text-truncate lh-sm">{user.display_name}</div>
                                                    <div className="user-email small text-muted text-truncate">{user.email}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))
                                    :
                                   null
                                
                                }
                                </ul>
                            </div>
                        </div>
                    </nav>

                    {/* Message Area */}
                    <div className="message-wrap" id="message-box-scroll">
                        {/* If Chat message available */}
                        <div className="chatArea d-none px-3 px-xl-4 pt-2 mx-auto w-100" id="admin_chat-printing-area" ></div>

                        {/* When starting new chat */}
                        <div className="chatwindow-wrapper d-flex flex-column align-items-center justify-content-center h-100">
                            <div className="img-wrapper mb-3">
                                <img src={require('../resources/images/chat-window.png')} alt="Chat" className="img-fluid" style={{ maxWidth: "250px" }} />
                            </div>
                            <div className="chat-title fs-6 fw-semibold mb-1">You're reading a new conversation</div>
                            <div className="chat-subtitle"></div>
                        </div>
                    </div>

                    <div className="message-footer w-100 pt-4 pb-2 px-3 px-xl-4 mx-auto" style={{ maxWidth: '1056px' }}>
                    
                        
                        <div className="icon-wrapper d-flex align-items-baseline justify-content-between gap-2">
                            <div className="left-menu d-flex flex-wrap gap-1">
                               
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <JoinNowPopup user={props?.user} isGoLive={isGoLive} to="room" data={""} /> */}

        <button
        style={{ display: "none" }}
        id="p-box"
        data-bs-toggle="modal"
        data-bs-target="#joinCall"
        className="btn btn-warning btn-custom btn-next rounded-1 mt-1"
      ></button>
        </>
       
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setIdentityAction: (identity) => dispatch(setIdentity(identity)),
      setRoomIdAction: (id) => dispatch(setRoomId(id)),
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(ChatBoat);

//   export default ChatBoat;
