import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { database } from "../../firebaseConfig";
import { environment } from "../../environment";

const LeaveRoomButton = ({
  room,
  comingFrom,
  handleRoomDisconnection,
  participants,
  handleRoomDisconnectionWithoutForm,
  isClientOnCall,
  roomId
}) => {
  const clientOnCall = useSelector((state) => state.clientOnCall);
  
  const camClosed = async() =>{
    window.onbeforeunload = null;
    //console.log("in CAM cloased", roomId)
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
     
     
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
      
        updateDoc(formRef, {
            
          isCamClosedFeedBackForm: true
        }).then(() => {
          //console.log("cam tab closed successfully");
        })
        .catch((error) => {
          console.log(error);
        }); 
           
        
      
    });
  }
  const bdmClosed = async()=>{
    window.onbeforeunload = null;
    //console.log("in bdm cloased", roomId)
    const formData = query(
      collection(database, environment.REACT_APP_FIREBASE_DB),
      where("roomId", "==", roomId)
    );
    const querySnapshot = await getDocs(formData);
    querySnapshot.forEach(async (docData) => {
     
     
      const formRef = doc(
        database,
        environment.REACT_APP_FIREBASE_DB,
        docData.id
      );
       
        updateDoc(formRef, {
            
          isBdmClosedFeedBackForm: true
        }).then(() => {
          //console.log("bdm tab closed successfully");
        })
        .catch((error) => {
          console.log(error);
        }); 
       
          
        
        
      
    });
  }
  if (
    (comingFrom === true && clientOnCall) ||
    (comingFrom === "Bdm" && clientOnCall)
  ) {
    window.onbeforeunload = (event) => {
      const e = event || window.event;
      // Cancel the event
      //console.log(event, window.event);
      e.preventDefault();
     // console.log("beforeunload::",e,comingFrom);
      if(comingFrom === true && comingFrom !== "Bdm"){
        camClosed();
      } else{
        bdmClosed();
      }
      if (e) {
        e.returnValue = ""; // Legacy method for cross browser support

      }
      return ""; // Legacy method for cross browser support
    };
  } else {
    window.onbeforeunload = null;
  }
  //console.log(clientOnCall);
  return (
    <div className="video_button_container">
      {
        clientOnCall ? (
          comingFrom == "Bdm" ? (
            <button
              className="d-flex fw-semibold btn btn-danger rounded-1 video_button_end leave-btn py-1 px-3"
              onClick={handleRoomDisconnection}
              data-bs-toggle="modal"
              data-bs-target="#bdmQuestionModal"
            >
              <span>
                <i className="bi bi-telephbone-fill"></i>
              </span>
              <span className="ms-2 ps-1 d-none d-lg-block">Leave</span>
            </button>
          ) : comingFrom === true ? (
            <button
              className="d-flex fw-semibold btn btn-danger rounded-1 video_button_end leave-btn py-1 px-3"
              onClick={handleRoomDisconnection}
              data-bs-toggle="modal"
              data-bs-target="#camQuestionModal"
            >
              <span>
                <i className="bi bi-telephone-fill"></i>
              </span>
              <span className="ms-2 ps-1 d-none d-lg-block">Leave</span>
            </button>
          ) : (
            <button
              className="d-flex fw-semibold btn btn-danger rounded-1 video_button_end leave-btn py-1 px-3"
              onClick={handleRoomDisconnectionWithoutForm}
            >
              <span>
                <i className="bi bi-telephone-fill"></i>
              </span>
              <span className="ms-2 ps-1 d-none d-lg-block">Leave</span>
            </button>
          )
        ) : (
          //   //comment from here

          <button
            className="d-flex fw-semibold btn btn-danger rounded-1 video_button_end leave-btn py-1 px-3"
            onClick={handleRoomDisconnectionWithoutForm}
          >
            <span>
              <i className="bi bi-telephone-fill"></i>
            </span>
            <span className="ms-2 ps-1 d-none d-lg-block">Leave</span>
          </button>
        )
 
        // // end here
      }
    </div>
  );
};

export default LeaveRoomButton;
