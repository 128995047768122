import React, { Component } from "react";
import Participant from "./Participant";
import { setParticipants } from "../../../store/actions";
import { store } from "../../../store/store";
import { doc, setDoc, updateDoc,query, collection, where } from 'firebase/firestore'
import { app, database,auth } from '../../../firebaseConfig';

// env import
import {environment} from '../../../environment';
import { callAxios } from "../../../utils/utils";

class TwilioRoom extends Component {
  constructor(props) {
    super(props);

    const remoteParticipants = Array.from(
      this.props.room.participants.values()
    );

    this.state = {
      remoteParticipants: remoteParticipants,
    };

    remoteParticipants.forEach((participant) => {
      this.addParticipantToStore(participant);
    });
  }

  componentDidMount() {
    this.props.room.on("participantConnected", (participant) =>{
      //console.log(participant.identity)
      //console.log(this.props.room)
      if(participant.identity.includes("_CL_") || participant.identity.includes("+17862041697-")){
        callAxios(environment.REACT_APP_API_URL_Live + 'api/v1/video-media/start-recording', { RoomSid: this.props.room.sid }, "post");
      }
      
      this.addParticipant(participant)

  });

    this.props.room.on("participantDisconnected", (participant) => {
      this.removeParticipant(participant);
    });
  }

  async addParticipantToStore(participant) {
    const participants = store.getState().participants;
     let roomID = store.getState().roomId;
    if (participants.find((p) => p.identity === participant.identity)) {
      return;
    } else {
      const newParticipants = [...participants];
      let participantName = participant.identity.slice(36, participant.identity.length);
      if(participantName != "Admin" && participant.identity != "live")
      {
        newParticipants.push({ identity: participant.identity });
      }
    
      if(this.props.isCam)
      {
        let formID = store.getState().formId;
        const formRef = doc(database,environment.REACT_APP_FIREBASE_DB,formID);
       await updateDoc(formRef,{participants: newParticipants}); 
      }
      store.dispatch(setParticipants(newParticipants));
      //Adding Participant to Firestore

    }
  }

  addParticipant(participant) {
    this.addParticipantToStore(participant);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
    });
  }

  async removeParticipantFromStore(participant) {
    let formID = store.getState().formId;
    const participants = store
      .getState()
      .participants.filter((p) => p.identity !== participant.identity);
    store.dispatch(setParticipants(participants));
    // if(this.props.isCam)
    //   {
    //     let formID = store.getState().formId;
    //     const formRef = doc(database,environment.REACT_APP_FIREBASE_DB,formID);
    //    await updateDoc(formRef,{participants: participants, callStatus : participants?.length > 0 ? 1 : 0}); 
    //   }
  }

  removeParticipant(participant) {
    this.removeParticipantFromStore(participant);
    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
    });
  }

  render() {
    return (
      <div className="room">
        <div className="participants">
          <Participant
            key={this.props.room.localParticipant.identity}
            localParticipant
            participant={this.props.room.localParticipant}
            comingFrom={this.props.comingFrom}
            setBackgroundImageAction={null}
          />
          {this.state.remoteParticipants.map((participant) => {
            return (
              <Participant
                key={participant.identity}
                participant={participant}
                comingFrom={this.props.comingFrom}
                setBackgroundImageAction={null}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default TwilioRoom;
