import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// Datepicker NPM
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { connect } from 'react-redux';
import { setUserInfo } from '../store/actions';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import Card from './Card';
import DateRange from '../Reports/DateRange';

import "./pstn.css";
import { intitializeDevice } from '../utils/pstn';
// import { intitializeDevice } from '../utils/pstn';
// // import * as Twilio from '@twilio/voice-sdk';


const PstnListing = ({ setLoggedinUser }) => {
  const logDiv = document.getElementById("log");

  const [accessToken, setAccessToken] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();


  const [allData, setData] = useState([]);

  const [isShowCalendar, setIsShowCalendar] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate()


  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    if (loading) return;
    if (!user) navigate("/");
    else {

      const userInfo = {
        uid: user?.uid,
        email: user?.email,
        displayName: user?.displayName,
        accessToken: user?.accessToken,
        idToken: user?.idToken,
      };

      setLoggedinUser(userInfo);
      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/get-voice-t", { identity: user?.displayName }, "post").then((token) => {
        setAccessToken(token.data)

      })



    }
  }, [user, loading]);
  useEffect(() => {
    setStartDate(new Date());
setEndDate(new Date());
    if (accessToken)
      intitializeDevice(accessToken)
  }, [accessToken])
  useEffect(() => {


    // getMeetingData(new Date(), true);

    getDataByDate(new Date(), new Date())

  }, [user]);


  const getDataByDate = (startdate,enddate) =>{
    // setStartDate(date)
    
    setIsLoading(true);
    console.log(startdate,enddate)
    callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/call-crm",{systemId:'',isDateFilter:true,startdate:startdate.toString(),enddate:enddate.toString()},"post").then((meetingData) => {
      // console.log("downloadData:::",meetingData.data[0].participants)

      setData(meetingData.data);
      //    console.log(meetingData)
      setIsLoading(false);
    })
  }
  const handleNameSearch = ()=>{
      const clientName = document.getElementById("search-by-client-name").value;
      console.log(clientName)
      setIsLoading(true);
      
      callAxios(environment.REACT_APP_API_URL_Live + "api/v2/crm/call-crm",{systemId:'',isFilterName: true, page: 1, limit: 100, filterValue: clientName},"post").then((meetingData) => {
        // console.log("downloadData:::",meetingData.data[0].participants)
  
        setData(meetingData.data);
        //    console.log(meetingData)
        setIsLoading(false);
      })
  }

  const onChange = (ranges) => {
    setIsShowCalendar(false);
    setStartDate(ranges.startDate);
    setEndDate(ranges.endDate);
    // console.log(ranges.startDate,ranges.endDate)
    getDataByDate(ranges.startDate,ranges.endDate)
  };

  const showCalendar = () =>{
    // setIsShowCalendar(true);
    setIsShowCalendar(!isShowCalendar);
  
  }

  return (
    <div className='MarketingData client_queries my-3'>
      <div className="container">
        <div className="row align-items-center g-2 mb-3">
          <div className="col-lg-5">
            <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">Live Listing</div>
          </div>

          <div className="col-lg-7 text-md-end">
          <div className="d-flex d-lg-inline-flex flex-column flex-sm-row gap-2">
      
            <div className="input-group">
              <input type="search" className="form-control" placeholder="Search by leadId, client name and unique name" id='search-by-client-name' autoComplete='off'/>
              <span className="input-group-text pointer" id="basic-addon2" onClick={handleNameSearch}><i className="bi bi-search"></i></span>
            </div>
            <div className='custom-date-range'>
           
                           
                            
                          <input placeholder={new Date(startDate).toLocaleString("default",{day:'2-digit', month:'short', year:'numeric' }) +' - '+ new Date(endDate).toLocaleString("default",{day:'2-digit', month:'short', year:'numeric' })}  type="text" className="form-control" id="calendar" onClick={showCalendar}  />
                           
                        
                          {isShowCalendar?
                           <div className="date-range-chart" style={{zIndex:11}}>
                           <DateRange  onChange={onChange} startDate={startDate} endDate={endDate} showIcon="true" />
                           </div>
                           :null
                        }

            </div>
            </div>
           
            
          </div>
        </div>
        <div className="dark-theme-bg rounded-2 p-3 mt-3 pb-1 mb-3 hide overflow-auto" id="log" style={{ height: "150px" }}></div>
        <div className="meeting-details-page small rounded-3">
          <div className="list-header fw-semibold text-white rounded-3 p-3 mb-3 d-none d-md-block" style={{ background: "linear-gradient(168.68deg,#0052c9 1.12%,#0a91db)" }}>
            <div className="row gx-3">
              <div className="col col-md-8">Client Details</div>
              <div className="col col-md-4 text-md-end">Make a call</div>
            </div>
          </div>



          {isLoading ? (
            <div
              className=""
              id="lead-list-spinner"
              style={{
                position: "absolute",
                margin: "21px 0px 0px 0px",
                left: "44%",
              }}
            >
              <div
                style={{ marginLeft: "12px", float: "right" }}
                className="lead-list-spinner"
              ></div>{" "}
              Please wait ....
            </div>
          ) :
            allData && allData.leads.length > 0 ?
              allData.leads.map((item, index) => {
                // console.log("hello")
                //  return <Card startDate={startDate} item={item} index={index} accessToken={accessToken} device={device}/>
                return <Card startDate={startDate} item={item} index={index} user={user} />
              })
              :
              <p> Data not found!!</p>
          }

        </div>
        {/* {allData.pagination && !isLoading?
          <nav className='mt-3'>
            <ul className="pagination justify-content-center m-0">
              {allData.pagination.currentPage > limit ?
                <li className="page-item"><button className="page-link" onClick={() => { doPaginate(allData.pagination.currentPage - 1) }} >Previous</button></li>
                : null}
              {pagination}
              {allData.pagination.totalPage > 1 && allData.pagination.currentPage !== allData.pagination.totalPage ?
                <li className="page-item"><button className="page-link" onClick={() => { doPaginate(allData.pagination.currentPage + 1) }}>Next</button></li>
                : null}
            </ul>
          </nav>
          : null} */}
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(PstnListing);
