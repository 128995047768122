import {
    collection,
    doc,
    documentId,
    runTransaction,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    serverTimestamp,
    setDoc,
    updateDoc,
    where,
    orderBy,
  } from "firebase/firestore";
  import React, { useEffect, useState } from "react";

  
  import { auth, database } from "../firebaseConfig";
  import { environment } from "../environment";
import { useSelector } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import notificationSound from "../static/reloadRing.mp3";
  
  
  export const WelcomeScreenPopup = (props) => {
    const [audio, setAudio] = useState(new Audio(notificationSound));
    const [user, loading, error] = useAuthState(auth);
    const [total, setTotal] = useState(0);
    const [userInfo, setUserInfo] = useState({});
    const [presentedDays, setProfileData] = useState([]);
    const queryConstraints = [];
   
    queryConstraints.push(orderBy('timeStamp', 'desc'));
    const q = query(collection(database, environment.REACT_APP_FIREBASE_DB), ...queryConstraints);
    
    const userRoleStore = useSelector((state) => state.userRole);
    const userRole = userRoleStore? userRoleStore:localStorage.getItem("userRole");
    let q_info;
    if(user?.email){
        q_info = query(
        collection(database, environment.REACT_APP_USERS_DB),
        where("email", "==", user?.email)
      );
    }
    
    

    useEffect(()=>{
            if(user?.email){
                onSnapshot(q_info, (querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      const id = doc.id;
                      const data = doc.data();
                      data["id"] = doc.id;
                      //console.log(data)
                      setUserInfo(data);
                    });
                  });
                
              
              
         
            onSnapshot(q, (querySnapshot) => {
                let meetings = [];
                querySnapshot.forEach((doc) => {
                  const id = doc.id;
                  const data = doc.data();
                  data["id"] = doc.id;
                  if(userRole === "CAM"){
                    if(data.attendantEmail === user?.email  && (data.clientJoinedLiveTime || Number(data.CallDuration) > 50)){
                        meetings.push({ ...doc.data(), id: doc.id });
                    }
                  }
    
                  if(userRole === "BDM"){
                    if(data.attendantBdmEmail === user?.email &&  (data.clientJoinedLiveTime || Number(data.CallDuration) > 50)){
                        meetings.push({ ...doc.data(), id: doc.id });
                    }
                  }
                  
                  
                  
                });
                setTotal(meetings.length);
               // setIsloading(false)
              });
            }
              
       
       
        
    },[user])

    //console.log(userInfo)
    let dateNew;
    if(userInfo?.timeStamp){
        const fireBaseTime = new Date(
            userInfo?.timeStamp.seconds * 1000 + userInfo?.timeStamp.nanoseconds / 1000000
          );
    
        dateNew = fireBaseTime.getDate() + ' '+ fireBaseTime.toLocaleString('default', { month: 'long' }) +' '+fireBaseTime.getFullYear();
        
    }
    
    const handleBeginWork = () =>{
        //console.log("In hanlde ring")
        localStorage.setItem("camJoinedLead", null);
        localStorage.setItem("bdmJoinedLead", null);
        localStorage.setItem("checkAudio", new Date());
        audio.play();
        document.getElementById("welcome-screen-pop-up").classList.remove("show");
        document.getElementById("welcome-screen-pop-up").style.display = "none";
    }
    return (
      <div className="join-call-model">
       
        {/* Modal */}
        <div
          className="modal fade"
          id="welcome-screen-pop-up"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
            style={{ maxWidth: "650px" }}
          >
            <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
              <div className="modal-body p-4 p-md-5 text-center">
                <div
                  className="fs-5 fw-semibold mb-4"
                  id="DeivceErrorMessage"
                ></div>
                <div className="fs-5 fw-semibold mb-4">
                   {/* title text here */}
                </div>
                <div className="row">
                  <div className="col-md-12">
                  Notification: You've been a member of this platform since {dateNew} and have attended {total} calls till date. Keep up the good work!
                <br></br>
                <button className="btn btn-info mt-2" onClick={handleBeginWork}>Begin Work</button>

                  </div>
                  
                </div>
                
                
              </div>
            </div>
          </div>
        </div>
  
        {/* Modal */}
      </div>
    );
  };
  