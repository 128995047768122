import React from 'react'
import { connect } from "react-redux"

const SingleParticipant = ({ identity, lastItem }) => {
    const getParticipantName = (identity) => {
        const identityArray = identity.split("_");
        // console.log(identity) 
        //  console.log(identityArray)
         
        //return identity.slice(36, identity.length)
        // if(identityArray.length === 1){
        //     return "Client"
        // } else{
            if(identityArray.length !== 1){
                if(identityArray[1] === "CL"){
                    return identityArray[2]
                } 
                else if(identityArray[1] === "VE"){
                    return identityArray[1] +' : '+ identityArray[2]
                }
                else{
                    return identityArray[1] +' : '+ identityArray[2]
                }
            }
            
           
       // }
        
    }

const getShortName = (identity) => {
    const identityArray = identity.split("_");
        //console.log(identityArray)
    let defaultVal = "";
    if(identityArray[2])
    {
        //var str = identity.slice(36, identity.length);
        var str = identityArray[2];
        var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
        var acronym = matches?.join(''); // JSON
        return acronym ;
    }
    else return defaultVal;
   
}   

    return (
        <>
            <div className="chip-wrapper d-flex align-items-center gap-2 text-white border-bottom border-black">
                {getShortName(identity)?
                <div className='chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary' width="45" height="45">
                    <img src="img_avatar.png" alt="" />
                    <span className='short-name text-uppercase'>{getShortName(identity)}</span>
                </div>
                :null}
                {getParticipantName(identity)?
                <p className='participants_paragraph m-0 text-capitalize'>{getParticipantName(identity)}</p>
                :null }
                {/* {!lastItem && <span className='participants_separator_line'></span>} */}
            </div>
        </>
    )
}


const Participants = ({ participants }) => {
    return (
        <div className='participants_container overflow-auto h-100 p-3 pt-0'>
            {participants?.map((participant, index) => {
                return <SingleParticipant
                    key={participant.identity}
                    identity={participant.identity}
                    lastItem={participant.length === index + 1}
                />
            })}
        </div>
    )
}

const mapStoreStateToProps = (state) => {
    return {
        ...state,
    };
}

export default connect(mapStoreStateToProps)(Participants)