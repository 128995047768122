import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { callAxios, convertReadable, convertReadableTime, convertSecondsToMinutes } from '../utils/utils';
import { environment } from '../environment';
import { setUserInfo } from '../store/actions';
import { connect } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';

export const MarketingDetails = ({setLoggedinUser}) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const [key, setKey] = useState('home');
    const [show, setShow] = useState(false);
    const [showCAM, setShowCAM] = useState(false);
    const handleCloseBDM = () => setShow(false);
    const handleShowBDM = () => setShow(true);

    const handleCloseCAM = () => setShowCAM(false);
    const handleShowCAM = () => setShowCAM(true);

    const [actionData, setActionData] = useState({});
    const [feedbackData, setFeedbackData] = useState([]);
    const [camFeedback, setCamFeedBack] = useState([]);
    const [bdmFeedback, setBdmFeedBack] = useState([]);

    const {state} = useLocation();
    const leadData= state.leadData;
    const participantsArray= state.participantsArray;

    const ppcPramUrl = leadData.ppcurl.split("?")[1];
    
    const urlParams = new URLSearchParams(ppcPramUrl);

    // console.log(leadData)

    const [user, loading, error] = useAuthState(auth);
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
                    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };

          setLoggedinUser(userInfo);
         
        }
      }, [user, loading]);

    useEffect(()=>{
        if(leadData.roomId){
          callAxios(environment.REACT_APP_API_URL_Live +'api/v1/conversation/call-sheela',{roomname:leadData.roomId, "role":"admin"},"post").then((response)=>{
              // console.log(response.data)
              setIsLoading(false);
            //   if(Object.entries(response.data['quries']).length)
            const queries = response.data['quries'].interactions?response.data['quries'].interactions:[response.data['quries']];
              setActionData(queries)
            })
        }
          
          
      },[leadData.roomId])

      const backtoMeetingPage = () =>{
        navigate("/client-queries");
      }

      const getFeedBackData = (roomId)=>{
        //console.log(roomId)
        callAxios(environment.REACT_APP_API_URL_Live +'api/v1/cam-bdm-feedback/get-feedback-by-roomId',{roomId:roomId},"post").then((response)=>{
          setFeedbackData(response.data)
          })
      }

      //console.log(feedbackData)
      useEffect(()=>{
        
    
      if(key === "profile"){
        callAxios(environment.REACT_APP_API_URL_Live +'api/v1/cam-bdm-feedback/get-feedback-by-roomId',{roomId:leadData.roomId},"post").then((response)=>{
            if(response.data.length > 0){
                const camFeedback = [];
                const bdmFeedback= [];
                response.data.forEach((item)=>{
                    //console.log(item)
                    if(item.userType ==="bdm"){
                        bdmFeedback.push(item);
                    }
                    if(item.userType ==="cam"){
                        camFeedback.push(item);
                    }
                })

                setCamFeedBack(camFeedback);
                setBdmFeedBack(bdmFeedback)
            }
           
            })
      }

    },[key])
   // console.log(camFeedback,bdmFeedback)
    let camStarObj = [];
    bdmFeedback.map((item)=>{
        
        if(item.question === "How would you rate the CAM person on this call?"){
            camStarObj.push(item.answer)
        }
        
    })

    let bdmStarObj = [];
    camFeedback.map((item)=>{
        
        if(item.question === "How would you rate the performance of the BDM during this call?"){
            bdmStarObj.push(item.answer)
        }
        
    })
    console.log(camFeedback,bdmFeedback)
    const goLeadPage = (e) =>{
        //console.log(leadData.ppcurl);
        e.stopPropagation();
        const leadUrl = leadData.ppcurl.replace("[","").replace('"','').replace("]");
        window.open("https://"+leadUrl,"_blank")
    }
    return (
        <div className='marketing_details my-3'>
            <div className="container">
            
                <div className="fs-3 fw-semibold text-white text-opacity-75 mb-2"><i className="bi bi-arrow-left pointer" onClick={backtoMeetingPage} ></i> Meeting Details for {leadData.name}</div>
                
                <div className="tabs_card card dark-theme-bg">
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="flex-nowrap border-bottom border-secondary border-opacity-25"
                    >
                        <Tab eventKey="home" title="Overview" className='p-3'>
                            <div className="row g-2 content_desc small">
                                <div className="col-md-4">
                                    <div className="head-label fs-6 fw-semibold text-capitalize mb-1">Client Detail</div>
                                    {leadData.name?
                                    <div className="client-name text-white fw-medium text-capitalize mb-1">{leadData.name}</div>
                                    :null}
                                    {leadData.timeStamp !== null?
                                    <div className="date-time-state text-white text-opacity-75 mb-1">{leadData.displayTime}</div>
                                    :null}
                                      {leadData.callTimeInSeconds !== null?
                                    <div className="date-time-state text-white text-opacity-75 mb-1">Call Duration: {convertSecondsToMinutes(leadData.callTimeInSeconds)}</div>
                                    :null}
                                    <div className="countryy-name text-capitalize text-warning text-opacity-75">Client Location - {leadData.country}</div>
                                </div>
                                {leadData.description.length > 0?
                                <div className="col-md-8">
                                    <div className="head-label fs-6 fw-semibold text-capitalize mb-1">Requirement</div>
                                    <p className="text-light m-0">
                                        {leadData.description}
                                    </p>
                                </div>
                                :null}
                            </div>
                        </Tab>
                        <Tab eventKey="profile" title="More Details" className='p-3'>
                            <div className="row g-2 content_desc small">
                                <div className="col-lg-4 fs-6">
                                    <div className="d-flex flex-wrap gap-1 mb-2">
                                        <div className="head-label fw-semibold text-capitalize">BDM Feedback -</div>
                                       {bdmFeedback.length > 0?  <div className="d-flex gap-1">
                                           
                                            <span className='text-light'>Yes</span>
                                            <span className='review_wrapper d-flex mx-2'>
                                            { [...Array(5)].map((item,index)=>{
                                                // console.log(item,index)
                                                    if(index <=parseInt(bdmStarObj[0]) -1){
                                                        return <i className="bi bi-star-fill text-warning"></i>;
                                                    } else{
                                                        return <i className="bi bi-star"></i>;
                                                    }
                                                 
                                               })}
                                            </span>
                                            <span className='pointer seen-status'><i className="bi bi-eye-fill" title='View feedback details' onClick={handleShowBDM}></i></span>
                                            
                                        </div>:<span className='text-light'>No</span>}
                                    </div>
                                    <div className="d-flex flex-wrap gap-1 mb-1">
                                        <div className="head-label fw-semibold text-capitalize">CAM Feedback - </div>
                                        {camFeedback.length > 0?  <div className="d-flex gap-1">
                                           
                                           <span className='text-light'>Yes</span>
                                           <span className='review_wrapper d-flex mx-2'>
                                               { [...Array(5)].map((item,index)=>{
                                                // console.log(item,index)
                                                    if(index <=parseInt(camStarObj[0]) -1){
                                                        return <i className="bi bi-star-fill text-warning"></i>;
                                                    } else{
                                                        return <i className="bi bi-star"></i>;
                                                    }
                                                 
                                               })}
                                           </span>
                                           <span className='pointer seen-status'><i className="bi bi-eye-fill" title='View feedback details' onClick={handleShowCAM}></i></span>
                                           
                                       </div>:<span className='text-light'>No</span>}
                                    </div>
                                </div>
                                
                                {urlParams.get("source") ? (
                                    <div className="col-sm-6 col-lg-4">
                                         {urlParams.get("adgroup") ? (
                                        <div className="client-name text-white text-opacity-75 text-capitalize mb-1">Lead Ad Group: <span className='text-white fw-medium'>{urlParams.get("adgroup").toUpperCase()}</span></div>
                                        ):null }

                                         {urlParams.get("medium") ? (
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Campaign Type: <span className='text-white fw-medium'>{urlParams.get("medium").toUpperCase()}</span></div>
                                         ):null}

                                         {urlParams.get("source") ? (
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Lead Source: <span className='text-white fw-medium'>{urlParams.get("source").toUpperCase()}</span></div>
                                         )
                                        : null }
                                        {urlParams.get("campaign") ? (
                                        <div className="date-time-state text-white text-opacity-75 mb-1">Lead Campaign: <span className='text-white fw-medium'>{urlParams.get("campaign").toUpperCase()}</span></div>
                                        ):null}

                                        <div className="date-time-state text-white text-opacity-75 mb-1">Lead Page: <button className='btn btn-sm btn-outline-success lh-sm px-4 pointer' onClick={goLeadPage}>Visit</button></div>
                                    </div>
                                    ):null
                                    }
                                <div className="col-sm-6 col-lg-4">
                                    <div className="head-label fs-6 fw-semibold text-capitalize mb-2">Total number of people on call</div>
                                    {leadData.attendantName?
                                    <div className="d-flex flex-wrap gap-2 cam-name mb-1">
                                        <div className="text-light text-capitalize text-end">{leadData.attendantName} - </div>
                                        <div className="department text-uppercase text-start w-25">CAM</div>
                                    </div>
                                    :null}

                                    {leadData.attendantBdmName?
                                    <div className="d-flex flex-wrap gap-2 cam-name mb-1">
                                        <div className="text-light text-capitalize text-end">{leadData.attendantBdmName} - </div>
                                        <div className="department text-uppercase text-start w-25">BDM</div>
                                    </div>
                                    :null}
                                     {participantsArray.length > 0?
                                            participantsArray.map((participant)=>{
                                    return <div className="d-flex flex-wrap gap-2 cam-name mb-1">
                                        <div className="text-light text-capitalize text-end">{participant.identity.split("_")[2]} - </div>
                                        <div className="department text-uppercase text-start">{participant.identity.split("_")[1]}</div>
                                    </div>
                                            })
                                    :null}
                                    {leadData.name?
                                    <div className="d-flex flex-wrap gap-2 cam-name mb-1">
                                        <div className="text-light text-capitalize text-end">{leadData.name} - </div>
                                        <div className="department text-uppercase text-start w-25">Client</div>
                                    </div>
                                    :null}
                                    {/* <div className="d-flex justify-content-lg-end gap-2 mb-1">
                                        <div className="text-light text-capitalize text-end">Penelope - </div>
                                        <div className="department text-uppercase text-start w-25">MAD</div>
                                    </div>
                                    <div className="d-flex justify-content-lg-end gap-2 mb-1">
                                        <div className="text-light text-capitalize text-end">Samantha - </div>
                                        <div className="department text-uppercase text-start w-25">MAD</div>
                                    </div> */}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                    {/* CAM, BDM Feedback Popup */}
                    <Modal show={show} onHide={handleCloseBDM} backdrop="static" keyboard={false} size="md" centered contentClassName="small mx-sm-3" dialogClassName='px-sm-4'>
                        <Modal.Header closeButton closeVariant="black opacity-100" className='fs-18 border-0 pb-0 text-black'>
                            <Modal.Title className="fs-4 fw-bold">BDM Feedback</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='pt-2'>
                            <div className='review_wrapper fs-6 text-muted d-flex mb-2'>
                            { [...Array(5)].map((item,index)=>{
                                                // console.log(item,index)
                                if(index <=parseInt(bdmStarObj[0]) -1){
                                    return <i className="bi bi-star-fill text-warning"></i>;
                                } else{
                                    return <i className="bi bi-star"></i>;
                                }
                                
                            })}
                            </div>
                            {bdmFeedback && bdmFeedback.length > 0 ?
                                bdmFeedback.map((item,index)=>{

                               
                            return <div className='content-block mb-2 pb-1'>
                                <div className='feed_ques text-black text-opacity-75 lh-sm'>{item.question}</div>
                                <div className='feed_ans fw-bold text-black'>{item.answer}</div>
                            </div>;

                            })
                            :null}
                            
                        </Modal.Body>
                    </Modal>

                    <Modal show={showCAM} onHide={handleCloseCAM} backdrop="static" keyboard={false} size="md" centered contentClassName="small mx-sm-3" dialogClassName='px-sm-4'>
                        <Modal.Header closeButton closeVariant="black opacity-100" className='fs-18 border-0 pb-0 text-black'>
                            <Modal.Title className="fs-4 fw-bold">CAM Feedback</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className='pt-2'>
                            <div className='review_wrapper fs-6 text-muted d-flex mb-2'>
                               
                            { [...Array(5)].map((item,index)=>{
                                // console.log(item,index)
                                    if(index <=parseInt(camStarObj[0]) -1){
                                        return <i className="bi bi-star-fill text-warning"></i>;
                                    } else{
                                        return <i className="bi bi-star"></i>;
                                    }
                                    
                                })}
                            </div>
                            {camFeedback && camFeedback.length > 0 ?
                                camFeedback.map((item,index)=>{

                               
                            return <div className='content-block mb-2 pb-1'>
                                <div className='feed_ques text-black text-opacity-75 lh-sm'>{item.question}</div>
                                <div className='feed_ans fw-bold text-black'>{item.answer}</div>
                            </div>;

                            })
                            :null}
                        </Modal.Body>
                    </Modal>
                </div>

                {/* Queries and replies section */} 
                {isLoading ? (
                            <div
                              className=""
                              id="lead-list-spinner"
                              style={{
                                position: "absolute",
                                margin: "21px 0px 0px 0px",
                                left: "44%",
                              }}
                            >
                              <div
                                style={{ marginLeft: "12px", float: "right" }}
                                className="lead-list-spinner"
                              ></div>{" "}
                              Please wait ....
                            </div>
                          ):actionData.length > 0?
                <div className='queries_replies_sec mt-3'>
               
                    <div className="fs-5 fw-medium text-white text-opacity-75 mb-2">Queries and Reply</div>
                    
                    <div className="dark-theme-bg card p-3">
                        {
                        
                        actionData.length > 0 && actionData.map((item, index) => {
                            // console.log(item['client-query'])
                            return (<div className='queries_replies_wrapper' key={index}>
                                <div className='client_query mb-2'>
                                    <div className="text-danger text-capitalize">Query</div>
                                    <div className="text-white text-opacity-75">{item['client-query']}</div>
                                </div>
                                <div className='our_reply'>
                                    <div className="text-success text-capitalize">Reply</div>
                                    <div className="text-white text-opacity-75">{item['our-reply']}</div>
                                </div>
                            </div>);
                            }
                             )}
                    </div>
                </div>
                :<div className='queries_replies_sec mt-3 text-center'><p>No queries found!</p></div>}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(MarketingDetails);
