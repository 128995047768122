import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../../firebaseConfig";


// import tostify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callAxios, callCrmApi } from "../../utils/utils";
import { environment } from "../../environment";
import { addMoreParticipant, makeOutgoingCall } from "../../utils/pstn";
import $ from "jquery";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


export const AfterCallFeedback = ({showFeedback, callInfo,setFeedbackForm}) => {
    
    // console.log("FeedbackForm:::",callInfo)
    const [show, setShow] = useState(showFeedback);
  
   
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(false);
     const [isDisabled, setDisabled] = useState(true)
    const FeedbackSubmit = (e) =>{
        e.preventDefault();
        setIsLoading(true)
       
        callAxios(environment.REACT_APP_API_URL_Live+"api/v1/crm/save-after-call-feedback",{ lead_opp_id:callInfo.lead_id, question:"CAM Feedback", answer:description,call_sid:callInfo.call_sid},"post").then(async(response)=>{
          const systemuserid = response.data.systemuserid
            if(response.status === 200){
                setShow(false)
                setFeedbackForm(false)
                toast.success("CAM feedback submit successfully!!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });

                  const data = JSON.stringify({
                    operation:"createannotations",
                    leadid: callInfo.lead_id,
                    subject:"CAM Feedback [voice]",
                    notetext:description,
                    MSCRMCallerID:systemuserid
                  });
            
                  //  console.log(data)
             
                await callCrmApi(data)
                
            } else{
                toast.error("network problem occured!!", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                
            }
        })
    }
    useEffect(()=>{
   if(description && description.length >0){
    $("#feedback-pstn").prop('disabled',false)
   } else{
    $("#feedback-pstn").prop('disabled',true)
   }
    },[description])
    

return (
    <>
      
     
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      
        <Modal.Body>
        
              <form
                id="afterCallFeedback"
                className="d-flex flex-column overflow-hidden"
                method="post"
                onSubmit={FeedbackSubmit}
              >
                <div class="modal-header bg-primary text-white px-lg-4 py-2">
                  <div>
                  
                
                  </div>
                    {isLoading?
                    <div
                      class="meeting-pointer-spinner"
                      style={{ marginRight: "10px" }}
                    ></div>
                    
                  : null}
                 
                  <button
                    type="submit"
                    id="feedback-pstn"
                    className="btn btn-success border-white"
                    style={{ minWidth: "80px" }}
                    disabled={isDisabled}
                  >
                    Submit Feedback
                  </button>
                </div>
                <div className="modal-body px-lg-4 py-4 small">
                <div className="mb-3">
                    <label for="noting" className="form-label text-muted">
                    Add your feedback for this call.
                    </label>
                  </div>
                <div className="mb-3" id="call-description">
                   
                    <textarea
                      className="form-control"
                      rows="4"
                      placeholder=""
                      required
                      onChange={(e) => {
                        setDescription(e.target.value);
                        setDisabled(false)
                      }}
                    ></textarea>
                  </div>
                 
                </div>
              </form>
              
              {/* <Button variant="secondary" className="" onClick={handleClose} id="beforeCallingCancel">
            Close
          </Button> */}
        </Modal.Body>

        
      </Modal>
    </>
  );
};
