import React, { useState } from 'react'
import VideoButtons from './VideoButtons'
import Videos from './Videos'
import { connectToRoom } from '../../utils/twilioUtils'
import { useEffect } from 'react'

 const VideoSection = (props) => {
  const { comingFrom, isCam, camera, isMicMuted, isRejoin } = props
  
  const [room, setRoom] = useState(null);

 

  
  
  useEffect(()=>{
      
      
         connectToRoom(props.twilioAccessToken, props.roomId, setRoom, comingFrom, camera, isMicMuted);
      
     
    
  }, [isRejoin])
   

  return (
    <>
      <VideoButtons room={room} comingFrom={comingFrom} isCam={isCam} setFormIdAction={null} camera={camera} isMicMuted={isMicMuted}/>
      <div className='video_section_container'>
       
        <Videos room={room} setRoom={setRoom} isCam={isCam} comingFrom={comingFrom} camera={camera} isMicMuted={isMicMuted}/>
      </div>
    </>

  )
  
}

export default VideoSection