import React from 'react'
import ParticipantsLabel from './ParticipantsLabel'
import Participants from './Participants'

const ParticipantsSection = () => {
  return (
    <div className='participants_section_container d-flex flex-column justify-content-between h-100'>
        <ParticipantsLabel />
        <Participants />
    </div>
  )
}

export default ParticipantsSection