import { Rating } from 'react-simple-star-rating';
const ResultCard = ({cardType})=>{

    return (
        <div class="info-cards int-result small rounded pointer overflow-auto mt-2">
        <div className="info-header fs-6 text-white text-capitalize text-end px-3 py-2">{cardType}</div>
        <div className="info-body p-3">
            <div class="row g-3 mb-3">
                <div class="col-sm-6">
                    <div className="cand-name text-primary fs-6 fw-medium text-capitalize">Vasheem Ahmad</div>
                    <div className="cand-role text-muted text-capitalize">Full Stack Developer</div>
                    <div className="cand-company text-black text-capitalize">Mobile Enterprises Ltd</div>
                </div>
                <div class="col-sm-6 text-xl-end">
                    <div className="cand-date text-muted text-capitalize">Thu Mar 02 2023 13:31:16</div>
                    <div className="cand-status fw-medium text-danger text-capitalize">Notice Period</div>
                </div>
            </div>

            <div class="row g-3">
                <div class="col-sm-6 col-xl-3">
                    <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                        <div className="label-title text-black">Current CTC:</div>
                        <div className="label-value text-danger">8 Lakh</div>
                    </div>
                    <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                        <div className="label-title text-black">Interview Medium:</div>
                        <div className="label-value text-danger">NM-Interview</div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize mb-1">
                        <div className="label-title text-black">Expected CTC:</div>
                        <div className="label-value text-danger">12 Lakh</div>
                    </div>
                    <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                        <div className="label-title text-black">Interview Date:</div>
                        <div className="label-value text-danger">15 Feb 2024</div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div className="info-box fw-medium text-capitalize d-flex flex-wrap align-items-center column-gap-2 mb-1">
                        <div className="label-title text-black">Com Skills:</div>
                        <Rating size={18} style={{ marginTop: '-6px' }}
                            initialValue={3.5}
                            allowFraction
                            // showTooltip
                            readonly
                            fillColor={"#E43545"}
                        />
                    </div>
                    <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                        <div className="label-title text-black">Interview Time:</div>
                        <div className="label-value text-danger">06:15 PM</div>
                    </div>
                </div>
                <div class="col-sm-6 col-xl-3">
                    <div className="info-box d-flex flex-wrap column-gap-2 fw-medium text-capitalize">
                        <div className="label-title text-black">Interview Scheduled By:</div>
                        <div className="label-value text-danger">Rehmat Kumar</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ResultCard;