import $ from "jquery";
import * as Twilio from '@twilio/voice-sdk';
import { ToastContainer, toast } from "react-toastify";
import { callAxios } from "./utils";
import { environment } from "../environment";


    // var speakerDevices = document.getElementById("speaker-devices");
    // var ringtoneDevices = document.getElementById("ringtone-devices");
    // var outputVolumeBar = document.getElementById("output-volume");
    // var inputVolumeBar = document.getElementById("input-volume");
    // var volumeIndicators = document.getElementById("volume-indicators");
    // var callButton = document.getElementById("button-call");
    // var outgoingCallHangupButton = document.getElementById("button-hangup-outgoing");
    // var callControlsDiv = document.getElementById("call-controls");
    // var audioSelectionDiv = document.getElementById("output-selection");
    // var getAudioDevicesButton = document.getElementById("get-devices");
    var logDiv = document.getElementById("log");
    // var incomingCallDiv = document.getElementById("incoming-call");
    // var incomingCallHangupButton = document.getElementById(
    //   "button-hangup-incoming"
    // );
    // var incomingCallAcceptButton = document.getElementById(
    //   "button-accept-incoming"
    // );
    // var incomingCallRejectButton = document.getElementById(
    //   "button-reject-incoming"
    // );
    // var phoneNumberInput = document.getElementById("phone-number");
    // var incomingPhoneNumberEl = document.getElementById("incoming-number");
   
  let device;
  let callCopy;
    // Event Listeners
  
    // callButton.onclick = (e) => {
    //   e.preventDefault();

    //    makeOutgoingCall();
    // };
    // getAudioDevicesButton.onclick = getAudioDevices;
    // speakerDevices.addEventListener("change", updateOutputDevice);
    // ringtoneDevices.addEventListener("change", updateRingtoneDevice);
    
  
 
  
    // SETUP STEP 3:
    // Instantiate a new Twilio.Device
   export const intitializeDevice = (accessToken) => {
        //  console.log("i am initializing....")
    //   logDiv.classList.remove("hide");
    $("#log").removeClass("hide") 
      log("Initializing device");
      
      device = new Twilio.Device(accessToken, {
        logLevel:false,
        // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
        // providing better audio quality in restrained network conditions.
        codecPreferences: ["opus", "pcmu"],
      });
  
      addDeviceListeners(device);
  
      // Device must be registered in order to receive incoming calls
      device.register();
    }
  
   
    // SETUP STEP 4:
    // Listen for Twilio.Device states
    const addDeviceListeners = (device) => {
      device.on("registered", function () {
        log("Device Ready to make calls!");
        // callControlsDiv.classList.remove("hide");
      });
  
      device.on("error", function (error) {
        console.log(error)
        console.log("eCODE::::",error.code)
       // log("Device got some Errors: "+error);
        if(error.code === 20104){
          log("Token expired or about to expire. Refreshing token...");
          // Call a function to refresh the token
          refreshAccessToken();
        }
        else {
          // Handle other errors
          log('Device Error: ' + error.message);
      }
      });

     
  
    //   device.on("incoming", handleIncomingCall);
  
    //   device.audio.on("deviceChange", updateAllAudioDevices.bind(device));
  
      // Show audio selection UI if it is supported by the browser.
      if (device.audio.isOutputSelectionSupported) {
        // audioSelectionDiv.classList.remove("hide");
      }
    }

    function refreshAccessToken() {
      callAxios(environment.REACT_APP_API_URL_Live + "api/v1/crm/get-voice-t",{}, "post").then((token) => {
       
        device.updateToken(token.data);

      })
     
  }
  
    // MAKE AN OUTGOING CALL
   export const addMoreParticipant = (clientName,Phone,cid,setShow,type,countryCode,callerEmail) =>{
    // console.log(clientName,Phone,cid,setShow,type,countryCode)
    toast.success("Please keep patience while we are adding "+Phone+" with "+clientName, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    
    $("#phoneNumber").val("+91")
    callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio-voice/participant-join-handler",{ to:Phone, callSid:cid, type:type,countryCode:countryCode,clientName:clientName,callerEmail:callerEmail},"post").then((resp)=>{
      if(resp.data.statusCode === 200){
        toast.success("Please patience we are initiating call", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
      if(resp.data.statusCode === 403){
        toast.error(resp.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    })
   }

   export const makeOutgoingCall = async(id,identity,countryCode,clientNAme,callerEmail,beforeCallTableId,leadDesc,formattedDate,topic) => {
    toast.success("Please keep patience we are trying to connect..", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    // log(phoneNumberInput,id)
     
     var params = {
       
        id: id,
        identity:identity,
        callTo:"client",
        callerEmail:callerEmail,
        name:clientNAme,
        type:"client",
        countryCode:countryCode,
        statusCallback: 'https://dev.virtualemployee.com/node/conference-events',
        statusCallbackEvent: ['initiated', 'answered', 'ringing', 'completed','failed'], // Specify the desired events
        statusCallbackMethod: 'GET',  // Set the HTTP method (POST recommended for data)
      };
    
    // console.log(params)
       
      // log(params.id,params.countryCode,params.identity)
      if (device) {
        log(`Attempting to call please wait a moment ...`);
  
        // Twilio.Device.connect() returns a Call object
        device.connect({ params })
        .then(call => {
          // console.log("call sid------------------------->",call, call.parameters.CallSid)
          // 
         
         
       
            
            call.on('accept', (callObject)=>{
              // console.log("===========================>",callObject.parameters.CallSid)
              if(callObject.parameters.CallSid)
              {
                
                $("#button-call-"+id).attr('data-whoDialed',"client")
                $("#button-call-"+id).attr('data-cid',callObject.parameters.CallSid)
                $("#button-call-"+id).addClass("d-none")
              callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-call-sid",{ id:beforeCallTableId, cid:callObject.parameters.CallSid},"post")
              // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/voice-recording",{cid:callObject.parameters.CallSid},"post")
              }
              //  log("Call initiated by "+ identity);
              //   callButton.disabled = false;
              $('.button-call').prop('disabled', true);
              $("#button-hangup-outgoing-"+id).removeClass("hide")
             
              // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call initiated",caller:callerEmail,clientName:clientNAme},"post")
            })
        
    
          call.on('ringing', (callObject) => {
            
            $("#in-progressPop").show()
          // log("Ringing please wait a moment")
          $("#button-call-"+id).addClass("d-none")
          $('.button-call').prop('disabled', true);
           if(callObject.parameters.CallSid)
            {
             
             
               callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-call-sid",{ id:beforeCallTableId, cid:callObject.parameters.CallSid},"post")
            }
          //  callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call rining",caller:callerEmail,clientName:clientNAme},"post")
           
          });

          call.on('disconnect', (payload) => {
            if(payload.parameters.CallSid)
            {
               callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-call-sid",{ id:beforeCallTableId, cid:payload.parameters.CallSid},"post")
            }
            // log('Call Disconnected !',);
            $("#button-call-"+id).removeClass("d-none")
            $('.button-call').prop('disabled', false);
            $("#button-hangup-outgoing-"+id).addClass("hide")
            $("#button-call-"+id).attr('data-cid','')
            $("#button-call-"+id).attr('data-whoDialed',"")
            // $("#button-call-"+id).addClass("d-none")
            // $("#dial-pad-"+id).addClass("d-none")
            call.disconnect();
            // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
          });

          

          call.on('cancel', () => {
            // log('Call cancelled',);
            $('.button-call').prop('disabled', false);
            $("#button-hangup-outgoing-"+id).addClass("hide")
            $("#button-call-"+id).addClass("d-none")
            // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
          });

        

                

          $("#button-hangup-outgoing-"+id).click(function() {
            localStorage.setItem("conferenceObject",[])
            localStorage.setItem("completedConferenceParticipants",[])
            call.disconnect();
            // $("#dial-pad-"+id).addClass("d-none")
            // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
          });

          $("#button-hangup-outgoing-ask-"+id).click(function() {
           
            call.disconnect();
            // $("#dial-pad-"+id).addClass("d-none")
            // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
          });
    
          // ... other event handlers
        })
        .catch(error => {
          console.error('Error creating call:', error);
        });
       
  
      } else {
        log("Unable to make call.");
      }
    }
    
    export const makeOutgoingCallTOVEFirst = async(id,phone,identity,email,fullname,leadDesc,formattedDate,topic,leadOpportunityId) => {
      toast.success("Please keep patience we are trying to connect..", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      // log(phoneNumberInput,id)
       
       var params = {
        id: id,
          To: phone,
          type: "VEMemberFirst",
          callTo:"VEMember",
          name:"ve-member",
          countryCode:"+91",
          callerEmail:email,
          statusCallback: 'https://dev.virtualemployee.com/node/conference-events',
          statusCallbackEvent: ['initiated', 'answered', 'ringing', 'completed', 'failed'], // Specify the desired events
          statusCallbackMethod: 'GET',  // Set the HTTP method (POST recommended for data)
        };
      
      // console.log(params)
         
        // log(params.id,params.countryCode,params.identity)
        if (device) {
          log(`Attempting to call please wait a moment ...`);
    
          // Twilio.Device.connect() returns a Call object
          device.connect({ params })
          .then(call => {
            // console.log("call sid------------------------->",call, call.parameters.CallSid)
            // 
           
            call.on('_status', () => {
              // console.log('The call was rejected.');
             });
              
              call.on('accept', (callObject)=>{
                // console.log("===========================>",callObject.parameters.CallSid)
                if(callObject.parameters.CallSid)
                {
                  
                  $("#button-call-"+id).attr('data-whoDialed',"VEMember")
                  $("#button-call-"+id).attr('data-cid',callObject.parameters.CallSid)
                  // callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/client-call-modal",{ cid:callObject.parameters.CallSid,leadId:id},"post")
                  callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/before-call-question-answer",{ leadId:leadOpportunityId, callerEmail:email,call_sid:callObject.parameters.CallSid, clientName:fullname,question:'What is the primary purpose of your call to the client today?',answer:'',description:'',callerName:identity,caller_type:localStorage.getItem("userRole"),lead_description:leadDesc,formatted_date:formattedDate,topic:topic},"post")
                // callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-call-sid",{ id:beforeCallTableId, cid:callObject.parameters.CallSid},"post")
                // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/voice-recording",{cid:callObject.parameters.CallSid},"post")
                }
                //  log("Call initiated by "+ identity);
                //   callButton.disabled = false;
                $("#button-call-"+id).addClass("d-none")
                $('.button-call').prop('disabled', true);
                $("#button-hangup-outgoing-"+id).removeClass("hide")
              
                // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call initiated",caller:callerEmail,clientName:clientNAme},"post")
              })
          
      
            call.on('ringing', (callObject) => {
              
              $("#in-progressPop").show()
            //  log("Ringing please wait a moment")
            $("#button-call-"+id).addClass("d-none")
            $('.button-call').prop('disabled', true);
             
            });
  
            call.on('disconnect', (payload) => {
              if(payload.parameters.CallSid)
              {
                //  callAxios(environment.REACT_APP_API_URL_Live+"api/v1/twilio/update-call-sid",{ id:beforeCallTableId, cid:payload.parameters.CallSid},"post")
              }
              // log('Call Disconnected !',);
              $("#button-call-"+id).removeClass("d-none")
              $('.button-call').prop('disabled', false);
              $("#button-hangup-outgoing-"+id).addClass("hide")
              $("#button-call-"+id).attr('data-cid','')
              $("#button-call-"+id).attr('data-whoDialed','')
             
              // $("#button-call-"+id).addClass("d-none")
              call.disconnect();
              // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
            });
  
            
  
            call.on('cancel', () => {
              // log('Call cancelled',);
              $('.button-call').prop('disabled', false);
              $("#button-hangup-outgoing-"+id).addClass("hide")
              $("#button-call-"+id).addClass("d-none")
              // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
            });
  
                  
  
            $("#button-hangup-outgoing-"+id).click(function() {
              localStorage.setItem("conferenceObject",[])
    localStorage.setItem("completedConferenceParticipants",[])
              call.disconnect();
              // $("#dial-pad-"+id).addClass("d-none")
              // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
            });

            $("#button-hangup-outgoing-ask-"+id).click(function() {
              // console.log("disconnect click from dialer")
              call.disconnect();
              // $("#dial-pad-"+id).addClass("d-none")
              // callAxios(environment.REACT_APP_API_URL_Live + "api/v1/twilio/save-call-logs",{formId:id,identity:identity,type:"call cancelled",caller:callerEmail,clientName:clientNAme},"post")
            });
      
            // ... other event handlers
          })
          .catch(error => {
            console.error('Error creating call:', error);
          });
         
    
        } else {
          log("Unable to make call.");
        }
      }
      
    export const handleDisconnect = (id) =>{
      // console.log(callCopy)
        // log("Hanging up ...");
        // $("#button-hangup-outgoing-"+id).addClass("hide")
        // $('.button-call').prop('disabled', false);
        // callCopy.disconnect();
    }
   

    const updateUIAcceptedOutgoingCall = async(id) =>{
      log("Call in progress ...");
    //   callButton.disabled = true;
      $('.button-call').prop('disabled', true);
      $("#button-hangup-outgoing-"+id).removeClass("hide")
    //   outgoingCallHangupButton.classList.remove("hide");
    //   volumeIndicators.classList.remove("hide");
    //   bindVolumeIndicators(call);
    }
  
    // const updateUIDisconnectedOutgoingCall = (id) =>{
    //   log("Call disconnected.");
    // //   callButton.disabled = false;
    // $('.button-call').prop('disabled', false);
    // $("#button-hangup-outgoing-"+id).addClass("hide")
    // //   $("#button-hangup-outgoing").addClass("hide")
    // //   volumeIndicators.classList.add("hide");
    // }
  
//     // HANDLE INCOMING CALL
  
//     const handleIncomingCall = (call) =>{
//       log(`Incoming call from ${call.parameters.From}`);
  
//       //show incoming call div and incoming phone number
//       incomingCallDiv.classList.remove("hide");
//       incomingPhoneNumberEl.innerHTML = call.parameters.From;
  
//       //add event listeners for Accept, Reject, and Hangup buttons
//       incomingCallAcceptButton.onclick = () => {
//         acceptIncomingCall(call);
//       };
  
//       incomingCallRejectButton.onclick = () => {
//         rejectIncomingCall(call);
//       };
  
//       incomingCallHangupButton.onclick = () => {
//         hangupIncomingCall(call);
//       };
  
//       // add event listener to call object
//       call.on("cancel", handleDisconnectedIncomingCall);
//       call.on("disconnect", handleDisconnectedIncomingCall);
//       call.on("reject", handleDisconnectedIncomingCall);
//     }
  
//     // ACCEPT INCOMING CALL
  
//     const acceptIncomingCall = (call) => {
//       call.accept();
  
//       //update UI
//       log("Accepted incoming call.");
//       incomingCallAcceptButton.classList.add("hide");
//       incomingCallRejectButton.classList.add("hide");
//       incomingCallHangupButton.classList.remove("hide");
//     }
  
//     // REJECT INCOMING CALL
  
//     const rejectIncomingCall = (call) => {
//       call.reject();
//       log("Rejected incoming call");
//       resetIncomingCallUI();
//     }
  
//     // HANG UP INCOMING CALL
  
//     const hangupIncomingCall = (call) => {
//       call.disconnect();
//       log("Hanging up incoming call");
//       resetIncomingCallUI();
//     }
  
//     // HANDLE CANCELLED INCOMING CALL
  
//     const handleDisconnectedIncomingCall = () => {
//       log("Incoming call ended.");
//       resetIncomingCallUI();
//     }
  
//     // MISC USER INTERFACE
  
//     // Activity log
    const log = (message) =>{
      // console.log("message printing...",message);
      $("#log").append(`<div class="log-entry mb-2">&gt;&nbsp; ${message} </div>`)
      // logDiv.innerHTML += `<p class="log-entry">&gt;&nbsp; ${message} </p>`;
      // $("#log").scrollTop = $("#log").scrollHeight;
      $("#log").scrollTop( $("#log").prop("scrollHeight"));
    }

    export const log_ = (message) =>{
      $("#log").append(`<div class="log-entry mb-2">&gt;&nbsp; ${message} </div>`)
      $("#log").scrollTop( $("#log").prop("scrollHeight"));
    }
  
//     const setClientNameUI = (clientName) => {
//       var div = document.getElementById("client-name");
//       div.innerHTML = `Your client name: <strong>${clientName}</strong>`;
//     }
  
//     const resetIncomingCallUI = () =>{
//       incomingPhoneNumberEl.innerHTML = "";
//       incomingCallAcceptButton.classList.remove("hide");
//       incomingCallRejectButton.classList.remove("hide");
//       incomingCallHangupButton.classList.add("hide");
//       incomingCallDiv.classList.add("hide");
//     }
  
//     // AUDIO CONTROLS
  
//     const getAudioDevices = async() =>{
//       await navigator.mediaDevices.getUserMedia({ audio: true });
//       updateAllAudioDevices.bind(device);
//     }
  
//     const updateAllAudioDevices = () =>{
//       if (device) {
//         updateDevices(speakerDevices, device.audio.speakerDevices.get());
//         updateDevices(ringtoneDevices, device.audio.ringtoneDevices.get());
//       }
//     }
  
//     const updateOutputDevice = () => {
//       const selectedDevices = Array.from(speakerDevices.children)
//         .filter((node) => node.selected)
//         .map((node) => node.getAttribute("data-id"));
  
//       device.audio.speakerDevices.set(selectedDevices);
//     }
  
//     const updateRingtoneDevice = () => {
//       const selectedDevices = Array.from(ringtoneDevices.children)
//         .filter((node) => node.selected)
//         .map((node) => node.getAttribute("data-id"));
  
//       device.audio.ringtoneDevices.set(selectedDevices);
//     }
  
//     const bindVolumeIndicators = (call) =>{
//       call.on("volume", function (inputVolume, outputVolume) {
//         var inputColor = "red";
//         if (inputVolume < 0.5) {
//           inputColor = "green";
//         } else if (inputVolume < 0.75) {
//           inputColor = "yellow";
//         }
  
//         inputVolumeBar.style.width = Math.floor(inputVolume * 300) + "px";
//         inputVolumeBar.style.background = inputColor;
  
//         var outputColor = "red";
//         if (outputVolume < 0.5) {
//           outputColor = "green";
//         } else if (outputVolume < 0.75) {
//           outputColor = "yellow";
//         }
  
//         outputVolumeBar.style.width = Math.floor(outputVolume * 300) + "px";
//         outputVolumeBar.style.background = outputColor;
//       });
//     }
  
//     // Update the available ringtone and speaker devices
//     const updateDevices = (selectEl, selectedDevices) => {
//       selectEl.innerHTML = "";
  
//       device.audio.availableOutputDevices.forEach(function (device, id) {
//         var isActive = selectedDevices.size === 0 && id === "default";
//         selectedDevices.forEach(function (device) {
//           if (device.deviceId === id) {
//             isActive = true;
//           }
//         });
  
//         var option = document.createElement("option");
//         option.label = device.label;
//         option.setAttribute("data-id", id);
//         if (isActive) {
//           option.setAttribute("selected", "selected");
//         }
//         selectEl.appendChild(option);
//       });
//     }

  