import React, { useEffect, useState } from "react";
import ChatSection from "./ChatSection/ChatSection";
import LeadDetailsSection from "./LeadSection/LeadDetailsSection";
import ParticipantsSection from "./ParticipantsSection/ParticipantsSection";
import "./RoomPage.css";
import VideoSection from "./VideoSection/VideoSection";
import { connect } from "react-redux";
import { setTwilioAccessToken } from "../store/actions";
import { getTokenFromTwilio } from "../utils/twilioUtils";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Overlay from "./Overlay";
import BackgroundSetting from "./BgSettingSection/BackgroundSetting";
import { CallOnMobile } from "./VideoSection/CallOnMobile";
import notificationSound from "../static/callTune.mp3";
import RejoinRoom from "./RejoinRoom";
import { collection, doc, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { database } from "../firebaseConfig";
import { environment } from "../environment";
import VideoSectionPrivate from "./VideoSection/VideoSectionPrivate";
import { callAxios } from "../utils/utils";
import { update_user_status } from "../utils/twilioConversation";



function Call(props) {
  const { identity, roomId, setTwilioAccessTokenAction, showOverlay, formId } = props;
  //console.log(props)
  let navigate = useNavigate();
  const [audio, setAudio] = useState(new Audio(notificationSound));
  const { state } = useLocation();
  const [allCalls, setAllDate] = useState([]);
  const [callStatus, setCallStatus] = useState(null);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [isCallPickedByUser, setIsCallPickedByUser] = useState(false);
  // const { camera, isMicMuted, isRejoin, callFromName, callToName,  comingFrom } = state;

  const [searchParams, setSearchParams] = useSearchParams();
  const state_ = searchParams.get("state");

  const objStr = atob(state_)

  const state_obj = JSON.parse(objStr)
   //console.log(state_obj)

  const callFromName = state_obj.callFromName;
  const callFromEmail = state_obj.callFromEmail;
  const isMicMuted = state_obj.isMicMuted;
  const callType = state_obj.callType;
  const isRejoin = state_obj.isRejoin;
  const callToName = state_obj.callToName;
  const comingFrom = "private";
  const camera= state_obj.camera;
  const ROOMID = state_obj.roomId;
  const docId = state_obj.docId;
  const fromShortName = callFromName.split(" ").map((shortName) => {
    return shortName[0];
  });

  const isTryingToJoining = state_obj.isTryingToJoining

  const loggedInUser = callFromEmail?callFromEmail  : localStorage.getItem("userEmail");
  useEffect(() => {
    // if (!identity || !roomId) {
    //   navigate("/");
    // } else {
      if(callType === "private"){
        getTokenFromTwilio(
          setTwilioAccessTokenAction,
          "_Private_" + callFromName
        );
      }
      else{

        getTokenFromTwilio(
          setTwilioAccessTokenAction,
          "_Confrence_" + callFromName
        );
      }
      
    // }
  }, []);

  var startOfToday = new Date();
   startOfToday.setHours(0, 0, 0, 0);
 
   var endOfToday = new Date();
   endOfToday.setHours(23, 59, 59, 999);
 
 
   const queryConstraints = [];
   queryConstraints.push(where("timeStamp", ">=", startOfToday));
   queryConstraints.push(where("timeStamp", "<=", endOfToday));
  //  queryConstraints.push(where("callStatus", "==", "initiated"));
   const q = query(
     collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
     ...queryConstraints
   );
   const diff_minutes = (currentTime, formTime) => {
    const dt2 = new Date(currentTime);
    const dt1 = new Date(formTime);
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };


   const checkAudioVideoCall  = () =>{
    onSnapshot(q, (querySnapshot) => {
      let forms = [];
    
     // if(user){
        querySnapshot.forEach(async(docu) => {
          const id = docu.id;
          const data = docu.data();
          data["id"] = docu.id;
          forms.push({ ...docu.data(), id: docu.id });
          
          setCallStatus(data.callStatus);
          const loggedInUser = props?.userInfo?.email? props?.userInfo?.email : localStorage.getItem("userEmail");
           //console.log(loggedInUser, data.isReceived)
          

          

           if (data.callStatus === 'cancelled' && data.callType === "group" && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || data.callGroupEmails.includes(loggedInUser))) {
            // console.log(loggedInUser, data.callStatus,ROOMID)
              stopSound();
              window.close();
             
           
           }

          if (data.callStatus === 'completed' && data.callType === "private" && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || loggedInUser === data.callToEmail)) {
           // console.log(loggedInUser, data.callStatus,ROOMID)
            stopSound();
            
           window.close();
           
          }

          if (data.callStatus === 'completed' && data.callType === "group" && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || data.callGroupEmails.includes(loggedInUser))) {
            // console.log(loggedInUser, data.callStatus,ROOMID)
            //  stopSound();
            //  window.close();
             
             data.participants.forEach((participant)=>{
                if(participant.identity === loggedInUser && participant.status === "leaved"){

                   stopSound();
                 window.close();
                  
                }
                
              })

              
            
           }


          if (data.callStatus === 'no-response' && data.callType === "private" && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || loggedInUser === data.callToEmail)) {
            //console.log(loggedInUser, data.callStatus,ROOMID)
            stopSound();
            
           window.close();
           
          }

          if (data.callStatus === 'no-response' && data.callType === "group" && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || data.callGroupEmails.includes(loggedInUser))) {
            //console.log(loggedInUser, data.callStatus,ROOMID)
            stopSound();
            
           window.close();
           
          }

          if (data.callStatus === 'rejected' && data.callType === "private"  && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || loggedInUser === data.callToEmail)) {
            //console.log(loggedInUser, data.callStatus,ROOMID)
           
            stopSound();
           window.close();
           
          }

          if (data.callStatus === 'rejected' && data.callType === "group"  && data.roomId ===ROOMID &&  (loggedInUser === data.callFromEmail || data.callGroupEmails.includes(loggedInUser))) {
            //console.log(loggedInUser, data.callStatus,ROOMID)
           
            stopSound();
           window.close();
           
          }

      
           
          if (data.callStatus === 'in-progress' && data.callType === "private" && data.roomId ===ROOMID && (loggedInUser === data.callFromEmail || loggedInUser === data.callToEmail)) {
            setIsCallPickedByUser(true)
            stopSound();
           
          } 

          if (data.callStatus === 'in-progress' && data.callType === "group" && data.roomId ===ROOMID && (data.callGroupEmails.includes(loggedInUser) || loggedInUser === data.callFromEmail)) {
            setIsCallPickedByUser(data.isCallPickedByUser);
            // stopSound();
            if(data.joinedMembers){

            
            const joinedMemberCount = data.joinedMembers.length;

            let leaveCount =0;
            data.participants.forEach(async(participant)=>{
              if(participant.status === "leaved"){
                leaveCount +=1;
              }

              if(participant.identity === loggedInUser && participant.status === "leaved"){
                // const updatedParticipant = data.joinedMembers.filter(item => item !== participant.identity);
                //   console.log(updatedParticipant)
                //   const formRef2 = doc(
                //     database,
                //     environment.REACT_APP_FIREBASE_DB_CALLS,
                //     data.docId
                //   );
                //   updateDoc(formRef2, {
                //     joinedMembers: updatedParticipant
                                       
                
                //   })
                //     .then(() => {
                //       console.log("participant updated after leaved");
                //     })
                //     .catch((error) => {
                //       console.log(error);
                //     });
                stopSound();
                window.close();
              }

              if(participant.identity === loggedInUser && participant.status === "rejected"){
                stopSound();
                document.getElementById("audiVideoCall").classList.remove("d-none");
              }
              

              if(participant.identity === loggedInUser && participant.status === "initiated" && participant.identity !== data.callFromEmail){
              
                const openCallModal = document.getElementById("audiVideoCall");
                if(openCallModal){
                  openCallModal.classList.remove("d-none");
                }
                
              }
              if(participant.identity === loggedInUser && participant.status === "joined" && participant.identity !== data.callFromEmail){
                stopSound();
                const openCallModal = document.getElementById("audiVideoCall");
                if(openCallModal){
                document.getElementById("audiVideoCall").classList.add("d-none");
                }
               
              }
           })

           if(leaveCount > 0 && joinedMemberCount - leaveCount < 2 ){
            stopSound();
            await callAxios(environment.REACT_APP_API_URL_Live + `api/v1/video-media/one-to-one-disconnect`, {roomId:data.roomId,identity:loggedInUser,callType:"private"}, "post");
            window.close();
          }  
         
           console.log("joinedMemberCount:",joinedMemberCount,"--LeavedCount--",leaveCount)
          }
          } 

         

         
         

          
          
          
          
        });
        setAllDate(forms);
       
      //}
     
    
    });
   }
   
   //console.log()

   useEffect(()=>{
    
       checkAudioVideoCall();

    //console.log("calling useEffect!!")
    
    
   },[])

   const stopSound = () =>{
    audio.pause();
   
     
    }
   ////console.log("allCalls from Call.js::::", allCalls)
   document.addEventListener('visibilitychange', function() {
    if(document.hidden)
        {
            // console.log("In another tab chat")
            setIsOnScreen(false)

       
        }
    else
        {
            // console.log("In chat tab screen")
            setIsOnScreen(true)
               
        }
});

document.addEventListener('load', function() {
  
          // console.log("In chat tab screen")
          setIsOnScreen(true)
             
});

useEffect(()=>{
    // console.log("in user status update useEffect--->",isOnScreen)
    update_user_status(loggedInUser,isOnScreen)
},[isOnScreen])



  return (
    <>
      <VideoSectionPrivate comingFrom={callType} isCam={false} camera={camera} isMicMuted={isMicMuted} isRejoin={isRejoin} roomId={ROOMID} docId={docId} user={props?.userInfo} callType={callType} isTryingToJoining={isTryingToJoining}/>
     
      {/* Main Video Section (When Shared Screen) */}
      <div id="main" className="room_container main-section p-lg-3 overflow-hidden">
        <div id="shared_screen" className="shared_screen_preview d-flex flex-wrap w-100 h-100 overflow-hidden position-relative border border-1 border-info hide"></div>
        
       
        <div id="videos_portal" className="d-none when-normal d-flex flex-wrap h-100 overflow-auto position-relative"></div>
       
        {!isCallPickedByUser?
        <div id="firstCalling" className="when-calling d-flex align-items-center justify-content-center h-100 border border-secondary border-opacity-25 position-absolute top-0 start-0 end-0 bottom-0 z-5 bg-dark" style={{zIndex:111}}>
          <div className="caller-wrapper text-center mx-auto">
            <div className="chip-img bg-dark d-flex align-items-center justify-content-center rounded-circle border border-secondary overflow-hidden mx-auto">
              {/* <div className='user-shortname display-4 fw-medium text-secondary text-capitalize'>VA</div> */}
              <img src={require('../resources/images/Avatar.png')} className="img-fluid w-100 h-100 object-fit-cover" alt="Caller" />
            </div>
            { callType==="group"?
            <div className="caller-name text-white text-truncate mt-3">{"Confrence call "+callToName +" "}Calling...</div>
            :
<div className="caller-name text-white text-truncate mt-3">Calling...</div>
            }
          </div>
          <div className="receiver-wrapper position-absolute end-0 bottom-0">
            <div className="chip-img bg-dark d-flex align-items-center justify-content-center rounded-circle border border border-secondary overflow-hidden mx-auto">
              <div className='user-shortname fs-4 text-capitalize'>{fromShortName}</div>
              {/* <img src={require('../resources/images/Avatar.png')} className="img-fluid w-100 h-100 object-fit-cover" alt="Caller" /> */}
            </div>
          </div>
        </div>
        :null}
      </div>

      {/* Chat Sidenav */}
      <div id="chatSidenav" className="sidebar border-start border-black">
        <ChatSection />
        {showOverlay && <Overlay />}
      </div>

      {/* Participants Sidenav */}
      <div id="partcipantSidenav" className="sidebar border-start border-black">
        <ParticipantsSection />
      </div>

      {/* Participants Sidenav */}
      {/* <div
        id="LeadDetailsSidenav"
        className="sidebar border-start border-black"
      >
        <LeadDetailsSection />
      </div> */}

      {/* Background Setting Sidenav */}
      <div id="BgSettingSidenav" className="sidebar border-start border-black">
        <BackgroundSetting comingFrom={true} />
      </div>

      {/* <CallOnMobile formId={formId} audio={audio}/> */}

      {/* Rejoin Modal */}

      {/* <RejoinRoom roomId={roomId}  identity={identity} twilioAccessToken={props.twilioAccessToken} showOverlay={showOverlay} formId={formId} camera={camera} isMicMuted = {isMicMuted} isRejoin={isRejoin} /> */}
    </>
  );
}
const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapActionsToProps = (dispatch) => {
  return {
    setTwilioAccessTokenAction: (token) =>
      dispatch(setTwilioAccessToken(token)),
  };
};

export default connect(mapStoreStateToProps, mapActionsToProps)(Call);
