import React, { useEffect } from "react";
import { useState } from "react";

// import preloader css
import "../LeadsListingPage/LeadsListingPage.css";

import * as docx from "docx-preview";

const DocPreview = ({url, blobData, type}) => {
  // console.log("preview url::", url,blobData, type)
   const closePreview_ = () =>{
    document.getElementById("DocumentPreviewDocxModal").style.display= "none";
   }

   const container = document.querySelector("#container-preview");
   docx.renderAsync(blobData, container);
    return (
        <>
        
            <div
        className="modal fade"
        id="DocumentPreviewDocxModal"
        tabIndex={-1}
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
          <div className="modal-content text-body">
            <div className="modal-header bg-primary bg-gradient text-white px-4">
              <h1 className="modal-title fs-6" id="exampleModalLabel">
                Preview
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ filter: "invert(100)" }}
                onClick={closePreview_}
              ></button>
            </div>
            <div className="modal-body px-4">
           
            
            <div id="container-preview" style={{ height: "600px", overflowY: "auto" }} />
            
            </div>

            

            <div className="modal-footer px-4">
            
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
 export default DocPreview;

