import {
  collection,
  doc,
  documentId,
  runTransaction,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  LocalAudioTrack,
  LocalVideoTrack,
  createLocalVideoTrack,
} from "twilio-video";
import { database } from "../firebaseConfig";
import { environment } from "../environment";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

const videoConstraints = {
  audio: true,
  video: {
    width: 1280,
    height: 720,
  },
};

export const JoinNowPopup = (props) => {
  const roomId = useSelector((state) => state.roomId);
  const formID = useSelector((state) => state.formId);
  //console.log(roomId,formID)
  let navigate = useNavigate();
  const [isCameraOn, setCamera] = useState(true);
  const [button, setButton] = useState("");
  const [isMicMuted, setIsMuted] = useState(true);
  const [devices, setDevicePermission] = useState();
  const [bdmRecord, setBdmRecord] = useState({});
  const isFirefox = typeof InstallTrigger !== "undefined";

  if (!isFirefox) {
    navigator.permissions.query({ name: "camera" }).then((deviceStatus) => {
      const allowed = deviceStatus.state === "granted";
      if (allowed) {
        setDevicePermission(true);
      } else {
        setDevicePermission(false);
      }
    });
  }

  //const microphone_perm = await navigator.permissions.query( { name: 'microphone' } );

  const [isLocalVideoTrackDisabled, setIsLocalVideoTrackDisabled] =
    useState(false);

  let roomInfoObj = {};
  const [roomDetails, setRoomDetails] = useState({});
  const [isBdm, setIsBdm] = useState(false);
  const [isCam, setIsCam] = useState(false);

  useEffect(() => {
    const testCamera2 = async () => {
      if (!isFirefox) {
        const camera_perm = await navigator.permissions.query({
          name: "camera",
        });

        const microphone_perm = await navigator.permissions.query({
          name: "microphone",
        });

        camera_perm.onchange = (evt) => {
          const allowed = camera_perm.state === "granted";
          if (allowed) {
            setDevicePermission(true);
          } else {
            setDevicePermission(false);
          }
        };

        microphone_perm.onchange = (evt) => {
          const allowed = microphone_perm.state === "granted";
          if (allowed) {
            setDevicePermission(true);
          } else {
            setDevicePermission(false);
          }
        };
      }
    };
    testCamera2();
  }, [devices]);
  //console.log(props.isGoLive)
  useEffect(() => {
    if (props.isGoLive) {
      const updateCamJoined = async (formID) => {
        //console.log("in updating functipn::", formID);
        const myDocRef = doc(
          database,
          environment.REACT_APP_FIREBASE_DB,
          formID
        );
        const myDoc = await getDoc(myDocRef);
        if (myDoc.exists()) {
          //console.log("isExist::", myDoc.data());
          setIsBdm(myDoc.data()?.bdmJoinedAt ? true : false);
          setIsCam(myDoc.data()?.camJoinedAt ? true : false);
          if (props.to === "room") {
            if (myDoc.data()?.formstatus == 1 && myDoc.data().callStatus != 1) {
              setButton("cam_join");
            } else if (myDoc.data().callStatus == 1) {
              setButton("cam_joined");
            } else {
              setButton("cam_missed");
            }
          } else {
            if (
              myDoc.data()?.callStatus == 1 &&
              !myDoc.data().bdmJoinedAt &&
              !myDoc.data().camLeftAt &&
              myDoc.data().description
            ) {
              setButton("bdm_join");
            } else if (myDoc.data().bdmCallStatus == 1) {
              setButton("bdm_joined");
            } else {
              setButton("bdm_missed");
            }
          }
        } else {
          return false;
        }
      };
      updateCamJoined(formID);
    }

    if (props.isGoLive) {
      const joinNowBtn = document.getElementById("joinNowBtn");
      const CameraIconColor = document.getElementById("CameraIconColor");
      const testDeviceButton = document.getElementById("testDeviceButton");
      const videoPreview = document.getElementById("video-preview");
      
      if (devices) {
        if (joinNowBtn) {
          joinNowBtn.classList.add("d-none");
        }

        if (CameraIconColor) {
          CameraIconColor.style.color = "red";
        }
        if (testDeviceButton) {
          testDeviceButton.classList.add("d-none");
        }
      } else {
        if (joinNowBtn) {
          joinNowBtn.classList.remove("d-none");
        }
        if (CameraIconColor) {
          CameraIconColor.style.color = "red";
        }
        if (testDeviceButton) {
          testDeviceButton.classList.add("d-none");
        }
        if (videoPreview) {
          videoPreview.innerHTML =
            '<span style="color:red;"><b>Unable to Access Media:</b></span> You have blocked your camera or microphone for this App. Please allow camera and microphone for this app before join!!';
        }
      }
      const getLocalTrack = async () => {
        // new logic
        const joinNowBtn = document.getElementById("joinNowBtn");
        const CameraIconColor = document.getElementById("CameraIconColor");
        const DeivceErrorMessage = document.getElementById("DeivceErrorMessage");
  
        if (joinNowBtn) {
          joinNowBtn.classList.remove("d-none");
        }
        if (CameraIconColor) {
          CameraIconColor.style.color = "green";
        }
        if (DeivceErrorMessage) {
          DeivceErrorMessage.innerHTML = "";
        }
  
        navigator.mediaDevices
          .getUserMedia(videoConstraints)
          .then(async (stream) => {
            // create data track for messages
            window.localstream = stream;
            new LocalAudioTrack(stream.getAudioTracks()[0]);
  
            trackInfo = new LocalVideoTrack(stream.getVideoTracks()[0]);
            const localMediaContainer = document.getElementById("video-preview");
            localMediaContainer.innerHTML = "";
            if (isLocalVideoTrackDisabled === false) {
              localMediaContainer.appendChild(trackInfo.attach());
            } else {
              const profileDiv =
                '<div class="audio-user-wrapper d-flex align-items-center justify-content-center w-100 h-100"> <img src=' +
                require("../resources/images/Avatar.png") +
                ' class="img-fluid border rounded-circle" style="width:70px; height:70px" alt="Mobile User" /></div>';
              localMediaContainer.innerHTML = profileDiv;
            }
  
            const stopButton = document.querySelector('#ClosePreview');
            stopButton.addEventListener('click', function() {
              //console.log("in stop devices")
              stream.getTracks().forEach(track => {
                if (track.kind === 'video') {
                  track.stop();
                }
                if (track.kind === 'audio') {
                  track.stop();
                }
              });
            });
          })
          .catch((error) => {
            //console.log(error.name)
            const joinNowBtn = document.getElementById("joinNowBtn");
            const CameraIconColor = document.getElementById("CameraIconColor");
            const testDeviceButton = document.getElementById("testDeviceButton");
            const videoPreview = document.getElementById("video-preview");
            if (error.name === "NotReadableError") {
              if (joinNowBtn) {
                joinNowBtn.classList.add("d-none");
              }
              if (CameraIconColor) {
                CameraIconColor.style.color = "orange";
              }
              if (testDeviceButton) {
                testDeviceButton.classList.remove("d-none");
              }
  
              if (videoPreview) {
                videoPreview.innerHTML =
                  '<span style="color:orange;"><b>Unable to Access Media:</b></span> Your camera device is occupied by another app. Please close that app first before join!!';
              }
            }
  
            if (error.name === "NotAllowedError") {
              if (joinNowBtn) {
                joinNowBtn.classList.add("d-none");
              }
              if (CameraIconColor) {
                CameraIconColor.style.color = "red";
              }
              if (testDeviceButton) {
                testDeviceButton.classList.remove("d-none");
              }
              if (videoPreview) {
                videoPreview.innerHTML =
                  '<span style="color:red;"><b>Unable to Access Media:</b></span> You have blocked your camera or microphone for this App. Please allow camera and microphone for this app before join!!';
              }
            }
  
            if (error.name === "NotFoundError") {
              if (joinNowBtn) {
                joinNowBtn.classList.add("d-none");
              }
              if (CameraIconColor) {
                CameraIconColor.style.color = "yellow";
              }
              if (testDeviceButton) {
                testDeviceButton.classList.remove("d-none");
              }
              if (videoPreview) {
                videoPreview.innerHTML =
                  '<span style="color:yellow;"><b>Unable to Access Media:</b></span> Your camera or microphone may not be connected properly. Please connect your camera and microphone before join!!';
              }
            }
          });
      };
      trackInfo = getLocalTrack();
    }
  }, [props?.data, formID]);

  //console.log(button, formID,isCam,isBdm);
  let trackInfo;
  

  const handleCameraButtonPressed = () => {
    isLocalVideoTrackDisabled ? startVideo() : stopVideo();
    setIsLocalVideoTrackDisabled(!isLocalVideoTrackDisabled);
  };

  const startVideo = () => {
    setCamera(true);
    testCamera();
  };

  const stopVideo = () => {
    setCamera(false);
    testCamera();
  };

  const handleMicButtonPressed = () => {
    isMicMuted ? unmute() : mute();
    setIsMuted(!isMicMuted);
  };

  const mute = () => {
    setIsMuted(true);
    testCamera();
  };

  const unmute = () => {
    setIsMuted(false);
    testCamera();
  };

  // const checkBdm = async (formID, transaction) => {
  //   const myDocRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
  //   const myDoc = await transaction.get(myDocRef);
  //   // const myDoc = await getDoc(myDocRef);
  //   if (myDoc.exists()) {
  //     // setBdmRecord(myDoc.data()?.attendantBdmEmail);
  //     //console.log("Document data:", myDoc.data());
  //     return myDoc.data()?.bdmJoinedAt ? true : false;
  //   } else {
  //     return false;
  //   }
  // };

  // const checkCam = async (formID, transaction) => {
  //   const myDocRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
  //   const myDoc = await transaction.get(myDocRef);
  //   //const myDoc = await getDoc(myDocRef);
  //  // console.log("In cam trasaction::", myDoc.data());
  //   if (myDoc.exists()) {
  //     // setBdmRecord(myDoc.data()?.attendantBdmEmail);
  //     //console.log("Document data:", myDoc.data());
  //     return myDoc.data()?.camJoinedAt ? true : false;
  //   } else {
  //     return false;
  //   }
  // };

  const checkBdm = async (formID) => {
    const myDocRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {
      // console.log("Document data:", myDoc.data());
      return myDoc.data()?.bdmJoinedAt ? true : false;
    } else {
      return false;
    }
  };

  const checkCam = async (formID) => {
    const myDocRef = doc(database, environment.REACT_APP_FIREBASE_DB, formID);
    const myDoc = await getDoc(myDocRef);
    //const myDoc = await getDoc(myDocRef);
    // console.log("In cam trasaction::", myDoc.data());
    if (myDoc.exists()) {
      return myDoc.data()?.camJoinedAt ? true : false;
    } else {
      return false;
    }
  };

  // const updateIsCallOnProgress = async (email) => {
  //   console.log("userEmal::",email);
  //   const userRef = collection(database, environment.REACT_APP_USERS_DB);
  //   // const docRef = doc(database, environment.REACT_APP_USERS_DB, email);
  //   const q = query(userRef, where("email", "==", email));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     if (doc.data()) {
  //     // updateDoc(docRef, {
  //     //   isDeleted: 0,
  //     //   restoredBy: user?.email,
  //     //   deletedBy: "",
  //     //   deletedAt: "",
  //     //   userType: docSnap.data().type,
  //     // })
  //     //   .then(() => {
  //     //     toast.success("Member restored successfully!", {
  //     //       position: "top-right",
  //     //       autoClose: 3000,
  //     //       hideProgressBar: false,
  //     //       closeOnClick: true,
  //     //       pauseOnHover: true,
  //     //       draggable: true,
  //     //       progress: undefined,
  //     //       theme: "dark",
  //     //     });
  //     //     setDeleteUserID("");
  //     //   })
  //     //   .catch((error) => {
  //     //     setDeleteUserID("");
  //     //   });
  //     console.log(doc.data());
  //   }
    
  //   });
    
  // };
  const handleJoinNow = async () => {
   // updateIsCallOnProgress(props?.user?.email);
     
    const closePreview = document.getElementById("ClosePreview");

    if (closePreview) {
      closePreview.click();
    }

    // document.getElementById("joining-room").innerHTML = ' <button type="button" class="btn btn-primary bg-gradient rounded-1 px-4 py-2  me-2" >Joining...</button>'
    // document.getElementById("ClosePreview").click();
    if (props?.to === "room") {
      // navigate("/" + props.to, {
      //   state: {
      //     id: 0,
      //     camera: isCameraOn,
      //     isMicMuted: isMicMuted,
      //     isRejoin: false,
      //   },
      // });

      const stateData =  {
        id: 0,
        camera: isCameraOn,
        isMicMuted: isMicMuted,
        isRejoin: false,
        roomId:roomId,
        formID:formID,
        identity:props?.user?.displayName,
        userRole: props?.userRole,
        uid: props?.user?.uid,
        email: props?.user?.email,
        displayName: props?.user?.displayName,
        accessToken: props?.user?.accessToken,
        idToken: props?.user?.idToken,
      }
      const stateQueryString = JSON.stringify(stateData);
                  
      const url = `${props.to}?id=${btoa(stateQueryString)}`;
                  
      window.open(url, '_blank');

      const isCamExist = await checkCam(formID);
      setIsBdm(isCamExist);
      if (!isCamExist) {
        localStorage.setItem("camJoinedLead", true);
        setDoc(doc(database, "playerstreamer", formID), {
          playerStreamerID: "",
          roomId: roomId,
          attendantEmail: props?.user?.email,
          isCamJoined: true,
          timeStamp: serverTimestamp(),
        })
          .then(() => {
            console.log("Data Sent");
          })
          .catch((err) => {
            console.log(err);
          });
        const formRef = doc(
          database,
          environment.REACT_APP_FIREBASE_DB,
          formID
        );
        await updateDoc(formRef, {
          playerStreamerID: "",
          roomId: roomId,
          callStatus: 1,
          bdmCallStatus: 0,
          isCamRejoined: false,
          attendantName: props?.user?.displayName,
          attendantEmail: props?.user?.email,
          streamerStatus: 1,
          isMicMuted: true,
          isFirstTimeMute: true,
          isCameraOn: isCameraOn,
          camJoinedAt: serverTimestamp(),
        });
        axios
          .post(
            environment.REACT_APP_API_URL_Live +
              "api/v1/calls/record-calls-logs",
            {
              email: props?.user?.email,
              joinedCount: 1,
              type: "joined",
              roomId: roomId,
            }
          )
          .then(function (response) {
            //console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setButton("cam_join");
        toast.error("Sorry, CAM already joined on this call", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      //   document.getElementById("ClosePreview").click();
      //   navigate("/"+props.to, { state: { id: 0, camera: isCameraOn, isMicMuted:isMicMuted, isRejoin: false } });
    } else {
      // navigate("/" + props.to, {
      //   state: {
      //     id: 0,
      //     camera: isCameraOn,
      //     isMicMuted: isMicMuted,
      //     isRejoin: false,
      //   },
      // });

      const stateData =  {
        id: 0,
        camera: isCameraOn,
        isMicMuted: isMicMuted,
        isRejoin: false,
        roomId:roomId,
        formID:formID,
        identity:props?.user?.displayName,
        userRole: props?.userRole,
        uid: props?.user?.uid,
        email: props?.user?.email,
        displayName: props?.user?.displayName,
        accessToken: props?.user?.accessToken,
        idToken: props?.user?.idToken,
      }
      const stateQueryString = JSON.stringify(stateData);
                  
      const url = `${props.to}?id=${btoa(stateQueryString)}`;
                  
      window.open(url, '_blank');
      const isBdmExist = await checkBdm(formID);
      setIsBdm(isBdmExist);
      if (!isBdmExist) {
        localStorage.setItem("bdmJoinedLead", true);
        setDoc(doc(database, "playerstreamer", formID), {
          playerStreamerID: "",
          roomId: roomId,
          isBdmJoined: true,
          timeStamp: serverTimestamp(),
        })
          .then(() => {
            //console.log("Data Sent");
          })
          .catch((err) => {
            console.log(err);
          });
        const formRef = doc(
          database,
          environment.REACT_APP_FIREBASE_DB,
          formID
        );
        await updateDoc(formRef, {
          playerStreamerID: "",
          roomId: roomId,
          bdmCallStatus: 1,
          isBdmRejoined: false,
          attendantBdmName: props?.user?.displayName,
          attendantBdmEmail: props?.user?.email,
          streamerStatus: 1,
          isMicMutedBDM: true,
          isFirstTimeMuteBDM: true,
          isCameraOnBDM: isCameraOn,
          bdmJoinedAt: serverTimestamp(),
        });
        axios
          .post(
            environment.REACT_APP_API_URL_Live +
              "api/v1/calls/record-calls-logs",
            {
              email: props?.user?.email,
              joinedCount: 1,
              type: "joined",
              roomId: roomId,
            }
          )
          .then(function (response) {
            //console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        // navigate("/"+props.to, { state: { id: 0, camera: isCameraOn, isMicMuted:isMicMuted, isRejoin: false } });
      } else {
        setButton("bdm_join");
        toast.error("Sorry, BDM already joined on this call", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  };

  const testCamera = async () => {
    const joinNowBtn = document.getElementById("joinNowBtn");
    const CameraIconColor = document.getElementById("CameraIconColor");
    if (joinNowBtn) {
      joinNowBtn.classList.remove("d-none");
    }
    if (CameraIconColor) {
      CameraIconColor.style.color = "green";
    }

    navigator.mediaDevices
      .getUserMedia(videoConstraints)
      .then(async (stream) => {
        // create data track for messages
        new LocalAudioTrack(stream.getAudioTracks()[0]);

        trackInfo = new LocalVideoTrack(stream.getVideoTracks()[0]);
        const localMediaContainer = document.getElementById("video-preview");
        localMediaContainer.innerHTML = "";
        if (isLocalVideoTrackDisabled === false) {
          localMediaContainer.appendChild(trackInfo.attach());
        } else {
          const profileDiv =
            '<div class="audio-user-wrapper d-flex align-items-center justify-content-center w-100 h-100"> <img src=' +
            require("../resources/images/Avatar.png") +
            ' class="img-fluid border rounded-circle" style="width:70px; height:70px" alt="Mobile User" /></div>';
          localMediaContainer.innerHTML = profileDiv;
        }
        document.getElementById("testDeviceButton").classList.add("d-none");
        const stopButton = document.querySelector('#ClosePreview');
          stopButton.addEventListener('click', function() {
            console.log("in stop devices")
            stream.getTracks().forEach(track => {
              if (track.kind === 'video') {
                track.stop();
              }
              if (track.kind === 'audio') {
                track.stop();
              }
            });
          });
      })
      .catch((error) => {
        // console.log(error.name)
        const joinNowBtn = document.getElementById("joinNowBtn");
        const CameraIconColor = document.getElementById("CameraIconColor");
        const videoPreview = document.getElementById("video-preview");
        const testDeviceButton = document.getElementById("testDeviceButton");

        if (error.name === "NotReadableError") {
          if (joinNowBtn) {
            joinNowBtn.classList.add("d-none");
          }
          if (CameraIconColor) {
            CameraIconColor.style.color = "orange";
          }

          if (testDeviceButton) {
            testDeviceButton.classList.remove("d-none");
          }
          if (videoPreview) {
            videoPreview.innerHTML =
              '<span style="color:orange;"><b>Unable to Access Media:</b></span> Your camera device is occupied by another app. Please close that app first before join!!';
          }
        }

        if (error.name === "NotAllowedError") {
          if (joinNowBtn) {
            joinNowBtn.classList.add("d-none");
          }
          if (CameraIconColor) {
            CameraIconColor.style.color = "red";
          }

          if (testDeviceButton) {
            testDeviceButton.classList.remove("d-none");
          }
          if (videoPreview) {
            videoPreview.innerHTML =
              '<span style="color:red;"><b>Unable to Access Media:</b></span> You have blocked your camera or microphone for this App. Please allow camera and microphone for this app before join!!';
          }
        }

        if (error.name === "NotFoundError") {
          if (joinNowBtn) {
            joinNowBtn.classList.add("d-none");
          }
          if (CameraIconColor) {
            CameraIconColor.style.color = "yellow";
          }

          if (testDeviceButton) {
            testDeviceButton.classList.remove("d-none");
          }
          if (videoPreview) {
            videoPreview.innerHTML =
              '<span style="color:yellow;"><b>Unable to Access Media:</b></span> Your camera or microphone may not be connected properly. Please connect your camera before join!!';
          }
        }
      });
 
  };
  const stopCamera = () => {
    //console.log("in camera off",trackInfo)
    // navigator.getUserMedia({audio: false, video: true},
    //   function(stream) {
    //           // can also use getAudioTracks() or getVideoTracks()
    //       var track = stream.getTracks()[0];  // if only one media track
    //       // ...
    //       track.stop();
    //   },
    //   function(error){
    //       console.log('getUserMedia() error', error);
    //   });
  };

  return (
    <div className="join-call-model">
      <ToastContainer />
      {/* Modal */}
      <div
        className="modal fade"
        id="joinCall"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          style={{ maxWidth: "650px" }}
        >
          <div className="modal-content bg-dark border-top border-5 border-primary rounded-0 shadow">
            <div className="modal-body p-4 p-md-5 text-center">
              <div
                className="fs-5 fw-semibold mb-4"
                id="DeivceErrorMessage"
              ></div>
              <div className="fs-5 fw-semibold mb-4">
                Choose your video & audio option
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="video-sec position-relative h-100 border border-1 border-secondary">
                    <div id="video-preview"></div>

                    <div className="user-info small position-absolute bottom-0 start-0 text-capitalize">
                      {isMicMuted ? (
                        <i className="bi bi-mic-mute-fill me-1"></i>
                      ) : (
                        <i className="bi bi-mic-fill me-1"></i>
                      )}
                      {props?.user?.displayName}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-3 mt-md-0">
                  <div className="action-sec h-100">
                    <button
                      className="btn btn-outline-light opacity-75 rounded-0 w-100 py-2"
                      onClick={handleMicButtonPressed}
                    >
                      {isMicMuted ? (
                        <i className="bi bi-mic-mute-fill"></i>
                      ) : (
                        <i className="bi bi-mic-fill"></i>
                      )}
                      {isMicMuted ? "Muted" : "Mute"}{" "}
                    </button>
                    <button
                      className="btn btn-outline-light opacity-75 rounded-0 w-100 py-2 mt-3"
                      onClick={handleCameraButtonPressed}
                    >
                      {isLocalVideoTrackDisabled ? (
                        <i
                          className="bi bi-camera-video-off"
                          id="CameraIconColor"
                        ></i>
                      ) : (
                        <i
                          className="bi bi-camera-video"
                          id="CameraIconColor"
                        ></i>
                      )}{" "}
                      {isLocalVideoTrackDisabled ? "Start" : "Stop"} Video
                    </button>
                    {/* <div className="dropdown">
                                            <button className="btn btn-outline-light opacity-75 rounded-0 w-100 py-2 mt-3" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i className="bi bi-gear"></i> Settings
                                            </button>
                                            <ul className="dropdown-menu">
                                                <li><a className="dropdown-item" href="#">About</a></li>
                                                <li><a className="dropdown-item" href="#">Audio and Video Settings</a></li>
                                                <li><a className="dropdown-item" href="#">Connection Setting</a></li>
                                            </ul>
                                        </div> */}
                  </div>
                </div>
              </div>
              <div
                className="btn-wrapper border-1 border-top pt-4 mt-4"
                id="disabledDiv"
              >
                <button
                  type="button"
                  className="btn btn-secondary bg-gradient rounded-1 px-4 py-2 me-2"
                  id="ClosePreview"
                  data-bs-dismiss="modal"
                  // onClick={stopCamera}
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-success bg-gradient rounded-1 px-4 py-2 d-none"
                  id="testDeviceButton"
                  onClick={testCamera}
                >
                  Check Camera
                </button>

                {isBdm && props.to === "bdmroom" ? (
                  <button
                    type="button"
                    className="btn btn-warning bg-gradient rounded-1 px-4 py-2  me-2"
                    id="joinNowBtn"
                  >
                    BDM Joined
                  </button>
                ) : isCam && props.to === "room" ? (
                  <button
                    type="button"
                    className="btn btn-warning bg-gradient rounded-1 px-4 py-2  me-2"
                    id="joinNowBtn"
                  >
                    CAM Joined
                  </button>
                ) : button === "cam_missed" && props.to === "room" ? (
                  <button
                    type="button"
                    className="btn btn-danger bg-gradient rounded-1 px-4 py-2  me-2"
                    id="joinNowBtn"
                  >
                    You missed
                  </button>
                ) : button === "bdm_missed" && props.to === "bdmroom" ? (
                  <button
                    type="button"
                    className="btn btn-danger bg-gradient rounded-1 px-4 py-2  me-2"
                    id="joinNowBtn"
                  >
                    You missed
                  </button>
                ) : (
                  // button === 'cam_join' && props.to ==="room"?
                  // <button type="button" className="btn btn-primary bg-gradient rounded-1 px-4 py-2  me-2" id='joinNowBtn' onClick={handleJoinNow} >Join Now</button>

                  // :
                  // button === 'bdm_join' && props.to ==="bdmroom"?
                  // <button type="button" className="btn btn-primary bg-gradient rounded-1 px-4 py-2  me-2" id='joinNowBtn' onClick={handleJoinNow} >Join Now</button>

                  // :
                  <button
                    type="button"
                    className="btn btn-primary bg-gradient rounded-1 px-4 py-2  me-2"
                    id="joinNowBtn"
                    onClick={handleJoinNow}
                  >
                    Join Now
                  </button>
                )}
              </div>
              <div
                className="btn-wrapper border-1 mt-2 "
                id="view-button-area-join-now"
              >
                <div class="loader-joining"></div> Joining Room please wait
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
    </div>
  );
};
